import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import { useHistory } from 'react-router-dom';
import { fetchTeamList } from '@actions';
import { withTheme } from 'styled-components';
import { NAV_SECTIONS, CHALLENGE_TABLE_FILTERS } from '@global';
import ChallengeTable from '@challenges/ChallengeTable';
import styles from '@review/challenges/ReviewChallenge.module.css';
import LoadingWrapper from '@components/LoadingWrapper';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { MuseoText, BasicButton, BottomTableLoader, SearchBar, FilterTabs } from '@components';
import { Input, Space } from 'antd';
import useOnScreen from '@components/useOnScreen';
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor';
import { getAPIRequest, getChallegesByWaitingForReview } from '@actions';

const { Search } = Input;

let _searchTimeout = null;

const ReviewPortalChallenges = ({
  adminUser,
  getAPIRequest,
  getChallegesByWaitingForReview,
  theme
}) => {

  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const history = useHistory();

  const [challenges, setChallenges] = useState([]);
  const [seenIds, setSeenIds] = useState([]);
  const [isMore, setIsMore] = useState(true);
  const isFetchingMoreFromBottom = useRef(false);
  const [hasFetchedChallenges, setHasFetchedChallenges] = useState(false);

  useEffect(() => {
    fetchChallenges();
  }, []);

  const onBottomLoadingVisible = (isVisible) => {
    if (isVisible) {
      if (!isFetchingMoreFromBottom.current && isMore) fetchChallenges();
    }
  }

  const fetchChallenges = () => {
    if (!isMore && isFetchingMoreFromBottom.current) return;
    isFetchingMoreFromBottom.current = true;

    getChallegesByWaitingForReview(
      challenges ? challenges.map((s) => s.id) : [],
      (newChallenges) => {
        setChallenges(challenges.concat(newChallenges));
        setSeenIds(seenIds.concat(newChallenges.map((c) => c.id)));
        setIsMore(newChallenges.length === 20);
        isFetchingMoreFromBottom.current = false;
        setHasFetchedChallenges(true);
      },
      () => {},
      true
    );
  };


  const performSearch = () => {
    const currentSearchTerm = searchTerm;
    if (currentSearchTerm.length > 0) {
      getAPIRequest(
        '/admin/search_challenges',
        { query: currentSearchTerm, is_for_review: true, is_portal: true },
        response => {
          if (currentSearchTerm === searchTerm) {
            setSearchResults(response.challenges);
          }
        },
        error => {
          //onFail(error.error);
        },
      );
    }
  }

  useEffect(() => {
    clearTimeout(_searchTimeout);
    if (searchTerm.length > 0) {
      _searchTimeout = setTimeout(() => performSearch(), 200);
    }
  }, [searchTerm]);

  const searchBar = () => {
    return (
      <div>
        <SearchBar
          searchTerm={searchTerm}
          onChange={(value) => setSearchTerm(value)}
        />
      </div>
    )
  }


  return (
    <PageWrapper>
      <FixedHeaderContainer
        text={'Review Portal Challenges'}
        paddingBuffer="80px"
        paddingTop="56px"
      >
        <div className={styles.review_challenges_home_container}>
          <BasicButton
            text="Review all"
            type="primary"
            style={{ width: "360px", height: "44px" }}
            onClick={() => {
              history.push({
                pathname: `/review_portal_challenge`,
              });
            }}
          />

          <div className={styles.or_search_text}>
            -- or --
          </div>
          <div className={styles.search_and_table}>
            {searchBar()}
            <ChallengeTable
              challengeList={searchTerm.length > 0 ? searchResults : challenges}
              onChallengeSelected={(challenge) => {
                history.push({
                  pathname: `/review_portal_challenge/${challenge.id}`,
                });
              }}
              marginTop="40px"
              isForWaiting
            />

            {isMore &&
              <VisibilitySensor onChange={onBottomLoadingVisible}>
                <BottomTableLoader />
              </VisibilitySensor>
            }
          </div>
        </div>
      </FixedHeaderContainer>
    </PageWrapper>
  )
}

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getAPIRequest,
  getChallegesByWaitingForReview
})(withTheme(ReviewPortalChallenges));
