import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Icon, Spin } from 'antd';
import { TitleText, BasicText, ShortInput, LongInput, CopyUrl, BasicButton } from '@components';
import styles from '@components/HeaderAndTable.module.css';

const ReportedEntryTable = ({
  submissionList,
  onSubmissionSelected,
  marginTop
}) => {

  const getTeamTableData = () => {
    let dataArray = [];
    submissionList.forEach((submission, i) => {
      dataArray.push({
        id: submission.id,
        username: submission.username,
        challenge_name: submission.challenge_name,
        submitted_on: submission.submitted_on,
        num_reports: submission.num_reports
      })
    });
    return dataArray;
  }

  const getColumns = () => {
    let columns = [
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Member Name</div>;
        },
        dataIndex: 'username',
        key: 'username',
        width: 120,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px" bold>{text}</BasicText>
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Challenge</div>;
        },
        dataIndex: 'challenge_name',
        key: 'challenge_name',
        width: 120,
        render(text, record) {
          return {
            children: <div style={{ display: "flex", alignItems: 'center', maxWidth: 150, overflow: 'hidden' }}>
                        <BasicText fontSize="15px" bold>{text}</BasicText>
                      </div>
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Submitted</div>;
        },
        dataIndex: 'submitted_on',
        key: 'submitted_on',
        width: 120,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px">{text}</BasicText>
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Reports</div>;
        },
        dataIndex: 'num_reports',
        key: 'num_reports',
        width: 120,
        render(text, record) {
          return {
            children: <BasicText fontSize="12px">{text}</BasicText>,
          };
        }
      }
    ];

    return columns;
  }

  return (
    <div
      className={styles.table_container_style}
      style={{ marginTop: marginTop ? marginTop : "30px" }}
    >
      <Table
        pagination={false}
        columns={getColumns()}
        dataSource={getTeamTableData()}
        rowKey="id"
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              onSubmissionSelected(record)
            }
          };
        }}
      />
    </div>
  )
}

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {

})(ReportedEntryTable);
