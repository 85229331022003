import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Form, Input } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, CustomButton, BasicButton, ShortInput, MuseoText } from '@components';
import '../App.css';
import './login.css'
import logo from '../images/leftAlignSingleWordmark_light.png';


const BrandLogin = ({
  handleSubmit,
  loading,
  error
}) => {
  const [password, setPassword] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const history = useHistory();

  const validateForm = () => {
    return password.length > 5;
  }

  useEffect(() => {
    setButtonDisabled(!validateForm());
  }, [password]);

  const onFormSubmit = () => {
    handleSubmit(password);
  }

  const loginForm = () => {
    return (
      <div className="login-container">
        <Form onFinish={onFormSubmit} initialValues={{ remember: true }}>
          <Form.Item>
            <div className="input_section">
              <ShortInput
                isPassword
                placeholder="password"
                onChange={(val) => setPassword(val)}
                value={password}
              />
            </div>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%", marginTop: "20px", height: "50px" }}
              disabled={buttonDisabled}
              loading={loading}
            >
                Submit
            </Button>
          </Form.Item>

          {error && <BasicText color="red">{error}</BasicText>}
        </Form>
      </div>
    );
  };

  return (
    <div style={{ justifyContent: "center", alignItems: "center", display: "flex", flexDirection: 'column', padding: 20}}>
      <img src={logo} alt="logo" style={{ width: "240px", marginTop: "50px", marginBottom: "36px" }} />
      <Card className="Login-card">
        <div style={{ flexDirection: "column", alignItems: "center", display: "flex", width: "100%" }}>
          <MuseoText>
            Live Stats Login
          </MuseoText>

          <div style={{ marginTop: "24px" }}>
            <BasicText>Enter the password provided by the Hyype Space Team</BasicText>
          </div>

          {loginForm()}
        </div>
      </Card>
    </div>
  );
};

// const mapStatesToProps = (state) => {
//   const { name, logged_in, login_error, force_email } = state.account;
//   const { invite_company_name } = state.company;
//   return { name, logged_in, login_error, force_email, invite_company_name };
// };

export default connect(null, {

})(BrandLogin);
