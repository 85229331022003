import React from 'react';
import ChallengeDetails from '@challenges/stats/ChallengeDetails';
import PodiumAndLeaderboard from '@challenges/stats/PodiumAndLeaderboard';
import PerformanceSection from '@challenges/stats/PerformanceSection';
import styles from './ChallengeStats.module.css';

const LiveStats = ({
  challenge
}) => {
  const hasData = challenge.has_submissions;

  return (
    <>
      <div className={styles.time_status}>
        {challenge.time_status}
      </div>

      <ChallengeDetails
        challenge={challenge}
      />

      <PodiumAndLeaderboard
        challenge={challenge}
        hasData={hasData}
      />

      <PerformanceSection
        challenge={challenge}
        hasData={hasData}
      />
    </>
  );
};

export default LiveStats;
