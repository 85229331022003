import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import RouterComponent from '@navigation/router';
import './App.css';
import {
  checkAuthorized
} from '@actions';

const AppContainer = (props) => {
  useEffect(() => {
    if (!props.has_check_authorized) {
      props.checkAuthorized();
    }
  }, []);

  const renderContent = () => {
    if (props.has_check_authorized) {
      return (
        <div style={{ height: "100%"}}>
            <div className="logged-in-page page-wrap">
              <RouterComponent />
            </div>
          </div>
      )
    } else {
      return <div> </div>;
    }
  }

  return (
    renderContent()
  )
}

const mapStatesToProps = (state) => {
  const { logged_in, has_check_authorized } = state.auth;
  return { logged_in, has_check_authorized };
};

export default connect(mapStatesToProps, {
  checkAuthorized
})(AppContainer);
