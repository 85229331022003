import React from 'react';
import styles from './ChallengeStats.module.css';
import { withTheme } from 'styled-components';

const LeaderboardItem = ({
  submission,
  theme
}) => {
  return (
    <div
      className={styles.leaderboard_item_container}
      onClick={() => window.open(submission.url.content, "_blank")}
    >
      <div className={styles.rank_username}>
        <div className={styles.leaderboard_rank}>
          {submission.current_rank}
        </div>
        <div className={styles.leaderboard_username}>
          {submission.user.username}
        </div>
      </div>
      <div className={styles.leaderboard_claps}>
        {submission.string_vote_count}
      </div>
    </div>
  );
}

export default withTheme(LeaderboardItem);
