import React from 'react';
import styles from './Navbar.css';
import { MuseoText } from '@components';

const NavCounter = ({
  number,
  showDanger
}) => {
  return (
    <div className={showDanger ? "danger_counter" : "nav_counter"}>
      {number}
    </div>
  );
};

export default NavCounter;
