import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DatePicker, Form, Space, TimePicker, Button } from 'antd';
import moment from 'moment-timezone';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, BasicButton, ShortInput, LongInput } from '@components';
import styles from './Create.module.css';

const PrizeDisplay = ({
  prize,
  index,
  onDelete,
  onEdit
}) => {
  return (
    <div key={index} className={styles.prize_container}>
      <div className={styles.image_and_content_container}>
        <div style={{ marginRight: "20px" }}>
          {prize.image_url ?
            <div
              style={{ cursor: "pointer" }}
              onClick={() => window.open(prize.image_url, "_blank")}
            >
              <img src={prize.image_url} width="80" height="80" style={{borderRadius: "12px"}}/>
            </div>
          :
            <div
              style={{ cursor: "pointer", borderRadius: "12px", width: "80px", height: "80px", backgroundColor: "#dedede", display: "flex", justifyContent: 'center', alignItems: 'center' }}
              onClick={() => onEdit()}
            >
              <BasicText fontSize="11px">No Image{"\n"}Selected</BasicText>
            </div>
          }
        </div>

        <div style={{ flex: 1 }}>
          <div className={styles.prize_top_container}>
            <div>
              <div>
                <BasicText useSpan fontSize="14px" bold>Rank: </BasicText>
                <BasicText useSpan fontSize="14px">{prize.final_rank}</BasicText>
              </div>
              <div style={{ marginTop: "3px"}}>
                <BasicText useSpan fontSize="14px" bold>Title: </BasicText>
                <BasicText useSpan fontSize="14px">{prize.title}</BasicText>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: "row" }}>
              <BasicButton
                type="primary"
                text="Edit"
                onClick={() => onEdit()}
              />
              <div style={{ marginLeft: "12px" }} />
              <BasicButton
                type="danger"
                text="Delete"
                onClick={() => onDelete()}
              />
            </div>
          </div>

          <div style={{ marginTop: "3px"}}>
            <BasicText useSpan fontSize="14px" bold>Description: </BasicText>
            <BasicText useSpan fontSize="14px">{prize.description}</BasicText>
          </div>
        </div>
      </div>


    </div>
  );
};

export default PrizeDisplay;
