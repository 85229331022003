import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Icon, Spin } from 'antd';
import { TitleText, BasicText, ShortInput, LongInput, CopyUrl, BasicButton } from '@components';
import styles from '@components/HeaderAndTable.module.css';

const TeamTable = ({
  teamList,
  onMemberSelected
}) => {

  const getTeamTableData = () => {
    let dataArray = [];
    teamList.forEach((member, i) => {
      dataArray.push({
        id: member.id,
        first_name: member.first_name,
        last_name: member.last_name,
        role: member.role,
        status: member.status,
        company_name: member.company_name
      })
    });
    return dataArray;
  }

  const getColumns = () => {
    let columns = [
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>First Name</div>;
        },
        dataIndex: 'first_name',
        key: 'first_name',
        width: 80,
        render(text, record) {
          return {
            children: <div style={{ display: "flex", alignItems: 'center', maxWidth: 150, overflow: 'hidden' }}>
                        <BasicText fontSize="15px" bold>{text}</BasicText>
                      </div>
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Last Name</div>;
        },
        dataIndex: 'last_name',
        key: 'last_name',
        width: 80,
        render(text, record) {
          return {
            children: <div style={{ display: "flex", alignItems: 'center', maxWidth: 150, overflow: 'hidden' }}>
                        <BasicText fontSize="15px" bold>{text}</BasicText>
                      </div>
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Role</div>;
        },
        dataIndex: 'role',
        key: 'role',
        width: 80,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px">{text}</BasicText>,
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Company</div>;
        },
        dataIndex: 'company_name',
        key: 'company_name',
        width: 80,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px">{text}</BasicText>,
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Status</div>;
        },
        dataIndex: 'status',
        key: 'status',
        width: 120,
        render(text, record) {
          return {
            children: <BasicText color={text.includes("Expired") ? 'red' : 'black'} fontSize="15px">{text}</BasicText>,
          };
        }
      }
    ];

    return columns;
  }

  return (
    <div
      className={styles.table_container_style}
      style={{ marginTop: "220px" }}
    >
      <Table
        pagination={false}
        columns={getColumns()}
        dataSource={getTeamTableData()}
        rowKey="id"
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              onMemberSelected(record)
            }
          };
        }}
      />
    </div>
  )
}

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {

})(TeamTable);
