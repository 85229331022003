import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, Form } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withTheme } from 'styled-components';
import PageWrapper from '@components/PageWrapper';
import LoadingWrapper from '@components/LoadingWrapper';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { EditFilled } from '@ant-design/icons';
import { Table, Icon, Spin } from 'antd';
import florrette from '@images/prizeFloretteSolid_shadow.png';
import {
  TitleText,
  BasicText,
  ShortInput,
  LongInput,
  CopyUrl,
  BasicButton,
  DropDown,
  ConfirmModal,
  MuseoText,
} from '@components';
import styles from '@components/HeaderAndTable.module.css';
import { showSuccessMessage, showErrorMessage, CHALLENGE_TYPES } from '@global';
import { getMemberInvites } from '@actions';
import ReferralTable from './ReferralTable';

const MemberReferrals = ({ member, getMemberInvites, theme }) => {
  const history = useHistory();
  const [invites, setInvites] = useState(null);

  console.log(invites);

  useEffect(() => {
    fetchInvites();
  }, []);

  const fetchInvites = () => {
    getMemberInvites(
      member.id,
      (response) => {
        setInvites(response.users);
      },
      () => {}
    );
  };

  const numReferralsAndLink = () => {
    return (
      <div className={styles.stat_grids_container}>
        <div className={styles.stat_container}>
          <div className={styles.stat_title}>Referred Members</div>
          <div className={styles.stat_overall_container}>
            <div className={styles.stat_overall}>{member.num_invites}</div>
          </div>
        </div>
        <div className={styles.link_container}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <BasicText bold>Referral Link</BasicText>

            <CopyToClipboard
              text={member.invite_url}
              onCopy={() => showSuccessMessage('Referral Link Copied!')}
            >
              <div className={styles.copy_button}>Copy Link</div>
            </CopyToClipboard>
          </div>
          <div style={{ color: theme.colors.blurple, marginTop: '22px' }}>
            <BasicText>{member.invite_url}</BasicText>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div>
        <div className={styles.table_container_style} style={{}}>
          {numReferralsAndLink()}

          {invites && <ReferralTable memberList={invites} />}
        </div>
      </div>
    </div>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getMemberInvites,
})(withTheme(MemberReferrals));
