import React, { Component } from 'react';
import { connect } from 'react-redux';
import onClickOutside from "react-onclickoutside";
import { createBrowserHistory } from 'history';
import { Link } from 'react-router-dom';
import xImage from '@components/x.png';
import {
  setAccountMenu,
  logoutUser
} from '../actions';
import ResetPasswordModal from '@admin/ResetPasswordModal';

const history = createBrowserHistory();

class AccountMenu extends Component {
  state = { showResetPasswordModal: false };

  handleClickOutside = evt => {
    this.props.setAccountMenu(false);
  };

  onAccountMenuPressed() {
    this.props.setAccountMenu(!this.props.show_account_menu);
  }

  onLogout() {
    this.props.logoutUser(() => {
      history.push('/');
      window.location.reload();
    });
  }

  onLinkClicked(path) {
    history.push(path);
  }

  renderLinkWithNameAndPath(name, path) {
    return <Link to={path} onClick={() => this.props.setAccountMenu(false)} style={styles.linkStyle}>{name}</Link>;
  }

  renderAccountMenu() {
    if (this.props.show_account_menu) {
      return (
        <div style={styles.containerStyle}>
          <div style={styles.nameContainer}>
            {this.props.adminUser.first_name} {this.props.adminUser.last_name}
            <img style={styles.xImageStyle} src={xImage} alt="logo" onClick={() => this.props.setAccountMenu(false)} />
          </div>
          <div style={styles.sepStyle} />
          <div style={{ marginTop: '4px' }} />
          <div style={styles.linkStyle} onClick={() => this.setState({ showResetPasswordModal: true })}>
            Reset Password
          </div>
          <div style={{ marginTop: '4px' }} />
          <div style={styles.linkStyle} onClick={() => this.onLogout()}>
            Log Out
          </div>
        </div>
      );
    }
  }


  render() {
    if (history.location.pathname.includes("/lv_st/")) return null;
    
    return (
      <div style={{ zIndex: 2, position: 'absolute', right: "12px", top: "12px", display: "flex", alignItems: "center" }}>
        <button style={styles.accountStyle} onClick={() => this.onAccountMenuPressed()}>
          {this.props.adminUser.initials}
        </button>
        {this.renderAccountMenu()}

        <ResetPasswordModal
          visible={this.state.showResetPasswordModal}
          onCancel={() => this.setState({ showResetPasswordModal: false })}
        />
      </div>
    )
  }
}

const mapStatesToProps = (state) => {
  const { show_account_menu, adminUser } = state.auth;
  return { show_account_menu, adminUser };
};

export default connect(mapStatesToProps, {
  setAccountMenu,
  logoutUser
})(onClickOutside(AccountMenu));

const styles = {
  containerStyle: {
    width: "260px",
    paddingBottom: "18px",
    backgroundColor: '#fff',
    position: 'absolute',
    right: "8px",
    top: "48px",
    borderRadius: "3px",
    borderWidth: "1px",
    borderStyle: 'solid',
    borderColor: '#D3D3D3',
    boxShadow: "0px 10px 10px 0px #D3D3D3",
    display: 'flex',
    flexDirection: 'column',
    zIndex: 2
  },
  nameContainer: {
    textAlign: 'center',
    paddingTop: "12px",
    fontSize: "12px",
    color: '#484848',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row'
  },
  sepStyle: {
    marginLeft: '12px',
    marginRight: '12px',
    backgroundColor: '#E0E0E0',
    height: '1px',
    marginTop: '12px'
  },
  xImageStyle: {
    height: '10px',
    width: '10px',
    position: 'absolute',
    right: '20px',
    cursor: 'pointer'
  },
  linkStyle: {
    textDecoration: 'none',
    color: 'black',
    fontSize: '14px',
    marginLeft: '12px',
    marginTop: '16px',
    cursor: 'pointer'
  },
  accountStyle: {
    marginLeft: "12px",
    width: "30px",
    height: "30px",
    borderRadius: "15px",
    backgroundColor: "#BBFFCA",
    color: "#222",
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    display: 'flex',
    fontSize: '11px',
    fontWeight: 'bold',
    cursor: 'pointer',
    border: 'none',
    outline: 'none'
  }
}
