import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Button, Modal, Form } from "antd";
import { withTheme } from "styled-components";
import { PlayCircleOutlined, PlayCircleTwoTone } from "@ant-design/icons";
import { useHistory, Link } from "react-router-dom";
import {
  TitleText,
  BasicText,
  BasicButton,
  ShortInput,
  LongInput,
} from "@components";
import styles from "./ReviewReportHistory.module.css";

const ReviewReportHistory = ({ entry, title, theme }) => {
  const historyList = (list) => {
    return list.map((review) => (
      <div key={review.id} className={styles.review_section}>
        <div className={styles.review_title}>{review.admin_text}</div>

        <div className={styles.review_text}>{review.time_text}</div>

        {review.rejected_reason && (
          <div className={styles.review_text}>{review.rejected_reason}</div>
        )}
      </div>
    ));
  };

  return (
    <div className={styles.history_container}>
      {entry.review_history && (
        <>
          <div className={styles.history_header}>Review History</div>
          <div className={styles.history_scroll_section}>
            {entry.review_history.length > 0 ? (
              <>{historyList(entry.review_history)}</>
            ) : (
              <div>No Reviews...</div>
            )}
          </div>
        </>
      )}

      <div className={styles.history_header}>Report History</div>
      <div className={styles.history_scroll_section}>
        {entry.report_history.length > 0 ? (
          <>{historyList(entry.report_history)}</>
        ) : (
          <div>No Reports...</div>
        )}
      </div>
    </div>
  );
};

const mapStatesToProps = (state) => {
  const { reviewStats } = state.review;
  return { reviewStats };
};

export default connect(mapStatesToProps, {})(withTheme(ReviewReportHistory));
