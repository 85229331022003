import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form } from 'antd';
import { withTheme } from 'styled-components';
import { PlayCircleOutlined, PlayCircleTwoTone } from '@ant-design/icons';
import BeatLoader from "react-spinners/BeatLoader";
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, BasicButton, ShortInput, LongInput } from '@components';
import StatsLoader from '@challenges/stats/StatsLoader';
import LeaderboardItem from '@challenges/stats/LeaderboardItem';
import FullLeaderboardModal from '@challenges/stats/FullLeaderboardModal';
import styles from './ChallengeStats.module.css';
import { downloadFiles, downlaodVideosIntoZip } from '@global';
import emptyLeaderboard from '@images/blankLeaderboard.svg';

const Leaderboard = ({
  stats,
  hasData,
  loading,
  challenge,
  theme
}) => {

  const [showFullLeaderboard, setShowFullLeaderboard] = useState(false);
  const [downloading, setDownloading] = useState(false);

  const leaderboardContent = () => {
    if (loading) {
      return (
        <div className={styles.loading_container}>
          <StatsLoader />
        </div>
      )
    } else {
      return (
        <>
          {hasData ?
            <div>
              <div className={styles.leaderboard_items}>
                {stats.leaderboard.map((submission, i) => {
                  return <LeaderboardItem key={i} submission={submission}/>;
                })}
                <div
                  className={styles.see_full_leaderboard}
                  onClick={() => setShowFullLeaderboard(true)}
                >
                  View full Leaderboard
                </div>
              </div>

              <div
                className={styles.download_button}
                onClick={() => {
                  setDownloading(true)
                  downlaodVideosIntoZip(
                    stats.top_ten_download,
                    challenge.basic_info.title,
                    10,
                    () => setDownloading(false)
                  )
                }}
              >
                {downloading ?
                  <div style={{ marginTop: "2px" }}>
                    <BeatLoader
                      size={8}
                      color={theme.colors.blurple}
                    />
                  </div>
                :
                  <div className={styles.download_text}>
                    Download Top 10 Entry Videos
                  </div>
                }
              </div>
            </div>
          :
            <div className={styles.empty_leaderboard}>
              <img
                src={emptyLeaderboard}
              />
              <div className={styles.empty_text} style={{ marginTop: "-80px" }}>
                Check back here for the standings of the Challenge once there are entries!
              </div>
            </div>
          }
        </>
      )
    }
  }

  return (
    <div className={styles.leaderboard_section}>
      <div className={styles.section_title}>
        Leaderboard
      </div>
      {leaderboardContent()}
      {showFullLeaderboard &&
        <FullLeaderboardModal
          challenge={challenge}
          initialSubmissions={stats.leaderboard}
          onCancel={() => setShowFullLeaderboard(false)}
        />
      }
    </div>
  );
};



export default connect(null, {

})(withTheme(Leaderboard));
