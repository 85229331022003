import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';

function PrivateRoute ({component: Component, authed, redirectPath, ...rest}) {
  return (
    <Route
      {...rest}
      render={(props) => authed === true ?
        <Component {...rest} {...props} />
      : <Redirect to={{pathname: '/login', state: {from: props.location}}} />}
    />
  )
}

export default PrivateRoute;
