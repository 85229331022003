import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { Space } from 'antd';
import PageWrapper from '@components/PageWrapper';
import { NAV_SECTIONS } from '@global';
import UserGraph from '@admin/UserGraph';
import ReviewTime from '@review/ReviewTime';
import styles from '@admin/OverviewStats.module.css';

const OverviewStats = ({ adminUser, overallReportPending, theme }) => {
  const stats = adminUser.overview_stats;

  const lastThirty = (useLastDay) => {
    return (
      <div className={styles.last_thirty_text}>
        {useLastDay ? 'Last 24h' : 'Last 30d'}
      </div>
    );
  };

  const temporalStats = (statData, useLastDay) => {
    return (
      <>
        {useLastDay ? (
          <div className={styles.stat_past_all_container}>
            <div className={styles.stat_past_single_container}>
              <div className={styles.last_thirty_text}>Last 24h</div>
              <div className={styles.last_thirty_stat}>
                {statData.last_24_hours}
              </div>
            </div>
          </div>
        ) : (
          <div className={styles.stat_past_all_container}>
            <div className={styles.stat_past_single_container}>
              <div className={styles.last_thirty_text}>Last 30d</div>
              <div className={styles.last_thirty_stat}>
                {statData.last_thirty}
              </div>
            </div>
            <div className={styles.stat_past_single_container}>
              <div className={styles.last_thirty_text}>Last 7d</div>
              <div className={styles.last_thirty_stat}>
                {statData.last_seven}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <PageWrapper>
      <div className={styles.container}>
        <div className={styles.dashboard_title}>Overview dashboard</div>

        <div className={styles.stats_sections}>
          <div className={styles.stat_container}>
            <div className={styles.stat_title}>Members</div>
            <div className={styles.stat_overall_container}>
              <div className={styles.stat_overall}>{stats.members.total}</div>
            </div>
            {temporalStats(stats.members)}
          </div>

          <div className={styles.stat_container}>
            <div className={styles.stat_title}>iOS Members</div>
            <div className={styles.stat_overall_container}>
              <div className={styles.stat_overall}>{stats.ios.total}</div>
            </div>
            {temporalStats(stats.ios)}
          </div>

          <div className={styles.stat_container}>
            <div className={styles.stat_title}>Android Members</div>
            <div className={styles.stat_overall_container}>
              <div className={styles.stat_overall}>{stats.android.total}</div>
            </div>
            {temporalStats(stats.android)}
          </div>
        </div>

        <div className={styles.stats_sections}>
          <div className={styles.stat_container}>
            <div className={styles.stat_title}>Challenges</div>
            <div className={styles.stat_overall_container}>
              <div className={styles.stat_overall}>
                {stats.challenges.total}
              </div>
            </div>
            {temporalStats(stats.challenges)}
          </div>

          <div className={styles.stat_container}>
            <div className={styles.stat_title}>Entries</div>
            <div className={styles.stat_overall_container}>
              <div className={styles.stat_overall}>
                {stats.submissions.total}
              </div>
            </div>
            {temporalStats(stats.submissions)}
          </div>

          <div className={styles.stat_container}>
            <div className={styles.stat_title}>Total Claps</div>
            <div className={styles.stat_overall_container}>
              <div className={styles.stat_overall}>{stats.claps.total}</div>
            </div>
            {temporalStats(stats.claps)}
          </div>
        </div>

        <div className={styles.stats_sections}>
          <div className={styles.stat_container}>
            <div className={styles.stat_title}>Video Reactions</div>
            <div className={styles.stat_overall_container}>
              <div className={styles.stat_overall}>
                {stats.video_reactions.total}
              </div>
            </div>
            {temporalStats(stats.video_reactions)}
          </div>

          <div className={styles.stat_container}>
            <div className={styles.stat_title}>Hyype Groups</div>
            <div className={styles.stat_overall_container}>
              <div className={styles.stat_overall}>
                {stats.hyype_groups.total}
              </div>
            </div>
            {temporalStats(stats.hyype_groups)}
          </div>

          <div className={styles.stat_container}>
            <div className={styles.stat_title}>Hyype Group Entries</div>
            <div className={styles.stat_overall_container}>
              <div className={styles.stat_overall}>
                {stats.hyype_group_entries.total}
              </div>
            </div>
            {temporalStats(stats.hyype_group_entries)}
          </div>
        </div>

        <div className={styles.stats_sections}>
          <div className={styles.stat_container}>
            <div className={styles.stat_title}>Thunder Purchased</div>
            <div className={styles.stat_overall_container}>
              <div className={styles.stat_overall}>
                {stats.thuder_purchases.total}
              </div>
            </div>
            {temporalStats(stats.thuder_purchases)}
          </div>

          <div className={styles.stat_container}>
            <div className={styles.stat_title}>Pending Reports</div>
            <div className={styles.stat_overall_container}>
              <div
                className={styles.stat_overall}
                style={{ backgroundColor: theme.colors.danger }}
              >
                {overallReportPending}
              </div>
            </div>
            {temporalStats(stats.reports, true)}
          </div>
        </div>

        <div>
          <ReviewTime />
        </div>
      </div>
      <UserGraph />
    </PageWrapper>
  );
};

const mapStatesToProps = (state) => {
  const { overallReportPending } = state.review;
  const { adminUser } = state.auth;
  return { adminUser, overallReportPending };
};

export default connect(mapStatesToProps, {})(withTheme(OverviewStats));
