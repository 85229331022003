import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import { EditFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Table, Icon, Spin, Switch, Modal, Form } from 'antd';
import { NAV_SECTIONS, CHALLENGE_ASSET_TYPES } from '@global';
import styles from '@components/HeaderAndTable.module.css';
import LoadingWrapper from '@components/LoadingWrapper';
import DraggableBodyRow from '@components/DraggableBodyRow';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {
  MuseoText,
  BasicButton,
  ShortInput,
  SearchBar,
  BasicText,
} from '@components';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import {
  getTemplates,
  editTemplate,
  createTemplate,
  getUrlFromUploadId,
  setTemplateOrder
} from '@actions';
import AssetUploader from '@challenges/create/AssetUploader';


const AddEntryForm = ({
  onAddEntry,
  cancel,
  getUrlFromUploadId,
}) => {
  const [entry, setEntry] = useState({})
  function handleCancel() {
    setEntry(null)
    cancel()
  }
  return (
    <>
      <Form.Item>
        <BasicText marginBottom="16px" bold>Video</BasicText>
        {entry?.media_upload_id ?
          <div style={{ marginBottom: 8 }}><BasicButton text="Delete Video" type='default'
            onClick={() => setEntry({ ...entry, media_upload_id: null, video_url: null })} /></div> :
          <AssetUploader
            asset_type={CHALLENGE_ASSET_TYPES.HYYPE_GROUP_VIDEO}
            onSuccess={(fileInfo) => {
              getUrlFromUploadId(fileInfo.presignInfo.upload_id, (url) => {
                setEntry({
                  ...entry,
                  media_upload_id: fileInfo.presignInfo.upload_id,
                  video_url: url
                });
              });
            }}
          />
        }
        {entry?.video_url && <video controls width="120px" src={entry.video_url} />}
      </Form.Item>
      <div
        style={{
          marginTop: '40px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <BasicButton
          type='default'
          text='Cancel'
          onClick={handleCancel}
          style={{ width: '48%' }}
        />

        <BasicButton
          type='primary'
          text='Submit'
          disabled={!entry?.video_url}
          onClick={() => onAddEntry(entry)}
          style={{ width: '48%' }}
        />
      </div>
    </>
  )
}

const EntryListItem = ({
  entry,
  onDelete,
  onMove,
  canMoveUp,
  canMoveDown,
}) => {
  return (
    <div style={{
      display: "grid", gridTemplateColumns: "120px 60px 60px 60px", columnGap: "8px"
    }}>
      <div>
        {entry?.video_url ? <video controls width="120px" src={entry.video_url} /> : entry?.upload?.url?.content ? <video controls width="120px" src={entry?.upload?.url?.content} /> : null}
      </div>
      <div><BasicButton type="default" disabled={!canMoveUp} style={{ padding: 8 }} onClick={() => onMove('up')} text="Up" /></div>
      <div><BasicButton type="default" disabled={!canMoveDown} style={{ padding: 8 }} padding="8px" onClick={() => onMove('down')} text="Down" /></div>
      <div><BasicButton type="default" padding="8px" style={{ padding: 8 }} onClick={onDelete} text="Delete" /></div>
    </div>
  )
}

const EditTemplateForm = ({
  template,
  onUpdate,
  getUrlFromUploadId,
}) => {
  const [showNewEntry, setShowNewEntry] = useState(false)
  function handleAddEntry(entry) {
    onUpdate({
      ...template,
      entries: [...(template.entries || []), entry]
    })
    setShowNewEntry(false)
  }
  function handleDeleteEntry(index) {
    // Delete by index to handle unpersisted or persisted entries
    onUpdate({
      ...template,
      entries: template.entries.slice(0, index).concat(template.entries.slice(index + 1))
    })
  }
  function handleMove(index, direction) {
    const entries = [...template.entries]
    const entry = entries[index];
    entries.splice(index, 1)
    entries.splice(direction == 'up' ? index - 1 : index + 1, 0, entry)
    onUpdate({
      ...template,
      entries
    })
  }
  return (
    <>
      <Form.Item>
        <ShortInput
          inputHeader='Title'
          onChange={(val) =>
            onUpdate({ ...template, title: val })
          }
          maxLength={50}
          value={template?.title}
        />
      </Form.Item>
      <Form.Item>
        <ShortInput
          inputHeader='Description'
          onChange={(val) =>
            onUpdate({ ...template, description: val })
          }
          value={template?.description}
          maxLength={240}
        />
      </Form.Item>
      <Form.Item>
        <ShortInput
          type="number"
          inputHeader='Template Order'
          onChange={(val) =>
            onUpdate({ ...template, template_order: val })
          }
          value={template?.template_order}
        />
      </Form.Item>
      <Form.Item>
        <BasicText marginBottom="16px" bold>Video</BasicText>
        {template?.explainer_upload_id ?
          <div style={{ marginBottom: 8 }}><BasicButton type="default" text="Delete Video" onClick={() => onUpdate({ ...template, explainer_upload_id: null, explainer_video_url: null })} /></div> :
          <AssetUploader
            asset_type={CHALLENGE_ASSET_TYPES.HYYPE_GROUP_VIDEO}
            onSuccess={(fileInfo) => {
              getUrlFromUploadId(fileInfo.presignInfo.upload_id, (url) => {
                onUpdate({
                  ...template,
                  explainer_upload_id: fileInfo.presignInfo.upload_id,
                  explainer_video_url: url
                });
              });
            }}
          />
        }
        {template?.explainer_video_url && <video controls width="160px" src={template.explainer_video_url} />}
      </Form.Item>
      <Form.Item>
        <BasicText marginBottom="16px" bold>Entries (Add 4)</BasicText>
        {(template?.entries || []).length < 4 && <BasicButton
          type='secondary'
          text='+ NEW ENTRY'
          onClick={() => setShowNewEntry(true)}
          style={{ marginBottom: 16 }}
        />}
        {template?.entries?.map((entry, i) => {
          return (
            <EntryListItem
              canMoveUp={i > 0}
              canMoveDown={i < template.entries.length - 1}
              entry={entry} key={i}
              onDelete={() => handleDeleteEntry(i)}
              onMove={(direction) => handleMove(i, direction)}
            />
          )
        })}
      </Form.Item>
      {
        showNewEntry &&
        <Modal
          title='New Entry'
          visible={true}
          onCancel={() => setShowNewEntry(false)}
          footer={null}
        >
          <AddEntryForm onAddEntry={(entry) => handleAddEntry(entry)} cancel={() => setShowNewEntry(false)} getUrlFromUploadId={getUrlFromUploadId} />
        </Modal>
      }
    </>
  )
}

const HyypeGroupTemplates = ({
  adminUser,
  getTemplates,
  createTemplate,
  editTemplate,
  getUrlFromUploadId,
  setTemplateOrder,
  theme,
}) => {
  const [templates, setTemplates] = useState(null);
  const [templateToEdit, setTemplateToEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newTemplate, setNewTemplate] = useState(null);

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = () => {
    getTemplates(
      (b) => setTemplates(b),
      () => { }
    );
  };

  const getTableData = () => {
    if (templates == null) return [];
    let dataArray = [];
    templates.forEach((template, i) => {
      dataArray.push({ ...template });
    });
    return dataArray;
  };

  const moveRow = useCallback(
    async (dragIndex, hoverIndex) => {
      const _templates = [...templates]
      const template = _templates[dragIndex];
      _templates.splice(dragIndex, 1)
      _templates.splice(hoverIndex, 0, template)
      setTemplates(
        _templates
      );
      setTemplateOrder(_templates.map((t) => t.id), () => { }, () => { })
    },
    [templates, setTemplates, setTemplateOrder],
  );

  const getColumns = () => {
    let columns = [
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Title</div>;
        },
        dataIndex: 'title',
        key: 'title',
        width: 100,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <BasicText fontSize='15px'>{text}</BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Description</div>;
        },
        dataIndex: 'description',
        key: 'description',
        width: 100,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  overflow: 'hidden',
                }}
              >
                <BasicText fontSize='15px'>{text}</BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: "Active",
        dataIndex: 'visible',
        key: 'visible',
        width: 40,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Switch
                  defaultChecked={record.template_active}
                  onChange={(checked) => {
                    checked ? onShowTemplate(record) : onHideTemplate(record);
                  }}
                />
              </div>
            ),
          };
        },
      },
      {
        title: "",
        dataIndex: 'edit',
        key: 'edit',
        width: 50,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => setTemplateToEdit(record)}
                >
                  <EditFilled
                    style={{
                      color: theme.colors.blurple,
                      fontSize: '20px',
                      marginTop: '4px',
                    }}
                  />
                </div>
              </div>
            ),
          };
        },
      },
    ];

    return columns;
  };

  const onShowTemplate = (template) => {
    setLoading(true);
    editTemplate(
      { ...template, template_active: true },
      (b) => {
        setLoading(false);
        setTemplates(b);
      },
      () => {
        setLoading(false);
      }
    );
  }

  const onHideTemplate = (template) => {
    setLoading(true);
    editTemplate(
      { ...template, template_active: false },
      (b) => {
        setLoading(false);
        setTemplates(b);
      },
      () => {
        setLoading(false);
      }
    );
  }

  const onEditConfirm = () => {
    setLoading(true);
    editTemplate(
      templateToEdit,
      (b) => {
        setLoading(false);
        setTemplates(b);
        setTemplateToEdit(null);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const onCreateConfirm = () => {
    setLoading(true);
    createTemplate(
      newTemplate,
      (b) => {
        setLoading(false);
        setTemplates(b);
        setNewTemplate(null);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const onClose = () => {
    setLoading(false);
    setTemplateToEdit(null)
    setNewTemplate(null)
  }

  const shouldEnableCreateTemplate = () => {
    return (
      newTemplate &&
      newTemplate.title?.length > 0 &&
      newTemplate.description?.length > 0
    );
  };

  const shouldEnableEditTemplate = () => {
    return (
      templateToEdit &&
      templateToEdit.title?.length > 0 &&
      templateToEdit.description?.length > 0
    );
  };

  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  return (
    <LoadingWrapper
      isLoading={templates == null}
      loadingText='Fetching Template Data'
    >
      <div style={{ marginTop: '220px' }}>
        <div style={{ marginBottom: '24px' }}>
          *Templates will show up in the listed order. Drag and drop to reorder.
        </div>
        <div style={{ marginBottom: '24px' }}>
          <BasicButton
            type='secondary'
            text='+ NEW TEMPLATE'
            onClick={() => setNewTemplate({})}
          />
        </div>
        <DndProvider backend={HTML5Backend}>
          <Table
            pagination={false}
            columns={getColumns()}
            dataSource={getTableData()}
            rowKey='id'
            components={components}
            rowClassName={styles.unclickable_table}
            onRow={(_, index) => {
              const attr = {
                index,
                moveRow,
              };
              return attr
            }}
          />
        </DndProvider>
      </div>

      {templateToEdit != null && (
        <Modal
          title='Edit Template'
          visible={templateToEdit != null}
          onCancel={onClose}
          footer={null}
        >
          <EditTemplateForm template={templateToEdit} onUpdate={setTemplateToEdit} getUrlFromUploadId={getUrlFromUploadId} />
          <div
            style={{
              marginTop: '40px',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <BasicButton
              type='default'
              text='Cancel'
              onClick={() => setTemplateToEdit(null)}
              style={{ width: '48%' }}
            />

            <BasicButton
              type='primary'
              text='Submit'
              disabled={!shouldEnableEditTemplate()}
              onClick={() => onEditConfirm()}
              style={{ width: '48%' }}
              loading={loading}
            />
          </div>
        </Modal>
      )}

      {newTemplate != null && (
        <Modal
          title='New Template'
          visible={newTemplate != null}
          onCancel={onClose}
          footer={null}
        >
          <EditTemplateForm template={newTemplate} onUpdate={setNewTemplate} getUrlFromUploadId={getUrlFromUploadId} />
          <div
            style={{
              marginTop: '40px',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <BasicButton
              type='default'
              text='Cancel'
              onClick={() => setNewTemplate(null)}
              style={{ width: '48%' }}
            />

            <BasicButton
              type='primary'
              text='Submit'
              disabled={!shouldEnableCreateTemplate()}
              onClick={() => onCreateConfirm()}
              style={{ width: '48%' }}
              loading={loading}
            />
          </div>
        </Modal>
      )}
    </LoadingWrapper>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getTemplates,
  createTemplate,
  editTemplate,
  getUrlFromUploadId,
  setTemplateOrder,
})(withTheme(HyypeGroupTemplates));
