import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Select, Radio } from 'antd';
import PageWrapper from '@components/PageWrapper';
import { Tabs } from 'antd';
const { TabPane } = Tabs;
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import LoadingWrapper from '@components/LoadingWrapper';
import { withTheme } from 'styled-components';
import { PlayCircleOutlined, PlayCircleTwoTone } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, MuseoText } from '@components';
import styles from '@fulfillment/Fulfill.module.css';
import HostPayoutHome from '@fulfillment/hosts/HostPayoutHome';
import FulfillPrizeHome from '@fulfillment/prizes/FulfillPrizeHome';
import MemberHome from '@members/MemberHome';
import HostHome from '@hosts/HostHome';
import { updateViewingMembers } from '@actions';

const CommunityHome = ({
  adminUser,
  updateViewingMembers,
  viewingMemberList,
  theme
}) => {
  const [selectedTab, setSelectedTab] = useState(viewingMemberList ? 0 : 1);

  useEffect(() => {
    setSelectedTab(viewingMemberList ? 0 : 1);
  }, [viewingMemberList])


  const tabSelectorOptions = () => {
    return ["Members", "Hosts"].map((stat, index) => (
      <TabPane
        key={index}
        value={index}
        tab={stat}
      />
    ));
  }

  const onSelectedOption = (option) => {
    updateViewingMembers(option === 0 || option === "0" ? true : false);
  };

  const tableContent = () => {
    if (viewingMemberList) {
      return <MemberHome />;
    } else {
      return <HostHome />;
    }
  }

  const header = () => {
    return (
      <div className={styles.header}>
        <div className={styles.header_container}>
          <div className={styles.header_text}>
            <MuseoText>Community</MuseoText>
          </div>
        </div>
        <div className={styles.radio_container}>
          <Tabs defaultActiveKey={selectedTab === 0 || selectedTab === "0" ? "0" : "1"} onChange={(e) => onSelectedOption(e)}>
            {tabSelectorOptions()}
          </Tabs>
        </div>
      </div>
    )
  }

  return (
    <PageWrapper>
      <div className={styles.container}>

        {tableContent()}
        {header()}
      </div>
    </PageWrapper>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  const { viewingMemberList } = state.member;
  return { adminUser, viewingMemberList };
};

export default connect(mapStatesToProps, {
  updateViewingMembers
})(withTheme(CommunityHome));
