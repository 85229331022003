import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DatePicker, Form, Space, TimePicker, Button, Modal } from 'antd';
import moment from 'moment-timezone';
import { useHistory, Link } from 'react-router-dom';
import AssetUploader from '@challenges/create/AssetUploader';
import AssetPreview from '@challenges/create/AssetPreview';
import { TitleText, BasicText, BasicButton, ShortInput, LongInput, ConfirmModal } from '@components';
import PromoInfoModal from '@challenges/create/PromoInfoModal';
import PromoDisplay from '@challenges/create/PromoDisplay';
import { getUrlFromUploadId } from '@actions';
import {
  NEW_CHALLENGE_STATES,
  NEW_CHALLENGE_STEPS,
  CHALLENGE_ASSET_TYPES
} from '@global';
import styles from './Create.module.css';

const BrandInfo = ({
  brand_info,
  promo_info,
  updateChallenge,
  getUrlFromUploadId,
  isPortal,
  isCharity,
  isEdit
}) => {

  console.log("is charity" , isCharity);

  const [showUploadLogo, setShowUploadLogo] = useState(false);
  const [showPromoInfo, setShowPromoModal] = useState(false);
  const [isPromoBlank, setIsPromoBlank] = useState(promo_info == null || promo_info.title == null);
  const [showPromoDeleteConfrim, setShowPromoDeleteConfirm] = useState(false);

  const uploadLogo = () => {
    return (
      <Modal
        title="Upload Brand Logo"
        visible={showUploadLogo}
        footer={null}
        onCancel={() => setShowUploadLogo(false)}
      >
        <AssetUploader
          asset_type={CHALLENGE_ASSET_TYPES.BRAND_LOGO}
          isLogo
          onSuccess={(fileInfo) => {
            getUrlFromUploadId(fileInfo.presignInfo.upload_id, (url) =>{
              updateChallenge({ brand_info: { ...brand_info,
                logo_upload_id: fileInfo.presignInfo.upload_id,
                logo_file_name: fileInfo.fileName,
                logo_url: url
              }});
              setShowUploadLogo(false);
            });
          }}
        />
      </Modal>
    )
  };

  const deletePromo = () => {
    updateChallenge({ promo_info: { title: null, description: null, promotion_link: null, coupon_code: null }});
    setIsPromoBlank(true);
  }

  return (
    <div style={{ marginBottom: isEdit ? "40px" : "0px" }}>
      {!isPortal &&
        <>
          <div className={styles.brand_info_and_image}>
            <div style={{ minWidth: "400px", marginRight: "40px" }}>
              <div className={styles.brand_name_and_color}>
                <div style={{ minWidth: isEdit ? "220px" : "230px", marginRight: "24px" }}>
                  <Form.Item>
                    <ShortInput
                      inputHeader="Enter Brand Name"
                      placeholder="XYZ Awesome Brand"
                      onChange={(val) => updateChallenge({ brand_info: {
                        ...brand_info, display_name: val
                      }})}
                      value={brand_info.display_name}
                    />
                  </Form.Item>
                </div>

                <Form.Item>
                  <div className={styles.input_and_color}>
                    <ShortInput
                      inputHeader='Primary Color'
                      placeholder="#ff0080"
                      maxWidth={isEdit ? "100px" : "120px"}
                      onChange={(val) => {
                        if (val.length <= 7) {
                          updateChallenge({ brand_info: {
                            ...brand_info, brand_color: val
                          }});
                        }
                      }}
                      value={brand_info.brand_color}
                    />
                    <div style={{
                      marginLeft: "12px",
                      marginTop: "38px",
                      width: "42px",
                      height: "42px",
                      border: `2px solid #dedede`,
                      backgroundColor: brand_info.brand_color }}
                    />
                  </div>
                </Form.Item>
              </div>

              <Form.Item>
                <ShortInput
                  inputHeader="Enter Brand Site"
                  placeholder="https://xyzawesome.co"
                  onChange={(val) => updateChallenge({ brand_info: {
                    ...brand_info, site_url: val
                  }})}
                  value={brand_info.site_url}
                />
              </Form.Item>

            </div>
            <Form.Item>
              <div className={styles.input_label}>
                <BasicText bold>
                  Brand Logo
                </BasicText>
              </div>

              <AssetPreview
                url={brand_info.logo_url}
                assetName="Logo"
                onClickEdit={() => setShowUploadLogo(true)}
                instructions="Must have square dimensions. 1500 x 1500 px image recommended"
                showInstructionsOnSide
              />
            </Form.Item>
          </div>

          <div className={styles.promotion_separartor} />
        </>
      }

      {isCharity ?
        <>

          <Form.Item>
            <ShortInput
              inputHeader="Charity Link"
              placeholder="https://example.com"
              onChange={(val) => {
                updateChallenge({
                  promo_info: { charity_link: val }
                });
              }}
              value={promo_info.charity_link}
            />
          </Form.Item>
        </>
      :
        <>
          <div className={styles.promition_section_title}>
            Promotion Link + Code (Optional)
          </div>

          {!isPromoBlank ?
            <PromoDisplay
              promo_info={promo_info}
              onDelete={() => {
                setShowPromoDeleteConfirm(true);
              }}
              onEdit={() => setShowPromoModal(true)}
            />
          :
            <BasicButton
              text="Add Link + Code"
              type="primary"
              style={{ marginTop: "12px" }}
              onClick={() => setShowPromoModal(true)}
            />
          }
        </>
      }



      {showPromoInfo &&
        <PromoInfoModal
          promo_info={promo_info}
          updateChallenge={(data) => updateChallenge(data)}
          onCancel={() => setShowPromoModal(false)}
          visible={showPromoInfo}
          onDone={() => {
            setIsPromoBlank(false);
            setShowPromoModal(false);
          }}
        />
      }

      {showPromoDeleteConfrim &&
        <ConfirmModal
          title={"Confirm Delete Promo"}
          visible={showPromoDeleteConfrim}
          onCancel={() => setShowPromoDeleteConfirm(false)}
          onSubmit={() => {
            deletePromo();
            setShowPromoDeleteConfirm(false);
          }}
        >
          <BasicText marginBottom="20px">
            Are you sure you want to delete the info for this Promotion? {isEdit ? "If deleting, you must also click save above after confirming." : ""}
          </BasicText>
        </ConfirmModal>
      }

      {showUploadLogo && uploadLogo()}
    </div>
  );
};

const mapStatesToProps = (state) => {
  const { newChallengeInfo } = state.challenge;
  return { newChallengeInfo };
};

export default connect(mapStatesToProps, {
  getUrlFromUploadId
})(BrandInfo);
