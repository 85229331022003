import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import StatsLoader from '@challenges/stats/StatsLoader';
import styles from './ChallengeStats.module.css';
import GeographicItem from '@challenges/stats/GeographicItem';
import { getGeographicPerformance } from '@actions';
import FullStatesModal from '@challenges/stats/FullStatesModal';
import blankGeographicReach from '@images/blankGeographicReach.svg';


const GeographicReach = ({
  challenge,
  hasData,
  getGeographicPerformance,
  theme
}) => {

  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(hasData);
  const [showFullStates, setShowFullStates] = useState(false);

  useEffect(() => {
    if (hasData) {
      getGeographicPerformance(
        challenge.challenge_id,
        (performance) => {
          setStats(performance);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          console.log(error);
        }
      )
    }
  }, [])

  const content = () => {
    if (loading) {
      return (
        <div className={styles.loading_container}>
          <StatsLoader />
        </div>
      )
    } else {
      return (
        <>
          {stats ?
            <div className={styles.leaderboard_items}>
              {stats.state_counts.slice(0, 6).map((data, i) => {
                return (
                  <GeographicItem
                    key={i}
                    data={data}
                    rank={i+1}
                  />
                );
              })}
              {stats.state_counts.length > 6 &&
                <div
                  className={styles.see_all_states}
                  onClick={() => setShowFullStates(true)}
                >
                  View all zipcodes
                </div>
              }
            </div>
          :
            <div className={styles.empty_leaderboard}>
              <img
                src={blankGeographicReach}
              />
              <div className={styles.empty_text} style={{ marginTop: "-60px" }}>
                See the geographic reach of the Challenge here!
              </div>
            </div>
          }
        </>
      )
    }
  }

  return (
    <div>
      <div className={styles.section_title}>
        Geographic reach
      </div>
      {content()}
      {showFullStates &&
        <FullStatesModal
          stats={stats}
          onCancel={() => setShowFullStates(false)}
        />
      }
    </div>
  );
};

export default connect(null, {
  getGeographicPerformance
})(withTheme(GeographicReach));
