import React from 'react';
import MoonLoader from "react-spinners/BeatLoader";
import { withTheme } from 'styled-components';

const BottomTableLoader = ({ size, theme }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '80px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <MoonLoader
        size={size ? size : 16}
        color={theme.colors.blurple}
      />
    </div>
  )
}

export default withTheme(BottomTableLoader);
