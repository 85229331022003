import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import StatsLoader from '@challenges/stats/StatsLoader';
import styles from './ChallengeStats.module.css';
import { getOverallPerformance } from '@actions';
import emptyOverview from '@images/blankOverview.svg';


const OverallStats = ({
  challenge,
  hasData,
  getOverallPerformance,
  theme
}) => {

  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(hasData);

  useEffect(() => {
    if (hasData) {
      getOverallPerformance(
        challenge.challenge_id,
        (performance) => {
          setStats(performance);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
          console.log(error);
        }
      )
    }
  }, [])

  const gridStat = (number, stat, isPair) => {
    return (
      <div className={styles.grid_stat_container}>
        <div className={isPair ? styles.grid_pair_number : styles.grid_trio_number}>
          {number}
        </div>
        <div className={isPair ? styles.grid_pair_text : styles.grid_trio_text}>
          {stat}
        </div>
      </div>
    )
  }

  const content = () => {
    if (loading) {
      return (
        <div className={styles.loading_container}>
          <StatsLoader />
        </div>
      )
    } else {
      return (
        <>
          {stats ?
            <div className={styles.overall_stats_grid}>
              <div className={styles.grid_pair_item}>
                {gridStat(stats.submissions, "Entries", true)}
              </div>
              <div className={styles.grid_pair_item}>
                {gridStat(stats.subscribers, "Subscribers", true)}
              </div>

              <div className={styles.grid_pair_item}>
                {gridStat(stats.total_views, "Total Views", true)}
              </div>
              <div className={styles.grid_pair_item}>
                {gridStat(stats.unique_views, "Unique Views", true)}
              </div>

              <div className={styles.grid_trio_item}>
                {gridStat(stats.engaged_total, "Engaged", false)}
              </div>
              <div className={styles.grid_trio_item}>
                {gridStat(stats.shares, "Shares", false)}
              </div>
              <div className={styles.grid_trio_item}>
                {gridStat(stats.claps, "Claps", false)}
              </div>
            </div>
          :
            <div className={styles.empty_leaderboard}>
              <img
                src={emptyOverview}
              />
              <div className={styles.empty_text} style={{ marginTop: "-60px" }}>
                Monitor key performance metrics of the Challenge here!
              </div>
            </div>
          }
        </>
      )
    }
  }

  return (
    <div>
      <div className={styles.section_title}>
        Overview
      </div>
      {content()}
    </div>
  );
};

export default connect(null, {
  getOverallPerformance
})(withTheme(OverallStats));
