import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Button, Modal, Form, Space } from "antd";
import PageWrapper from '@components/PageWrapper';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { withTheme } from "styled-components";
import { PlayCircleOutlined, PlayCircleTwoTone } from "@ant-design/icons";
import { useHistory, Link } from "react-router-dom";
import { showSuccessMessage, showErrorMessage, hyypeGroupsDomain } from '@global';
import ClipLoader from "react-spinners/ClipLoader";
import {
  TitleText,
  BasicText,
  BasicButton,
  ShortInput,
  LongInput,
  RejectButton,
  ApproveButton,
  ConfirmModal
} from "@components";
import styles from "./Report.module.css";
import { getReportedHyypeGroups, reviewReportedHyypeGroup } from '@actions';

const ReportedHyypeGroups = ({
  adminUser,
  getReportedHyypeGroups,
  reviewReportedHyypeGroup,
  theme
}) => {

  const [loading, setLoading] = useState(false);
  const [reportedHyypeGroups, setReportedHyypeGroups] = useState([]);
  const [hasFetched, setHasFetched] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getReportedHyypeGroups(
      (groups) => {
        setReportedHyypeGroups(groups);
        setHasFetched(true);
      },
      () => { }
    )
  }, []);


  const onReview = (reported_group_id, remove_group) => {
    setLoading(true);
    reviewReportedHyypeGroup(
      reported_group_id,
      remove_group,
      (groups) => {
        setReportedHyypeGroups(groups);
        setLoading(false);
        if (remove_group) {
          showSuccessMessage("Hyype Group successfully removed.");
        } else {
          showSuccessMessage("Hyype Group kept live.");
        }
      },
      () => {
        setLoading(false);
        showErrorMessage("Something went wrong.")
      }
    )
  }


  const reportedHyypeGroup = (rhg) => {
    const domain = hyypeGroupsDomain(adminUser)
    const groupUrl = `${domain}/groups/${rhg.group.external_id}`
    return (
      <div key={rhg.id} style={{ maxWidth: "400px", marginBottom: "32px" }}>
        <BasicText bold>
          {rhg.admin_text} on {rhg.time_text}
        </BasicText>

        <div style={{ marginTop: "6px" }}>
          Reason: {rhg.reason}
        </div>

        <div style={{ marginTop: "6px" }}>
          Url: <a href={groupUrl} target="_blank" rel="noreferrer">{groupUrl}</a>
        </div>
        <div style={{ marginTop: "6px" }}>
          Password: {rhg.group.share_password ? rhg.group.share_password : 'Public Group'}
        </div>

        <div style={{ marginTop: "6px" }}>
          <h4>{rhg.group.title}</h4>
          <p>{rhg.group.description}</p>
          <div style={{ width: '100%', height: 300, overflow: "auto", display: "flex", flexWrap: "wrap" }}>
            <video src={rhg.group.explainer_video_url} width="150px" controls />
            {
              rhg.entries.map(entry => {
                return <video key={entry.upload.url.content} src={entry.upload.url.content} width="150px" controls />
              })
            }
          </div>
        </div>

        <div style={{ marginTop: "16px" }}>
          <Space>
            <RejectButton
              onClick={() => {
                onReview(rhg.id, true);
              }}
              text="Remove"
            />
            <ApproveButton
              onClick={() => {
                onReview(rhg.id, false);
              }}
              text="Keep Live"
            />
          </Space>
        </div>

        <div style={{ width: "100%", height: "1px", backgroundColor: "#999", marginTop: "12px" }} />
      </div>
    );
  }



  const content = () => {
    if (hasFetched && reportedHyypeGroups.length === 0) {
      return (
        <div style={{ marginTop: "120px" }}>
          All reported Hyype Groups have been reviewed.
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: "120px" }}>
          {reportedHyypeGroups.map((rhg) =>
            reportedHyypeGroup(rhg)
          )}
        </div>
      );
    }
  }


  return (
    <PageWrapper>
      <FixedHeaderContainer
        text={'Reported Hyype Groups'}
        paddingBuffer="80px"
        paddingTop="56px"
      >
        {content()}
      </FixedHeaderContainer>

      <Modal
        title={"Submitting..."}
        visible={loading}
        footer={null}
        closable={false}

      >
        <div style={{ width: "100%", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <ClipLoader />
        </div>
      </Modal>
    </PageWrapper>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
}

export default connect(mapStatesToProps, {
  getReportedHyypeGroups,
  reviewReportedHyypeGroup,
})(withTheme(ReportedHyypeGroups));
