import {
  SET_REVENUE_STATS,
  SET_PAYOUT_STATS,
  SET_MEMBER_PAYOUT_STATS
} from '@actions/types';

const INITIAL_STATE = {
  revenueData: null,
  payoutData: null,
  memberData: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_REVENUE_STATS:
      return { ...state, revenueData: action.payload };
    case SET_PAYOUT_STATS:
      return { ...state, payoutData: action.payload };
    case SET_MEMBER_PAYOUT_STATS:
      return { ...state, memberData: action.payload };
    default:
      return state;
  }
};
