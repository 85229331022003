import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import { useHistory } from 'react-router-dom';
import { fetchTeamList } from '@actions';
import { withTheme } from 'styled-components';
import { NAV_SECTIONS, CHALLENGE_TABLE_FILTERS } from '@global';
import PurchaseHistoryTable from '@members/PurchaseHistoryTable';
import styles from '@components/HeaderAndTable.module.css';
import LoadingWrapper from '@components/LoadingWrapper';
import CreateContainer from '@challenges/create/CreateContainer';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { MuseoText, BasicButton, BottomTableLoader, SearchBar, FilterTabs } from '@components';
import { Input, Space } from 'antd';
import { getPurchaseHistory } from '@actions';
import useOnScreen from '@components/useOnScreen';
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor';


const PurchaseHistory = ({
  adminUser,
  getPurchaseHistory,
  theme,
  match
}) => {

  const listInnerRef = useRef();
  const [member, setMember] = useState(null);
  const [purchases, setPurchases] = useState([]);
  const [seenIds, setSeenIds] = useState([]);
  const [isMore, setIsMore] = useState(true);
  const [isFetchingPurchases, setIsFetchingPurchases] = useState(true);
  const history = useHistory();
  const isFetchingMoreFromBottom = useRef(false);
  const userId = match.params.user_id;

  console.log(purchases.length);


  useEffect(() => {
    fetchPurchases();
  }, []);

  const onBottomLoadingVisible = (isVisible) => {
    if (isVisible) {
      if (!isFetchingMoreFromBottom.current && isMore) fetchPurchases();
    }
  }

  const fetchPurchases = () => {
    if (!isMore && isFetchingMoreFromBottom.current) return;
    isFetchingMoreFromBottom.current = true;

    getPurchaseHistory(
      userId,
      seenIds,
      (response) => {
        const newPurchases = response.purchases;
        setMember(response.member);
        setPurchases(purchases.concat(newPurchases));
        setSeenIds(seenIds.concat(newPurchases.map((c) => c.id)));
        setIsMore(newPurchases.length === 20);
        isFetchingMoreFromBottom.current = false;
        setIsFetchingPurchases(false);
      },
      () => {}
    )
  }

  const getHeaderText = () => {
    if (member) {
      return  `Purchase History - @${member.username}`;
    } else {
      return  `Purchase History`;
    }
  }

  return (
    <PageWrapper>
      <FixedHeaderContainer
        text={getHeaderText()}
        paddingBuffer="80px"
        paddingTop="56px"
      >
        {isFetchingPurchases?
          <div style={{ display: "flex" , flex: 1, alignItems: 'center' , justifyContent: "center" }}>

          </div>
        :
          <>
            <PurchaseHistoryTable
              purchaseList={purchases}
            />
            {isMore &&
              <VisibilitySensor onChange={onBottomLoadingVisible}>
                <BottomTableLoader />
              </VisibilitySensor>
            }
          </>
        }
      </FixedHeaderContainer>
    </PageWrapper>
  )
}

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getPurchaseHistory
})(withTheme(PurchaseHistory));
