import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form, Space } from 'antd';
import { withTheme } from 'styled-components';
import { PlayCircleOutlined, CheckCircleFilled, WarningFilled } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, BasicButton, ShortInput, LongInput } from '@components';
import styles from './ReviewChallenge.module.css';
import ResponseButtons from '@review/hosts/ResponseButtons';
import { formatPhoneNumber } from '@global';

const BasicSection = ({
  challenge,
  reviewJson,
  updateReviewJson,
  theme
}) => {

  const { promo_info } = challenge;

  return (
    <div className={styles.approve_section}>
      <div className={styles.section_header_container}>
        <div className={styles.section_header}>
          {challenge.is_charity ? "Charity Link" : "Promo Information"}
        </div>

        {reviewJson["Promo"].decided_on &&
          <>
            {!reviewJson["Promo"].approved ?
              <div className={styles.approve_check}>
                <WarningFilled style={{ color: theme.colors.danger, fontWeight: 700, fontSize: "26px"  }} />
              </div>
            :
              <div className={styles.approve_check}>
                <CheckCircleFilled style={{ color: theme.colors.blurple, fontWeight: 700, fontSize: "26px"  }} />
              </div>
            }
          </>
        }
      </div>


      {challenge.is_charity ?
        <>
          <div className={styles.attribute_title}>
            Charity Link
          </div>
          <div className={styles.attribute_text}>
            {promo_info.charity_link}
          </div>
        </>
      :
        <>
          <div className={styles.attribute_title}>
            Included Promotion
          </div>

          <div className={styles.attribute_text} style={{ color: theme.colors.blurple, fontWeight: "700", fontSize: "20px" }}>
            {promo_info.has_promo ? "YES" : "NO"}
          </div>

          {promo_info.has_promo &&
            <>
              <div className={styles.attribute_title}>
                Promo Title
              </div>
              <div className={styles.attribute_text}>
                {promo_info.title}
              </div>

              <div className={styles.attribute_title}>
                Promo Description
              </div>
              <div className={styles.attribute_text}>
                {promo_info.description}
              </div>

              <div className={styles.attribute_title}>
                Promo Link
              </div>
              <div
                className={styles.attribute_text}
                style={{ color: theme.colors.blurple, cursor: "pointer" }}
                onClick={() => window.open(promo_info.promotion_link, "_blank")}
              >
                {promo_info.promotion_link}
              </div>

              <div className={styles.attribute_title}>
                Promo Code
              </div>
              <div className={styles.attribute_text}>
                {promo_info.coupon_code ? promo_info.coupon_code : "N/A"}
              </div>
            </>
          }
        </>
      }


      <div style={{ marginTop: "20px" }}>
        <ResponseButtons
          approveText={challenge.is_charity ? "Approve Link" : "Approve Promo"}
          rejectText={challenge.is_charity ? "Reject Link" : "Reject Promo"}
          showRejected={reviewJson["Promo"].decided_on && !reviewJson["Promo"].approved}
          rejectedReason={reviewJson["Promo"].reason}
          onApprove={() => {
            updateReviewJson({
              "Promo": { decided_on: true, approved: true }
            })
          }}
          onReject={() => {
            updateReviewJson({
              "Promo": { decided_on: true, approved: false }
            })
          }}
          updateRejectedReason={(val) => {
            updateReviewJson({
              "Promo": { decided_on: true, approved: false, reason: val }
            })
          }}
        />
      </div>
    </div>
  );
};

export default connect(null, {

})(withTheme(BasicSection));
