import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import { useHistory } from 'react-router-dom';
import { fetchTeamList } from '@actions';
import { withTheme } from 'styled-components';
import { NAV_SECTIONS, CHALLENGE_TABLE_FILTERS } from '@global';
import MemberEntryTable from '@members/MemberEntryTable';
import styles from '@components/HeaderAndTable.module.css';
import LoadingWrapper from '@components/LoadingWrapper';
import CreateContainer from '@challenges/create/CreateContainer';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { MuseoText, BasicButton, BottomTableLoader, SearchBar, FilterTabs } from '@components';
import { Input, Space } from 'antd';
import { getAPIRequest, getMemberEntries } from '@actions';
import useOnScreen from '@components/useOnScreen';
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor';

const { Search } = Input;

let _searchTimeout = null;

const MemberEntries = ({
  adminUser,
  getMemberEntries,
  getAPIRequest,
  theme,
  match
}) => {

  const listInnerRef = useRef();
  const [member, setMember] = useState(null);
  const [submissions, setSubmissions] = useState([]);
  const [seenIds, setSeenIds] = useState([]);
  const [isMore, setIsMore] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFetchingSubmissions, setIsFetchingSubmissions] = useState(true);
  const history = useHistory();
  const isFetchingMoreFromBottom = useRef(false);
  const userId = match.params.user_id;


  const performSearch = () => {
    const currentSearchTerm = searchTerm;
    if (currentSearchTerm.length > 0) {
      getAPIRequest(
        '/admin/search_member_entries',
        { query: currentSearchTerm,  user_id: match.params.user_id },
        response => {
          if (currentSearchTerm === searchTerm) {
            setSearchResults(response.submissions);
          }
        },
        error => {
          //onFail(error.error);
        },
      );
    }
  }

  useEffect(() => {
    fetchSubmissions();
  }, []);


  useEffect(() => {
    clearTimeout(_searchTimeout);
    if (searchTerm.length > 0) {
      _searchTimeout = setTimeout(() => performSearch(), 200);
    }
  }, [searchTerm]);

  const onBottomLoadingVisible = (isVisible) => {
    if (isVisible) {
      if (!isFetchingMoreFromBottom.current && isMore) fetchSubmissions();
    }
  }

  const fetchSubmissions = () => {
    if (!isMore && isFetchingMoreFromBottom.current) return;
    isFetchingMoreFromBottom.current = true;

    getMemberEntries(
      userId,
      seenIds,
      (response) => {
        const newSubmissions = response.submissions;
        setMember(response.member);
        setSubmissions(submissions.concat(newSubmissions));
        setSeenIds(seenIds.concat(newSubmissions.map((c) => c.id)));
        setIsMore(newSubmissions.length === 20);
        isFetchingMoreFromBottom.current = false;
        setIsFetchingSubmissions(false);
      },
      () => {}
    )
  }

  const searchBar = () => {
    return (
      <div style={{ marginTop: "30px" }}>
        <SearchBar
          searchTerm={searchTerm}
          onChange={(value) => setSearchTerm(value)}
        />
        <div>Search by Challenge name</div>
      </div>
    )
  }

  const getHeaderText = () => {
    if (member) {
      return  `Member Entries - @${member.username}`;
    } else {
      return  `Member Entries`;
    }
  }

  return (
    <PageWrapper>
      <FixedHeaderContainer
        text={getHeaderText()}
        paddingBuffer="80px"
        paddingTop="56px"
        fixedContent={searchBar()}
      >
        {isFetchingSubmissions ?
          <div style={{ display: "flex" , flex: 1, alignItems: 'center' , justifyContent: "center" }}>

          </div>
        :
          <>
            <MemberEntryTable
              submissionList={searchTerm.length > 0 ? searchResults : submissions}
              onSubmissionSelected={(submission) => {
                history.push({
                  pathname: `/view_entry/${submission.id}`,
                });
              }}
            />
            {isMore && searchTerm.length === 0 &&
              <VisibilitySensor onChange={onBottomLoadingVisible}>
                <BottomTableLoader />
              </VisibilitySensor>
            }
          </>
        }
      </FixedHeaderContainer>
    </PageWrapper>
  )
}

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getMemberEntries,
  getAPIRequest
})(withTheme(MemberEntries));
