import { REFRESH_MEMBER_LIST, SET_VIEWING_MEMBER_LIST } from '@actions/types';
import { getAPIRequest, postAPIRequest } from '@actions';

export const updateViewingMembers = (viewingMembers) => {
  return (dispatch) => {
    dispatch({ type: SET_VIEWING_MEMBER_LIST, payload: viewingMembers });
  };
};

export const getMemberList = (seen_ids, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      postAPIRequest(
        '/admin/get_member_list',
        {
          seen_ids,
        },
        (response) => {
          onSuccess(response);
        },
        (error) => {
          onFail(error);
        }
      )
    );
  };
};

export const getUserInfo = (user_id, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_user_info',
        {
          user_id,
        },
        (response) => {
          onSuccess(response.member);
        },
        (error) => {
          onFail(error);
        }
      )
    );
  };
};

export const getMemberReferrals = (onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_member_referrals',
        {},
        (response) => {
          console.log(response);
          onSuccess(response.data);
        },
        (error) => {
          onFail(error);
        }
      )
    );
  };
};

export const getMemberContactInfo = (onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_user_contact_data',
        {},
        (response) => {
          console.log(response);
          onSuccess(response.data);
        },
        (error) => {
          onFail(error);
        }
      )
    );
  };
};

export const getMemberChallenges = (user_id, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_member_challenges',
        {
          user_id,
        },
        (response) => {
          onSuccess(response.challenges);
        },
        (error) => {
          onFail(error);
        }
      )
    );
  };
};

export const suspendUser = (user_id, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/suspend_user',
        { user_id },
        (response) => {
          onSuccess(response.member);
        },
        (error) => {
          onFail('Something went wrong.');
        }
      )
    );
  };
};

export const unSuspendUser = (user_id, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/unsuspend_user',
        { user_id },
        (response) => {
          onSuccess(response.member);
        },
        (error) => {
          onFail('Something went wrong.');
        }
      )
    );
  };
};

export const setRefreshMemberList = (payload) => {
  return (dispatch) => {
    dispatch({ type: REFRESH_MEMBER_LIST, payload });
  };
};

export const getMemberEntries = (user_id, seen_ids, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/member_entries',
        {
          user_id,
          seen_ids,
        },
        (response) => {
          onSuccess(response);
        },
        (error) => {
          console.log(error);
          onFail(error);
        }
      )
    );
  };
};

export const addThunder = (user_id, add_amount, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/add_thunder',
        { user_id, add_amount },
        (response) => {
          onSuccess(response.member);
        },
        (error) => {
          onFail('Something went wrong.');
        }
      )
    );
  };
};

export const addTokens = (user_id, add_amount, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/add_tokens',
        { user_id, add_amount },
        (response) => {
          onSuccess(response.member);
        },
        (error) => {
          onFail('Something went wrong.');
        }
      )
    );
  };
};

export const getPurchaseHistory = (user_id, seen_ids, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_member_purchase_history',
        {
          user_id,
          seen_ids,
        },
        (response) => {
          onSuccess(response);
        },
        (error) => {
          onFail(error);
        }
      )
    );
  };
};

export const toggleMemberVerified = (
  user_id,
  is_verified,
  onSuccess,
  onFail
) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/update_verified_user',
        { user_id, is_verified },
        (response) => {
          onSuccess(response.member);
        },
        (error) => {
          onFail('Something went wrong.');
        }
      )
    );
  };
};

export const sendNotificationFromAdmin = (
  challenge_id,
  message,
  onSuccess,
  onFail
) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/send_admin_notif',
        { challenge_id, message },
        (response) => {
          onSuccess();
        },
        (error) => {
          onFail('Something went wrong.');
        }
      )
    );
  };
};

export const getMemberPrizes = (user_id, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_member_prizes',
        {
          user_id,
        },
        (response) => {
          onSuccess(response);
        },
        (error) => {
          onFail(error);
        }
      )
    );
  };
};

export const toggleVotingSuspended = (
  user_id,
  voting_suspended,
  onSuccess,
  onFail
) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/toggle_suspend_voting',
        { user_id, voting_suspended },
        (response) => {
          onSuccess(response.member);
        },
        (error) => {
          onFail('Something went wrong.');
        }
      )
    );
  };
};

export const toggleFlagEntries = (user_id, flag_entries, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/toggle_flag_entries',
        { user_id, flag_entries },
        (response) => {
          onSuccess(response.member);
        },
        (error) => {
          onFail('Something went wrong.');
        }
      )
    );
  };
};

export const getMemberInvites = (user_id, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_member_invites',
        {
          user_id,
        },
        (response) => {
          onSuccess(response);
        },
        (error) => {
          onFail(error);
        }
      )
    );
  };
};

export const sendWegiftCard = (user_id, amount, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/send_wegift_gift_card',
        { user_id, amount },
        (response) => {
          if (response.success) {
            onSuccess(response);
          } else {
            onFail();
          }
        },
        (error) => {
          onFail('Something went wrong.');
        }
      )
    );
  };
};
