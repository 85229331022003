import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { withTheme } from "styled-components";
import { connect } from "react-redux";
import { Table, Icon, Spin } from "antd";
import {
  TitleText,
  BasicText,
  ShortInput,
  LongInput,
  CopyUrl,
  BasicButton,
  ConfirmModal
} from "@components";
import styles from "@components/HeaderAndTable.module.css";
import { refundChallengePayment, refundBundlePayment } from '@actions';

const PurchasesTable = ({
  purchases,
  updateData,
  refundChallengePayment,
  refundBundlePayment,
  theme
}) => {
  const [loading, setLoading] = useState(false);
  const [refundPurchase, setRefundPurchase] = useState(null);

  const getPurchaseTableData = () => {
    let dataArray = [];
    purchases.forEach((purchase, i) => {
      dataArray.push({
        id: purchase.id,
        time_string: purchase.time_string,
        was_refunded: purchase.was_refunded,
        is_bundle: purchase.is_bundle,
        amount: purchase.amount,
        text: purchase.text,
        brand_name: purchase.brand_name,
        stripe_intent_id: purchase.stripe_intent_id
      });
    });
    return dataArray;
  };

  const getColumns = () => {
    let columns = [
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Description</div>;
        },
        dataIndex: "text",
        key: "text",
        width: 180,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <BasicText fontSize="15px" bold>
                  {text}
                </BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Date</div>;
        },
        dataIndex: "time_string",
        key: "time_string",
        width: 80,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: 150,
                  overflow: "hidden",
                }}
              >
                <BasicText fontSize="15px">{text}</BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Amount</div>;
        },
        dataIndex: "amount",
        key: "amount",
        width: 80,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: 150,
                  overflow: "hidden",
                }}
              >
                <BasicText fontSize="15px">{text}</BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}></div>;
        },
        dataIndex: "refund",
        key: "refund",
        width: 80,
        render(text, record) {
          if (record.was_refunded) {
            return {
              children: (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    maxWidth: 150,
                    overflow: "hidden",
                  }}
                >
                  <BasicText fontSize="15px" color={theme.colors.danger}>Refunded - {record.amount}</BasicText>
                </div>
              ),
            };
          } else {
            return {
              children: (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    maxWidth: 150,
                    overflow: "hidden",
                  }}
                >
                  <BasicButton
                    style={{ width: "52px", height: "30px", borderRadius: "20px", fontSize: "14px", display: "flex", justifyContent: "center", alignItems: "center" }}
                    type="primary"
                    text="Refund"
                    stopPropagation
                    onClick={() => {
                      setRefundPurchase(record);
                    }}
                  />
                </div>
              ),
            };
          }
        },
      }

    ];

    return columns;
  };

  const onRefund = () => {
    setLoading(true);

    if (refundPurchase.is_bundle) {
      refundBundlePayment(
        refundPurchase.id,
        (updatedPurchases) => {
          setRefundPurchase(null);
          updateData(updatedPurchases);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
        }
      )
    } else {
      refundChallengePayment(
        refundPurchase.id,
        (updatedPurchases) => {
          setRefundPurchase(null);
          updateData(updatedPurchases);
          setLoading(false);
        },
        (error) => {
          setLoading(false);
        }
      )
    }


  }

  return (
    <div className={styles.member_container_style} style={{ marginTop: "40px" }}>
      <Table
        pagination={false}
        columns={getColumns()}
        dataSource={getPurchaseTableData()}
        rowKey="id"
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              window.open(`https://dashboard.stripe.com/payments/${record.stripe_intent_id}`, "_blank");
            }
          };
        }}
      />

      {refundPurchase != null &&
        <ConfirmModal
          title={"Confirm Refund?"}
          visible={refundPurchase != null}
          loading={loading}
          onCancel={() => setRefundPurchase(null)}
          onSubmit={() => onRefund()}
        >
          <div style={{ marginBottom: "20px" }}>
            <BasicText>
              You have selected to refund {refundPurchase.brand_name} for their purchase of {refundPurchase.text} on {refundPurchase.time_string}.
            </BasicText>
          </div>

          <div style={{ marginBottom: "20px" }}>
            <BasicText useSpan>
              The total of
              <BasicText bold useSpan>
                {` ${refundPurchase.amount} `}
              </BasicText>
              will be refunded to their account. Would you like to send the refund now.
            </BasicText>
          </div>
        </ConfirmModal>
      }
    </div>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  refundChallengePayment,
  refundBundlePayment
})(withTheme(PurchasesTable));
