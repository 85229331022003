import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form } from 'antd';
import { withTheme } from 'styled-components';
import GeographicItem from '@challenges/stats/GeographicItem';
import styles from './ChallengeStats.module.css';
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor';
import { BottomTableLoader } from '@components';

const FullStatesModal = ({
  stats,
  onCancel,
  theme
}) => {

  return (
    <Modal
      title="Geographic Reach"
      visible
      footer={null}
      onCancel={() => onCancel()}
      bodyStyle={{overflowY: 'scroll'}}
    >
      <div className={styles.full_leaderboard_modal}>
        {stats.state_counts.map((data, i) => {
          return (
            <GeographicItem
              key={i}
              data={data}
              rank={i+1}
            />
          );
        })}

        <div style={{ width: "100%", height: "24px" }} />

        <div style={{ width: "100%", height: "24px", position: 'absolute', bottom: 0, left: 0, backgroundColor: "#fff" }} />
      </div>
    </Modal>
  );
}

export default connect(null, {

})(withTheme(FullStatesModal));
