import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form, Space } from 'antd';
import { withTheme } from 'styled-components';
import { PlayCircleOutlined, CheckCircleFilled, WarningFilled } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, BasicButton, ShortInput, LongInput } from '@components';
import styles from './ReviewHosts.module.css';

const ResponseButtons = ({
  approveText,
  rejectText,
  onApprove,
  onReject,
  showRejected,
  rejectedReason,
  updateRejectedReason,
  disabled,
  theme
}) => {

  return (
    <div>
      <div style={{ width: "100%", display: "flex", marginTop: "30px" }}>
        <div
          className={styles.response_button} style={{ backgroundColor: disabled ? "#dedede" : theme.colors.blurple }}
          onClick={() => !disabled && onApprove()}
        >
          {approveText}
        </div>

        <div style={{ width: "20px" }}/>

        <div
          className={styles.response_button} style={{ backgroundColor: disabled ? "#dedede" : theme.colors.danger }}
          onClick={() => !disabled && onReject("stupid reason")}
        >
          {rejectText}
        </div>
      </div>

      {showRejected &&
        <div style={{ marginTop: "12px" }}>
          <LongInput
            inputHeader='Rejected Reason'
            placeholder="Reason for rejection..."
            onChange={(val) => {
              updateRejectedReason(val);
            }}
            value={rejectedReason}
          />
        </div>
      }
    </div>
  );
};

export default connect(null, {

})(withTheme(ResponseButtons));
