import {
  UPDATE_NEW_CHALLENGE,
  RESET_NEW_CHALLENGE,
  SET_CHALLENGE_LIST,
  UPDATE_VIEWED_CHALLENGE,
  RESET_VIEWED_CHALLENGE,
  REFRESH_CHALLENGE_LIST,
  UPDATE_FULFILL_SECTION
} from '@actions/types';
import {
  NEW_CHALLENGE_STATES,
  NEW_CHALLENGE_STEPS
} from '@global';

// using snake case to be consistent with rails server
const getInitialChallengeInfo = () => {
  return {
    showNewChallengeSteps: false,
    currentStepIndex: 0,
    challenge_id: null,
    refreshChallengeList: false,
    basic_info: {},
    brand_info: {},
    media_info: {},
    promo_info: {},
    prize_info: [],
    rules_info: {
      "Exluding States": "",
      "Prizes monetary value": "",
      "Sponsor Brand Legal Name": "",
      "International Countries": "",
      "Legal Contact": "",
      "Insurance Disclaimer": ""
    }
  };
};

const getInitialState = () => {
  return {
    newChallengeInfo: getInitialChallengeInfo(),
    viewedChallengeInfo: getInitialChallengeInfo(),
    challengeList: [],
    fetchedChallenges: false,
    refreshChallengeList: false,
    viewingFulfillHosts: true
  };
};

const INITIAL_STATE = getInitialState();

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_NEW_CHALLENGE:
      return {...state, newChallengeInfo: { ...state.newChallengeInfo, ...action.payload }};
    case UPDATE_VIEWED_CHALLENGE:
      return {...state, viewedChallengeInfo: { ...state.viewedChallengeInfo, ...action.payload }};
    case RESET_NEW_CHALLENGE: {
      return {...state, ...getInitialState(), challengeList: state.challengeList, fetchedChallenges: true };
    }
    case RESET_VIEWED_CHALLENGE: {
      return {...state, ...getInitialState(), challengeList: state.challengeList, fetchedChallenges: true };
    }
    case SET_CHALLENGE_LIST: {
      return {...state, challengeList: action.payload, fetchedChallenges: true };
    }
    case REFRESH_CHALLENGE_LIST: {
      return {...state, refreshChallengeList: action.payload };
    }
    case UPDATE_FULFILL_SECTION: {
      return {...state, viewingFulfillHosts: action.payload };
    }
    default:
      return state;
  }
};
