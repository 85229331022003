import React, { useState, useEffect } from 'react';
import styles from '@review/Review.module.css';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import LoadingWrapper from '@components/LoadingWrapper';
import { useHistory } from 'react-router-dom';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { withTheme } from 'styled-components';
import { BasicButton, MuseoText, BasicText } from '@components';
import ReviewStats from '@review/ReviewStats';
import { getPendingReports } from '@actions';

const ReportHome = ({
  getPendingReports,
  theme
}) => {

  const history = useHistory();
  const [overallStats, setOverallStats] = useState(null);

  useEffect(() => {
    getPendingReports(
      (response) => setOverallStats(response),
      () => { }
    )
  }, []);

  const gridStat = (number, stat, useRed = false) => {
    return (
      <div className={styles.grid_stat_container}>
        <div className={styles.grid_trio_number} style={{ color: useRed ? theme.colors.danger : "#000" }}>
          {number}
        </div>
        <div className={styles.grid_trio_text}>
          {stat}
        </div>
      </div>
    )
  }

  const header = () => {
    return (
      <div className={styles.fixed_header}>
        <div className={styles.header_container}>
          <div className={styles.header_text}>
            <MuseoText>Reported</MuseoText>
          </div>
        </div>
      </div>
    )
  }

  return (
    <PageWrapper>
      <LoadingWrapper isLoading={overallStats == null} loadingText="Fetching Review Data" size="large">
        <FixedHeaderContainer hideHeader additionalContent={header()}>
          {overallStats &&
            <div className={styles.home_container}>
              <div className={styles.stats_section_container}>
                <ReviewStats
                  title={"Entries"}
                  stats={[
                    { title: "Pending", value: overallStats.submissions.pending, showDanger: true },
                    { title: "Kept Live", value: overallStats.submissions.kept_live, showDanger: false },
                    { title: "Removed", value: overallStats.submissions.removed, showDanger: false },
                  ]}
                />
                <BasicButton
                  text="Review Entry Reports"
                  type="primary"
                  style={{ marginTop: "16px", width: "100%", height: "44px" }}
                  onClick={() => {
                    history.push({
                      pathname: `/reported_entries`,
                    });
                  }}
                />
              </div>

              <div className={styles.stats_section_container}>
                <ReviewStats
                  title={"Challenges"}
                  stats={[
                    { title: "Pending", value: overallStats.challenges.pending, showDanger: true },
                    { title: "Kept Live", value: overallStats.challenges.kept_live, showDanger: false },
                    { title: "Removed", value: overallStats.challenges.removed, showDanger: false },
                  ]}
                />
                <BasicButton
                  text="Review Challenge Reports"
                  type="primary"
                  style={{ marginTop: "16px", width: "100%", height: "44px" }}
                  onClick={() => {
                    history.push({
                      pathname: `/reported_challenges`,
                    });
                  }}
                />
              </div>

              <div className={styles.stats_section_container}>
                <ReviewStats
                  title={"Members"}
                  stats={[
                    { title: "Pending", value: overallStats.users.pending, showDanger: true },
                    { title: "Kept Live", value: overallStats.users.kept_live, showDanger: false },
                    { title: "Removed", value: overallStats.users.removed, showDanger: false },
                  ]}
                />
                <BasicButton
                  text="Review Member Reports"
                  type="primary"
                  style={{ marginTop: "16px", width: "100%", height: "44px" }}
                  onClick={() => {
                    history.push({
                      pathname: `/reported_members`,
                    });
                  }}
                />
              </div>

              <div className={styles.stats_section_container}>
                <ReviewStats
                  title={"Host Reactions"}
                  stats={[
                    { title: "Pending", value: overallStats.reactions.pending, showDanger: true },
                    { title: "Kept Live", value: overallStats.reactions.kept_live, showDanger: false },
                    { title: "Removed", value: overallStats.reactions.removed, showDanger: false },
                  ]}
                />
                <BasicButton
                  text="Review Host Reaction Reports"
                  type="primary"
                  style={{ marginTop: "16px", width: "100%", height: "44px" }}
                  onClick={() => {
                    history.push({
                      pathname: `/reported_reactions`,
                    });
                  }}
                />
              </div>

              <div className={styles.stats_section_container}>
                <ReviewStats
                  title={"Video Reactions"}
                  stats={[
                    { title: "Pending", value: overallStats.video_reaction.pending, showDanger: true },
                    { title: "Kept Live", value: overallStats.video_reaction.kept_live, showDanger: false },
                    { title: "Removed", value: overallStats.video_reaction.removed, showDanger: false },
                  ]}
                />
                <BasicButton
                  text="Review Video Reaction Reports"
                  type="primary"
                  style={{ marginTop: "16px", width: "100%", height: "44px" }}
                  onClick={() => {
                    history.push({
                      pathname: `/reported_video_reactions`,
                    });
                  }}
                />
              </div>

              {true &&
                <div className={styles.stats_section_container}>
                  <ReviewStats
                    title={"Hyype Groups"}
                    stats={[
                      { title: "Pending", value: overallStats.hyype_groups.pending, showDanger: true },
                      { title: "Kept Live", value: overallStats.hyype_groups.kept_live, showDanger: false },
                      { title: "Removed", value: overallStats.hyype_groups.removed, showDanger: false },
                    ]}
                  />
                  <BasicButton
                    text="Review Hyype Groups Reports"
                    type="primary"
                    style={{ marginTop: "16px", width: "100%", height: "44px" }}
                    onClick={() => {
                      history.push({
                        pathname: `/reported_hyype_groups`,
                      });
                    }}
                  />
                </div>
              }
              {true &&
                <div className={styles.stats_section_container}>
                  <ReviewStats
                    title={"Hyype Group Entries"}
                    stats={[
                      { title: "Pending", value: overallStats.hyype_group_entries.pending, showDanger: true },
                      { title: "Kept Live", value: overallStats.hyype_group_entries.kept_live, showDanger: false },
                      { title: "Removed", value: overallStats.hyype_group_entries.removed, showDanger: false },
                    ]}
                  />
                  <BasicButton
                    text="Review Hyype Group Entries Reports"
                    type="primary"
                    style={{ marginTop: "16px", width: "100%", height: "44px" }}
                    onClick={() => {
                      history.push({
                        pathname: `/reported_hyype_group_entries`,
                      });
                    }}
                  />
                </div>
              }
            </div>
          }
        </FixedHeaderContainer>
      </LoadingWrapper>
    </PageWrapper>
  );
};

export default connect(null, {
  getPendingReports
})(withTheme(ReportHome));
