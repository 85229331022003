const theme = {
  colors: {
    normalText: '#fff',
    background: '#2C2C2C',
    backgroundShade: '#222222',
    backgroundTint: '#444444',
    lightShade: "#9E9E9E",
    blurple: '#7176F9',
    mint: '#BBFFCA',
    yellow: '#FFC700',
    blue: '#226FE0',
    errorRed: 'red',
    notification: 'orange',
    danger: '#ff4b4b',
    snow: "#fff",
    grayBackground: '#E5E5E5',
    buttonDisabled: '#9e9e9e',
    inputBackground: "#f4f4f4",
    approveGreen: "#0BDA51"
  },
  fonts: {
    bold: 'Roboto-Bold',
    regular: 'Roboto',
    titleBold: 'MuseoModerno-Bold'
  },
};

export default theme;
