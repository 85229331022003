import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { DatePicker, Form, Space, TimePicker, Button } from 'antd';
import moment from 'moment-timezone';
import { useHistory, Link } from 'react-router-dom';
import { VideoCameraAddOutlined, FileAddOutlined } from '@ant-design/icons';
import { TitleText, BasicText, BasicButton, ShortInput, LongInput } from '@components';
import styles from './Create.module.css';

const AssetPreview = ({
  url,
  fileName,
  onClickEdit,
  assetName,
  instructions,
  showInstructionsOnSide,
  isVideo
}) => {
  const videoRef = useRef();
  const previousUrl = useRef(url);

  useEffect(() => {
    if (previousUrl.current === url) {
      return;
    }

    if (videoRef.current) {
      videoRef.current.load();
    }

    previousUrl.current = url;
  }, [url]);


  return (
    <div>
      <div style={{ display: "flex" }}>
        <div className={styles.asset_placeholder}>
          {url ?
            <div
              style={{ cursor: "pointer", marginTop: "8px", marginBottom: "5px" }}
              onClick={() => window.open(url, "_blank")}
            >
              {isVideo ?
                <video ref={videoRef} width="160" height="160" style={{ backgroundColor: "#000", borderRadius: "12px" }}>
                  <source src={url} type="video/mp4" />
                </video>
              :
                <img src={url} width="160" height="160" style={{ borderRadius: "12px", backgroundColor: "#222" }} />
              }
            </div>
          :
            <div className={styles.blank_asset}>
              {isVideo ?
                <VideoCameraAddOutlined style={{ color: "#fff", fontSize: "40px" }} />
              :
                <FileAddOutlined style={{ color: "#fff", fontSize: "40px" }} />
              }
            </div>
          }
        </div>
        {instructions && showInstructionsOnSide &&
          <div style={{ display: "flex", flexDirection: 'column', justifyContent: "flex-end", paddingBottom: "20px" }}>
            <div className={styles.instructions_label} style={{ marginLeft: "20px" }}>
              <BasicText>{instructions}</BasicText>
            </div>
            <BasicButton
              style={{ marginTop: "0px", marginLeft: "12px" }}
              type="primary"
              onClick={() => onClickEdit()}
              text={url ? `Edit ${assetName}` : `Upload ${assetName}`}
            />
          </div>
        }
      </div>

      {fileName &&
        <div style={{ maxWidth: "200px", marginBottom: "12px"}}>
          <BasicText color="blue" fontSize="11px">
            {fileName}
          </BasicText>
        </div>
      }

      {instructions && !showInstructionsOnSide &&
        <div className={styles.instructions_label}>
          <BasicText>{instructions}</BasicText>
        </div>
      }

      {!showInstructionsOnSide &&
        <BasicButton
          style={{ marginTop: "4px", marginBottom: "4px", marginRight: "12px" }}
          type="primary"
          onClick={() => onClickEdit()}
          text={url ? `Edit ${assetName}` : `Upload ${assetName}`}
        />
      }
    </div>
  );
};

export default AssetPreview;
