import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import { useHistory } from 'react-router-dom';
import { fetchTeamList } from '@actions';
import { withTheme } from 'styled-components';
import { NAV_SECTIONS, CHALLENGE_TABLE_FILTERS } from '@global';
import ReportedMemberTable from '@reports/members/ReportedMembersTable';
import styles from '@review/entries/ReviewEntry.module.css';
import LoadingWrapper from '@components/LoadingWrapper';
import CreateContainer from '@challenges/create/CreateContainer';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { MuseoText, BasicButton, BottomTableLoader, SearchBar, FilterTabs } from '@components';
import { Input, Space } from 'antd';
import useOnScreen from '@components/useOnScreen';
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor';
import { getUserReports } from '@actions';

const ReportedMembers = ({
  adminUser,
  getUserReports,
  theme
}) => {

  const history = useHistory();
  const [members, setMembers] = useState([]);
  const [seenIds, setSeenIds] = useState([]);
  const [isMore, setIsMore] = useState(true);
  const isFetchingMoreFromBottom = useRef(false);
  const [hasFetchedMembers, setHasFetchedMembers] = useState(false);


  useEffect(() => {
    fetchReports();
  }, []);

  const onBottomLoadingVisible = (isVisible) => {
    if (isVisible) {
      if (!isFetchingMoreFromBottom.current && isMore) fetchReports();
    }
  }

  const fetchReports = () => {
    if (!isMore && isFetchingMoreFromBottom.current) return;
    isFetchingMoreFromBottom.current = true;

    getUserReports(
      members ? members.map((s) => s.id) : [],
      (newMembers) => {
        setMembers(members.concat(newMembers));
        setSeenIds(seenIds.concat(newMembers.map((m) => m.id)));
        setIsMore(newMembers.length === 20);
        isFetchingMoreFromBottom.current = false;
        setHasFetchedMembers(true);
      },
      () => {}
    );
  };

  return (
    <PageWrapper>
      <FixedHeaderContainer
        text={'Reported Members'}
        paddingBuffer="80px"
        paddingTop="56px"
      >
        {hasFetchedMembers &&
          <div className={styles.review_entries_home_container}>
            <BasicButton
              text="Review all"
              type="primary"
              style={{ width: "360px", height: "44px" }}
              onClick={() => {
                history.push({
                  pathname: `/reported_member`,
                });
              }}
            />

            <div className={styles.or_search_text}>
              -- or --
            </div>
            <>
              <ReportedMemberTable
                memberList={members}
                onMemberSelected={(member) => {
                  history.push({
                    pathname: `/reported_member/${member.id}`,
                  });
                }}
              />
              {isMore &&
                <VisibilitySensor onChange={onBottomLoadingVisible}>
                  <BottomTableLoader />
                </VisibilitySensor>
              }
            </>
          </div>
        }
      </FixedHeaderContainer>
    </PageWrapper>
  )
}

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getUserReports
})(withTheme(ReportedMembers));
