import React, { useState } from 'react';
import styles from './Members.module.css';
import { useHistory } from 'react-router-dom';
import { BasicButton } from '@components';
import AddThunderModal from '@members/AddThunderModal';

const ThunderSection = ({
  member,
  onUpdatedThunder
}) => {

  const history = useHistory();
  const { thunder_info } = member;
  const [showThunderModal, setShowThunderModal] = useState(false);

  return (
    <div className={styles.image_and_stats} style={{ marginTop: "0px" }}>
      <div className={styles.claps_balance_section}>
        <div className={styles.claps_balance_text}>
          {thunder_info.balance}
        </div>

        <div>
          <div className={styles.claps_subtitle_text}>
            Thunderous
          </div>
          <div className={styles.claps_subtitle_text}>
            Balance
          </div>
        </div>
      </div>

      <div className={styles.claps_buttons_section}>
        <BasicButton
          text="Add"
          type="primary"
          onClick={() => {
              setShowThunderModal(true);
          }}
          style={{ height: "44px", width: "160px" }}
        />
      </div>

      {showThunderModal &&
        <AddThunderModal
          visible={showThunderModal}
          member={member}
          onCancel={() => setShowThunderModal(false)}
          onUpdatedThunder={(newMember) => {
            onUpdatedThunder(newMember);
            setShowThunderModal(false);
          }}
        />
      }
    </div>
  );
};

export default ThunderSection;
