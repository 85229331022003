import React, { useState, useEffect } from 'react';
import styles from '@review/Review.module.css';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import LoadingWrapper from '@components/LoadingWrapper';
import { useHistory } from 'react-router-dom';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { withTheme } from 'styled-components';
import { BasicButton, MuseoText, BasicText } from '@components';
import ReviewStats from '@review/ReviewStats';
import { CSVDownload } from "react-csv";
import { getMemberReferrals } from '@actions';

const DownloadReferrals = ({
  match,
  getMemberReferrals,
  theme
}) => {

  const [downloadData, setDownloadData] = useState(null);

  useEffect(() => {
    getMemberReferrals(
      (data) => {
        console.log("data", data);
        setDownloadData(data);
      },
      () => {
        console.log("error")
      }
    )
  }, []);

  return (
    <PageWrapper>
      <div style={{ paddingTop: "44px" }}>
        <MuseoText>Downloading CSV for referrals...</MuseoText>
      </div>

      <div style={{ marginTop: "120px" }}>
        {downloadData != null ?
          <div>
            <BasicText>
              Data downloaded to downloads folder
            </BasicText>
            <CSVDownload data={downloadData} target="_blank" />
          </div>
        :
          <LoadingWrapper isLoading loadingText="Downloading Data" />
        }
      </div>

    </PageWrapper>
  );
};

export default connect(null, {
  getMemberReferrals
})(withTheme(DownloadReferrals));
