import React from 'react';
import { Button } from 'antd';
import { BasicText } from '@components';

const BasicButton = (props) => {
  return (
    <Button
      {...props}
      onClick={(e) => {
        if (props.stopPropagation) e.stopPropagation();
        props.onClick();
      }}
      style={{ ...{ height: "36px", paddingLeft: "40px", paddingRight: "40px" }, ...props.style }}
      danger={props.danger}
    >
      {props.text}
    </Button>
  )
}

export default BasicButton;
