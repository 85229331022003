import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form } from 'antd';
import { withTheme } from 'styled-components';
import LeaderboardItem from '@challenges/stats/LeaderboardItem';
import { getMoreLeaderBoard } from '@actions';
import styles from './ChallengeStats.module.css';
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor';
import { BottomTableLoader } from '@components';

const FullLeaderboardModal = ({
  challenge,
  initialSubmissions,
  getMoreLeaderBoard,
  onCancel,
  theme
}) => {
  const [submissions, setSubmissions] = useState(initialSubmissions);
  const [seenIds, setSeenIds] = useState([]);
  const [isMore, setIsMore] = useState(true);
  const isFetchingMoreFromBottom = useRef(false);

  const fetchMore = () => {
    if (!isMore || isFetchingMoreFromBottom.current) return;
    isFetchingMoreFromBottom.current = true;

    console.log("FETCHING MORE");

    getMoreLeaderBoard(
      challenge.challenge_id,
      submissions.map((s) => s.id),
      (newSubmssions) => {
        setSubmissions(submissions.concat(newSubmssions));
        isFetchingMoreFromBottom.current = false;
        setIsMore(newSubmssions.length === 20);
      },
      () => {}
    );
  };

  const onBottomLoadingVisible = (isVisible) => {
    if (isVisible) fetchMore();
  }



  return (
    <Modal
      title="Leaderboard"
      visible
      footer={null}
      onCancel={() => onCancel()}
      bodyStyle={{overflowY: 'scroll'}}
    >
      <div className={styles.full_leaderboard_modal}>
        {submissions.map((submission, i) => {
          return <LeaderboardItem key={i} submission={submission}/>;
        })}

        {isMore &&
          <VisibilitySensor onChange={onBottomLoadingVisible}>
            <BottomTableLoader size={12} />
          </VisibilitySensor>
        }

        <div style={{ width: "100%", height: "24px" }} />

        <div style={{ width: "100%", height: "24px", position: 'absolute', bottom: 0, left: 0, backgroundColor: "#fff" }} />
      </div>
    </Modal>
  );
}

export default connect(null, {
  getMoreLeaderBoard
})(withTheme(FullLeaderboardModal));
