import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import { Table, Icon, Spin } from 'antd';
import {
  TitleText,
  BasicText,
  ShortInput,
  LongInput,
  CopyUrl,
  BasicButton,
} from '@components';
import styles from '@components/HeaderAndTable.module.css';

const HostTable = ({ hostList, onHostSelcted, theme }) => {
  const getHostTableData = () => {
    let dataArray = [];
    hostList.forEach((host, i) => {
      dataArray.push({
        id: host.id,
        username: host.username,
        status: host.status,
        business_name: host.business_name,
        num_challenges: host.num_challenges,
        host_name: host.host_name,
        host_email: host.host_email,
        is_approved: host.is_approved,
      });
    });
    return dataArray;
  };

  const getColumns = () => {
    let columns = [
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Username</div>;
        },
        dataIndex: 'username',
        key: 'username',
        width: 100,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: 150,
                  overflow: 'hidden',
                }}
              >
                <BasicText fontSize="15px" bold>
                  {text}
                </BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Name</div>;
        },
        dataIndex: 'business_name',
        key: 'business_name',
        width: 80,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: 150,
                  overflow: 'hidden',
                }}
              >
                <BasicText fontSize="15px">{text}</BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Contact Email</div>;
        },
        dataIndex: 'host_email',
        key: 'host_email',
        width: 80,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  fontSize: '15px',
                  cursor: 'pointer',
                  color: theme.colors.blurple,
                  textDecoration: 'underline',
                }}
                onClick={(e) => {
                  //e.preventDefault();
                  window.open(`mailto:${text}`, '_blank');
                }}
              >
                {text}
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Contact Name</div>;
        },
        dataIndex: 'host_name',
        key: 'host_name',
        width: 80,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px">{text}</BasicText>,
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Challenges</div>;
        },
        dataIndex: 'num_challenges',
        key: 'num_challenges',
        width: 80,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px">{text}</BasicText>,
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Account Status</div>;
        },
        dataIndex: 'status',
        key: 'status',
        width: 80,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px">{text}</BasicText>,
          };
        },
      },
    ];

    return columns;
  };

  return (
    <div
      className={styles.member_container_style}
      style={{ marginTop: '40px' }}
    >
      <Table
        pagination={false}
        columns={getColumns()}
        dataSource={getHostTableData()}
        rowKey="id"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              onHostSelcted(record);
            },
          };
        }}
      />
    </div>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {})(withTheme(HostTable));
