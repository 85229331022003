import React, { useState } from 'react';
import styles from './Members.module.css';
import { BasicButton, BasicText, DropDown, ConfirmModal } from '@components';
import { Table, Icon, Spin, Switch, Modal, Form } from 'antd';
import { connect } from 'react-redux';
import { sendWegiftCard } from '@actions';
import { showSuccessMessage, showErrorMessage } from '@global';

const SendGiftCardButton = ({ member, sendWegiftCard }) => {
  const [showEnterAmount, setShowEnterAmount] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [amount, setAmount] = useState(null);
  const [loading, setLoading] = useState(false);

  const onConfirm = () => {
    setLoading(true);
    sendWegiftCard(
      member.id,
      amount,
      (response) => {
        showSuccessMessage(
          `$${amount}.00 sent to ${member.first_name} ${member.last_name}`
        );
        setLoading(false);
        setShowConfirm(false);
        setShowEnterAmount(false);
      },
      (error) => {
        setLoading(false);
        showErrorMessage('Something went wrong.');
      }
    );
  };

  return (
    <div>
      <BasicButton
        key="submit"
        type="secondary"
        text="Send Gift Card"
        onClick={() => setShowEnterAmount(true)}
        style={{ fontSize: '14px' }}
      />

      {showEnterAmount && (
        <Modal
          title="Send Gift Card"
          visible={showEnterAmount}
          onCancel={() => setShowEnterAmount(false)}
          footer={null}
        >
          <div style={{ marginBottom: '20px' }}>
            <BasicText style={{ marginBottom: '10px' }}>
              Enter the amount you would like to send to {member.first_name}{' '}
              {member.last_name}.
            </BasicText>
          </div>

          <DropDown
            options={[...Array(25).keys()]}
            placeholder="Gift Card Amount"
            onSelect={(value) => {
              setAmount(value);
            }}
            minWidth="300px"
          />

          <div
            style={{
              marginTop: '40px',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <BasicButton
              type="default"
              text="Cancel"
              onClick={() => setShowEnterAmount(false)}
              style={{ width: '48%' }}
            />

            <BasicButton
              type="primary"
              text="Continue"
              disabled={amount === null}
              onClick={() => setShowConfirm(true)}
              style={{ width: '48%' }}
            />
          </div>
        </Modal>
      )}

      {showConfirm && (
        <ConfirmModal
          title={'Confirm Send Gift Card'}
          visible={showConfirm}
          loading={loading}
          onCancel={() => setShowConfirm(false)}
          onSubmit={() => {
            onConfirm();
          }}
        >
          <BasicText marginBottom="20px">
            Are you sure you want to send ${amount} to {member.first_name}{' '}
            {member.last_name}?
          </BasicText>
        </ConfirmModal>
      )}
    </div>
  );
};

export default connect(null, { sendWegiftCard })(SendGiftCardButton);
