import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

const DropDown = (props) => {
  const renderOptions = () => {
    return props.options.map((o) =>
      <Option key={o} value={o}>{o}</Option>
    )
  }

  return (
    <Select
      defaultValue={props.defaultOption}
      placeholder={props.placeholder}
      onChange={(val) => props.onSelect(val)}
      style={{ minWidth: props.minWidth ? props.minWidth : 0, fontSize: "16px" }}
      disabled={props.disabled}
    >
      {renderOptions()}
    </Select>
  )
}

export default DropDown;
