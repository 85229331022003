import React from 'react';
import { Input } from 'antd';
const { TextArea } = Input;
import { BasicText } from '@components';
import './inputs.css';

const LongInput = (props) => {
  return (
    <div className="short-input-container">
      {props.inputHeader &&
        <div className="input_label">
          <BasicText bold>{props.inputHeader}</BasicText>
        </div>
      }
      <TextArea
        placeholder={props.placeholder}
        autoSize={{ minRows: props.minRows ? props.minRows : 5, maxRows: props.maxRows ? props.maxRows : 8 }}
        onChange={(e) => props.onChange(e.target.value)}
        value={props.value}
        style={{ fontSize: "16px", borderRadius: "6px", minWidth: "100%", backgroundColor: "#f4f4f4", borderWidth: "0px" }}
      />
    </div>
  )
}

export default LongInput;
