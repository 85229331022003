import React, { useState, useEffect } from 'react';
import styles from './Review.module.css';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import LoadingWrapper from '@components/LoadingWrapper';
import { useHistory } from 'react-router-dom';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { withTheme } from 'styled-components';
import { BasicButton, MuseoText, BasicText } from '@components';
import ReviewStats from '@review/ReviewStats';
import { getPendingReviews } from '@actions';

const ReviewHome = ({
  adminUser,
  getPendingReviews,
  theme
}) => {

  const history = useHistory();
  const [overallStats, setOverallStats] = useState(null);

  useEffect(() => {
    getPendingReviews(
      (response) => setOverallStats(response),
      () => {}
    )
  }, []);

  const gridStat = (number, stat, useRed=false) => {
    return (
      <div className={styles.grid_stat_container}>
        <div className={styles.grid_trio_number} style={{ color: useRed ? theme.colors.danger : "#000" }}>
          {number}
        </div>
        <div className={styles.grid_trio_text}>
          {stat}
        </div>
      </div>
    )
  }

  const header = () => {
    return (
      <div className={styles.fixed_header}>
        <div className={styles.header_container}>
          <div className={styles.header_text}>
            <MuseoText>Review</MuseoText>
          </div>
        </div>
      </div>
    )
  }

  return (
    <PageWrapper>
      <LoadingWrapper isLoading={overallStats == null} loadingText="Fetching Review Data" size="large">
        <FixedHeaderContainer hideHeader additionalContent={header()}>
          {overallStats &&
            <div className={styles.home_container}>
              <div className={styles.stats_section_container}>
                <ReviewStats
                  title={"Entries"}
                  stats={[
                    { title: "Pending", value: overallStats.submissions.pending, showDanger: true },
                    { title: "Approved", value: overallStats.submissions.approved, showDanger: false },
                    { title: "Rejected", value: overallStats.submissions.rejected, showDanger: false },
                  ]}
                />
                <BasicButton
                  text="Review Entries"
                  type="primary"
                  style={{ marginTop: "16px", width: "100%", height: "44px" }}
                  onClick={() => {
                    history.push({
                      pathname: `/review_entries`,
                    });
                  }}
                />
              </div>

              {adminUser.role === "ADMIN" &&
                <div className={styles.stats_section_container}>
                  <ReviewStats
                    title={"Portal Challenges"}
                    stats={[
                      { title: "Pending", value: overallStats.portal_challenges.pending, showDanger: true },
                      { title: "Approved", value: overallStats.portal_challenges.approved, showDanger: false },
                      { title: "Rejected", value: overallStats.portal_challenges.rejected, showDanger: false },
                    ]}
                  />
                  <BasicButton
                    text="Review Portal Challenges"
                    type="primary"
                    style={{ marginTop: "16px", width: "100%", height: "44px" }}
                    onClick={() => {
                      history.push({
                        pathname: `/review_portal_challenges`,
                      });
                    }}
                  />
                </div>
              }

              {adminUser.role === "ADMIN" &&
                <div className={styles.stats_section_container}>
                  <ReviewStats
                    title={"MGC Challenges"}
                    stats={[
                      { title: "Pending", value: overallStats.challenges.pending, showDanger: true },
                      { title: "Approved", value: overallStats.challenges.approved, showDanger: false },
                      { title: "Rejected", value: overallStats.challenges.rejected, showDanger: false },
                    ]}
                  />
                  <BasicButton
                    text="Review MGC Challenges"
                    type="primary"
                    style={{ marginTop: "16px", width: "100%", height: "44px" }}
                    onClick={() => {
                      history.push({
                        pathname: `/review_mgc_challenges`,
                      });
                    }}
                  />
                </div>
              }

              {adminUser.role === "ADMIN" &&
                <div className={styles.stats_section_container}>
                  <ReviewStats
                    title={"Hosts"}
                    stats={[
                      { title: "Pending", value: overallStats.brands.pending, showDanger: true },
                      { title: "Approved", value: overallStats.brands.approved, showDanger: false },
                      { title: "Rejected", value: overallStats.brands.rejected, showDanger: false },
                    ]}
                  />
                  <BasicButton
                    text="Review Hosts"
                    type="primary"
                    style={{ marginTop: "16px", width: "100%", height: "44px" }}
                    onClick={() => {
                      history.push({
                        pathname: `/review_hosts`,
                      });
                    }}
                  />
                </div>
              }

              {adminUser.role === "ADMIN" &&
                <div className={styles.stats_section_container}>
                  <ReviewStats
                    title={"Video Reactions"}
                    stats={[
                      { title: "Pending", value: overallStats.video_reactions.pending, showDanger: true },
                      { title: "Approved", value: overallStats.video_reactions.approved, showDanger: false },
                      { title: "Rejected", value: overallStats.video_reactions.rejected, showDanger: false },
                    ]}
                  />
                  <BasicButton
                    text="Review Reactions"
                    type="primary"
                    style={{ marginTop: "16px", width: "100%", height: "44px" }}
                    onClick={() => {
                      history.push({
                        pathname: `/review_reactions`,
                      });
                    }}
                  />
                </div>
              }
            </div>
          }
        </FixedHeaderContainer>
      </LoadingWrapper>
    </PageWrapper>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getPendingReviews
})(withTheme(ReviewHome));
