import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, BasicButton, ShortInput, LongInput } from '@components';
import IndividualPrize from '@challenges/create/IndividualPrize';
import {
  NEW_CHALLENGE_STATES,
  NEW_CHALLENGE_STEPS,
  CHALLENGE_ASSET_TYPES
} from '@global';
import styles from './Create.module.css';

const RulesInfo = ({
  updateChallenge,
  rules_info,
  isEdit
}) => {

  console.log("rules info", rules_info);


  const rulesList = () => {
    return Object.keys(rules_info).map((rule, index) =>
      <div key={index} className={styles.rules_container}>
        <Form.Item>
          <ShortInput
            inputHeader={rule}
            onChange={(val) => {
              rules_info[rule] = val;
              updateChallenge({ rules_info: rules_info });
            }}
            value={rules_info[rule]}
          />
        </Form.Item>
      </div>
    );
  };

  return (
    <div style={ isEdit ? {} : { height: '350px', overflowY: 'scroll' }}>
      {rulesList()}
    </div>
  );
};

const mapStatesToProps = (state) => {
  const { newChallengeInfo } = state.challenge;
  return { newChallengeInfo };
};

export default connect(mapStatesToProps, {
})(RulesInfo);
