import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { withTheme } from "styled-components";
import { connect } from "react-redux";
import { Table, Icon, Spin } from "antd";
import {
  TitleText,
  BasicText,
  ShortInput,
  LongInput,
  CopyUrl,
  BasicButton,
  ConfirmModal
} from "@components";
import styles from "@components/HeaderAndTable.module.css";

const PayoutsTable = ({
  payouts,
  theme
}) => {

  const getPayoutsTableData = () => {
    let dataArray = [];
    payouts.forEach((purchase, i) => {
      dataArray.push({
        id: purchase.id,
        time_string: purchase.time_string,
        amount: purchase.amount,
        text: purchase.text,
        stripe_transfer_id: purchase.stripe_transfer_id
      });
    });
    return dataArray;
  };

  const getColumns = () => {
    let columns = [
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Description</div>;
        },
        dataIndex: "text",
        key: "text",
        width: 180,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <BasicText fontSize="15px" bold>
                  {text}
                </BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Date</div>;
        },
        dataIndex: "time_string",
        key: "time_string",
        width: 180,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: 180
                }}
              >
                <BasicText fontSize="15px">{text}</BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Amount</div>;
        },
        dataIndex: "amount",
        key: "amount",
        width: 80,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: 150,
                  overflow: "hidden"
                }}
              >
                <BasicText fontSize="15px">{text}</BasicText>
              </div>
            ),
          };
        },
      }
    ];

    return columns;
  };


  return (
    <div className={styles.member_container_style} style={{ marginTop: "40px" }}>
      <Table
        pagination={false}
        columns={getColumns()}
        dataSource={getPayoutsTableData()}
        rowKey="id"
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              window.open(`https://dashboard.stripe.com/connect/transfers/${record.stripe_transfer_id}`, "_blank");
            }
          };
        }}
      />

    </div>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {

})(withTheme(PayoutsTable));
