import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Icon, Spin } from 'antd';
import { TitleText, BasicText, ShortInput, LongInput, CopyUrl, BasicButton } from '@components';
import styles from '@components/HeaderAndTable.module.css';

const ReportedMemberTable = ({
  memberList,
  onMemberSelected,
  marginTop
}) => {

  const getTeamTableData = () => {
    let dataArray = [];
    memberList.forEach((member, i) => {
      dataArray.push({
        id: member.id,
        username: member.username,
        first_name: member.first_name,
        last_name: member.last_name,
        num_reports: member.num_reports
      })
    });
    return dataArray;
  }

  const getColumns = () => {
    let columns = [
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Member Name</div>;
        },
        dataIndex: 'username',
        key: 'username',
        width: 120,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px" bold>{text}</BasicText>
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>First Name</div>;
        },
        dataIndex: 'first_name',
        key: 'first_name',
        width: 120,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px">{text}</BasicText>
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Last Name</div>;
        },
        dataIndex: 'last_name',
        key: 'last_name',
        width: 120,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px">{text}</BasicText>
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Reports</div>;
        },
        dataIndex: 'num_reports',
        key: 'num_reports',
        width: 120,
        render(text, record) {
          return {
            children: <BasicText fontSize="12px">{text}</BasicText>,
          };
        }
      }
    ];

    return columns;
  }

  return (
    <div
      className={styles.table_container_style}
      style={{ marginTop: marginTop ? marginTop : "30px" }}
    >
      <Table
        pagination={false}
        columns={getColumns()}
        dataSource={getTeamTableData()}
        rowKey="id"
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              onMemberSelected(record)
            }
          };
        }}
      />
    </div>
  )
}

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {

})(ReportedMemberTable);
