import React, { useEffect } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import Helmet from 'react-helmet';
import thunk from 'redux-thunk';
import './App.css';
import { ThemeProvider } from 'styled-components';
import rootReducer from "./reducers/index";
import AppContainer from './AppContainer';
import theme from './theme';
import "typeface-roboto";

const store = createStore(rootReducer, applyMiddleware(thunk));

// For local dev reload on save: ./bin/webpack-dev-server
const App = () => {
  return (
    <Provider store={store}>
      <Helmet bodyAttributes={{style: 'background-color: #fafafa'}}/>
      <ThemeProvider theme={theme}>
        <AppContainer />
      </ThemeProvider>
    </Provider>
  )
}

export default App;
