import React from 'react';
import styles from './Members.module.css';
import { BasicButton } from '@components';

const BasicInfo = ({
  member
}) => {

  const infoSection = (header, text) => {
    return (
      <div className={styles.info_container}>
        <div className={styles.info_header}>
          {header}
        </div>

        <div className={styles.info_text}>
          {text}
        </div>
      </div>
    )
  }

  return (
    <div>
      {infoSection("First Name", member.first_name)}
      {infoSection("Last Name", member.last_name)}
      {infoSection("Email", member.email)}
      {infoSection("Birthday", member.birthday)}
      {infoSection("Phone", member.phone_number)}
      {infoSection("Zip", member.zip_code)}
      {infoSection("State", member.state)}
    </div>
  );
};

export default BasicInfo;
