import {
  SET_PLAYBACK_SPEED,
  UPDATE_REVIEW_STATS,
  SET_OVERALL_REVIEW_PENDING,
  SET_OVERALL_REPORT_PENDING,
  SET_OVERALL_FULFILL_PENDING
} from '@actions/types';

const INITIAL_STATE = {
  playBackSpeed: 1,
  reviewStats: null,
  overallReviewPending: 0,
  overallReportPending: 0,
  overallFulfillPending: 0
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PLAYBACK_SPEED:
      return { ...state, playBackSpeed: action.payload };
    case UPDATE_REVIEW_STATS:
      return { ...state, reviewStats: action.payload };
    case SET_OVERALL_REVIEW_PENDING:
      return { ...state, overallReviewPending: action.payload };
    case SET_OVERALL_REPORT_PENDING:
      return { ...state, overallReportPending: action.payload };
    case SET_OVERALL_FULFILL_PENDING:
      return { ...state, overallFulfillPending: action.payload };
    default:
      return state;
  }
};
