import React, { useState } from 'react';
import styles from './Members.module.css';
import { Button, Modal } from 'antd';
import { connect } from 'react-redux';
import { BasicButton, BasicText, ConfirmModal } from '@components';
import { withTheme } from 'styled-components';
import logo from '@images/brandmark_light.png';
import { addThunder } from '@actions';

const AddThunderModal = ({
  member,
  onCancel,
  visible,
  addThunder,
  onUpdatedThunder,
  theme
}) => {
  const [addAmount, setAddAmount] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const onAddThunder = () => {
    setLoading(true);
    addThunder(
      member.id,
      addAmount,
      (newMember) => {
        onUpdatedThunder(newMember);
        setShowConfirmModal(false);
      },
      () => {
        setShowConfirmModal(false);
      }
    )
  }

  return (
    <Modal
      title="Thunderous Applause Balance"
      visible={visible}
      footer={null}
      onCancel={() => onCancel()}
    >
      <div className={styles.thunder_modal_container}>
        <img src={logo} alt="logo" style={{ width: "100px", height: "100px", marginBottom: "30px", marginTop: "20px" }} />

        <BasicText textAlign="center">
          {`How many Thunderous Applause would you like to${"\n"}add to @${member.username}'s balance?`}
        </BasicText>


        <div className={styles.thunder_toggle_container}>
          <div
            className={styles.thunder_toggle_button}
            style={{ backgroundColor: addAmount > 0 ? theme.colors.blurple : theme.colors.buttonDisabled }}
            onClick={() => {
              if (addAmount > 0) {
                setAddAmount(addAmount - 1);
              }
            }}
          >
            -
          </div>

          <div className={styles.thunder_balance}>
            {addAmount}
          </div>

          <div
            className={styles.thunder_toggle_button}
            onClick={() => {
              setAddAmount(addAmount + 1);
            }}
          >
            +
          </div>
        </div>

        <div className={styles.thunder_buttons}>
          <BasicButton
            type="secondary"
            text="Cancel"
            style={{ height: "44px", width: "40%" }}
            onClick={() => onCancel()}
          />

          <BasicButton
            type="primary"
            text="Submit"
            disabled={addAmount === 0}
            style={{ height: "44px", width: "40%" }}
            onClick={() => setShowConfirmModal(true)}
          />
        </div>
      </div>

      {showConfirmModal &&
        <ConfirmModal
          title={"Confirm Add Thunder"}
          visible={showConfirmModal}
          loading={loading}
          onCancel={() => setShowConfirmModal(false)}
          onSubmit={() => {
            onAddThunder();
          }}
        >
          <BasicText marginBottom="20px">
            {`Are you sure you want to add ${addAmount} Thunderous Applause to @${member.username}'s account`}?
          </BasicText>
        </ConfirmModal>
      }
    </Modal>
  );
};

export default connect(null, {
  addThunder
})(withTheme(AddThunderModal));
