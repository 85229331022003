import React from 'react';
import { BasicText } from '@components';
import { withTheme } from 'styled-components';
import styles from './FilterTabs.module.css';


const FilterTabs = ({
  options,
  selectedOption,
  onSelectOption,
  theme
}) => {

  const button = (option) => {
    const isReported = option === "Reported";
    const isSelected = option === selectedOption;
    return (
      <div
        key={option}
        className={styles.button_style}
        style={{
          borderColor: isReported ? theme.colors.danger : theme.colors.blurple,
          borderWidth: "3px",
          backgroundColor: !isSelected ? theme.colors.snow : isReported ? theme.colors.danger : theme.colors.blurple,
          color: isSelected ? theme.colors.snow : isReported ? theme.colors.danger : theme.colors.blurple
        }}
        onClick={() => onSelectOption(option)}
      >
        <BasicText fontSize="14px">
          {option}
        </BasicText>
      </div>
    )
  };

  return (
    <div className={styles.container_style}>
      {options.map((option) => button(option))}
    </div>
  );
}

export default withTheme(FilterTabs);
