import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Button, Switch } from 'antd';
import PageWrapper from '@components/PageWrapper';
import LoadingWrapper from '@components/LoadingWrapper';
import {
  BasicButton,
  BasicText,
  DeleteButton,
  ConfirmModal,
} from '@components';
import { Tabs } from 'antd';
const { TabPane } = Tabs;
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import ReviewReportHistory from '@review/ReviewReportHistory';
import MemberSection from '@review/hosts/MemberSection';
import ContactSection from '@review/hosts/ContactSection';
import OrganizationSection from '@review/hosts/OrganizationSection';
import HostChallenges from '@hosts/HostChallenges';
import PurchasesTable from '@hosts/PurchasesTable';
import PayoutsTable from '@hosts/PayoutsTable';
import {
  getHostInfo,
  suspendHost,
  unSuspendHost,
  toggleFreeform,
  toggleChargingForBrand,
} from '@actions';
import styles from '@members/Members.module.css';
import { validateEmail, showSuccessMessage, showErrorMessage } from '@global';
import MemberForm from '@team/MemberForm';

const ACCOUNT = 'Account';
const CHALLENGES = 'Challenges';
const PURCHASES = 'Purchases';
const PAYMENTS = 'Payouts';

const ViewHost = ({
  match,
  getHostInfo,
  suspendHost,
  unSuspendHost,
  toggleFreeform,
  toggleChargingForBrand,
  theme,
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [blockLoading, setBlockLoading] = useState(false);
  const [host, setHost] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [showBlockConfirm, setShowBlockConfirm] = useState(false);
  const isReporting = history.location.pathname.includes('/reported_user');
  const [selectedTab, setSelectedTab] = useState('0');

  useEffect(() => {
    getHostInfo(
      match?.params?.host_id,
      (response) => {
        setHost(response.host);
        setPaymentInfo(response.payment_info);
      },
      () => {}
    );
  }, []);

  const onSuspendConfirmed = () => {
    setBlockLoading(true);
    suspendHost(
      host.id,
      (response) => {
        setBlockLoading(false);
        setHost(response);
        setShowBlockConfirm(false);
      },
      (errorMessage) => {
        setBlockLoading(false);
      }
    );
  };

  const onUnsuspendConfirmed = () => {
    setBlockLoading(true);
    unSuspendHost(
      host.id,
      (response) => {
        setBlockLoading(false);
        setHost(response);
        setShowBlockConfirm(false);
      },
      (errorMessage) => {
        setBlockLoading(false);
      }
    );
  };

  if (host) console.log('reactions', host.brand.freeform_reactions_enabled);

  const blockButton = () => {
    return (
      <div style={{ marginLeft: '30px' }}>
        <BasicButton
          text={host.is_suspended ? 'Reinstate' : 'Suspend'}
          loading={loading}
          type="primary"
          danger
          onClick={() => {
            setShowBlockConfirm(true);
          }}
        />
      </div>
    );
  };

  const additionalContent = () => {
    if (isReporting) {
    } else {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {blockButton()}
        </div>
      );
    }
  };

  const tabSelectorOptions = () => {
    return [ACCOUNT, CHALLENGES, PURCHASES, PAYMENTS].map((stat, index) => (
      <TabPane key={index} value={stat} tab={stat} />
    ));
  };

  const tabContent = () => {
    switch (selectedTab) {
      case '0':
        return (
          <div>
            {host.is_suspended && (
              <div
                style={{
                  color: theme.colors.danger,
                  fontWeight: 700,
                  marginBottom: '20px',
                  fontSize: '18px',
                }}
              >
                CURRENTLY SUSPENDED
              </div>
            )}

            <div className={styles.member_since}>{host.host_since}</div>

            {host.member_profile && (
              <div className={styles.username} style={{ width: '400px' }}>
                @{host.member_profile.username}
              </div>
            )}

            <div className={styles.username}>Host Type: {host.host_type}</div>

            <ContactSection
              host={host}
              reviewJson={null}
              updateReviewJson={(update) => {}}
              isView
            />

            {host.member_profile && (
              <MemberSection
                host={host}
                reviewJson={null}
                updateReviewJson={(update) => {}}
                isView
              />
            )}

            {host.host_type !== 'INDIVIDUAL' && (
              <OrganizationSection
                host={host}
                reviewJson={null}
                updateReviewJson={(update) => {}}
                isView
              />
            )}
          </div>
        );
      case '1':
        return <HostChallenges brand={host.brand} />;
      case '2': {
        return (
          <PurchasesTable
            purchases={paymentInfo.payments}
            updateData={(data) =>
              setPaymentInfo({ ...paymentInfo, payments: data })
            }
          />
        );
      }
      case '3': {
        return <PayoutsTable payouts={paymentInfo.payouts} />;
      }
      default:
        return null;
    }
  };

  const onToggleFreeForm = (is_allowed) => {
    toggleFreeform(
      host.brand.id,
      is_allowed,
      (h) => {
        setHost(h);
        showSuccessMessage(
          `Host free form comments ${is_allowed ? 'enabled' : 'disabled'}`
        );
      },
      () => showErrorMessage('Something went wrong.')
    );
  };

  const onToggleShouldCharge = (should) => {
    toggleChargingForBrand(
      host.brand.id,
      should,
      (h) => {
        setHost(h);
        showSuccessMessage(
          `Charging is now ${should ? 'enabled' : 'disabled'}`
        );
      },
      () => showErrorMessage('Something went wrong.')
    );
  };

  return (
    <PageWrapper>
      <FixedHeaderContainer
        text={
          host == null
            ? 'Loading host...'
            : `${host.member_profile?.first_name}`
        }
        paddingBuffer="80px"
        paddingTop="56px"
        additionalContent={host == null ? null : additionalContent()}
        fixedContent={
          host ? (
            <div style={{ marginTop: '20px' }}>
              <Tabs
                defaultActiveKey={ACCOUNT}
                onChange={(e) => setSelectedTab(e)}
              >
                {tabSelectorOptions()}
              </Tabs>
            </div>
          ) : null
        }
      >
        <LoadingWrapper isLoading={host == null} loadingText="" size="large">
          {host && (
            <div style={{ marginTop: '200px' }}>
              <div
                style={{
                  marginBottom: '40px',
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
              >
                <div style={{ marginRight: '60px' }}>
                  <div style={{ fontWeight: '700', marginBottom: '6px' }}>
                    Free Form Reactions
                  </div>
                  <Switch
                    defaultChecked={host.brand.freeform_reactions_enabled}
                    onChange={(isToggled) => onToggleFreeForm(isToggled)}
                    style={{
                      backgroundColor: host.brand.freeform_reactions_enabled
                        ? theme.colors.blurple
                        : theme.colors.lightShade,
                    }}
                  />
                </div>

                <div>
                  <div style={{ fontWeight: '700', marginBottom: '6px' }}>
                    Charge Challenges
                  </div>
                  <Switch
                    defaultChecked={
                      host.brand.should_charge && host.brand.global_charging
                    }
                    onChange={(isToggled) => onToggleShouldCharge(isToggled)}
                    disabled={!host.brand.global_charging}
                    style={{
                      backgroundColor:
                        host.brand.should_charge && host.brand.global_charging
                          ? theme.colors.blurple
                          : theme.colors.lightShade,
                    }}
                  />
                  {!host.brand.global_charging && (
                    <div
                      style={{
                        fontSize: '12px',
                        color: '#666',
                        maxWidth: '200px',
                      }}
                    >
                      Global charging is disabled. To enable charging for this
                      Host, you first need to toggle global charging in
                      settings.
                    </div>
                  )}
                </div>
              </div>

              {tabContent()}
            </div>
          )}
        </LoadingWrapper>
      </FixedHeaderContainer>

      {showBlockConfirm && (
        <ConfirmModal
          title={host.is_suspended ? 'Unsuspend Host' : 'Suspend Host'}
          visible={showBlockConfirm}
          loading={blockLoading}
          onCancel={() => setShowBlockConfirm(false)}
          onSubmit={() => {
            if (host.is_suspended) {
              onUnsuspendConfirmed();
            } else {
              onSuspendConfirmed();
            }
          }}
        >
          <BasicText marginBottom="20px">
            Are you sure you want to{' '}
            {host.is_suspended ? 'reinstate' : 'suspend'} this host?
          </BasicText>

          {!host.is_suspended && (
            <BasicText marginBottom="20px">
              Suspending the host will block them from using the portal.
              However, if you want to remove their Challenges you need to do it
              via the inidividual Challenge.
            </BasicText>
          )}
        </ConfirmModal>
      )}
    </PageWrapper>
  );
};

export default connect(null, {
  getHostInfo,
  suspendHost,
  unSuspendHost,
  toggleFreeform,
  toggleChargingForBrand,
})(withTheme(ViewHost));
