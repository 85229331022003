import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DatePicker, Form, Space, TimePicker } from 'antd';
import moment from 'moment-timezone';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, BasicButton, ShortInput, LongInput } from '@components';
import {
  NEW_CHALLENGE_STATES,
  NEW_CHALLENGE_STEPS
} from '@global';
import styles from './Create.module.css';

const BasicInfo = ({
  basic_info,
  updateChallenge,
  isPortal
}) => {
  return (
    <div>
      <div style={{ marginBottom: "20px", fontSize: "13px", color: "#555" }}>
        Created by: {basic_info.created_by}
      </div>

      <Form.Item>
        <div className="input_section">
          <ShortInput
            inputHeader="Challenge title (max length 25 chars)"
            placeholder="Backyard Olympics"
            onChange={(val) => {
              if (val.length <= 25) {
                updateChallenge({ basic_info: {
                  ...basic_info, title: val
                }});
              }
            }}
            value={basic_info.title}
          />
        </div>
      </Form.Item>

      <Form.Item>
        <div className="input_section">
          <LongInput
            inputHeader="Challenge description (Recommend < 100 words)"
            onChange={(val) => updateChallenge({ basic_info: {
              ...basic_info, description: val
            }})}
            value={basic_info.description}
          />
        </div>
      </Form.Item>

      <Form.Item>
        <div className="input_section">
          <div className={styles.date_and_time_container}>
            <div>
              <div className={styles.input_label}>
                <BasicText bold>Start Date and Time (EST)</BasicText>
              </div>
              <div className={styles.date_and_time_container}>
                <DatePicker
                  format="YYYY-MM-DD hh:mm a"
                  use12Hours
                  defaultValue={basic_info.start_time && moment(basic_info.start_time)}
                  showTime={{ use12Hours: true, format: "hh:mm a" }}
                  onChange={(val) => {
                    console.log(val.format("YYYY-MM-DD HH:mm"))
                    updateChallenge({ basic_info: {
                      ...basic_info, start_time: val.format("YYYY-MM-DD HH:mm")
                    }});
                  }}
                />
              </div>
            </div>

            <div style={{ marginLeft: "40px" }}>
              <div className={styles.input_label}>
                <BasicText bold>End Date and Time (EST)</BasicText>
              </div>
              <div className={styles.date_and_time_container}>
                <DatePicker
                  format="YYYY-MM-DD hh:mm a"
                  use12Hours
                  defaultValue={basic_info.end_time && moment(basic_info.end_time)}
                  showTime={{ use12Hours: true, format: "hh:mm a" }}
                  onChange={(val) => updateChallenge({ basic_info: {
                    ...basic_info, end_time: val.format("YYYY-MM-DD HH:mm")
                  }})}
                />
              </div>
            </div>
          </div>
        </div>
      </Form.Item>

      {isPortal &&
        <Form.Item>
          <div className={styles.input_and_color}>
            <ShortInput
              inputHeader='Challenge Color'
              placeholder="#ff0080"
              maxWidth={"120px"}
              onChange={(val) => {
                if (val.length <= 7) {
                  updateChallenge({ basic_info: {
                    ...basic_info, challenge_color: val
                  }});
                }
              }}
              value={basic_info.challenge_color}
            />
            <div style={{
              marginLeft: "12px",
              marginTop: "38px",
              width: "42px",
              height: "42px",
              border: `2px solid #dedede`,
              backgroundColor: basic_info.challenge_color }}
            />
          </div>
        </Form.Item>
      }
    </div>
  );
};

const mapStatesToProps = (state) => {
  const { newChallengeInfo } = state.challenge;
  return { newChallengeInfo };
};

export default connect(mapStatesToProps, {

})(BasicInfo);
