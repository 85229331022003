import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form, Radio, Space, Switch } from 'antd';
import { withTheme } from 'styled-components';
import LoadingWrapper from '@components/LoadingWrapper';
import { PlayCircleOutlined, PlayCircleTwoTone } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import {
  TitleText,
  BasicText,
  BasicButton,
  ShortInput,
  LongInput,
  ConfirmModal,
} from '@components';
import styles from './Settings.module.css';
import { showErrorMessage, showSuccessMessage } from '@global';
import { toggleImmersiveFeed } from '@actions';

const FeedSettings = ({ adminUser, toggleImmersiveFeed, theme }) => {
  const [showImmersive, setShowImmersive] = useState(adminUser.show_immersive);

  const onToggleImmersive = (should) => {
    setShowImmersive(should);
    toggleImmersiveFeed(
      should,
      () => {
        showSuccessMessage(
          `Immersive Feed is now ${should ? 'enabled' : 'disabled'}`
        );
      },
      () => {}
    );
  };

  return (
    <div style={{ height: '100vh', marginTop: '220px' }}>
      <div style={{ marginBottom: '40px' }}>
        <Space direction="vertical">
          <TitleText>Show Immersive Feed</TitleText>

          <Switch
            defaultChecked={showImmersive}
            onChange={(isToggled) => onToggleImmersive(isToggled)}
            style={{
              backgroundColor: showImmersive
                ? theme.colors.blurple
                : theme.colors.lightShade,
            }}
          />
        </Space>
      </div>
    </div>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  toggleImmersiveFeed,
})(withTheme(FeedSettings));
