import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import { useHistory } from 'react-router-dom';
import { fetchTeamList } from '@actions';
import { withTheme } from 'styled-components';
import { NAV_SECTIONS, CHALLENGE_TABLE_FILTERS } from '@global';
import ChallengeTable from '@challenges/ChallengeTable';
import styles from '@components/HeaderAndTable.module.css';
import LoadingWrapper from '@components/LoadingWrapper';
import CreateContainer from '@challenges/create/CreateContainer';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import {
  MuseoText,
  BasicButton,
  BottomTableLoader,
  SearchBar,
  FilterTabs,
} from '@components';
import { Input, Space } from 'antd';
import {
  getAPIRequest,
  getChallengesList,
  updateNewChallenge,
  setRefreshChallengeList,
} from '@actions';
import useOnScreen from '@components/useOnScreen';
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor';

const { Search } = Input;

let _searchTimeout = null;

const ChallengeHome = ({
  adminUser,
  getChallengesList,
  getAPIRequest,
  updateNewChallenge,
  newChallengeInfo,
  refreshChallengeList,
  setRefreshChallengeList,
  theme,
}) => {
  const { showNewChallengeSteps } = newChallengeInfo;

  const listInnerRef = useRef();
  const [currentFilter, setCurrentFilter] = useState('All');
  const [challenges, setChallenges] = useState([]);
  const [seenIds, setSeenIds] = useState([]);
  const [isMore, setIsMore] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isFetchingChallenges, setIsFetchingChallenges] = useState(true);
  const [viewingChallenge, sestViewingChallenge] = useState(null);
  const history = useHistory();
  const isFetchingMoreFromBottom = useRef(false);

  const performSearch = () => {
    const currentSearchTerm = searchTerm;
    if (currentSearchTerm.length > 0) {
      getAPIRequest(
        '/admin/search_challenges',
        { query: currentSearchTerm },
        (response) => {
          if (currentSearchTerm === searchTerm) {
            setSearchResults(response.challenges);
          }
        },
        (error) => {
          //onFail(error.error);
        }
      );
    }
  };

  useEffect(() => {
    fetchChallengesForFilter('All');
  }, []);

  useEffect(() => {
    if (refreshChallengeList) {
      setCurrentFilter('All');
      fetchChallengesForFilter('All', true);
      setRefreshChallengeList(false);
    }
  }, [refreshChallengeList]);

  useEffect(() => {
    clearTimeout(_searchTimeout);
    if (searchTerm.length > 0) {
      _searchTimeout = setTimeout(() => performSearch(), 200);
    }
  }, [searchTerm]);

  const onBottomLoadingVisible = (isVisible) => {
    if (isVisible) {
      if (!isFetchingMoreFromBottom.current && isMore)
        fetchChallengesForFilter(currentFilter);
    }
  };

  const fetchChallengesForFilter = (type, isRefresh = false) => {
    const isNewTab = isRefresh || type != currentFilter;
    if (isNewTab) setCurrentFilter(type);

    if (!isNewTab && !isMore) return;
    if (!isNewTab && isFetchingMoreFromBottom.current) return;

    if (isNewTab) setIsFetchingChallenges(true);
    isFetchingMoreFromBottom.current = true;

    getChallengesList(
      type,
      isNewTab ? [] : seenIds,
      (newChallenges) => {
        console.log(isNewTab, type);

        if (isNewTab) {
          setSeenIds(newChallenges.map((c) => c.id));
          setChallenges(newChallenges);
        } else {
          setSeenIds(seenIds.concat(newChallenges.map((c) => c.id)));
          setChallenges(challenges.concat(newChallenges));
        }
        setIsMore(newChallenges.length === 20);
        isFetchingMoreFromBottom.current = false;
        setIsFetchingChallenges(false);
      },
      () => {}
    );
  };

  const header = () => {
    return (
      <div className={styles.fixed_header}>
        <div className={styles.header_container}>
          <div className={styles.header_text}>
            <MuseoText>Challenges</MuseoText>
          </div>

          <BasicButton
            text="Create new Challenge +"
            type="primary"
            onClick={() => {
              updateNewChallenge({ showNewChallengeSteps: true });
            }}
            style={{
              height: '46px',
              paddingLeft: '20px',
              paddingRight: '20px',
            }}
            fontSize="18px"
          />
        </div>

        <div style={{ marginBottom: '12px', fontSize: '16px' }}>
          <span style={{ fontWeight: '700' }}>Editing: </span>
          {adminUser.challenge_count_breakdown.editing} |
          <span style={{ fontWeight: '700' }}> In review: </span>
          {adminUser.challenge_count_breakdown.in_review} |
          <span style={{ fontWeight: '700' }}> Dropping: </span>
          {adminUser.challenge_count_breakdown.dropping} |
          <span style={{ fontWeight: '700' }}> Live: </span>
          {adminUser.challenge_count_breakdown.live} |
          <span style={{ fontWeight: '700' }}> Ended: </span>
          {adminUser.challenge_count_breakdown.ended} |
          <span style={{ fontWeight: '700' }}> Rejected: </span>
          {adminUser.challenge_count_breakdown.rejected}
        </div>

        <SearchBar
          searchTerm={searchTerm}
          onChange={(value) => setSearchTerm(value)}
        />

        {searchTerm.length === 0 && (
          <FilterTabs
            options={CHALLENGE_TABLE_FILTERS}
            selectedOption={currentFilter}
            onSelectOption={(selectedOption) => {
              if (selectedOption !== currentFilter) {
                fetchChallengesForFilter(selectedOption);
              }
            }}
          />
        )}
      </div>
    );
  };

  return (
    <PageWrapper>
      <FixedHeaderContainer hideHeader additionalContent={header()}>
        {isFetchingChallenges ? (
          <div
            style={{
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          ></div>
        ) : (
          <>
            <div className={styles.challenge_mobile_offest} />
            <ChallengeTable
              challengeList={searchTerm.length > 0 ? searchResults : challenges}
              showNumber={currentFilter === 'All' && searchTerm.length === 0}
              totalCount={adminUser.challenge_count}
              marginTop="320px"
              onChallengeSelected={(challenge) => {
                if (challenge.was_published) {
                  history.push({
                    pathname: `/challenge_stats/${challenge.id}`,
                  });
                } else {
                  if (challenge.is_ugc) {
                    history.push({
                      pathname: `/mgc_history/${challenge.id}`,
                    });
                  } else {
                    history.push({
                      pathname: `/challenges/${challenge.id}`,
                    });
                  }
                }
              }}
            />
            {isMore && searchTerm.length === 0 && (
              <VisibilitySensor onChange={onBottomLoadingVisible}>
                <BottomTableLoader />
              </VisibilitySensor>
            )}
          </>
        )}
      </FixedHeaderContainer>
      {showNewChallengeSteps && <CreateContainer />}
    </PageWrapper>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  const { newChallengeInfo, refreshChallengeList } = state.challenge;
  return { adminUser, newChallengeInfo, refreshChallengeList };
};

export default connect(mapStatesToProps, {
  getChallengesList,
  getAPIRequest,
  updateNewChallenge,
  setRefreshChallengeList,
})(withTheme(ChallengeHome));
