import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Button, Modal, Form, Space } from "antd";
import PageWrapper from '@components/PageWrapper';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { withTheme } from "styled-components";
import { PlayCircleOutlined, PlayCircleTwoTone } from "@ant-design/icons";
import { useHistory, Link } from "react-router-dom";
import { showSuccessMessage, showErrorMessage } from '@global';
import ClipLoader from "react-spinners/ClipLoader";
import {
  TitleText,
  BasicText,
  BasicButton,
  ShortInput,
  LongInput,
  RejectButton,
  ApproveButton,
  ConfirmModal
} from "@components";
import styles from "./Report.module.css";
import { getReportedReactions, reviewReportedReaction } from '@actions';

const ReportedReactions = ({
  getReportedReactions,
  reviewReportedReaction,
  theme
}) => {

  const [loading, setLoading] = useState(false);
  const [reportedReactions, setReportedReactions] = useState([]);
  const [hasFetched, setHasFetched] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getReportedReactions(
      (reactions) => {
        setReportedReactions(reactions);
        setHasFetched(true);
      },
      () => {}
    )
  }, []);

  console.log(reportedReactions);

  const onReview = (reported_reaction_id, remove_reaction) => {
    setLoading(true);
    reviewReportedReaction(
      reported_reaction_id,
      remove_reaction,
      (reactions) => {
        setReportedReactions(reactions);
        setLoading(false);
        if (remove_reaction) {
          showSuccessMessage("Reaction successfully removed.");
        } else {
          showSuccessMessage("Reaction kept live.");
        }
      },
      () => {
        setLoading(false);
        showErrorMessage("Something went wrong.")
      }
    )
  }


  const reportedReaction = (rr) => {
    return (
      <div key={rr.id} style= {{ maxWidth: "400px", marginBottom: "32px" }}>
        <BasicText bold>
          {rr.admin_text} on {rr.time_text}
        </BasicText>

        <div style={{ marginTop: "6px"}}>
          Host Reaction: {rr.host_reaction}
        </div>

        <div
          style={{ marginTop: "6px", cursor: "pointer", color: theme.colors.blurple, fontWeight: "700" }}
          onClick={() => {
            history.push({
              pathname: `/view_host/${rr.brand_id}`,
            });
          }}
        >
          View Host
        </div>

        <div
          style={{ marginTop: "6px", cursor: "pointer", color: theme.colors.blurple, fontWeight: "700" }}
          onClick={() => {
            window.open(rr.submission_url, "_blank");
          }}
        >
          View Entry
        </div>

        <div style={{ marginTop: "16px" }}>
          <Space>
            <RejectButton
              onClick={() => {
                onReview(rr.id, true);
              }}
              text="Remove"
            />
            <ApproveButton
              onClick={() => {
                onReview(rr.id, false);
              }}
              text="Keep Live"
            />
          </Space>
        </div>

        <div style={{ width: "100%", height: "1px", backgroundColor: "#999", marginTop: "12px" }} />
      </div>
    );
  }



  const content = () => {
    if (hasFetched && reportedReactions.length === 0) {
      return (
        <div style={{ marginTop: "120px" }}>
          All reported reactions have been reviewed.
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: "120px" }}>
          {reportedReactions.map((rr) =>
            reportedReaction(rr)
          )}
        </div>
      );
    }
  }


  return (
    <PageWrapper>
      <FixedHeaderContainer
        text={'Reported Reactions'}
        paddingBuffer="80px"
        paddingTop="56px"
      >
        {content()}
      </FixedHeaderContainer>

      <Modal
        title={"Submitting..."}
        visible={loading}
        footer={null}
        closable={false}

      >
        <div style={{ width: "100%", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <ClipLoader />
        </div>
      </Modal>
    </PageWrapper>
  );
};

const mapStatesToProps = (state) => {
  const { reviewStats } = state.review;
  return { reviewStats };
};

export default connect(mapStatesToProps, {
  getReportedReactions,
  reviewReportedReaction
})(withTheme(ReportedReactions));
