import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import PageWrapper from '@components/PageWrapper';
import LoadingWrapper from '@components/LoadingWrapper';
import { BasicButton, BasicText, DeleteButton, ConfirmModal } from '@components';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import  {
  getMemberInfo,
  editMember,
  resendInvite,
  deleteMember
} from '@actions';
import styles from './Team.module.css';
import { validateEmail, showSuccessMessage, showErrorMessage  } from '@global';
import MemberForm from '@team/MemberForm';

const ViewMember = ({
  match,
  getMemberInfo,
  editMember,
  resendInvite,
  deleteMember
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [resendInviteLoading, setResendInviteLoading] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [member, setMember] = useState(null);
  const [memberInfo, setMemberInfo] = useState({
    role: "",
    first_name: "",
    last_name: "",
    email: ""
  });
  const [showDeleteConfrim, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    getMemberInfo(
      match?.params?.admin_id,
      (mem) => {
        setMemberInfo(mem);
        setMember(mem);
      },
      () => {}
    );
  }, []);

  useEffect(() => {
    if (member != null) {
      setSaveEnabled(
        memberInfo.role != member.role ||
        memberInfo.first_name != member.first_name ||
        memberInfo.last_name != member.last_name
      );
    }
  }, [member, memberInfo]);

  const onSubmit = () => {
    setLoading(true);
    editMember(
      memberInfo,
      (response) => {
        setMember(response.member);
        setLoading(false);
        showSuccessMessage(response.message, 5);
      },
      (errorMessage) => {
        showErrorMessage(errorMessage, 6);
        setLoading(false);
      }
    );
  }

  const onResendInvite = () => {
    setResendInviteLoading(true);
    resendInvite(
      memberInfo,
      (response) => {
        setMember(response.member);
        setResendInviteLoading(false);
        showSuccessMessage(response.message, 5);
      },
      (errorMessage) => {
        showErrorMessage(errorMessage, 6);
        setResendInviteLoading(false);
      }
    );
  }

  const onDeleteConfirm = () => {
    setDeleteLoading(true);
    deleteMember(
      memberInfo,
      (response) => {
        setDeleteLoading(false);
        history.goBack();
        setTimeout(() => showSuccessMessage(response.message, 5), 200);
      },
      (errorMessage) => {
        showErrorMessage(errorMessage, 6);
        setDeleteLoading(false);
      }
    );
  }

  const saveButton = () => {
    return (
      <BasicButton
        text="Save"
        loading={loading}
        type="primary"
        disabled={!saveEnabled}
        onClick={() => {
          onSubmit();
        }}
      />
    );
  }

  const statsSection = () => {
    const { member_stats } = member;
    if (member_stats.has_accepted_invite) {
      return (
        <div className={styles.stats_container}>
          <BasicText>
            {member_stats.member_since}
          </BasicText>

          <div style={{ marginTop: "20px" }}>
            <BasicText>
              {member_stats.entry_reviews}
            </BasicText>
            <BasicText>
              {member_stats.challenge_reviews}
            </BasicText>
          </div>

          <div style={{ marginTop: "20px" }}>
            {member.status}
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.stats_container} style={{ marginTop: "150px", display: 'flex', alignItems: 'center' }}>
          <BasicText bold color={member.status.includes("Expired") ? 'red' : 'black'}>
            {member.status}
          </BasicText>

          <div style={{ marginLeft: "20px" }}>
            <BasicButton
              text="Resend Invite"
              loading={resendInviteLoading}
              style={{ fontSize: "13px", height: "32px", paddingLeft: "20px", paddingRight: "20px" }}
              type="primary"
              onClick={() => {
                onResendInvite();
              }}
            />
          </div>
        </div>
      );
    }
  };

  const additionalContent = () => {
    if (member.can_delete) {
      return (
        <div style={{ display: "flex", alignItems: "center"}}>
          {saveButton()}
          <div style={{ marginLeft: "40px"}}>
            <DeleteButton
              onClick={() => setShowDeleteConfirm(true)}
            />
          </div>
        </div>
      )
    } else {
      return saveButton();
    }
  }

  return (
    <PageWrapper>
      <FixedHeaderContainer
        text={member == null ? "Loading member..." : member.full_name}
        paddingBuffer="80px"
        paddingTop="56px"
        additionalContent={member == null ? null : additionalContent()}
      >
        <LoadingWrapper isLoading={member == null} loadingText="" size="large">
          {member != null &&
            <>
              {statsSection()}
              <MemberForm
                memberInfo={memberInfo}
                onInfoUpdated={(updatedInfo) => setMemberInfo(updatedInfo)}
                isEdit
                canEditRole={member.can_edit_role}
              />
            </>
          }
        </LoadingWrapper>
      </FixedHeaderContainer>

      {showDeleteConfrim &&
        <ConfirmModal
          title={"Confirm Delete"}
          visible={showDeleteConfrim}
          loading={deleteLoading}
          onCancel={() => setShowDeleteConfirm(false)}
          onSubmit={() => onDeleteConfirm()}
        >
          <BasicText marginBottom="20px">
            Are you sure you want to delete this member? This action can not be undone.
          </BasicText>
        </ConfirmModal>
      }
    </PageWrapper>
  )
};

export default connect(null, {
  getMemberInfo,
  editMember,
  resendInvite,
  deleteMember
})(ViewMember);
