import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form } from 'antd';
import { withTheme } from 'styled-components';
import { TitleText, BasicText, BasicButton, ShortInput, LongInput } from '@components';
import StatsLoader from '@challenges/stats/StatsLoader';
import styles from './ChallengeStats.module.css';
import { } from '@actions';
import OverallStats from '@challenges/stats/OverallStats';
import GeographicReach from '@challenges/stats/GeographicReach';

const PerformanceSection = ({
  challenge,
  hasData,
  theme
}) => {

  return (
    <div className={styles.performance_buffer}>
      <div className={styles.type_header}>
        Challenge Performance
      </div>

      <div className={styles.performance_container}>
        <div className={styles.overview_container}>
          <OverallStats
            challenge={challenge}
            hasData={hasData}
          />
        </div>

        <div className={styles.geographic_container}>
          <GeographicReach
            challenge={challenge}
            hasData={hasData}
          />
        </div>
      </div>
    </div>
  );
}

export default connect(null, {

})(withTheme(PerformanceSection));
