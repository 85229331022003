import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form, Space } from 'antd';
import { withTheme } from 'styled-components';
import { PlayCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, BasicButton, ShortInput, LongInput } from '@components';
import styles from './ReviewChallenge.module.css';

const BasicSection = ({
  challenge,
  isApproved,
  onApprove,
  showApprove,
  theme
}) => {

  return (
    <div className={styles.approve_section}>
      <div className={styles.section_header_container}>
        <div className={styles.section_header}>
          Basic Information
        </div>

        {showApprove &&
          <div className={styles.approve_check}>
            <div
              className={styles.approve_section_button}
              style={{ backgroundColor: isApproved ? theme.colors.blurple : theme.colors.buttonDisabled }}
            >
              <CheckOutlined style={{ color: theme.colors.snow, fontWeight: 700  }} />
            </div>
          </div>
        }
      </div>

      <div className={styles.attribute_title}>
        Creator
      </div>

      {challenge.is_ugc ?
        <div className={styles.attribute_text}>
          {challenge.user.first_name} {challenge.user.last_name} (@{challenge.user.username})
        </div>
      :
      <div className={styles.attribute_text}>
        {challenge.brand_info.display_name ? challenge.brand_info.display_name : challenge.brand_info.name}
      </div>
      }


      <div className={styles.attribute_title}>
        Challenge Title
      </div>
      <div className={styles.attribute_text}>
        {challenge.basic_info.title}
      </div>

      <div className={styles.attribute_title}>
        Challenge Description
      </div>
      <div className={styles.attribute_text}>
        {challenge.basic_info.description}
      </div>

      <Space size={160}>
        <div>
          <div className={styles.attribute_title}>
            Drop Date
          </div>
          <div className={styles.attribute_text}>
            {challenge.basic_info.drop_date}
          </div>
        </div>

        <div>
          <div className={styles.attribute_title}>
            End Date
          </div>
          <div className={styles.attribute_text}>
            {challenge.basic_info.end_date}
          </div>
        </div>
      </Space>

      <div className={styles.attribute_title}>
        Visibility
      </div>
      <div className={styles.attribute_text}>
        {challenge.visibility}
      </div>

      {showApprove &&
        <BasicButton
          text="Approve Basic Info"
          type={isApproved ? "primary" : "secondary"}
          style={{ marginTop: "20px", height: "44px", width: "100%" }}
          onClick={() => onApprove()}
        />
      }
    </div>
  );
};

export default connect(null, {

})(withTheme(BasicSection));
