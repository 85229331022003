import { message } from 'antd';
import axios from 'axios';
import fileDownload from 'js-file-download';
const JSZip = require('jszip');
const FileSaver = require('file-saver');

// pass in 0 to enforce closability
export const showSuccessMessage = (text, duration = 3) => {
  message.destroy();
  message.success(text, duration);
};

// pass in 0 to enforce closability
export const showErrorMessage = (text, duration = 3) => {
  message.destroy();
  message.error(text, duration);
};

export const clearAllMessages = () => {
  message.destroy();
};

export const COLORS = {
  BACKGROUND_GREY: '#f0f0f0',
  PRIMARY_COLOR: '#0098fb',
  LIVE_GREEN: '#32cd32',
  HIDDEN: '#a9a9a9',
};

export const NEW_CHALLENGE_STATES = {
  BASIC_INFO: 'BASIC_INFO',
  BRAND_INFO: 'BRAND_INFO',
  MEDIA_INFO: 'MEDIA_INFO',
  PROMO_INFO: 'PROMO_INFO',
  PRIZE_INFO: 'PRIZE_INFO',
  RULES_INFO: 'RULES_INFO',
};

export const NEW_CHALLENGE_STEPS = [
  NEW_CHALLENGE_STATES.BASIC_INFO,
  NEW_CHALLENGE_STATES.MEDIA_INFO,
  NEW_CHALLENGE_STATES.PRIZE_INFO,
  NEW_CHALLENGE_STATES.BRAND_INFO,
];

export const PRIZE_TYPES = {
  PAY_TO_PLAY: 'Pay-to-Play',
  CUSTOM_PRIZE: 'Custom Prize',
  BUNDLED_PRIZE: 'Bundled Prize',
};

export const CHALLENGE_ASSET_TYPES = {
  BRAND_LOGO: 'BRAND_LOGO',
  EXPLAINER_VIDEO: 'Explainer Video',
  EXPLAINER_COVER: 'Explainer Cover Image',
  LEADERBOARD_IMAGE: 'Leaderboard Image',
  LEADEARBOARD_DAY_IMAGE: 'Leaderboard < 24hr Image',
  PROMO_VIDEO: 'Promo Video',
  PROMO_IMAGE: 'Promo Image',
  PRIZE_IMAGE: 'Prize Image',
  HYYPE_GROUP_VIDEO: 'HYYPE_GROUP_VIDEO',
};

export const NAV_SECTIONS = {
  OVERVIEW: '/overview',
  CHALLENGES: '/challenges',
  ALL_TEAM: '/team',
  REVIEW_TEAM: '/review_team',
  COMMUNITY: '/community',
  REVIEW: '/review_content',
  REVIEW_TIME: '/review_time',
  REPORTED: '/reported',
  ENTRIES: '/explore_entries',
};

export const CHALLENGE_TYPES = {
  PAY_TO_PLAY: 'Pay-to-Play',
  CUSTOM_PRIZE: 'Custom Prize',
  BUNDLED_PRIZE: 'Bundled Prize',
};

export const GRAPH_TYPES = {
  ENTRIES: 'ENTRIES',
  SUBSCRIBERS: 'SUBSCRIBERS',
  TOTAL_VIEWS: 'TOTAL VIEWS',
  UNIQUE_VIEWS: 'UNIQUE VIEWS',
  SHARES: 'SHARES',
  CLAPS: 'CLAPS',
};

export const getHomePathForRole = (role) => {
  if (role === 'ADMIN') {
    return '/overview';
  } else if (role === 'PARTNERSHIPS') {
    return '/challenges';
  } else if (role === 'SUPERVISOR') {
    return '/review_team';
  } else {
    return '/review_content';
  }
};

export const hasAccessToSection = (adminUser, section) => {
  return adminUser.sections.includes(section);
};

export const CHALLENGE_TABLE_FILTERS = [
  'All',
  'Pending approval',
  'Sponsor',
  'Member',
  'Live',
  'Dropping',
  'Not published',
  'Completed',
  'Reported',
];

export const validateEmail = (email) =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );

export const downloadFiles = (files, challengTitle) => {
  files.forEach((url, i) => {
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(
          res.data,
          `${challengTitle.replace(' ', '_')}_${i + 1}.mp4`
        );
      });
  });
};

export const downlaodVideosIntoZip = (urls, challengeTitle, num, onSuccess) => {
  const zip = new JSZip();

  urls.forEach((url, i) => {});

  const list = urls.map(async (url, index) => {
    await axios
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        zip.file(`submission${index + 1}.mp4`, res.data);
      });

    return;
  });

  Promise.all(list).then(function() {
    zip.generateAsync({ type: 'blob' }).then(function(content) {
      FileSaver.saveAs(content, `top_submissions.zip`);
      onSuccess();
    });
  });
};

export const validURL = (str) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};

export const formatPhoneNumber = (input) => {
  input = input.replace(/\D/g, '').substring(0, 10); //Strip everything but 1st 10 digits
  var size = input.length;
  if (size > 0) {
    input = '(' + input;
  }
  if (size > 3) {
    input = input.slice(0, 4) + ') ' + input.slice(4);
  }
  if (size > 6) {
    input = input.slice(0, 9) + '-' + input.slice(9);
  }
  return input;
};

export const hyypeGroupsDomain = (adminUser) => {
  return adminUser.is_staging
    ? 'https://hyype-groups.vercel.app'
    : 'https://groups.hyype.space';
};

export const ENTRY_FEES = [
  '$1.00',
  '$2.00',
  '$3.00',
  '$4.00',
  '$5.00',
  '$6.00',
  '$7.00',
  '$8.00',
  '$9.00',
  '$10.00',
  '$11.00',
  '$12.00',
  '$13.00',
  '$14.00',
  '$15.00',
  '$16.00',
  '$17.00',
  '$18.00',
  '$19.00',
  '$20.00',
];

export const ordinalSuffixOf = (i) => {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + 'st';
  }
  if (j == 2 && k != 12) {
    return i + 'nd';
  }
  if (j == 3 && k != 13) {
    return i + 'rd';
  }
  return i + 'th';
};
