import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form, Space } from 'antd';
import { withTheme } from 'styled-components';
import {
  PlayCircleOutlined,
  CheckCircleFilled,
  WarningFilled,
} from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import {
  TitleText,
  BasicText,
  BasicButton,
  ShortInput,
  LongInput,
} from '@components';
import styles from './ReviewHosts.module.css';
import ResponseButtons from '@review/hosts/ResponseButtons';
import { formatPhoneNumber } from '@global';

const ContactSection = ({
  host,
  reviewJson,
  updateReviewJson,
  isView,
  theme,
}) => {
  return (
    <div className={styles.approve_section} style={{ marginTop: '40px' }}>
      <div
        className={styles.section_header_container}
        style={{ height: '50px' }}
      >
        <div className={styles.section_header}>Contact</div>

        {!isView && reviewJson['Contact'].decided_on && (
          <>
            {!reviewJson['Contact'].approved ? (
              <div className={styles.approve_check}>
                <WarningFilled
                  style={{
                    color: theme.colors.danger,
                    fontWeight: 700,
                    fontSize: '26px',
                  }}
                />
              </div>
            ) : (
              <div className={styles.approve_check}>
                <CheckCircleFilled
                  style={{
                    color: theme.colors.blurple,
                    fontWeight: 700,
                    fontSize: '26px',
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>

      <div className={styles.attribute_title}>Host Info</div>
      <div className={styles.attribute_text}>
        {host.first_name} {host.last_name} <br />
        {host.position && host.company_name && (
          <>
            {host.position} at {host.company_name} <br />
          </>
        )}
        {host.email} <br />
        {formatPhoneNumber(host.phone_number)}
      </div>

      {!isView && reviewJson && (
        <div style={{ marginTop: '20px' }}>
          <ResponseButtons
            approveText="Approve Contact"
            rejectText="Reject Contact"
            showRejected={
              reviewJson['Contact'].decided_on &&
              !reviewJson['Contact'].approved
            }
            rejectedReason={reviewJson['Contact'].reason}
            onApprove={() => {
              updateReviewJson({
                Contact: { decided_on: true, approved: true },
              });
            }}
            onReject={() => {
              updateReviewJson({
                Contact: { decided_on: true, approved: false },
              });
            }}
            updateRejectedReason={(val) => {
              updateReviewJson({
                Contact: { decided_on: true, approved: false, reason: val },
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

export default connect(null, {})(withTheme(ContactSection));
