import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form, Space } from 'antd';
import { withTheme } from 'styled-components';
import {
  PlayCircleOutlined,
  CheckCircleFilled,
  WarningFilled,
} from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import {
  TitleText,
  BasicText,
  BasicButton,
  ShortInput,
  LongInput,
} from '@components';
import styles from './ReviewHosts.module.css';
import ResponseButtons from '@review/hosts/ResponseButtons';

const MemberSection = ({
  host,
  reviewJson,
  updateReviewJson,
  isView,
  theme,
}) => {
  const user = host.member_profile;

  return (
    <div className={styles.approve_section} style={{ marginTop: '40px' }}>
      <div
        className={styles.section_header_container}
        style={{ height: '50px' }}
      >
        <div className={styles.section_header}>Profile</div>

        {!isView && reviewJson['Profile'].decided_on && (
          <>
            {!reviewJson['Profile'].approved ? (
              <div className={styles.approve_check}>
                <WarningFilled
                  style={{
                    color: theme.colors.danger,
                    fontWeight: 700,
                    fontSize: '26px',
                  }}
                />
              </div>
            ) : (
              <div className={styles.approve_check}>
                <CheckCircleFilled
                  style={{
                    color: theme.colors.blurple,
                    fontWeight: 700,
                    fontSize: '26px',
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>

      <Space style={{ marginBottom: '16px' }}>
        <Space
          style={{ cursor: 'pointer' }}
          onClick={() => window.open(user.image_url, '_blank')}
        >
          <img className={styles.image_style} src={user.image_url} />
          <div style={{ marginLeft: '8px', color: theme.colors.blurple }}>
            Profile Picture
          </div>
        </Space>
      </Space>

      <div className={styles.attribute_title}>Name</div>
      <div className={styles.attribute_text}>{user.first_name}</div>

      <div className={styles.attribute_title}>Username</div>
      <div className={styles.attribute_text}>{user.username}</div>

      <div className={styles.attribute_title}>Bio</div>
      <div className={styles.attribute_text}>{user.bio}</div>

      <div className={styles.attribute_title}>Website</div>
      <div className={styles.attribute_text}>{user.host_url}</div>

      {!isView && reviewJson && (
        <div style={{ marginTop: '20px' }}>
          <ResponseButtons
            approveText="Approve Profile"
            rejectText="Reject Profile"
            showRejected={
              reviewJson['Profile'].decided_on &&
              !reviewJson['Profile'].approved
            }
            rejectedReason={reviewJson['Profile'].reason}
            onApprove={() => {
              updateReviewJson({
                Profile: { decided_on: true, approved: true },
              });
            }}
            onReject={() => {
              updateReviewJson({
                Profile: { decided_on: true, approved: false },
              });
            }}
            updateRejectedReason={(val) => {
              updateReviewJson({
                Profile: { decided_on: true, approved: false, reason: val },
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

export default connect(null, {})(withTheme(MemberSection));
