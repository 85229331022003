import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { withTheme } from 'styled-components';
import { BasicButton, RejectButton, ApproveButton } from '@components';
import styles from './ReviewEntry.module.css';
import { validateEmail, showSuccessMessage, showErrorMessage } from '@global';
import { setPlaybackSpeed } from '@actions';
import ReviewStats from '@review/ReviewStats';
import EscalateEntry from '@review/entries/EscalateEntry';

const ReviewEntryVideo = ({
  submission,
  setPlaybackSpeed,
  playBackSpeed,
  onApprove,
  approveLoading,
  onReject,
  rejectLoading,
  buttonsText,
  showButtons,
  submissionReviewStats,
  isReporting,
  isViewing,
  onEscalated,
  adminUser,
  onSkipEntry,
  theme,
}) => {
  //console.log(buttonsText);

  const [progress, setProgress] = useState(0);
  const [enableApprove, setEnableApprove] = useState(false);
  const [showEscalate, setShowEscalate] = useState(false);
  const videoRef = useRef();
  const history = useHistory();

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = playBackSpeed;
    }
  }, [videoRef, playBackSpeed]);

  useEffect(() => {
    setEnableApprove(false);
  }, [submission]);

  const handleProgress = (e) => {
    console.log(e.target.currentTime);
  };

  const progressStats = () => {
    if (isReporting && submissionReviewStats) {
      return (
        <div className={styles.review_stats_container}>
          <ReviewStats
            stats={[
              {
                title: 'Pending',
                value: submissionReviewStats.pending,
                showDanger: true,
              },
              {
                title: 'Kept Live',
                value: submissionReviewStats.kept_live,
                showDanger: false,
              },
              {
                title: 'Removed',
                value: submissionReviewStats.removed,
                showDanger: false,
              },
            ]}
          />
        </div>
      );
    } else if (submissionReviewStats && !isViewing) {
      return (
        <div className={styles.review_stats_container}>
          <ReviewStats
            stats={[
              {
                title: 'Pending',
                value: submissionReviewStats.pending,
                showDanger: true,
              },
              {
                title: 'Approved',
                value: submissionReviewStats.approved,
                showDanger: false,
              },
              {
                title: 'Rejected',
                value: submissionReviewStats.rejected,
                showDanger: false,
              },
            ]}
          />
        </div>
      );
    }
  };

  //console.log(progress);

  return (
    <div>
      <div className={styles.video_and_controls}>
        <video
          key={submission.id}
          className={styles.video_container}
          onEnded={() => setEnableApprove(true)}
          controls
          ref={videoRef}
          autoPlay
        >
          <source src={submission.url.content} type="video/mp4" />
        </video>
      </div>

      {showButtons && (
        <>
          {submission.flag_entries && adminUser.role !== 'ADMIN' ? (
            <div
              style={{
                maxWidth: '300px',
                marginTop: '12px',
                marginBottom: '12px',
                fontSize: '18px',
                fontWeight: '700',
                lineHeight: '24px',
                backgroundColor: 'yellow',
              }}
            >
              {
                "This Member's entries have been flagged before, and require that you escalate the entry to a Hyype Space admin. Please escalate to Gabriel Dordetti."
              }
              <div className={styles.escalate_container}>
                <span className={styles.escalate_text}>
                  <div
                    style={{
                      marginTop: '12px',
                      marginBottom: '12px',
                      padding: '8px',
                      backgroundColor: theme.colors.danger,
                      color: theme.colors.snow,
                      borderRadius: '8px',
                      display: 'flex',
                      justifyContent: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => setShowEscalate(true)}
                  >
                    Escalate Entry
                  </div>
                </span>
              </div>
            </div>
          ) : (
            <div>
              <div className={styles.action_buttons_container}>
                {buttonsText.reject && (
                  <div style={{ marginRight: '6px', display: 'flex', flex: 1 }}>
                    <RejectButton
                      onClick={() => onReject()}
                      text={buttonsText.reject}
                    />
                  </div>
                )}

                {buttonsText.approve && (
                  <div style={{ marginLeft: '6px', display: 'flex', flex: 1 }}>
                    <ApproveButton
                      disabled={!enableApprove}
                      onClick={() => onApprove()}
                      text={buttonsText.approve}
                      loading={approveLoading}
                    />
                  </div>
                )}
              </div>

              <div className={styles.escalate_container}>
                <span className={styles.escalate_text}>
                  Not sure?
                  <span
                    className={styles.escalate_link}
                    onClick={() => setShowEscalate(true)}
                  >
                    Escalate Entry
                  </span>
                </span>
              </div>
            </div>
          )}
        </>
      )}

      {submission && (
        <>
          <div style={{ fontSize: '15px', color: '#000', fontWeight: 700 }}>
            @{submission.user.username} - {submission.challenge.title}
          </div>
          <div style={{ fontSize: '13px', color: '#555', fontWeight: 700 }}>
            STATUS: {submission.status}
          </div>
        </>
      )}

      <BasicButton
        text="Skip Entry"
        type="secondary"
        style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
        onClick={() => onSkipEntry()}
      />

      {progressStats()}

      <EscalateEntry
        visible={showEscalate}
        submission={submission}
        onCancel={() => setShowEscalate(false)}
        onEscalated={() => {
          setShowEscalate(false);
          onEscalated();
        }}
      />
    </div>
  );
};

// <div className={styles.speed_container}>
//   <div
//     className={playBackSpeed === 1 ? styles.speed_button_selected : styles.speed_button}
//     onClick={() => setPlaybackSpeed(1)}
//   >
//     1x
//   </div>
//   <div
//     className={playBackSpeed === 1.5 ? styles.speed_button_selected : styles.speed_button}
//     onClick={() => setPlaybackSpeed(1.5)}
//   >
//     1.5x
//   </div>
//   <div
//     className={playBackSpeed === 2 ? styles.speed_button_selected : styles.speed_button}
//     onClick={() => setPlaybackSpeed(2)}
//   >
//     2x
//   </div>
// </div>

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  const { playBackSpeed } = state.review;
  return { playBackSpeed, adminUser };
};

export default connect(mapStatesToProps, {
  setPlaybackSpeed,
})(withTheme(ReviewEntryVideo));
