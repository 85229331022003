import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Form, Input, Modal } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, CustomButton, BasicButton, ShortInput, MuseoText } from '@components';
import '../App.css';
import './login.css'
import { validateEmail } from '../global_vars';
import logo from '../images/leftAlignSingleWordmark_light.png';
import { sendResetPassword } from '@actions';

const ForgotPasswordModal = ({
  visible,
  onCancel,
  sendResetPassword
}) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [successfullySent, setSuccessfullySent] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [error, setError] = useState(false);

  const validateForm = () => {
    return validateEmail(email);
  }

  useEffect(() => {
    setButtonDisabled(!validateForm());
  }, [email]);

  const handleSubmit = () => {
    setError(false);
    setLoading(true);
    sendResetPassword(
      email,
      () => {
        setLoading(false);
        setSuccessfullySent(true);
      },
      (error) => {
        setLoading(false);
        setError("The email address you entered is not associated with an Admin count.");
      }
    );
  }

  const onFormSubmit = () => {
    handleSubmit();
  }


  return (
    <Modal
      title="Forgot Password"
      visible={visible}
      footer={null}
      onCancel={() => {
        setSuccessfullySent(false);
        onCancel();
      }}
    >

      {successfullySent ?
        <>
          <div style={{ marginBottom: "40px"}}>
            <BasicText>
              {"The link to reset your password should arrive in your inbox momentarily."}
            </BasicText>
          </div>
          <Button
            type="primary"
            style={{ width: "100%", marginTop: "20px", height: "50px" }}
            onClick={() => {
              setSuccessfullySent(false);
              onCancel();
            }}
          >
              OK
          </Button>
        </>
      :
        <Form onFinish={onFormSubmit} initialValues={{ remember: true }}>
          <div style={{ marginBottom: "40px"}}>
            <BasicText>
              {"Enter the password associated with your account and we'll send you a link to reset your password."}
            </BasicText>
          </div>
          <Form.Item>
            <div className="input_section">
              <ShortInput
                placeholder="email"
                onChange={(val) => setEmail(val.toLowerCase().trim())}
                value={email}
              />
            </div>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%", marginTop: "20px", height: "50px" }}
              disabled={buttonDisabled}
              loading={loading}
            >
                Submit
            </Button>
          </Form.Item>
          {error && <BasicText color="red">{error}</BasicText>}
        </Form>
      }
    </Modal>
  );
};

// const mapStatesToProps = (state) => {
//   const { name, logged_in, login_error, force_email } = state.account;
//   const { invite_company_name } = state.company;
//   return { name, logged_in, login_error, force_email, invite_company_name };
// };

export default connect(null, {
  sendResetPassword
})(ForgotPasswordModal);
