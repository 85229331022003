import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import { useHistory } from 'react-router-dom';
import { fetchTeamList } from '@actions';
import { withTheme } from 'styled-components';
import { NAV_SECTIONS, CHALLENGE_TABLE_FILTERS } from '@global';
import HostPayoutTable from '@fulfillment/hosts/HostPayoutTable';
import styles from '@components/HeaderAndTable.module.css';
import LoadingWrapper from '@components/LoadingWrapper';
import CreateContainer from '@challenges/create/CreateContainer';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { MuseoText, BasicButton, BottomTableLoader, SearchBar, FilterTabs } from '@components';
import { Input, Space } from 'antd';
import { getAPIRequest, getHostPayoutChallengesList } from '@actions';
import useOnScreen from '@components/useOnScreen';
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor';

const { Search } = Input;

let _searchTimeout = null;

const HostPayoutHome = ({
  adminUser,
  getHostPayoutChallengesList,
  getAPIRequest,
  theme
}) => {

  const [challenges, setChallenges] = useState([]);
  const [seenIds, setSeenIds] = useState([]);
  const [isMore, setIsMore] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFetchingChallenges, setIsFetchingChallenges] = useState(true);
  const [viewingChallenge, sestViewingChallenge] = useState(null);
  const history = useHistory();
  const isFetchingMoreFromBottom = useRef(false);

  useEffect(() => {
    fetchChallenges();
  }, []);


  const performSearch = () => {
    const currentSearchTerm = searchTerm;
    if (currentSearchTerm.length > 0) {
      getAPIRequest(
        '/admin/fulfill_host_search',
        { query: currentSearchTerm },
        response => {
          console.log(response);
          if (currentSearchTerm === searchTerm) {
            setSearchResults(response.challenges);
          }
        },
        error => {
          //onFail(error.error);
        },
      );
    }
  }

  useEffect(() => {
    clearTimeout(_searchTimeout);
    if (searchTerm.length > 0) {
      _searchTimeout = setTimeout(() => performSearch(), 200);
    }
  }, [searchTerm]);

  const onBottomLoadingVisible = (isVisible) => {
    if (isVisible) {
      if (!isFetchingMoreFromBottom.current && isMore) fetchChallenges();
    }
  }

  const fetchChallenges = (isRefresh = false) => {
    console.log("HGELLOW");
    if (!isMore) return;
    if (isFetchingMoreFromBottom.current) return;
    isFetchingMoreFromBottom.current = true;

    getHostPayoutChallengesList(
      seenIds,
      (newChallenges) => {
        console.log(newChallenges);
        setSeenIds(seenIds.concat(newChallenges.map((c) => c.id)));
        setChallenges(challenges.concat(newChallenges));
        setIsMore(newChallenges.length === 20);
        isFetchingMoreFromBottom.current = false;
        setIsFetchingChallenges(false);
      },
      () => {}
    )
  }

  return (
    <div style={{ marginTop: "220px" }}>
      <SearchBar
        searchTerm={searchTerm}
        onChange={(value) => setSearchTerm(value)}
      />
      {isFetchingChallenges ?
        <div style={{ display: "flex" , flex: 1, alignItems: 'center' , justifyContent: "center" }}>

        </div>
      :
        <>
          <HostPayoutTable
            marginTop="40px"
            challengeList={searchTerm.length > 0 ? searchResults : challenges}
            onChallengeSelected={(challenge) => {
              history.push({
                pathname: challenge.home_path,
              });
            }}
          />
          {isMore && searchTerm.length === 0 &&
            <VisibilitySensor onChange={onBottomLoadingVisible}>
              <BottomTableLoader />
            </VisibilitySensor>
          }
        </>
      }
    </div>
  )
}

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getHostPayoutChallengesList,
  getAPIRequest
})(withTheme(HostPayoutHome));
