import {
  UPDATE_TEAM_LIST,
  FETCHING_MORE_TEAM
} from '@actions/types';
import {
  getAPIRequest,
  postAPIRequest
} from '@actions';

export const fetchTeamList = () => {
  return (dispatch, getState) => {
    console.log("FETCHING MORE");
    const { seenIds, fetchingMore } = getState().team;
    if (fetchingMore) return;

    dispatch({ type: FETCHING_MORE_TEAM });

    dispatch(
      getAPIRequest(
        '/admin/get_team_list',
        {
          seen_ids: seenIds
        },
        response => {
          dispatch({ type: UPDATE_TEAM_LIST, payload: { members: response.members, isRefresh: false } });
        }
      )
    );
  };
};

export const inviteMember = (data, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/invite_member',
        {
          ...data,
        },
        response => {
          if (response.success) {
            dispatch({ type: UPDATE_TEAM_LIST, payload: { members: response.members, isRefresh: true } });
            onSuccess(response.message);
          } else {
            onFail(response.message);
          }
        },
        error => {
          onFail("Something went wrong.");
        },
      ),
    );
  };
};

export const getMemberInfo = (admin_id, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      getAPIRequest(
        '/admin/get_team_member_info',
        {
          admin_id
        },
        response => {
          onSuccess(response.member);
        },
        error => {
          console.log(error);
          onFail("Something went wrong.");
        },
      )
    );
  };
};

export const editMember = (data, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/edit_team_member',
        {
          ...data,
        },
        response => {
          dispatch({ type: UPDATE_TEAM_LIST, payload: { members: response.members, isRefresh: true } });
          onSuccess(response);
        },
        error => {
          onFail("Something went wrong.");
        },
      ),
    );
  };
};

export const resendInvite = (data, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/resend_invite',
        {
          ...data,
        },
        response => {
          dispatch({ type: UPDATE_TEAM_LIST, payload: { members: response.members, isRefresh: true } });
          onSuccess(response);
        },
        error => {
          onFail("Something went wrong.");
        },
      ),
    );
  };
};

export const deleteMember = (data, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/delete_team_member',
        {
          ...data,
        },
        response => {
          dispatch({ type: UPDATE_TEAM_LIST, payload: { members: response.members, isRefresh: true } });
          onSuccess(response);
        },
        error => {
          onFail("Something went wrong.");
        },
      ),
    );
  };
};

export const getMemberFromInviteOrResetHash = (invite_hash, reset_hash, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      getAPIRequest(
        '/admin/get_team_member_from_invite_hash',
        {
          invite_hash,
          reset_hash
        },
        response => {
          if (response.success) {
            onSuccess(response.member);
          } else {
            onFail();
          }
        },
        error => {
          onFail();
        },
      )
    );
  };
};

export const setMemberPassword = (data, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/set_password_for_team_member',
        {
          ...data,
        },
        response => {
          if (response.success) {
            onSuccess();
          } else {
            onFail("Something went wrong.");
          }
        },
        error => {
          onFail("Something went wrong.");
        },
      ),
    );
  };
};

export const sendResetPassword = (email, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/send_reset_password_link',
        {
          email
        },
        response => {
          console.log(response);
          if (response.success) {
            onSuccess();
          } else {
            onFail();
          }
        },
        error => {
          onFail();
        },
      ),
    );
  };
};

export const resetExistingPassword = (existing_password, new_password, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/reset_password_for_team_member',
        {
          existing_password,
          new_password
        },
        response => {
          console.log(response);
          if (response.success) {
            onSuccess();
          } else {
            onFail(response.message);
          }
        },
        error => {
          onFail("Something went wrong");
        },
      ),
    );
  };
};
