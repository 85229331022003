import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form, Space } from 'antd';
import { withTheme } from 'styled-components';
import {
  PlayCircleOutlined,
  CheckCircleFilled,
  WarningFilled,
} from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import {
  TitleText,
  BasicText,
  BasicButton,
  ShortInput,
  LongInput,
} from '@components';
import styles from './ReviewChallenge.module.css';
import ResponseButtons from '@review/hosts/ResponseButtons';
import IndividualPrize from '@review/portal_challenges/IndividualPrize';
import { formatPhoneNumber, PRIZE_TYPES } from '@global';

const PrizesSection = ({ challenge, reviewJson, updateReviewJson, theme }) => {
  const {
    portal_prize_info,
    challenge_type,
    charity_entry_fee_info,
  } = challenge;

  console.log(portal_prize_info);

  const prizeDetails = () => {
    if (challenge_type === PRIZE_TYPES.PAY_TO_PLAY) {
      return (
        <>
          <div className={styles.attribute_title}>Entry Fee</div>

          <div
            className={styles.attribute_text}
            style={{
              color: theme.colors.blurple,
              fontWeight: '700',
              fontSize: '20px',
            }}
          >
            {portal_prize_info.entry_fee}
          </div>
        </>
      );
    } else if (challenge_type === PRIZE_TYPES.BUNDLED_PRIZE) {
      return (
        <>
          <div className={styles.attribute_title}>Package Selected</div>

          <div
            className={styles.attribute_text}
            style={{
              color: theme.colors.blurple,
              fontWeight: '700',
              fontSize: '20px',
            }}
          >
            {portal_prize_info.bundle.display_name}
          </div>

          <div className={styles.attribute_title}>Amounts Breakdown</div>

          <div style={{ marginTop: '12px' }}>
            {portal_prize_info.bundle.prizes_info.map((b) => (
              <div key={b.name}>
                {b.name}: ${(b.face_value / 100).toFixed(2)}
              </div>
            ))}
          </div>

          {challenge.is_charity && (
            <div style={{ marginTop: '24px' }}>
              <div className={styles.attribute_title}>Entry Fee</div>

              <div
                className={styles.attribute_text}
                style={{
                  color: theme.colors.blurple,
                  fontWeight: '700',
                  fontSize: '20px',
                }}
              >
                {charity_entry_fee_info.entry_fee}
              </div>
            </div>
          )}
        </>
      );
    } else if (challenge_type === PRIZE_TYPES.CUSTOM_PRIZE) {
      return (
        <>
          {portal_prize_info.map((p, index) => (
            <IndividualPrize key={p.id} prize={p} index={index} />
          ))}

          {challenge.is_charity && (
            <div style={{ marginTop: '24px' }}>
              <div className={styles.attribute_title}>Entry Fee</div>

              <div
                className={styles.attribute_text}
                style={{
                  color: theme.colors.blurple,
                  fontWeight: '700',
                  fontSize: '20px',
                }}
              >
                {charity_entry_fee_info.entry_fee}
              </div>
            </div>
          )}
        </>
      );
    }
  };

  return (
    <div className={styles.approve_section} style={{ marginTop: '40px' }}>
      <div className={styles.section_header_container}>
        <div className={styles.section_header}>Prize Information</div>

        {reviewJson['Prizes'].decided_on && (
          <>
            {!reviewJson['Prizes'].approved ? (
              <div className={styles.approve_check}>
                <WarningFilled
                  style={{
                    color: theme.colors.danger,
                    fontWeight: 700,
                    fontSize: '26px',
                  }}
                />
              </div>
            ) : (
              <div className={styles.approve_check}>
                <CheckCircleFilled
                  style={{
                    color: theme.colors.blurple,
                    fontWeight: 700,
                    fontSize: '26px',
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>

      <div className={styles.attribute_title}>Prizes Type</div>

      <div
        className={styles.attribute_text}
        style={{
          color: theme.colors.blurple,
          fontWeight: '700',
          fontSize: '20px',
        }}
      >
        {challenge_type === 'Pay-to-Play' ? 'Prize Pool' : challenge_type}
      </div>

      {prizeDetails()}

      <div style={{ marginTop: '20px' }}>
        <ResponseButtons
          approveText="Approve Prizes"
          rejectText="Reject Prizes"
          showRejected={
            reviewJson['Prizes'].decided_on && !reviewJson['Prizes'].approved
          }
          rejectedReason={reviewJson['Prizes'].reason}
          onApprove={() => {
            updateReviewJson({
              Prizes: { decided_on: true, approved: true },
            });
          }}
          onReject={() => {
            updateReviewJson({
              Prizes: { decided_on: true, approved: false },
            });
          }}
          updateRejectedReason={(val) => {
            updateReviewJson({
              Prizes: { decided_on: true, approved: false, reason: val },
            });
          }}
        />
      </div>
    </div>
  );
};

export default connect(null, {})(withTheme(PrizesSection));
