import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import { useHistory } from 'react-router-dom';
import { fetchTeamList } from '@actions';
import { withTheme } from 'styled-components';
import { NAV_SECTIONS } from '@global';
import styles from '@components/HeaderAndTable.module.css';
import LoadingWrapper from '@components/LoadingWrapper';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { MuseoText, BasicButton, BottomTableLoader, SearchBar, FilterTabs, BasicText } from '@components';
import { Input, Space, Table } from 'antd';
import { getPaymentsForFilter } from '@actions';
import useOnScreen from '@components/useOnScreen';
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor';

const RevenueTableSection = ({
  adminUser,
  getPaymentsForFilter,
  currentFilter,
  theme
}) => {

  const listInnerRef = useRef();
  const [payments, setPayments] = useState([]);
  const [seenIds, setSeenIds] = useState([]);
  const [isMore, setIsMore] = useState(true);
  const [isFetchingPayments, setIsFetchingPayemnts] = useState(true);
  const history = useHistory();
  const isFetchingMoreFromBottom = useRef(false);

  useEffect(() => {
    fetchPaymentsForFilter(currentFilter, true);
  }, [currentFilter]);

  console.log(payments.length);

  const onBottomLoadingVisible = (isVisible) => {
    if (isVisible) {
      if (!isFetchingMoreFromBottom.current && isMore) fetchPaymentsForFilter(currentFilter);
    }
  }

  const fetchPaymentsForFilter = (type , isNewTab = false) => {

    if (!isNewTab && !isMore) return;
    if (!isNewTab && isFetchingMoreFromBottom.current) return;

    if (isNewTab) setIsFetchingPayemnts(true);
    isFetchingMoreFromBottom.current = true;

    getPaymentsForFilter(
      type,
      isNewTab ? [] : seenIds,
      (newPayments) => {
        if (isNewTab) {
          setSeenIds(newPayments.map((c) => c.id));
          setPayments(newPayments);
        } else {
          setSeenIds(seenIds.concat(newPayments.map((c) => c.id)));
          setPayments(payments.concat(newPayments));
        }
        setIsMore(newPayments.length > 0);
        isFetchingMoreFromBottom.current = false;
        setIsFetchingPayemnts(false);
      },
      () => {}
    )
  }

  const getPurchaseTableData = () => {
    let dataArray = [];
    payments.forEach((purchase, i) => {
      dataArray.push({
        id: purchase.id,
        time_string: purchase.time_string,
        was_refunded: purchase.was_refunded,
        is_bundle: purchase.is_bundle,
        amount: purchase.amount,
        text: purchase.text,
        username: purchase.username
      });
    });
    return dataArray;
  };

  const getColumns = () => {
    let columns = [
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Description</div>;
        },
        dataIndex: "text",
        key: "text",
        width: 180,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <BasicText fontSize="15px" bold>
                  {text}
                </BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Username</div>;
        },
        dataIndex: "username",
        key: "username",
        width: 80,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: 150,
                  overflow: "hidden",
                }}
              >
                <BasicText fontSize="15px">{text}</BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Date</div>;
        },
        dataIndex: "time_string",
        key: "time_string",
        width: 80,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: 150,
                  overflow: "hidden",
                }}
              >
                <BasicText fontSize="13px">{text}</BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Amount</div>;
        },
        dataIndex: "amount",
        key: "amount",
        width: 80,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: 150,
                  overflow: "hidden",
                }}
              >
                {record.was_refunded ?
                  <BasicText fontSize="15px" color={theme.colors.danger}>Refunded - {text}</BasicText>
                :
                  <BasicText fontSize="15px">{text}</BasicText>
                }
              </div>
            ),
          };
        },
      }
    ];

    return columns;
  };

  if (isFetchingPayments) return <div />;


  return (
    <div>
      <Table
        pagination={false}
        columns={getColumns()}
        dataSource={getPurchaseTableData()}
        rowKey="id"
        rowClassName={styles.unclickable_table}
      />

      {isMore &&
        <VisibilitySensor onChange={onBottomLoadingVisible}>
          <BottomTableLoader />
        </VisibilitySensor>
      }
    </div>
  )
}

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getPaymentsForFilter
})(withTheme(RevenueTableSection));
