import {
  SET_ACCOUNT_MENU
} from './types';


export const setAccountMenu = (showMenu) => {
  return (dispatch) => {
    dispatch({ type: SET_ACCOUNT_MENU, payload: showMenu });
  };
};
