import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form, Switch } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { withTheme } from 'styled-components';
import { } from '@actions';
import styles from './ChallengeStats.module.css';
import { showSuccessMessage } from '@global';

const ShareLiveStatsModal = ({
  challenge,
  onCancel,
  onToggleStatsVisibility,
  theme
}) => {

  const info = challenge.share_live_stats_info;

  console.log(info);

  const onToggleVisibility = (isToggled) => {
    // toggleLiveStatsVisibility(
    //   challenge.challenge_id,
    //   isToggled,
    //   (response) => {
    //     showSuccessMessage(response.message);
    //     setChallenge(response.challenge);
    //   },
    //   (error) => showErrorMessage(error)
    // );
  };

  return (
    <Modal
      title="Share Live Stats"
      visible
      footer={null}
      onCancel={() => onCancel()}
    >
      <div className={styles.share_stats_modal_container}>
        <div className={styles.share_stats_section}>
          <div>
            <div className={styles.share_section_title}>
              Share Link
            </div>
            <div className={styles.share_link_text}>
              {info.link}
            </div>
          </div>
          <CopyToClipboard
            text={info.link}
            onCopy={() => showSuccessMessage("Stats Link Copied!")}
          >
            <div className={styles.copy_button}>
              Copy Link
            </div>
          </CopyToClipboard>
        </div>

        <div className={styles.share_section_seperator} />

        <div className={styles.share_stats_section}>
          <div>
            <div className={styles.share_section_title}>
              Password
            </div>
            <div className={styles.share_link_text}>
              {info.password}
            </div>
          </div>
          <CopyToClipboard
            text={info.password}
            onCopy={() => showSuccessMessage("Link Password Copied!")}
          >
            <div className={styles.copy_button}>
              Copy Password
            </div>
          </CopyToClipboard>
        </div>

        <div className={styles.share_section_seperator} />

        <div className={styles.share_stats_section}>
          <div>
            <div className={styles.share_section_title}>
              Enabled Status
            </div>
            <div className={styles.share_link_text}>
              {info.is_enabled ? "ENABLED" : "DISABLED"}
            </div>
          </div>
          <Switch
            defaultChecked={info.is_enabled}
            onChange={(isToggled) => onToggleStatsVisibility(isToggled)}
            style={{ backgroundColor: info.is_enabled ? theme.colors.blurple : theme.colors.lightShade  }}
          />
        </div>

        <div
          className={styles.preview_stats}
          onClick={() => window.open(info.link, "_blank")}
        >
          Preview Stats Page
        </div>
      </div>
    </Modal>
  );
}

export default connect(null, {

})(withTheme(ShareLiveStatsModal));
