import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, Button, Form, Input } from 'antd';
import PageWrapper from '@components/PageWrapper';
import LoadingWrapper from '@components/LoadingWrapper';
import { TitleText, BasicText, CustomButton, BasicButton, ShortInput, MuseoText } from '@components';
import  { getMemberFromInviteOrResetHash, setMemberPassword } from '@actions';
import styles from './Team.module.css';
import { showSuccessMessage, showErrorMessage  } from '@global';
import logo from '../images/leftAlignSingleWordmark_light.png';

const AcceptInvite = ({
  getMemberFromInviteOrResetHash,
  setMemberPassword,
  isReset,
  match
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [buttonEnabled, setButtonEnabled] = useState(true);
  const [error, setError] = useState(false);
  const invite_hash = match?.params?.inv_hs;
  const reset_hash = match?.params?.rst_hs;
  const [member, setMember] = useState(null);
  const [fetchedMember, setFetchedMember] = useState(false);

  console.log(invite_hash, reset_hash);

  useEffect(() => {
    getMemberFromInviteOrResetHash(
      invite_hash,
      reset_hash,
      (mem) => {
        setMember(mem);
        setFetchedMember(true);
      },
      () => {
        setFetchedMember(true);
      }
    )
  }, []);

  useEffect(() => {
    setButtonEnabled(password.length > 6 && password === passwordConfirm);
  }, [password, passwordConfirm]);


  const onFormSubmit = () => {
    setLoading(true);
    setMemberPassword(
      { invite_hash, reset_hash, password },
      () => {
        setLoading(false);
        history.push('/login');
        setTimeout(() => showSuccessMessage("Password successfully set!", 5), 200);
      },
      (errorMessage) => {
        showErrorMessage(errorMessage, 6);
        setLoading(false);
      }
    );
  }

  const passwordSet = () => {
    return (
      <div className={styles.password_set_container}>
        <Form onFinish={onFormSubmit} initialValues={{ remember: true }}>

          <Form.Item>
            <div className="input_section">
              <ShortInput
                isPassword
                placeholder="password"
                onChange={(val) => setPassword(val)}
                value={password}
              />
            </div>
          </Form.Item>

          <Form.Item>
            <div className="input_section">
              <ShortInput
                isPassword
                placeholder="password confirm"
                onChange={(val) => setPasswordConfirm(val)}
                value={passwordConfirm}
              />
            </div>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%", marginTop: "20px", height: "50px" }}
              disabled={!buttonEnabled}
              loading={loading}
            >
                Submit
            </Button>
          </Form.Item>

          {error && <BasicText color="red">{error}</BasicText>}
        </Form>
      </div>
    );
  };

  const content = () => {
    if (member != null) {
      return (
        <>
          <div style={{ paddingTop: "20px" , paddingBottom: "40px"}}>
            <BasicText textAlign="center">
              Welcome {member.first_name},{"\n"}
              {reset_hash ?
                "Please reset your password below."
              :
                "Please set your password below to get started."
              }
            </BasicText>
          </div>
          {passwordSet()}
        </>
      );
    } else if (fetchedMember && member == null) {
      return (
        <BasicText textAlign="center">
          This link is invalid or has expired. Please check with the admin to resend a new invite.
        </BasicText>
      )
    }
  };

  return (
    <div style={{ justifyContent: "center", alignItems: "center", display: "flex", flexDirection: 'column', padding: 20}}>
      <LoadingWrapper isLoading={!fetchedMember}>
        <div style={{ width: "100%", justifyContent: "center", alignItems: "center", display: "flex", flexDirection: 'column'}}>
          <img src={logo} alt="logo" style={{ width: "240px", marginTop: "50px", marginBottom: "36px", textAlign: 'center' }} />
          {content()}
        </div>
      </LoadingWrapper>
    </div>
  )
};

export default connect(null, {
  getMemberFromInviteOrResetHash,
  setMemberPassword
})(AcceptInvite);
