import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form } from 'antd';
import { withTheme } from 'styled-components';
import { PlayCircleOutlined, PlayCircleTwoTone } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, BasicButton, ShortInput, LongInput } from '@components';
import styles from './ChallengeStats.module.css';

const ChallengeDetails = ({
  challenge,
  theme
}) => {

  return (
    <div>
      <div className={styles.type_header}>
        Challenge Details
      </div>
      <div className={styles.details_container}>
        <div className={styles.explainer_container}>
          <img
            className={styles.explainer_container}
            src={challenge.media_info.explainer_cover_url}
          />

          <div className={styles.play_explainer_cover} />

          <div
            className={styles.play_explainer}
            onClick={() => window.open(challenge.media_info.explainer_video_url, "_blank")}
          >
            <PlayCircleTwoTone
              style={{ fontSize: "60px" }}
              twoToneColor={challenge.brand_info.brand_color}
            />
          </div>
        </div>

        <div className={styles.description_container}>
          <div className={styles.detail_header}>
            <BasicText bold>Creator</BasicText>
          </div>
          <div className={styles.detail_text}>
            <BasicText>{challenge.creator}</BasicText>
          </div>

          <div className={styles.dates_container}>
            <div>
              <div className={styles.detail_header}>
                <BasicText bold>Drop Date</BasicText>
              </div>
              <div className={styles.detail_text}>
                <BasicText fontSize="13px">{challenge.basic_info.drop_date}</BasicText>
              </div>
            </div>

            <div style={{ marginLeft: "50px" }}>
              <div className={styles.detail_header}>
                <BasicText bold>End Date</BasicText>
              </div>
              <div className={styles.detail_text}>
                <BasicText fontSize="13px">{challenge.basic_info.end_date}</BasicText>
              </div>
            </div>
          </div>

          <div className={styles.detail_header}>
            <BasicText bold>Description</BasicText>
          </div>
          <div className={styles.detail_text}>
            <BasicText>{challenge.basic_info.description}</BasicText>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStatesToProps = (state) => {
  const { newChallengeInfo } = state.challenge;
  return { newChallengeInfo };
};

export default connect(mapStatesToProps, {
})(withTheme(ChallengeDetails));
