import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Select } from 'antd';
const { Option } = Select;
import PageWrapper from '@components/PageWrapper';
import {
  BasicButton,
  DeleteButton,
  ConfirmModal,
  BasicText,
  DropDown,
} from '@components';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import CompletionStatusBar from '@challenges/edit/CompletionStatusBar';
import { useHistory } from 'react-router-dom';
import BasicInfo from '@challenges/create/BasicInfo';
import BrandInfo from '@challenges/create/BrandInfo';
import MediaInfo from '@challenges/create/MediaInfo';
import PromoInfo from '@challenges/create/PromoInfo';
import PrizeInfo from '@challenges/create/PrizeInfo';
import RulesInfo from '@challenges/create/RulesInfo';
import LeavePageBlocker from '@components/LeavePageBlocker';
import {
  updateChallengeOnServer,
  updateViewedChallenge,
  getChallengeInfo,
  deleteChallenge,
  publishChallenge,
} from '@actions';
import styles from './EditChallenge.module.css';
import {
  validateEmail,
  showSuccessMessage,
  showErrorMessage,
  ENTRY_FEES,
} from '@global';

const EditChallenge = ({
  challengeList,
  updateViewedChallenge,
  viewedChallengeInfo,
  updateChallengeOnServer,
  getChallengeInfo,
  deleteChallenge,
  publishChallenge,
  adminUser,
  match,
}) => {
  const bundleOfferings = adminUser.bundle_offers;

  const {
    basic_info,
    brand_info,
    media_info,
    promo_info,
    prize_info,
    rules_info,
    complete_status,
    is_publishable,
    was_published,
    challenge_id,
  } = viewedChallengeInfo;

  console.log(viewedChallengeInfo);

  const history = useHistory();
  const [fetchedCallenge, setFetchedChallenge] = useState(false);
  const [challengeName, setChallengeName] = useState('');

  const [loading, setLoading] = useState(false);
  const [enableSave, setEnableSave] = useState(false);

  const [showDeleteConfrim, setShowDeleteConfirm] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [showPublishConfirm, setShowPublishConfirm] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);

  const getSelectedIndex = () =>
    bundleOfferings.findIndex((object) => {
      return object.id === prize_info.bundled_offering_id;
    });
  const [placePrizes, setPlacePrizes] = useState(
    prize_info.bundled_offering_id
      ? bundleOfferings[getSelectedIndex()].prizes
      : []
  );
  useEffect(() => {
    if (prize_info.bundled_offering_id) {
      setPlacePrizes(bundleOfferings[getSelectedIndex()].prizes);
    }
  }, [prize_info.bundled_offering_id]);

  const basicRef = useRef();
  const brandRef = useRef();
  const mediaRef = useRef();
  const promoRef = useRef();
  const prizeRef = useRef();
  const rulesRef = useRef();

  useEffect(() => {
    getChallengeInfo(match.params.challenge_id, (challenge) => {
      updateViewedChallenge({ ...viewedChallengeInfo, ...challenge });
      setFetchedChallenge(true);
      setChallengeName(challenge.basic_info.title);
    });
  }, []);

  if (!fetchedCallenge || basic_info.title == null) return <div />;

  const statusBar = () => {
    if (!viewedChallengeInfo.is_portal && !viewedChallengeInfo.is_ugc) {
      return (
        <CompletionStatusBar
          complete_status={complete_status}
          onBasicClicked={() =>
            basicRef.current.scrollIntoView({ behavior: 'smooth' })
          }
          onBrandClicked={() =>
            brandRef.current.scrollIntoView({ behavior: 'smooth' })
          }
          onMediaClicked={() => {
            mediaRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'start',
            });
          }}
          onPromoClicked={() =>
            promoRef.current.scrollIntoView({ behavior: 'smooth' })
          }
          onPrizeClicked={() =>
            prizeRef.current.scrollIntoView({ behavior: 'smooth' })
          }
          onRulesClicked={() =>
            rulesRef.current.scrollIntoView({ behavior: 'smooth' })
          }
        />
      );
    }
  };

  const onSave = () => {
    setLoading(true);
    updateChallengeOnServer(
      viewedChallengeInfo,
      (challenge) => {
        updateViewedChallenge(challenge);
        setLoading(false);
        setEnableSave(false);
        showSuccessMessage('Challenge successfully updated!');
        setChallengeName(challenge.basic_info.title);
      },
      (error) => {
        showErrorMessage(error);
        setLoading(false);
      },
      true
    );
  };

  const saveButton = () => {
    return (
      <BasicButton
        text="Save"
        loading={loading}
        type="primary"
        disabled={!enableSave}
        onClick={() => {
          onSave();
        }}
      />
    );
  };

  const publishButton = () => {
    return (
      <BasicButton
        text="Publish"
        type="primary"
        disabled={!is_publishable || enableSave}
        onClick={() => {
          setShowPublishConfirm(true);
        }}
      />
    );
  };

  const onPublishConfirm = () => {
    setPublishLoading(true);
    publishChallenge(
      viewedChallengeInfo.challenge_id,
      (response) => {
        setPublishLoading(false);
        history.goBack();
        setTimeout(
          () => showSuccessMessage('Challenge successfully published.', 5),
          200
        );
      },
      (message) => {
        showErrorMessage(message, 6);
        setPublishLoading(false);
      }
    );
  };

  const onDeleteConfirm = () => {
    setDeleteLoading(true);
    deleteChallenge(
      viewedChallengeInfo.challenge_id,
      (response) => {
        setDeleteLoading(false);
        history.goBack();
        setTimeout(
          () => showSuccessMessage('Challenge successfully deleted.', 5),
          200
        );
      },
      () => {
        showErrorMessage('Something wend wrong.', 6);
        setDeleteLoading(false);
      }
    );
  };

  console.log('is portal', !viewedChallengeInfo.is_portal);

  const additionalContent = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {!was_published && !viewedChallengeInfo.is_portal && publishButton()}
        <div style={{ marginLeft: '30px' }}>{saveButton()}</div>
        <div style={{ marginLeft: '30px' }}>
          <DeleteButton onClick={() => setShowDeleteConfirm(true)} />
        </div>
      </div>
    );
  };

  const prizesSection = () => {
    if (
      !viewedChallengeInfo.is_portal ||
      viewedChallengeInfo.challenge_type == 'Custom Prize'
    ) {
      return (
        <PrizeInfo
          updateChallenge={(data) => {
            updateViewedChallenge(data);
            setEnableSave(true);
          }}
          prize_info={viewedChallengeInfo.prize_info}
          isEdit
        />
      );
    } else if (viewedChallengeInfo.challenge_type == 'Pay-to-Play') {
      return (
        <div>
          <div className={styles.section_title}>
            Entry Fee: {viewedChallengeInfo.prize_info.entry_fee}
          </div>

          <DropDown
            options={ENTRY_FEES}
            placeholder="Entry Fee"
            onSelect={(value) => {
              updateViewedChallenge({ prize_info: { entry_fee: value } });
              setEnableSave(true);
            }}
            defaultOption={viewedChallengeInfo.prize_info.entry_fee}
            minWidth="300px"
          />
        </div>
      );
    } else if (viewedChallengeInfo.challenge_type == 'Bundled Prize') {
      return (
        <div>
          <Select
            defaultValue={prize_info.bundled_offering_id}
            placeholder={'Prize Bundle Selection'}
            onChange={(val) => {
              updateViewedChallenge({
                prize_info: { bundled_offering_id: val },
              });
              setEnableSave(true);
            }}
            style={{ minWidth: '300px', fontSize: '16px' }}
          >
            {bundleOfferings.map((bo) => (
              <div key={bo.id} value={bo.id}>
                {bo.display_name} - Price: {bo.total_price_string}
              </div>
            ))}
          </Select>

          <div style={{ marginTop: '20px' }}>
            {placePrizes.map((place) => (
              <div key={place.name} style={{ marginBottom: '6px' }}>
                {place.name}: {place.description}
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  return (
    <PageWrapper>
      <FixedHeaderContainer
        text={challengeName}
        onBackPressed={() => console.log('back pressed')}
        fixedContent={statusBar()}
        paddingBuffer="80px"
        paddingTop="56px"
        useMuseo
        additionalContent={additionalContent()}
      >
        <div
          className={styles.content_container}
          style={{
            marginTop: viewedChallengeInfo.is_portal ? '80px' : '180px',
          }}
        >
          <div ref={basicRef} className={styles.section_container}>
            <div className={styles.section_title_container}>
              <h2>Basic Info</h2>
            </div>

            <div className={styles.type_challenge_header}>
              TYPE:{' '}
              {viewedChallengeInfo.is_ugc
                ? `${viewedChallengeInfo.visibility} MGC`.toUpperCase()
                : viewedChallengeInfo.type.toUpperCase()}
            </div>

            <BasicInfo
              updateChallenge={(data) => {
                updateViewedChallenge(data);
                setEnableSave(true);
              }}
              basic_info={viewedChallengeInfo.basic_info}
              isPortal={viewedChallengeInfo.is_portal}
            />
          </div>

          <div ref={mediaRef} className={styles.section_container}>
            <div className={styles.section_title_container}>
              <h2 style={{ marginBottom: '24px' }}>Media Info</h2>
            </div>

            <MediaInfo
              updateChallenge={(data) => {
                updateViewedChallenge(data);
                setEnableSave(true);
              }}
              media_info={viewedChallengeInfo.media_info}
              isEdit
            />
          </div>

          <div ref={brandRef} className={styles.section_container}>
            <div className={styles.type_challenge_header}>
              PRIZE TYPE:{' '}
              {!viewedChallengeInfo.is_portal ||
              viewedChallengeInfo.challenge_type == 'Custom Prize'
                ? 'CUSTOM PRIZE'
                : viewedChallengeInfo.challenge_type.toUpperCase()}
            </div>

            {prizesSection()}
          </div>

          {!viewedChallengeInfo.is_ugc && (
            <>
              <div ref={brandRef} className={styles.section_container}>
                <div className={styles.section_title_container}>
                  <h2 style={{ marginBottom: '24px' }}>
                    {viewedChallengeInfo.is_charity
                      ? 'Charity Info'
                      : viewedChallengeInfo.is_portal
                      ? 'Promo Info'
                      : 'Brand Info'}
                  </h2>
                </div>

                <BrandInfo
                  updateChallenge={(data) => {
                    updateViewedChallenge(data);
                    setEnableSave(true);
                  }}
                  brand_info={viewedChallengeInfo.brand_info}
                  promo_info={viewedChallengeInfo.promo_info}
                  isEdit
                  isPortal={viewedChallengeInfo.is_portal}
                  isCharity={viewedChallengeInfo.is_charity}
                />
              </div>
            </>
          )}
        </div>
      </FixedHeaderContainer>

      <LeavePageBlocker when={enableSave} />

      {showDeleteConfrim && (
        <ConfirmModal
          title={'Confirm Delete'}
          visible={showDeleteConfrim}
          loading={deleteLoading}
          onCancel={() => setShowDeleteConfirm(false)}
          onSubmit={() => onDeleteConfirm()}
        >
          <BasicText marginBottom="20px">
            Are you sure you want to delete this challenge? This action can not
            be undone.
          </BasicText>
        </ConfirmModal>
      )}

      {showPublishConfirm && (
        <ConfirmModal
          title={'Confirm Publish'}
          visible={showPublishConfirm}
          loading={publishLoading}
          onCancel={() => setShowPublishConfirm(false)}
          onSubmit={() => onPublishConfirm()}
        >
          <BasicText marginBottom="20px">
            Are you sure you want to publish this challenge? This will make it
            visible to all members in the app.
          </BasicText>
        </ConfirmModal>
      )}
    </PageWrapper>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  const { viewedChallengeInfo } = state.challenge;
  return { viewedChallengeInfo, adminUser };
};

export default connect(mapStatesToProps, {
  updateChallengeOnServer,
  updateViewedChallenge,
  getChallengeInfo,
  deleteChallenge,
  publishChallenge,
})(EditChallenge);
