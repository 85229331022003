import React from 'react';
import { Input } from 'antd';
import { BasicText } from '@components';
import './inputs.css';

const ShortInput = (props) => {
  const renderInput = () => {
    if (props.isPassword) {
      return (
        <Input.Password
          ref={props.inputRef}
          placeholder={props.placeholder}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          style={{ fontSize: "16px", borderRadius: "6px", height: "48px", backgroundColor: "#f4f4f4", borderWidth: "0px" }}
          className="password"
          maxLength={props.maxLength}
        />
      )
    } else {
      return (
        <Input
          ref={props.inputRef}
          placeholder={props.placeholder}
          type={props.type ? props.type : "text"}
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
          style={{ fontSize: "16px", borderRadius: "6px", height: "48px", backgroundColor: "#f4f4f4", borderWidth: "0px" }}
          name={props.name}
          disabled={props.disabled}
          maxLength={props.maxLength}
        />
      )
    }
  }

  return (
    <div className="short-input-container" style={{ maxWidth: props.maxWidth ? props.maxWidth : "100%" }}>
      {props.inputHeader &&
        <div className="input_label">
          <BasicText bold>{props.inputHeader}</BasicText>
        </div>
      }
      {renderInput()}
    </div>
  )
}

export default ShortInput;
