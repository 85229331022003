import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DatePicker, Form, Space, TimePicker, Button, Modal } from 'antd';
import moment from 'moment-timezone';
import { useHistory, Link } from 'react-router-dom';
import AssetUploader from '@challenges/create/AssetUploader';
import AssetPreview from '@challenges/create/AssetPreview';
import { TitleText, BasicText, BasicButton, ShortInput, LongInput, ConfirmModal } from '@components';
import PromoInfoModal from '@challenges/create/PromoInfoModal';
import PromoDisplay from '@challenges/create/PromoDisplay';
import { sendNotificationFromAdmin } from '@actions';
import {
  showSuccessMessage, showErrorMessage
} from '@global';

const SendNotification = ({
  isChallenge,
  challengeId,
  visible,
  onCancel,
  sendNotificationFromAdmin
}) => {

  const MAX_CHARS = 150;

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const onConfirmSend = () => {
    setLoading(true);
    sendNotificationFromAdmin(
      challengeId,
      message,
      () => {
        setLoading(false);
        showSuccessMessage("Notification Sent!");
        setShowConfirm(false);
        onCancel();
      },
      () => {
        showErrorMessage("Something went wrong.");
        setLoading(false);
      }
    )
  }

  return (
    <Modal
      title="Send Notification"
      visible={visible}
      footer={null}
      onCancel={() => onCancel()}
    >
      <Form.Item>
        <LongInput
          inputHeader={`Message (${MAX_CHARS - message.length})`}
          onChange={(val) => {
            if (val.length <= MAX_CHARS) {
              setMessage(val);
            }
          }}
          value={message}
        />
      </Form.Item>

      <div
        style={{
          marginTop: '40px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <BasicButton
          type='default'
          text='Cancel'
          onClick={() => onCancel()}
          style={{ width: '48%' }}
        />

        <BasicButton
          type='primary'
          text='Submit'
          disabled={message.length === 0}
          onClick={() => setShowConfirm(true)}
          style={{ width: '48%' }}
        />
      </div>

      {showConfirm &&
        <ConfirmModal
          title={"Confirm Notification"}
          visible={showConfirm}
          loading={loading}
          onCancel={() => setShowConfirm(false)}
          onSubmit={() => onConfirmSend()}
        >
          <BasicText marginBottom="20px">
            Are you sure you want to send this notification to {isChallenge ? "all members in this Challenge?" : "all members?"}
          </BasicText>
        </ConfirmModal>
      }
    </Modal>
  )
};

const mapStatesToProps = (state) => {
  const { newChallengeInfo } = state.challenge;
  return { newChallengeInfo };
};

export default connect(mapStatesToProps, {
  sendNotificationFromAdmin
})(SendNotification);
