import { combineReducers } from 'redux';
import AuthReducer from './AuthReducer';
import ChallengeReducer from './ChallengeReducer';
import TeamReducer from './TeamReducer';
import MemberReducer from './MemberReducer';
import ReviewReducer from './ReviewReducer';
import EntryReducer from './EntryReducer';
import PaymentReducer from './PaymentReducer';

export default combineReducers({
    auth: AuthReducer,
    challenge: ChallengeReducer,
    team: TeamReducer,
    member: MemberReducer,
    review: ReviewReducer,
    entry: EntryReducer,
    payment: PaymentReducer
});
