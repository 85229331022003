import React, { useState, useEffect } from 'react';
import styles from './Review.module.css';
import { connect } from 'react-redux';
import { Button, Modal, Radio, Space } from 'antd';
import PageWrapper from '@components/PageWrapper';
import LoadingWrapper from '@components/LoadingWrapper';
import { useHistory } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { BasicButton, MuseoText, BasicText } from '@components';

const ChallengeInfoModal = ({
  visible,
  onCancel,
  challenge
}) => {

  return (
    <Modal
      title="Challenge Info"
      visible
      onCancel={() => onCancel()}
      footer={null}
    >
      <Space direction="vertical">
        <MuseoText fontSize="20px">{challenge.title}</MuseoText>
        <BasicText>{challenge.description}</BasicText>
      </Space>
    </Modal>
  );
};

export default connect(null, {

})(withTheme(ChallengeInfoModal));
