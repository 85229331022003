import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import { useHistory } from 'react-router-dom';
import { fetchTeamList } from '@actions';
import { withTheme } from 'styled-components';
import { NAV_SECTIONS, CHALLENGE_TABLE_FILTERS } from '@global';
import PrizeAckTable from '@fulfillment/prizes/PrizeAckTable';
import styles from '@components/HeaderAndTable.module.css';
import LoadingWrapper from '@components/LoadingWrapper';
import CreateContainer from '@challenges/create/CreateContainer';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { MuseoText, BasicButton, BottomTableLoader, SearchBar, FilterTabs } from '@components';
import { Input, Space } from 'antd';
import { getAPIRequest, getAckPrizeInfo } from '@actions';
import useOnScreen from '@components/useOnScreen';
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor';

const PrizeAcknowledgementList = ({
  adminUser,
  getAckPrizeInfo,
  getAPIRequest,
  theme
}) => {

  const [prizes, setPrizes] = useState([]);
  const [seenIds, setSeenIds] = useState([]);
  const [isMore, setIsMore] = useState(true);
  const history = useHistory();
  const isFetchingMoreFromBottom = useRef(false);
  const [isFetchingChallenges, setIsFetchingChallenges] = useState(true);

  useEffect(() => {
    fetchPrizes();
  }, []);

  const onBottomLoadingVisible = (isVisible) => {
    if (isVisible) {
      if (!isFetchingMoreFromBottom.current && isMore) fetchPrizes();
    }
  }

  const fetchPrizes = (isRefresh = false) => {
    console.log("HGELLOW");
    if (!isMore) return;
    if (isFetchingMoreFromBottom.current) return;
    isFetchingMoreFromBottom.current = true;

    getAckPrizeInfo(
      seenIds,
      (newPrizes) => {
        console.log(newPrizes);
        setSeenIds(seenIds.concat(newPrizes.map((c) => c.id)));
        setPrizes(prizes.concat(newPrizes));
        setIsMore(newPrizes.length === 20);
        isFetchingMoreFromBottom.current = false;
        setIsFetchingChallenges(false);
      },
      () => {}
    )
  }

  console.log(seenIds);

  return (
    <PageWrapper>
      <FixedHeaderContainer
        text={'Prize Acknowledgement Info'}
        paddingBuffer="80px"
        paddingTop="56px"
      >
        <div style={{ marginTop: "140px" }}>
          {isFetchingChallenges ?
            <div style={{ display: "flex" , flex: 1, alignItems: 'center' , justifyContent: "center" }}>

            </div>
          :
            <>
              <PrizeAckTable
                marginTop="40px"
                prizeData={prizes}
              />
              {isMore &&
                <VisibilitySensor onChange={onBottomLoadingVisible}>
                  <BottomTableLoader />
                </VisibilitySensor>
              }
            </>
          }
        </div>
      </FixedHeaderContainer>
    </PageWrapper>
  )
}

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getAckPrizeInfo,
  getAPIRequest
})(withTheme(PrizeAcknowledgementList));
