import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Icon, Spin } from 'antd';
import {
  TitleText,
  BasicText,
  ShortInput,
  LongInput,
  CopyUrl,
  BasicButton,
} from '@components';
import styles from '@components/HeaderAndTable.module.css';

const ChallengeTable = ({
  challengeList,
  onChallengeSelected,
  showNumber,
  totalCount,
  isForReview,
  isForWaiting,
  marginTop,
}) => {
  const getTeamTableData = () => {
    let dataArray = [];
    challengeList.forEach((challenge, i) => {
      dataArray.push({
        id: challenge.id,
        was_published: challenge.was_published,
        name: challenge.title,
        creator: challenge.creator,
        dates: challenge.date_string,
        type: challenge.type,
        visibility: challenge.visibility,
        status: challenge.status,
        pending_reviews: challenge.pending_reviews,
        is_ugc: challenge.is_ugc,
        submitted_on: challenge.submitted_on,
        numberDrop: totalCount - i,
      });
    });
    return dataArray;
  };

  const getLastColumn = () => {
    if (isForWaiting) {
      return {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Submitted On</div>;
        },
        dataIndex: 'submitted_on',
        key: 'submitted_on',
        width: 80,
        render(text, record) {
          return {
            children: (
              <BasicText bold fontSize="15px">
                {text}
              </BasicText>
            ),
          };
        },
      };
    } else if (isForReview) {
      return {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Pending Reviews</div>;
        },
        dataIndex: 'pending_reviews',
        key: 'pending_reviews',
        width: 80,
        render(text, record) {
          return {
            children: (
              <BasicText
                bold
                color={record.pending_reviews > 0 ? 'red' : 'black'}
                fontSize="15px"
              >
                {text}
              </BasicText>
            ),
          };
        },
      };
    } else {
      return {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Status</div>;
        },
        dataIndex: 'status',
        key: 'status',
        width: 80,
        render(text, record) {
          return {
            children: (
              <BasicText
                color={text.includes('Expired') ? 'red' : 'black'}
                fontSize="15px"
              >
                {text}
              </BasicText>
            ),
          };
        },
      };
    }
  };

  const getColumns = () => {
    let columns = [];

    if (showNumber) {
      columns = [
        {
          title: () => {
            return <div style={{ fontSize: '15px' }}>Drop #</div>;
          },
          dataIndex: 'numberDrop',
          key: 'numberDrop',
          width: 30,
          render(text, record) {
            return {
              children: <BasicText fontSize="12px">{text}</BasicText>,
            };
          },
        },
      ];
    }

    columns = columns.concat([
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Name</div>;
        },
        dataIndex: 'name',
        key: 'name',
        width: 100,
        render(text, record) {
          return {
            children: (
              <BasicText fontSize="15px" bold>
                {text}
              </BasicText>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Creator</div>;
        },
        dataIndex: 'creator',
        key: 'creator',
        width: 80,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: 150,
                  overflow: 'hidden',
                }}
              >
                <BasicText fontSize="15px" bold>
                  {text}
                </BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Dates</div>;
        },
        dataIndex: 'dates',
        key: 'dates',
        width: 80,
        render(text, record) {
          return {
            children: <BasicText fontSize="12px">{text}</BasicText>,
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Type</div>;
        },
        dataIndex: 'type',
        key: 'type',
        width: 80,
        render(text, record) {
          return {
            children: (
              <BasicText
                color={text.includes('Expired') ? 'red' : 'black'}
                fontSize="15px"
              >
                {text}
              </BasicText>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Visibility</div>;
        },
        dataIndex: 'visibility',
        key: 'visibility',
        width: 80,
        render(text, record) {
          return {
            children: (
              <BasicText
                color={text.includes('Expired') ? 'red' : 'black'}
                fontSize="15px"
              >
                {text}
              </BasicText>
            ),
          };
        },
      },
      getLastColumn(),
    ]);

    return columns;
  };

  return (
    <div
      className={styles.table_container_style}
      style={{ marginTop: marginTop ? marginTop : '270px' }}
    >
      <Table
        pagination={false}
        columns={getColumns()}
        dataSource={getTeamTableData()}
        rowKey="id"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              onChallengeSelected(record);
            },
          };
        }}
      />
    </div>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {})(ChallengeTable);
