import React from 'react';
import { Input, Space } from 'antd';
const { Search } = Input;
import { withTheme } from 'styled-components';
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import './inputs.css';

const SearchBar = ({
  onChange,
  searchTerm,
  theme
 }) => {
  return (
    <div className="search_container">
      <div style={{ flex: 1 }}>
        <SearchOutlined style={{ fontSize: "20px", color: "#555" }} />
        <input
          className="search_input"
          value={searchTerm}
          onChange={(e) => onChange(e.target.value)}
          placeholder="Search"
        />
      </div>

      {searchTerm.length > 0 &&
        <div
          style={{ padding: "12px", cursor: "pointer", paddingRight: "0px", fontSize: "20px" }}
          onClick={() => onChange("")}
        >
          <CloseCircleOutlined style={{ color: "#555" }}/>
        </div>
      }
    </div>
  )
}

export default withTheme(SearchBar);
