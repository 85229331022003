import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './ForgotPassword.module.css';
import logo from '@images/logo-with-wordmark.png';
import { PageWrapper, TitleText, BasicText, BasicButton, ShortInput } from '@components';

const ForgotPassword = ({ match }) => {
  const [isValidToken, setIsValidToken] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [passwordOne, setPasswordOne] = useState("");
  const [passwordTwo, setPasswordTwo] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  console.log("HELLO", match.params.reset_token);

  useEffect(() => {
    axios.get(`/api/register/check_reset_token`, { params: { reset_token: match.params.reset_token } })
    .then(response => {
      setUserInfo(response.data.user_info);
      setIsValidToken(true);
    })
    .catch(error => {
      console.log(error);
    });
  }, []);

  useEffect(() => {
    setButtonDisabled(!validateForm());
  }, [passwordOne, passwordTwo]);

  const validateForm = () => {
    return passwordOne.length > 5 && passwordOne === passwordTwo;
  };

  const submitPasswordReset = () => {
    setLoading(true);
    const authToken = userInfo.auth_token;
    let body = {
      withCredentials: true,
      user_id: userInfo.id,
      password: passwordOne
    };
    axios
    .post(`../api/user/reset_password`, body, {
      headers: {
        'Authorization': `Bearer ${authToken}`
      }
    })
    .then((response) => response.data)
    .then((json) => {
      setShowSuccess(true);
      setLoading(false);
    })
    .catch((error) => {
      console.log(error);
      setLoading(false);
    });
  }

  return (
    <div className={styles.container}>
      <div className={styles.input_container}>
        <div style={{ marginBottom: "30px "}}>
          <img src={logo} style={{ maxWidth: "60px" }} />
        </div>
        Hello {userInfo.first_name}, please resest your Ovation Password password below.
        {isValidToken &&
          <div>
            {showSuccess ?
              <div style={{ paddingTop: "30px"}}>
                <div>Your password has been successfully reset.</div>
              </div>
            :
              <div>
                <div className="input_section">
                  <ShortInput
                    inputHeader="Enter Password"
                    onChange={(text) => setPasswordOne(text)}
                    value={passwordOne}
                    type="password"
                  />
                </div>

                <div className="input_section">
                  <ShortInput
                    inputHeader="Confirm Password"
                    onChange={(text) => setPasswordTwo(text)}
                    value={passwordTwo}
                    type="password"
                  />
                </div>

                <BasicButton
                  text="Submit"
                  type="primary"
                  style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
                  disabled={buttonDisabled}
                  loading={loading}
                  onClick={() => submitPasswordReset()}
                />
              </div>
            }
          </div>
        }
      </div>
    </div>
  )
};

export default ForgotPassword;
