import React from 'react';
import styles from '@components/text/TextStyle.module.css';

const MuseoText = (props) => {

  return (
    <div
      style={{
        color: props.color ? props.color : "#222",
        fontSize: props.fontSize ? props.fontSize : "32px",
        marginBottom: props.marginBottom ? props.marginBottom : "0px",
        marginLeft: props.marginLeft ? props.marginLeft: "0px",
        fontFamily: "MuseoModerno-Bold"
      }}
    >
      {props.children}
    </div>
  )
}

export default MuseoText;
