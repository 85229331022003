import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import { useHistory } from 'react-router-dom';
import { fetchTeamList } from '@actions';
import { withTheme } from 'styled-components';
import { NAV_SECTIONS } from '@global';
import styles from '@components/HeaderAndTable.module.css';
import LoadingWrapper from '@components/LoadingWrapper';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { MuseoText, BasicButton, BottomTableLoader, SearchBar, FilterTabs, BasicText } from '@components';
import { Input, Space, Table } from 'antd';
import { getCumulativePayoutsForFilter } from '@actions';
import useOnScreen from '@components/useOnScreen';
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor';

const MemberTableSection = ({
  adminUser,
  memberData,
  getCumulativePayoutsForFilter,
  currentFilter,
  theme
}) => {

  const listInnerRef = useRef();
  const [payments, setPayments] = useState([]);
  const [seenIds, setSeenIds] = useState([]);
  const [isMore, setIsMore] = useState(true);
  const [isFetchingPayments, setIsFetchingPayemnts] = useState(true);
  const history = useHistory();
  const isFetchingMoreFromBottom = useRef(false);

  useEffect(() => {
    fetchPaymentsForFilter(currentFilter, true);
  }, [currentFilter]);

  const onBottomLoadingVisible = (isVisible) => {
    if (isVisible) {
      if (!isFetchingMoreFromBottom.current && isMore) fetchPaymentsForFilter(currentFilter);
    }
  }

  const fetchPaymentsForFilter = (type , isNewTab = false) => {

    if (!isNewTab && !isMore) return;
    if (!isNewTab && isFetchingMoreFromBottom.current) return;

    if (isNewTab) setIsFetchingPayemnts(true);
    isFetchingMoreFromBottom.current = true;

    getCumulativePayoutsForFilter(
      type,
      isNewTab ? [] : seenIds,
      (newPayments) => {
        if (isNewTab) {
          setSeenIds(newPayments.map((c) => c.id));
          setPayments(newPayments);
        } else {
          setSeenIds(seenIds.concat(newPayments.map((c) => c.id)));
          setPayments(payments.concat(newPayments));
        }
        setIsMore(newPayments.length > 0);
        isFetchingMoreFromBottom.current = false;
        setIsFetchingPayemnts(false);
      },
      () => {}
    )
  }

  const getPurchaseTableData = () => {
    let dataArray = [];
    payments.forEach((purchase, i) => {
      dataArray.push({
        id: purchase.id,
        email: purchase.email,
        zip_code: purchase.zip_code,
        phone_number: purchase.phone_number,
        earnings: purchase.earnings,
        text: purchase.text
      });
    });
    return dataArray;
  };

  const getColumns = () => {
    let columns = [
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Member</div>;
        },
        dataIndex: "text",
        key: "text",
        width: 180,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <BasicText fontSize="15px" bold>
                  {text}
                </BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Email</div>;
        },
        dataIndex: "email",
        key: "email",
        width: 180,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: 180,
                  overflow: "hidden",
                }}
              >
                <div
                  style={{ color: theme.colors.blurple, textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => window.open(`mailto:${record.email}`, "_blank")}
                >
                  <BasicText fontSize="15px">{text}</BasicText>
                </div>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Zip Code</div>;
        },
        dataIndex: "zip_code",
        key: "zip_code",
        width: 80,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: 150,
                  overflow: "hidden",
                }}
              >
                <BasicText fontSize="15px">{text}</BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Phone Number</div>;
        },
        dataIndex: "phone_number",
        key: "phone_number",
        width: 80,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: 150,
                  overflow: "hidden",
                }}
              >
                <BasicText fontSize="15px">{text}</BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Earnings (USD)</div>;
        },
        dataIndex: "earnings",
        key: "earnings",
        width: 80,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: 150,
                  overflow: "hidden",
                }}
              >
                <BasicText fontSize="15px">{text}</BasicText>
              </div>
            ),
          };
        },
      }
    ];

    return columns;
  };

  if (isFetchingPayments) return <div />;


  return (
    <LoadingWrapper isLoading={memberData === null}>
      <Table
        pagination={false}
        columns={getColumns()}
        dataSource={getPurchaseTableData()}
        rowKey="id"
        rowClassName={styles.unclickable_table}
      />

      {isMore &&
        <VisibilitySensor onChange={onBottomLoadingVisible}>
          <BottomTableLoader />
        </VisibilitySensor>
      }
    </LoadingWrapper>
  )
}

const mapStatesToProps = (state) => {
  const { memberData } = state.payment;
  const { adminUser } = state.auth;
  return { adminUser, memberData };
};

export default connect(mapStatesToProps, {
  getCumulativePayoutsForFilter
})(withTheme(MemberTableSection));
