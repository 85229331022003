import React from 'react';
import { Button } from 'antd';
import { BasicText } from '@components';
import { withTheme } from 'styled-components';
import { CheckOutlined } from '@ant-design/icons';
import ClipLoader from "react-spinners/ClipLoader";

const ApproveButton = ({
  disabled,
  text,
  onClick,
  loading,
  theme
}) => {
  return (
    <div
      style={{
        cursor: disabled ? "default" : "pointer",
        width: "100%",
        height: "44px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: "16px",
        paddingRight: "16px",
        backgroundColor: disabled ? theme.colors.buttonDisabled : theme.colors.blurple,
        borderRadius: "8px",
        color: theme.colors.snow
      }}
      onClick={() => !disabled && onClick()}
    >
      {loading ?
        <ClipLoader
          size={22}
          color={"#fff"}
        />
      :
        <CheckOutlined style={{ color: theme.colors.snow, fontSize: "20px" }}/>
      }


      <div style={{ marginLeft: "12px", fontSize: "16px", fontWeight: 700 }}>
        {text}
      </div>
    </div>
  )
}

export default withTheme(ApproveButton);
