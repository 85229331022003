import React from 'react';
import { LoadingAnimation } from '@components';
import styles from './Loading.module.css';

const LoadingWrapper = ({
  isLoading,
  size,
  width,
  height,
  loadingText,
  children
}) => {
  return (
    <div>
      {isLoading ? <LoadingAnimation loadingText={loadingText} /> : children}
    </div>
  )
}

export default LoadingWrapper;
