import {
  getAPIRequest,
} from '@actions';

export const getHyypeGroups = (onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      getAPIRequest(
        '/admin/hyype_groups',
        {},
        response => {
          onSuccess(response.groups);
        },
        error => {
          onFail();
        }
      )
    );
  };
};