import React from 'react';
import styles from '@components/text/TextStyle.module.css';

const TitleText = (props) => {
  const getTextClass = () => {
    switch (props.size) {
      case 'small':
        return styles.title_small;
      case 'medium':
        return styles.title_medium;
      case 'large':
        return styles.title_large;
      default:
        return styles.title_medium;
    }
  }

  return (
    <div
      className={getTextClass()}
      style={{
        color: props.color ? props.color : "",
        fontWeight: '600',
        fontSize: props.fontSize ? props.fontSize : "",
        marginBottom: props.marginBottom ? props.marginBottom : "0px",
        fontFamily: "Roboto"
      }}
    >
      {props.children}
    </div>
  )
}

export default TitleText;
