import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Button, Modal, Form, Space } from "antd";
import PageWrapper from '@components/PageWrapper';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { withTheme } from "styled-components";
import { PlayCircleOutlined, PlayCircleTwoTone } from "@ant-design/icons";
import { useHistory, Link } from "react-router-dom";
import { showSuccessMessage, showErrorMessage, hyypeGroupsDomain } from '@global';
import ClipLoader from "react-spinners/ClipLoader";
import {
  TitleText,
  BasicText,
  BasicButton,
  ShortInput,
  LongInput,
  RejectButton,
  ApproveButton,
  ConfirmModal
} from "@components";
import styles from "./Report.module.css";
import { getReportedHyypeGroupEntries, reviewReportedHyypeGroupEntry } from '@actions';

const ReportedHyypeGroupEntries = ({
  adminUser,
  getReportedHyypeGroupEntries,
  reviewReportedHyypeGroupEntry,
  theme
}) => {

  const [loading, setLoading] = useState(false);
  const [reportedHyypeGroupEntries, setReportedHyypeGroupEntries] = useState([]);
  const [hasFetched, setHasFetched] = useState(false);
  const history = useHistory();

  useEffect(() => {
    getReportedHyypeGroupEntries(
      (groupEntries) => {
        setReportedHyypeGroupEntries(groupEntries);
        setHasFetched(true);
      },
      () => { }
    )
  }, []);


  const onReview = (reported_entry_id, remove_entry) => {
    setLoading(true);
    reviewReportedHyypeGroupEntry(
      reported_entry_id,
      remove_entry,
      (entries) => {
        setReportedHyypeGroupEntries(entries);
        setLoading(false);
        if (remove_entry) {
          showSuccessMessage("Hyype Group Entry successfully removed.");
        } else {
          showSuccessMessage("Hyype Group Entry kept live.");
        }
      },
      () => {
        setLoading(false);
        showErrorMessage("Something went wrong.")
      }
    )
  }


  const reportedHyypeGroupEntry = (rhge) => {
    const domain = hyypeGroupsDomain(adminUser)
    const groupUrl = rhge.group ? `${domain}/groups/${rhge.group.external_id}` : ''
    return (
      <div key={rhge.id} style={{ maxWidth: "400px", marginBottom: "32px" }}>
        <BasicText bold>
          {rhge.admin_text} on {rhge.time_text}
        </BasicText>
        <div style={{ marginTop: "6px" }}>
          Group Url: <a href={groupUrl} target="_blank" rel="noreferrer">{groupUrl}</a>
        </div>
        <div style={{ marginTop: "6px" }}>
          Group Password: {rhge.group.share_password ? rhge.group.share_password : 'Public Group'}
        </div>

        <div style={{ marginTop: "6px" }}>
          Reason: {rhge.reason}
        </div>
        <video src={rhge.entry.upload.url.content} width="150px" controls />

        <div style={{ marginTop: "16px" }}>
          <Space>
            <RejectButton
              onClick={() => {
                onReview(rhge.id, true);
              }}
              text="Remove"
            />
            <ApproveButton
              onClick={() => {
                onReview(rhge.id, false);
              }}
              text="Keep Live"
            />
          </Space>
        </div>

        <div style={{ width: "100%", height: "1px", backgroundColor: "#999", marginTop: "12px" }} />
      </div>
    );
  }



  const content = () => {
    if (hasFetched && reportedHyypeGroupEntries.length === 0) {
      return (
        <div style={{ marginTop: "120px" }}>
          All reported Hyype Groups Entries have been reviewed.
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: "120px" }}>
          {reportedHyypeGroupEntries.map((rhge) =>
            reportedHyypeGroupEntry(rhge)
          )}
        </div>
      );
    }
  }


  return (
    <PageWrapper>
      <FixedHeaderContainer
        text={'Reported Hyype Group Entries'}
        paddingBuffer="80px"
        paddingTop="56px"
      >
        {content()}
      </FixedHeaderContainer>

      <Modal
        title={"Submitting..."}
        visible={loading}
        footer={null}
        closable={false}

      >
        <div style={{ width: "100%", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <ClipLoader />
        </div>
      </Modal>
    </PageWrapper>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
}

export default connect(mapStatesToProps, {
  getReportedHyypeGroupEntries,
  reviewReportedHyypeGroupEntry,
})(withTheme(ReportedHyypeGroupEntries));
