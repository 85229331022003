import {
  SET_RECENT_ENTRIES,
  SET_POPULAR_ENTRIES,
  CLEAR_ENTRIES,
  SET_REJECTED_ENTRIES,
} from '@actions/types';

const INITIAL_STATE = {
  recentEntries: {
    entries: [],
    isMore: true,
    isFetching: false,
    lastTimestamp: null,
  },
  popularEntries: {
    entries: [],
    isMore: true,
    isFetching: false,
    lastTimestamp: null,
  },
  rejectedEntries: {
    entries: [],
    isMore: true,
    isFetching: false,
    lastTimestamp: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_RECENT_ENTRIES:
      return { ...state, recentEntries: action.payload };
    case SET_POPULAR_ENTRIES:
      return { ...state, popularEntries: action.payload };
    case SET_REJECTED_ENTRIES:
      return { ...state, rejectedEntries: action.payload };
    case CLEAR_ENTRIES:
      return {
        ...state,
        popularEntries: INITIAL_STATE.popularEntries,
        recentEntries: INITIAL_STATE.recentEntries,
        rejectedEntries: INITIAL_STATE.rejectedEntries,
      };
    default:
      return state;
  }
};
