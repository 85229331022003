import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import { useHistory } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { NAV_SECTIONS, CHALLENGE_TABLE_FILTERS } from '@global';
import styles from '@entries/ExploreEntries.module.css';
import LoadingWrapper from '@components/LoadingWrapper';
import {
  MuseoText,
  BasicButton,
  BottomTableLoader,
  SearchBar,
  FilterTabs,
} from '@components';
import { Input, Space } from 'antd';
import PlayEntryModal from '@entries/PlayEntryModal';
import {
  getRecentEntries,
  getPopularEntries,
  clearEntries,
  getRejectedEntries,
} from '@actions';
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor';

const EntryGrid = ({
  type,
  recentEntries,
  popularEntries,
  getRecentEntries,
  getPopularEntries,
  getRejectedEntries,
  clearEntries,
  rejectedEntries,
  theme,
}) => {
  const isRecent = type === 'RECENT';
  const isPopular = type === 'POPULAR';
  const isRejected = type === 'REJECTED';

  const [entryData, setEntryData] = useState(
    isRecent ? recentEntries : isPopular ? popularEntries : rejectedEntries
  );
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const history = useHistory();
  const isFetchingMoreFromBottom = useRef(false);

  useEffect(() => {
    clearEntries();
    fetchEntries(true);
  }, [type]);

  useEffect(() => {
    if (isRecent) {
      setEntryData(recentEntries);
    }
  }, [recentEntries]);

  useEffect(() => {
    if (isPopular) {
      setEntryData(popularEntries);
    }
  }, [popularEntries]);

  useEffect(() => {
    if (isRejected) {
      setEntryData(rejectedEntries);
    }
  }, [rejectedEntries]);

  const fetchEntries = (isRefresh = false) => {
    if (isRecent) {
      if ((isRefresh || recentEntries.isMore) && !recentEntries.isFetching) {
        //console.log("FETCHING MORE");
        getRecentEntries();
      }
    } else if (isPopular) {
      if ((isRefresh || popularEntries.isMore) && !popularEntries.isFetching) {
        //console.log("FETCHING MORE");
        getPopularEntries();
      }
    } else {
      if (
        (isRefresh || rejectedEntries.isMore) &&
        !rejectedEntries.isFetching
      ) {
        //console.log("FETCHING MORE");
        getRejectedEntries();
      }
    }
  };

  //console.log(popularEntries.entries.length);

  const onBottomLoadingVisible = (isVisible) => {
    //console.log("HELLOW");
    if (isVisible) {
      fetchEntries();
    }
  };

  const entry = (submission, index) => {
    return (
      <div
        className={styles.grid_container}
        onClick={() => setSelectedIndex(index)}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            background:
              'linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.2), rgba(0,0,0,0.4),  rgba(0,0,0,0.6))',
            borderRadius: '12px',
          }}
        />
        <img className={styles.thumb_container} src={submission.url.thumb} />
        <div className={styles.bottom_grid_info}>
          <div
            className={styles.grid_rank}
            style={{
              color: isRejected ? theme.colors.danger : theme.colors.blurple,
            }}
          >
            {isRejected ? 'REJECTED' : `${submission.vote_count} Claps`}
          </div>
          <div className={styles.grid_username}>
            @{submission.user.username}
          </div>
          <div className={styles.grid_date}>{submission.created_at}</div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.entries_container}>
      {entryData.entries.map((submission, index) => (
        <div key={submission.id}>{entry(submission, index)}</div>
      ))}

      {entryData.entries.length > 25 && (
        <VisibilitySensor onChange={onBottomLoadingVisible}>
          <div style={{ color: '#fff' }}>hello</div>
        </VisibilitySensor>
      )}

      <PlayEntryModal
        submission={
          selectedIndex != null ? entryData.entries[selectedIndex] : null
        }
        closeModal={() => setSelectedIndex(null)}
        showForward={
          selectedIndex != null && selectedIndex < entryData.entries.length - 1
        }
        showBack={selectedIndex != null && selectedIndex > 0}
        onForward={() => {
          if (selectedIndex < entryData.entries.length - 1) {
            setSelectedIndex(selectedIndex + 1);
          }
        }}
        onBack={() => {
          if (selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1);
          }
        }}
        index={selectedIndex}
      />
    </div>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  const { recentEntries, popularEntries, rejectedEntries } = state.entry;
  return { adminUser, recentEntries, popularEntries, rejectedEntries };
};

export default connect(mapStatesToProps, {
  getRecentEntries,
  getPopularEntries,
  getRejectedEntries,
  clearEntries,
})(withTheme(EntryGrid));
