import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import {
  TitleText,
  BasicText,
  BasicButton,
  ShortInput,
  LongInput,
} from '@components';
import IndividualPrize from '@challenges/create/IndividualPrize';
import PrizeDisplay from '@challenges/create/PrizeDisplay';
import { ordinalSuffixOf } from '@global';
import {
  NEW_CHALLENGE_STATES,
  NEW_CHALLENGE_STEPS,
  CHALLENGE_ASSET_TYPES,
} from '@global';
import styles from './Create.module.css';

const PrizeInfo = ({
  newChallengeInfo,
  updateChallenge,
  prize_info,
  isEdit,
}) => {
  const [selectedPrize, setSelectedPrize] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  let prizeRef = useRef();

  const prizeModal = () => {
    return (
      <Modal
        title="Prize Info"
        visible={selectedPrize != null}
        footer={null}
        onCancel={() => setSelectedPrize(null)}
      >
        <IndividualPrize
          prize_info={prize_info}
          existingPrize={selectedPrize.prize}
          index={selectedPrize.index}
          onSave={(prize) => {
            const newPrizeArray = prize_info;
            newPrizeArray[prize.final_rank - 1] = prize;
            console.log('new arr', newPrizeArray);
            updateChallenge({
              prize_info: newPrizeArray.sort((a, b) =>
                a.final_rank > b.final_rank ? 1 : -1
              ),
            });
            setSelectedPrize(null);
            if (prize.index > 1) {
              setTimeout(
                () => prizeRef.current.scrollIntoView({ behavior: 'smooth' }),
                200
              );
            }
          }}
        />
      </Modal>
    );
  };

  const handleDeletePrize = (prize, index) => {
    if (prize.final_rank !== prize_info.length) {
      setShowDeleteModal(true);
    } else {
      const deletedPrize = prize_info.splice(index, 1)[0];
      prize_info.forEach((prize, i) => {
        if (prize.index > index) {
          prize.index = prize.index - 1;
        }
        if (prize.final_rank > deletedPrize.final_rank) {
          prize.final_rank = prize.final_rank - 1;
        }
      });
      updateChallenge({ prize_info: prize_info });
    }
  };

  const prizeList = () => {
    return prize_info
      .sort((a, b) => (a.final_rank > b.final_rank ? 1 : -1))
      .map((prize, index) => (
        <PrizeDisplay
          key={index}
          prize={prize}
          index={index}
          onDelete={() => handleDeletePrize(prize, index)}
          onEdit={() => setSelectedPrize({ prize: prize, index: index })}
        />
      ));
  };

  return (
    <div style={isEdit ? {} : { height: '420px', overflowY: 'scroll' }}>
      {prizeList()}
      {prize_info.length < 6 && (
        <BasicButton
          text="Add Prize"
          type="primary"
          onClick={() =>
            setSelectedPrize({
              index: prize_info.length,
              final_rank: prize_info.length + 1,
            })
          }
          style={{ marginTop: '20px' }}
        />
      )}
      {selectedPrize != null && prizeModal()}
      <div ref={prizeRef} />

      {showDeleteModal && (
        <Modal
          title={'Delete Prize'}
          visible={showDeleteModal}
          onCancel={() => setShowDeleteModal(false)}
          footer={[
            <BasicButton
              key="submit"
              type="primary"
              text="OK"
              onClick={() => setShowDeleteModal(false)}
              style={{ fontSize: '14px' }}
            />,
          ]}
        >
          <BasicText>
            You cannot delete this prize until you delete the prize for{' '}
            {ordinalSuffixOf(prize_info.length)} Place.
          </BasicText>
        </Modal>
      )}
    </div>
  );
};

const mapStatesToProps = (state) => {
  const { newChallengeInfo } = state.challenge;
  return { newChallengeInfo };
};

export default connect(mapStatesToProps, {})(PrizeInfo);
