import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Button, Switch } from 'antd';
import PageWrapper from '@components/PageWrapper';
import LoadingWrapper from '@components/LoadingWrapper';
import {
  BasicButton,
  BasicText,
  DeleteButton,
  ConfirmModal,
} from '@components';
import MemberStatsInfo from '@members/MemberStatsInfo';
import { Tabs } from 'antd';
const { TabPane } = Tabs;
import BasicInfo from '@members/BasicInfo';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import ReviewReportHistory from '@review/ReviewReportHistory';
import MemberChallenges from '@members/MemberChallenges';
import MemberPurchases from '@members/MemberPurchases';
import MemberPrizes from '@members/MemberPrizes';
import {
  getUserInfo,
  suspendUser,
  unSuspendUser,
  toggleMemberVerified,
  toggleVotingSuspended,
  toggleFlagEntries,
} from '@actions';
import styles from './Members.module.css';
import { validateEmail, showSuccessMessage, showErrorMessage } from '@global';
import MemberForm from '@team/MemberForm';
import MemberReferrals from '@members/MemberReferrals';

const ACCOUNT = 'Account';
const CHALLENGES = 'Challenges';
const PURCHASES = 'Purchases';
const PAYMENTS = 'Prizes';
const REFERRALS = 'Referrals';

const ViewMember = ({
  match,
  getUserInfo,
  suspendUser,
  unSuspendUser,
  toggleMemberVerified,
  toggleVotingSuspended,
  toggleFlagEntries,
  theme,
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [blockLoading, setBlockLoading] = useState(false);
  const [member, setMember] = useState(null);
  const [showBlockConfirm, setShowBlockConfirm] = useState(false);
  const isReporting = history.location.pathname.includes('/reported_user');
  const [selectedTab, setSelectedTab] = useState('0');

  useEffect(() => {
    getUserInfo(
      match?.params?.user_id,
      (mem) => {
        setMember(mem);
      },
      () => {}
    );
  }, []);

  const onSuspendConfirmed = () => {
    setBlockLoading(true);
    suspendUser(
      member.id,
      (response) => {
        setBlockLoading(false);
        setMember(response);
        showSuccessMessage('Member successfully suspended.', 5);
        setShowBlockConfirm(false);
      },
      (errorMessage) => {
        showErrorMessage(errorMessage, 6);
        setBlockLoading(false);
      }
    );
  };

  const onUnsuspendConfirmed = () => {
    setBlockLoading(true);
    unSuspendUser(
      member.id,
      (response) => {
        setBlockLoading(false);
        setMember(response);
        showSuccessMessage('Member successfully reinstated.', 5);
        setShowBlockConfirm(false);
      },
      (errorMessage) => {
        showErrorMessage(errorMessage, 6);
        setBlockLoading(false);
      }
    );
  };

  const reportsButton = () => {
    return (
      <BasicButton
        text={`${member.report_count} Reports`}
        loading={loading}
        type="default"
        danger
        onClick={() => {}}
      />
    );
  };

  const onToggleVerified = (isVerified) => {
    toggleMemberVerified(
      member.id,
      isVerified,
      (response) => {
        setBlockLoading(false);
        setMember(response);
        showSuccessMessage(
          `Member successfully updated to ${
            isVerified ? 'verified.' : 'not verified.'
          }.`,
          5
        );
      },
      (errorMessage) => {
        showErrorMessage(errorMessage, 6);
      }
    );
  };

  const onToggleVotingSuspended = (isSuspendedVoting) => {
    toggleVotingSuspended(
      member.id,
      isSuspendedVoting,
      (response) => {
        setBlockLoading(false);
        setMember(response);
        showSuccessMessage(
          `Member successfully updated to ${
            isSuspendedVoting ? 'block voting.' : 'allow voting.'
          }`,
          5
        );
      },
      (errorMessage) => {
        showErrorMessage(errorMessage, 6);
      }
    );
  };

  const onToggleFlagEntries = (isFlagging) => {
    toggleFlagEntries(
      member.id,
      isFlagging,
      (response) => {
        setBlockLoading(false);
        setMember(response);
        showSuccessMessage(
          `Member successfully updated to ${
            isFlagging
              ? 'have their entries flagged.'
              : 'no longer have their entries flagged.'
          }`,
          5
        );
      },
      (errorMessage) => {
        showErrorMessage(errorMessage, 6);
      }
    );
  };

  const blockButton = () => {
    return (
      <div style={{ marginLeft: '30px' }}>
        <BasicButton
          text={member.is_blocked ? 'Reinstate' : 'Suspend'}
          loading={loading}
          type="primary"
          danger
          onClick={() => {
            setShowBlockConfirm(true);
          }}
        />
      </div>
    );
  };

  const additionalContent = () => {
    if (isReporting) {
    } else {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {blockButton()}

          <div className={styles.toggle_verified_container}>
            <div style={{ marginTop: '4px', marginLeft: '12px' }}>
              <BasicText bold>Is Verified</BasicText>
            </div>
            <div style={{ marginLeft: '8px' }}>
              <Switch
                defaultChecked={member.is_verified}
                onChange={(isToggled) => onToggleVerified(isToggled)}
                style={{
                  backgroundColor: member.is_verified
                    ? theme.colors.blurple
                    : theme.colors.lightShade,
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  const tabSelectorOptions = () => {
    return [
      ACCOUNT,
      CHALLENGES,
      PURCHASES,
      PAYMENTS,
      REFERRALS,
    ].map((stat, index) => <TabPane key={index} value={stat} tab={stat} />);
  };

  const tabContent = () => {
    switch (selectedTab) {
      case '0':
        return (
          <div className={styles.member_content}>
            {member.is_blocked && (
              <div
                style={{
                  color: theme.colors.danger,
                  fontWeight: 700,
                  marginBottom: '20px',
                  fontSize: '18px',
                }}
              >
                CURRENTLY SUSPENDED
              </div>
            )}

            <div className={styles.member_since}>{member.member_since}</div>

            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                marginBottom: '32px',
              }}
            >
              <div style={{ marginRight: '24px' }}>
                <div className={styles.toggle_verified_container}>
                  <div style={{ marginTop: '4px' }}>
                    <BasicText bold>Suspend Voting</BasicText>
                  </div>
                  <div style={{ marginLeft: '8px' }}>
                    <Switch
                      defaultChecked={member.voting_suspended}
                      onChange={(isToggled) =>
                        onToggleVotingSuspended(isToggled)
                      }
                      style={{
                        backgroundColor: member.voting_suspended
                          ? theme.colors.blurple
                          : theme.colors.lightShade,
                      }}
                    />
                  </div>
                </div>
              </div>

              <div>
                <div className={styles.toggle_verified_container}>
                  <div style={{ marginTop: '4px' }}>
                    <BasicText bold>Flag Entries</BasicText>
                  </div>
                  <div style={{ marginLeft: '8px' }}>
                    <Switch
                      defaultChecked={member.flag_entries}
                      onChange={(isToggled) => onToggleFlagEntries(isToggled)}
                      style={{
                        backgroundColor: member.flag_entries
                          ? theme.colors.blurple
                          : theme.colors.lightShade,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.username_and_view_entries}>
              <div className={styles.username}>@{member.username}</div>

              <BasicButton
                type="secondary"
                text="View Entries"
                onClick={() => {
                  history.push({
                    pathname: `/member_entries/${member.id}`,
                  });
                }}
              />
            </div>

            <MemberStatsInfo member={member} />

            <div className={styles.basic_and_reports}>
              <BasicInfo member={member} />
              <ReviewReportHistory entry={member} />
            </div>
          </div>
        );
      case '1':
        return <MemberChallenges member={member} />;
      case '2': {
        return (
          <MemberPurchases
            memberObject={member}
            updateMember={(m) => setMember(m)}
          />
        );
      }
      case '3': {
        return <MemberPrizes member={member} />;
      }
      case '4': {
        return <MemberReferrals member={member} />;
      }
      default:
        return null;
    }
  };

  console.log(selectedTab);

  return (
    <PageWrapper>
      <FixedHeaderContainer
        text={
          member == null
            ? 'Loading member...'
            : `${member.first_name} ${member.last_name}`
        }
        paddingBuffer="80px"
        paddingTop="56px"
        additionalContent={member == null ? null : additionalContent()}
        fixedContent={
          member ? (
            <div style={{ marginTop: '20px' }}>
              <Tabs
                defaultActiveKey={ACCOUNT}
                onChange={(e) => setSelectedTab(e)}
              >
                {tabSelectorOptions()}
              </Tabs>
            </div>
          ) : null
        }
      >
        <LoadingWrapper isLoading={member == null} loadingText="" size="large">
          {member && <div style={{ marginTop: '180px' }}>{tabContent()}</div>}
        </LoadingWrapper>
      </FixedHeaderContainer>

      {showBlockConfirm && (
        <ConfirmModal
          title={member.is_blocked ? 'Unsuspend User' : 'Suspend User'}
          visible={showBlockConfirm}
          loading={blockLoading}
          onCancel={() => setShowBlockConfirm(false)}
          onSubmit={() => {
            if (member.is_blocked) {
              onUnsuspendConfirmed();
            } else {
              onSuspendConfirmed();
            }
          }}
        >
          <BasicText marginBottom="20px">
            Are you sure you want to{' '}
            {member.is_blocked ? 'reinstate' : 'suspend'} this member?
          </BasicText>
        </ConfirmModal>
      )}
    </PageWrapper>
  );
};

export default connect(null, {
  getUserInfo,
  suspendUser,
  unSuspendUser,
  toggleMemberVerified,
  toggleVotingSuspended,
  toggleFlagEntries,
})(withTheme(ViewMember));
