import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Space, TimePicker, Button, Modal, Radio } from 'antd';
import moment from 'moment-timezone';
import { useHistory, Link } from 'react-router-dom';
import AssetUploader from '@challenges/create/AssetUploader';
import AssetPreview from '@challenges/create/AssetPreview';
import { TitleText, BasicText, BasicButton, ShortInput, LongInput } from '@components';
import { getUrlFromUploadId } from '@actions';
import {
  NEW_CHALLENGE_STATES,
  NEW_CHALLENGE_STEPS,
  CHALLENGE_ASSET_TYPES
} from '@global';
import styles from './Create.module.css';

const PromoInfo = ({
  promo_info,
  updateChallenge,
  getUrlFromUploadId
}) => {

  const [uploadAsset, setUploadAsset] = useState(null);

  const onSuccessUpload = (fileInfo, url) => {
    updateChallenge({
      promo_info: {
        ...promo_info,
        upload_id: fileInfo.presignInfo.upload_id,
        is_video: promo_info.isUploadingVideo,
        is_image: !promo_info.isUploadingVideo,
        file_name: fileInfo.fileName,
        promo_url: url
      }
    });
    setUploadAsset(null);
  }

  const uploadAssetModal = () => {
    return (
      <Modal
        title={`Upload ${uploadAsset}`}
        visible={uploadAsset != null}
        footer={null}
        onCancel={() => setUploadAsset(null)}
      >
        <AssetUploader
          asset_type={uploadAsset}
          onSuccess={(fileInfo) => {
            getUrlFromUploadId(fileInfo.presignInfo.upload_id, (url) => {
              onSuccessUpload(fileInfo, url);
            });
          }}
        />
      </Modal>
    )
  };

  const uploadAssetView = () => {
    const uploadOrEdit = promo_info.file_name ? "Edit" : "Upload";
    return (
      <Form.Item>
        <BasicText fontSize="12px" marginBottom="8px" bold>
          {"Promotion Asset (Video or Image)"}
        </BasicText>

        <div className={styles.radio_container}>
          <Radio.Group
            onChange={(e) => updateChallenge({ promo_info: {
              ...promo_info, isUploadingVideo: e.target.value === "video"
            }})}
            value={promo_info.isUploadingVideo ? "video" : "image"}
            style={{ marginBottom: 8 }}
            optionType="button"
            buttonStyle="solid"
          >
            <Radio.Button value="video">Video</Radio.Button>
            <Radio.Button value="image">Image</Radio.Button>
          </Radio.Group>
        </div>

        <div style={{ marginTop: "8px" }}>
          <AssetPreview
            isVideo={promo_info.isUploadingVideo}
            url={promo_info.isUploadingVideo === promo_info.is_video ? promo_info.promo_url : null}
            assetName={promo_info.isUploadingVideo ? "Video" : "Image"}
            fileName={promo_info.file_name}
            onClickEdit={() => setUploadAsset(promo_info.isUploadingVideo ? CHALLENGE_ASSET_TYPES.PROMO_VIDEO : CHALLENGE_ASSET_TYPES.PROMO_IMAGE)}
          />
        </div>
      </Form.Item>
    )
  }

  return (
    <div>
      <Form.Item>
        <ShortInput
          inputHeader="Enter Promotion Link"
          placeholder="https://xyzawesome.co/new_product"
          onChange={(val) => updateChallenge({ promo_info: {
            ...promo_info, promotion_link: val
          }})}
          value={promo_info.promotion_link}
        />
      </Form.Item>

      <Form.Item>
        <ShortInput
          inputHeader="Enter Coupon Code"
          placeholder="XYZ123"
          onChange={(val) => updateChallenge({ promo_info: {
            ...promo_info, coupon_code: val
          }})}
          value={promo_info.coupon_code}
        />
      </Form.Item>

      {uploadAssetView()}

      {uploadAsset != null && uploadAssetModal()}
    </div>
  );
};

const mapStatesToProps = (state) => {
  const { newChallengeInfo } = state.challenge;
  return { newChallengeInfo };
};

export default connect(mapStatesToProps, {
  getUrlFromUploadId
})(PromoInfo);
