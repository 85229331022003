import {
  SET_RECENT_ENTRIES,
  SET_POPULAR_ENTRIES,
  CLEAR_ENTRIES,
  SET_REJECTED_ENTRIES,
} from '@actions/types';
import { getAPIRequest, postAPIRequest } from '@actions';

export const clearEntries = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_ENTRIES });
  };
};

export const getRecentEntries = () => {
  return (dispatch, getState) => {
    const { recentEntries } = getState().entry;
    const { entries, isMore, isFetching } = recentEntries;
    if (!isMore || isFetching) return;

    const entriesLength = entries.length;

    dispatch({
      type: SET_RECENT_ENTRIES,
      payload: { ...recentEntries, isFetching: true },
    });

    dispatch(
      postAPIRequest(
        '/admin/get_most_recent_entries',
        { seen_ids: entries.map((s) => s.id) },
        (response) => {
          const newEntries = response.submissions;
          const newEntryData = {
            entries: entries.concat(newEntries),
            isMore: newEntries.length > 0,
            isFetching: false,
            lastTimestamp: response.last_timestamp,
          };
          dispatch({ type: SET_RECENT_ENTRIES, payload: newEntryData });
        },
        (error) => {
          onFail && onFail('Someting went wrong.');
        }
      )
    );
  };
};

export const getPopularEntries = () => {
  return (dispatch, getState) => {
    const { popularEntries } = getState().entry;
    const { entries, isMore, isFetching } = popularEntries;
    if (!isMore || isFetching) return;

    const entriesLength = entries.length;

    dispatch({
      type: SET_POPULAR_ENTRIES,
      payload: { ...popularEntries, isFetching: true },
    });

    dispatch(
      postAPIRequest(
        '/admin/get_most_popular_entries',
        { seen_ids: entries.map((s) => s.id) },
        (response) => {
          const newEntries = response.submissions;
          const newEntryData = {
            entries: entries.concat(newEntries),
            isMore: newEntries.length > 0,
            isFetching: false,
            lastTimestamp: response.last_timestamp,
          };
          dispatch({ type: SET_POPULAR_ENTRIES, payload: newEntryData });
        },
        (error) => {
          onFail && onFail('Someting went wrong.');
        }
      )
    );
  };
};

export const getRejectedEntries = () => {
  return (dispatch, getState) => {
    const { rejectedEntries } = getState().entry;
    const { entries, isMore, isFetching } = rejectedEntries;
    if (!isMore || isFetching) return;

    const entriesLength = entries.length;

    dispatch({
      type: SET_REJECTED_ENTRIES,
      payload: { ...rejectedEntries, isFetching: true },
    });

    dispatch(
      postAPIRequest(
        '/admin/get_rejected_entries',
        { seen_ids: entries.map((s) => s.id) },
        (response) => {
          const newEntries = response.submissions;
          const newEntryData = {
            entries: entries.concat(newEntries),
            isMore: newEntries.length > 0,
            isFetching: false,
            lastTimestamp: response.last_timestamp,
          };
          dispatch({ type: SET_REJECTED_ENTRIES, payload: newEntryData });
        },
        (error) => {
          onFail && onFail('Someting went wrong.');
        }
      )
    );
  };
};

export const getVideoReactions = (seenIds, onSucces, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/get_video_reactions',
        { seen_ids: seenIds },
        (response) => {
          onSucces(response.reactions);
        },
        (error) => {
          onFail && onFail('Someting went wrong.');
        }
      )
    );
  };
};
