import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Form, Input } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, CustomButton, BasicButton, ShortInput, MuseoText } from '@components';
import '../App.css';
import './login.css'
import { validateEmail } from '../global_vars';
import logo from '../images/leftAlignSingleWordmark_light.png';
import { submitLogin } from '@actions';
import ForgotPasswordModal from '@admin/ForgotPasswordModal';

const Login = ({ submitLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [error, setError] = useState(false);
  const history = useHistory();
  const emailField = useRef(null);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

  const validateForm = () => {
    return validateEmail(email) && password.length > 5;
  }

  useEffect(() => {
    setButtonDisabled(!validateForm());
  }, [email, password]);

  const handleSubmit = () => {
    console.log("HEELLO");
    //if (buttonDisabled) return;

    setError(false);
    setLoading(true);
    submitLogin(
      {
        email,
        password
      },
      () => {
        history.push({
          pathname: '/',
        });
      },
      (error) => {
        setLoading(false);
        setError(error);
      }
    );
  }

  const onFormSubmit = () => {
    console.log("pressed enter");
    handleSubmit();
  }

  const loginForm = () => {
    return (
      <div className="login-container">
        <Form onFinish={onFormSubmit} initialValues={{ remember: true }}>
          <Form.Item>
            <div className="input_section">
              <ShortInput
                placeholder="email"
                onChange={(val) => setEmail(val.toLowerCase().trim())}
                value={email}
                type="email"
              />
            </div>
          </Form.Item>

          <Form.Item>
            <div className="input_section">
              <ShortInput
                isPassword
                placeholder="password"
                onChange={(val) => setPassword(val)}
                value={password}
              />
            </div>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%", marginTop: "20px", height: "50px" }}
              disabled={buttonDisabled}
              loading={loading}
            >
                Submit
            </Button>
          </Form.Item>

          {error && <BasicText color="red">{error}</BasicText>}

          <div className="forgot_password_link" onClick={() => setShowResetPasswordModal(true)}>
            <BasicText fontSize="14px">
              Forgot Password?
            </BasicText>
          </div>
        </Form>
      </div>
    );
  };

  return (
    <div style={{ justifyContent: "center", alignItems: "center", display: "flex", flexDirection: 'column', padding: 20}}>
      <img src={logo} alt="logo" style={{ width: "240px", marginTop: "50px", marginBottom: "36px" }} />
      <Card className="Login-card">
        <div style={{ flexDirection: "column", alignItems: "center", display: "flex", width: "100%" }}>
          <MuseoText>
            Login
          </MuseoText>

          {loginForm()}
        </div>
      </Card>

      <ForgotPasswordModal
        visible={showResetPasswordModal}
        onCancel={() => setShowResetPasswordModal(false)}
      />
    </div>
  );

};

// const mapStatesToProps = (state) => {
//   const { name, logged_in, login_error, force_email } = state.account;
//   const { invite_company_name } = state.company;
//   return { name, logged_in, login_error, force_email, invite_company_name };
// };

export default connect(null, {
  submitLogin
})(Login);
