import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import { EditFilled } from '@ant-design/icons';
import { withTheme } from 'styled-components';
import { Table, Icon, Spin, Switch, Modal, Form } from 'antd';
import AssetUploader from '@challenges/create/AssetUploader';
import { NAV_SECTIONS } from '@global';
import styles from '@components/HeaderAndTable.module.css';
import LoadingWrapper from '@components/LoadingWrapper';
import { EditOutlined, DeleteFilled } from '@ant-design/icons';
import {
  MuseoText,
  BasicButton,
  ShortInput,
  SearchBar,
  BasicText,
  ConfirmModal,
} from '@components';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import {
  getPromos,
  getUrlFromUploadId,
  createPromoRule,
  editPromoRule,
  deletePromoRule,
} from '@actions';

const PromoSettings = ({
  adminUser,
  getPromos,
  getUrlFromUploadId,
  createPromoRule,
  editPromoRule,
  deletePromoRule,
  theme,
}) => {
  const [promos, setPromos] = useState(null);
  const [editPromo, setEditPromo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newPromo, setNewPromo] = useState(null);
  const [uploadAsset, setUploadAsset] = useState(null);
  const [showUpload, setShowUpload] = useState(false);
  const [showDeleteConfrim, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    fetchPromos();
  }, []);

  const fetchPromos = () => {
    getPromos(
      (b) => setPromos(b),
      () => {}
    );
  };

  const getPromoTableData = () => {
    if (promos == null) return [];
    let dataArray = [];
    promos.forEach((promo, i) => {
      dataArray.push({
        id: promo.id,
        title: promo.title,
        link: promo.link,
        is_live: promo.is_live,
        filename: promo.filename,
        pdf_url: promo.pdf_url,
      });
    });
    return dataArray;
  };

  const getColumns = () => {
    let columns = [
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Title</div>;
        },
        dataIndex: 'title',
        key: 'title',
        width: 100,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  minWidth: '180px',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <BasicText fontSize="15px">{text}</BasicText>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    setNewPromo({
                      id: record.id,
                      title: record.title,
                      fileName: record.filename,
                      pdf_url: record.pdf_url,
                    })
                  }
                >
                  <EditFilled
                    style={{
                      color: theme.colors.blurple,
                      fontSize: '20px',
                      marginTop: '4px',
                    }}
                  />
                </div>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Website URL</div>;
        },
        dataIndex: 'link',
        key: 'link',
        width: 80,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: 150,
                  overflow: 'hidden',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                onClick={() => window.open(record.link, '_blank')}
              >
                <BasicText fontSize="15px" bold color={theme.colors.blurple}>
                  Link
                </BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}> Promo Status</div>;
        },
        dataIndex: 'is_live',
        key: 'is_live',
        width: 100,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: 190,
                  overflow: 'hidden',
                  justifyContent: 'space-between',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}
                >
                  <BasicText fontSize="15px">
                    {record.is_live ? 'Running' : 'Finished'}
                  </BasicText>

                  <div style={{ marginLeft: '20px' }}>
                    <Switch
                      defaultChecked={record.is_live}
                      onChange={(checked) => {
                        checked ? onSetLive(record) : onSetFinished(record);
                      }}
                    />
                  </div>
                </div>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}></div>;
        },
        dataIndex: 'link',
        key: 'link',
        width: 80,
        render(text, record) {
          return {
            children: (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => setShowDeleteConfirm(record.id)}
              >
                <DeleteFilled
                  style={{ fontSize: '20px', color: theme.colors.danger }}
                />
              </div>
            ),
          };
        },
      },
    ];

    return columns;
  };

  const onSetLive = (promo) => {
    editPromoRule(
      {
        promotion_rule_id: promo.id,
        is_live: true,
      },
      (b) => {
        setLoading(false);
        setPromos(b);
      },
      (b) => {}
    );
  };

  const onSetFinished = (promo) => {
    editPromoRule(
      {
        promotion_rule_id: promo.id,
        is_live: false,
      },
      (b) => {
        setLoading(false);
        setPromos(b);
      },
      (b) => {}
    );
  };

  const onCreateConfirm = () => {
    console.log(newPromo);
    if (newPromo.id) {
      setLoading(true);
      editPromoRule(
        {
          promotion_rule_id: newPromo.id,
          title: newPromo.title,
          media_upload_id: newPromo.presignInfo
            ? newPromo.presignInfo.upload_id
            : null,
          filename: newPromo.fileName,
        },
        (b) => {
          setLoading(false);
          setNewPromo(null);
          setPromos(b);
        },
        () => {
          setLoading(false);
        }
      );
    } else {
      setLoading(true);
      createPromoRule(
        {
          title: newPromo.title,
          upload_id: newPromo.presignInfo.upload_id,
          filename: newPromo.fileName,
        },
        (b) => {
          setLoading(false);
          setNewPromo(null);
          setPromos(b);
        },
        () => {
          setLoading(false);
        }
      );
    }
  };

  const onDeleteConfirm = () => {
    setLoading(true);
    deletePromoRule(
      {
        promotion_rule_id: showDeleteConfrim,
      },
      (b) => {
        setLoading(false);
        setShowDeleteConfirm(false);
        setPromos(b);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const shouldEnableCreatePromo = () => {
    return newPromo && newPromo.title?.length > 0 && newPromo.fileName != null;
  };

  const uploadAssetModal = () => {
    return (
      <Modal
        title={`Upload Promotion Rule PDF`}
        visible={showUpload}
        footer={null}
        onCancel={() => setShowUpload(false)}
      >
        <AssetUploader
          isPromo
          asset_type={'PROMO_RULES'}
          onSuccess={(fileInfo) => {
            getUrlFromUploadId(fileInfo.presignInfo.upload_id, (url) => {
              setShowUpload(false);
              setNewPromo({
                ...newPromo,
                fileName: fileInfo.fileName,
                presignInfo: fileInfo.presignInfo,
                pdf_url: url,
              });
            });
          }}
        />
      </Modal>
    );
  };

  return (
    <LoadingWrapper
      isLoading={promos == null}
      loadingText="Fetching Bundle Data"
    >
      <div style={{ marginTop: '220px' }}>
        <div style={{ marginBottom: '24px' }}>
          <BasicButton
            type="secondary"
            text="+ NEW PROMO"
            onClick={() =>
              setNewPromo({ title: '', fileName: null, presignInfo: null })
            }
          />
        </div>
        <Table
          pagination={false}
          columns={getColumns()}
          dataSource={getPromoTableData()}
          rowKey="id"
          rowClassName={styles.unclickable_table}
        />
      </div>

      {newPromo != null && (
        <Modal
          title={newPromo.id ? 'Edit Promo' : 'Create Promo'}
          visible={newPromo != null}
          onCancel={() => setNewPromo(null)}
          footer={null}
        >
          <Form.Item>
            <ShortInput
              inputHeader="Title"
              onChange={(val) => setNewPromo({ ...newPromo, title: val })}
              value={newPromo.title}
            />
          </Form.Item>

          <Form.Item>
            {newPromo.fileName != null ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    backgroundColor: '#f4f4f4',
                    textDecoration: 'underline',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: '4px',
                    width: '100%',
                    paddingLeft: '16px',
                  }}
                >
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => window.open(newPromo.pdf_url, '_blank')}
                  >
                    <BasicText fontSize="15px" color={theme.colors.blurple}>
                      {newPromo.fileName}
                    </BasicText>
                  </div>

                  <div
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: '20px',
                    }}
                    onClick={() => setShowUpload(true)}
                  >
                    <EditOutlined
                      style={{
                        color: theme.colors.blurple,
                        fontSize: '18px',
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <BasicButton
                text="+ UPLOAD PROMO RULES PDF"
                type="primary"
                style={{
                  width: '100%',
                  marginTop: '20px',
                  marginBottom: '20px',
                }}
                onClick={() => setShowUpload(true)}
              />
            )}
          </Form.Item>

          <div
            style={{
              marginTop: '40px',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <BasicButton
              type="default"
              text="Cancel"
              onClick={() => setNewPromo(null)}
              style={{ width: '48%' }}
            />

            <BasicButton
              type="primary"
              text="Submit"
              disabled={!shouldEnableCreatePromo()}
              onClick={() => onCreateConfirm()}
              style={{ width: '48%' }}
              loading={loading}
            />
          </div>
        </Modal>
      )}
      {showUpload && uploadAssetModal()}

      {showDeleteConfrim != null && (
        <ConfirmModal
          title={'Confirm Delete'}
          visible={showDeleteConfrim}
          loading={loading}
          onCancel={() => setShowDeleteConfirm(null)}
          onSubmit={() => onDeleteConfirm()}
        >
          <BasicText marginBottom="20px">
            Are you sure you want to delete this promotion? This action can not
            be undone.
          </BasicText>
        </ConfirmModal>
      )}
    </LoadingWrapper>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getPromos,
  getUrlFromUploadId,
  createPromoRule,
  editPromoRule,
  deletePromoRule,
})(withTheme(PromoSettings));
