import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Select, Radio } from 'antd';
import PageWrapper from '@components/PageWrapper';
import LoadingWrapper from '@components/LoadingWrapper';
import { Tabs } from 'antd';
const { TabPane } = Tabs;
import { withTheme } from 'styled-components';
import { PlayCircleOutlined, PlayCircleTwoTone } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, MuseoText } from '@components';
import styles from './ReviewTime.module.css';
import ReviewTimeTable from '@review/ReviewTimeTable';
import { getReviewTimeStats } from '@actions';

const ReviewTime = ({
  getReviewTimeStats,
  adminUser,
  theme
}) => {
  const [timeStats, setTimeStats] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);

  const isAdmin = adminUser.role == "ADMIN";

  useEffect(() => {
    getReviewTimeStats(
      (response) => setTimeStats(response),
      () => {}
    )
  }, []);

  // useEffect(() => {
  //   if (timeStats) {
  //     setSelectedTab(0);
  //   }
  // }, [timeStats]);

  const tabSelectorOptions = () => {
    return timeStats.map((stat, index) => (
      <TabPane
        key={index}
        value={index}
        tab={stat.tab_header}
      />
    ));
  }

  const onSelectedOption = (option) => {
    setSelectedTab(option);
  };

  return (
    <div className={styles.container}>
      <MuseoText>Review Time Stats</MuseoText>
      {timeStats &&
        <>
          {timeStats.length > 1 &&
            <div className={styles.radio_container}>
              <Tabs defaultActiveKey={selectedTab} onChange={(e) => onSelectedOption(e)}>
                {tabSelectorOptions()}
              </Tabs>
            </div>
          }

          <ReviewTimeTable
            reviewData={timeStats[selectedTab].stats}
            title={timeStats[selectedTab].table_header}
          />
        </>
      }
    </div>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getReviewTimeStats
})(withTheme(ReviewTime));
