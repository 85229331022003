import {
  REFRESH_CHALLENGE_LIST
} from '@actions/types';
import {
  getAPIRequest,
  postAPIRequest
} from '@actions';

export const getChallengeStats = (challenge_id, onSuccess) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_challenge_stats',
        { challenge_id },
        response => {
          onSuccess(response.challenge);
        },
        error => {
          onFail && onFail("Someting went wrong.");
        },
      )
    );
  };
};

export const toggleVisibility = (challenge_id, is_visible, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/toggle_visibility',
        { challenge_id, is_visible },
        response => {
          onSuccess(response);
        },
        error => {
          onFail("Something went wrong.");
        },
      ),
    );
  };
};

export const getLeaderBoardStats = (challenge_id, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_leaderboard_stats',
        { challenge_id },
        response => {
          onSuccess(response.stats);
        },
        error => {
          onFail("Someting went wrong.");
        },
      )
    );
  };
};

export const getMoreLeaderBoard = (challenge_id, seen_ids, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_more_leaderboard',
        { challenge_id, seen_ids },
        response => {
          onSuccess(response.submissions);
        },
        error => {
          onFail("Someting went wrong.");
        },
      )
    );
  };
};

export const getOverallPerformance = (challenge_id, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_challenge_performance',
        { challenge_id },
        response => {
          onSuccess(response.performance);
        },
        error => {
          onFail("Someting went wrong.");
        },
      )
    );
  };
};

export const getGeographicPerformance = (challenge_id, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_geographic_reach',
        { challenge_id },
        response => {
          onSuccess(response.performance);
        },
        error => {
          onFail("Someting went wrong.");
        },
      )
    );
  };
};

export const toggleStatsPageVisibility = (challenge_id, is_visible, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/toggle_stats_visibility',
        { challenge_id, is_visible },
        response => {
          onSuccess(response);
        },
        error => {
          onFail("Something went wrong.");
        },
      ),
    );
  };
};

export const getLiveStatsPage = (challenge_id, live_stats_hash, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_live_stats_page',
        { challenge_id, live_stats_hash },
        response => {
          onSuccess(response);
        },
        error => {
          onFail("Someting went wrong.");
        },
      )
    );
  };
};

export const submitBrandLogin = (challenge_id, live_stats_hash, sts_pwd, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/brand_sign_in',
        { challenge_id, live_stats_hash, sts_pwd },
        response => {
          onSuccess(response);
        },
        error => {
          onFail("Something went wrong.");
        },
      ),
    );
  };
};

export const getUserGraphData = (type, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/cumulative_user_graph',
        { type },
        response => {
          onSuccess(response.data);
        },
        error => {
          onFail && onFail("Someting went wrong.");
        },
      )
    );
  };
};
