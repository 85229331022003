import React from 'react';
import ScaleLoader from "react-spinners/HashLoader";
import { BasicText } from '@components';
import styles from './Loading.module.css';

const LoadingAnimation = ({
  isLoading,
  size,
  width,
  height,
  loadingText
}) => {
  return (
    <div className={styles.container_style}>
      <ScaleLoader
        size={size ? size : 50}
        height={height ? height : 60}
        width={width ? width : 8}
        color={"#222"}
      />
      <div className={styles.text_section}>
        {loadingText ? <BasicText fontSize="11px">{loadingText}</BasicText> : null}
      </div>
    </div>
  )
}

export default LoadingAnimation;
