import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import OverviewStats from '@admin/OverviewStats';
import ChallengeHome from '@challenges/ChallengeHome';
import ReviewHome from '@review/ReviewHome';
import TeamHome from '@team/TeamHome';
import { updateNewChallenge } from '@actions';
import { NAV_SECTIONS } from '@global';

const AdminHome = ({
  newChallengeInfo,
  updateNewChallenge,
  adminUser
}) => {

  const adminHome = () => {
    if (adminUser.sections.includes(NAV_SECTIONS.OVERVIEW)) {
      return <OverviewStats />;
    } else if (adminUser.sections.includes(NAV_SECTIONS.REVIEW_TEAM)) {
      return <TeamHome />;
    } else if (adminUser.sections.includes(NAV_SECTIONS.CHALLENGES)) {
      return <ChallengeHome />;
    } else if (adminUser.sections.includes(NAV_SECTIONS.REVIEW)) {
      return <ReviewHome />;
    } else {
      return <div/>;
    }
  };

  return adminHome();
}

const mapStatesToProps = (state) => {
  const { newChallengeInfo } = state.challenge;
  const { adminUser } = state.auth;
  return { newChallengeInfo, adminUser };
};

export default connect(mapStatesToProps, {
  updateNewChallenge
})(AdminHome);
