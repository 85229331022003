import {
  SET_REVENUE_STATS,
  SET_PAYOUT_STATS,
  SET_MEMBER_PAYOUT_STATS
} from '@actions/types';
import {
  getAPIRequest,
  postAPIRequest
} from '@actions';
import { showSuccessMessage, showErrorMessage } from '@global';

export const getRevenueData = (onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_revenue_stats',
        {},
        response => {
          dispatch({ type: SET_REVENUE_STATS, payload: response.revenue_stats });
          onSuccess();
        },
        error => {
          onFail(error);
        }
      )
    );
  };
};

export const getPaymentsForFilter = (filter, seen_ids, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/get_payments_for_filter',
        {
          filter, seen_ids
        },
        response => {
          onSuccess(response.payments);
        },
        error => {
          onFail("Something went wrong.");
        },
      ),
    );
  };
};

export const getPayoutData = (onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_payout_stats',
        {},
        response => {
          dispatch({ type: SET_PAYOUT_STATS, payload: response.payout_stats });
          onSuccess();
        },
        error => {
          onFail(error);
        }
      )
    );
  };
};

export const getPayoutsForFilter = (filter, seen_ids, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/get_payouts_for_filter',
        {
          filter, seen_ids
        },
        response => {
          console.log(response);
          onSuccess(response.payments);
        },
        error => {
          onFail("Something went wrong.");
        },
      ),
    );
  };
};

export const getMemberPayoutData = (onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_member_payout_stats',
        {},
        response => {
          dispatch({ type: SET_MEMBER_PAYOUT_STATS, payload: response.payout_stats });
          onSuccess();
        },
        error => {
          onFail(error);
        }
      )
    );
  };
};

export const getCumulativePayoutsForFilter = (filter, seen_ids, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/get_member_cumulative_payouts_for_filter',
        {
          filter, seen_ids
        },
        response => {
          console.log(response);
          onSuccess(response.payments);
        },
        error => {
          onFail("Something went wrong.");
        },
      ),
    );
  };
};

export const getDownloadDataForMemberPayouts = (download_type, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_download_data_for_member_payouts',
        { download_type },
        response => {
          onSuccess(response.data);
        },
        error => {
          onFail(error);
        }
      )
    );
  };
};
