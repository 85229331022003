import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { DatePicker, Form, Space, TimePicker, Button, Modal } from 'antd';
import AssetPreview from '@challenges/create/AssetPreview';
import moment from 'moment-timezone';
import { useHistory, Link } from 'react-router-dom';
import AssetUploader from '@challenges/create/AssetUploader';
import {
  TitleText,
  BasicText,
  BasicButton,
  ShortInput,
  LongInput,
} from '@components';
import { getUrlFromUploadId } from '@actions';
import {
  NEW_CHALLENGE_STATES,
  NEW_CHALLENGE_STEPS,
  CHALLENGE_ASSET_TYPES,
} from '@global';
import styles from './Create.module.css';

const MediaInfo = ({
  updateChallenge,
  media_info,
  getUrlFromUploadId,
  isEdit,
}) => {
  const [uploadAsset, setUploadAsset] = useState(null);

  const onSuccessUpload = (fileInfo, url) => {
    switch (uploadAsset) {
      case CHALLENGE_ASSET_TYPES.EXPLAINER_VIDEO:
        updateChallenge({
          media_info: {
            ...media_info,
            explainer_upload_id: fileInfo.presignInfo.upload_id,
            explainer_video_filename: fileInfo.fileName,
            explainer_video_url: url,
          },
        });
        setUploadAsset(null);
        break;
      case CHALLENGE_ASSET_TYPES.EXPLAINER_COVER:
        updateChallenge({
          media_info: {
            ...media_info,
            cover_upload_id: fileInfo.presignInfo.upload_id,
            explainer_cover_filename: fileInfo.fileName,
            explainer_cover_url: url,
          },
        });
        setUploadAsset(null);
        break;
      case CHALLENGE_ASSET_TYPES.LEADERBOARD_IMAGE:
        updateChallenge({
          media_info: {
            ...media_info,
            leaderboard_upload_id: fileInfo.presignInfo.upload_id,
            leaderboard_filename: fileInfo.fileName,
            leaderboard_url: url,
          },
        });
        setUploadAsset(null);
        break;
      case CHALLENGE_ASSET_TYPES.LEADEARBOARD_DAY_IMAGE:
        updateChallenge({
          media_info: {
            ...media_info,
            leaderboard_one_day_upload_id: fileInfo.presignInfo.upload_id,
            leaderboard_24_filename: fileInfo.fileName,
            leaderboard_24_url: url,
          },
        });
        setUploadAsset(null);
        break;
      default:
      // code block
    }
  };

  const uploadAssetModal = () => {
    return (
      <Modal
        title={`Upload ${uploadAsset}`}
        visible={uploadAsset != null}
        footer={null}
        onCancel={() => setUploadAsset(null)}
      >
        <AssetUploader
          asset_type={uploadAsset}
          onSuccess={(fileInfo) => {
            getUrlFromUploadId(fileInfo.presignInfo.upload_id, (url) => {
              onSuccessUpload(fileInfo, url);
            });
          }}
        />
      </Modal>
    );
  };

  return (
    <div>
      <div className={styles.upload_pair_container}>
        <Form.Item>
          <div className={styles.input_label}>
            <BasicText bold>Explainer Video (mp4 or mov)</BasicText>
          </div>

          <AssetPreview
            isVideo
            url={media_info.explainer_video_url}
            assetName="Video"
            fileName={media_info.explainer_video_filename}
            onClickEdit={() =>
              setUploadAsset(CHALLENGE_ASSET_TYPES.EXPLAINER_VIDEO)
            }
            instructions="30 second MP4, MOV supported"
          />
        </Form.Item>

        <div className={styles.second_media_pair}>
          <Form.Item>
            <div className={styles.input_label}>
              <BasicText bold>Explainer Cover (jpg or png)</BasicText>
            </div>

            <AssetPreview
              url={media_info.explainer_cover_url}
              assetName="Image"
              fileName={media_info.explainer_cover_filename}
              onClickEdit={() =>
                setUploadAsset(CHALLENGE_ASSET_TYPES.EXPLAINER_COVER)
              }
              instructions="Must have square dimensions. 1500 x 1500 px image recommended"
            />
          </Form.Item>
        </div>
      </div>

      {uploadAsset != null && uploadAssetModal()}
    </div>
  );
};

const mapStatesToProps = (state) => {
  const { newChallengeInfo } = state.challenge;
  return { newChallengeInfo };
};

export default connect(mapStatesToProps, {
  getUrlFromUploadId,
})(MediaInfo);
