import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Progress, Modal } from 'antd';
import ReactS3Uploader from 'react-s3-uploader';
import { CHALLENGE_ASSET_TYPES } from '@global';

const UploadForm = ({ asset_type, isLogo, isPromo, onSuccess }) => {
  const [uploadFile, setUploadFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const uploader = useRef();
  const presignInfo = useRef();
  const fileName = useRef();

  const getPresignedUrl = (file, onSuccess) => {
    if (isPromo) {
      axios
        .get(`/admin/get_promotion_presigned_url`, {
          params: {},
        })
        .then((response) => response.data)
        .then((json) => {
          if (json.success) {
            console.log(json);
            presignInfo.current = json;
            onSuccess({ signedUrl: json.url });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      axios
        .get(`/admin/get_presigned_url`, {
          params: {
            asset_type: asset_type,
          },
        })
        .then((response) => response.data)
        .then((json) => {
          if (json.success) {
            console.log(json);
            presignInfo.current = json;
            onSuccess({ signedUrl: json.url });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleSelect = (e) => {
    if (!e.target || !e.target.files || e.target.files.length === 0) {
      setUploadFile(null);
      return;
    }

    if (e.target.files[0].size > 100000000) {
      alert('File size to big. Max allowed is 100MB');
      const target = e.target;
      target.value = '';
    } else {
      //setUploadFile(e.target.files[0]);
      fileName.current = e.target.files[0].name;
      setUploadFile(e.target.files[0]);
      onUpload();
    }
  };

  const onUpload = () => {
    setIsUploading(true);
    uploader.current.uploadFile();
  };

  const cleanUpForm = () => {
    setIsUploading(false);
    setUploadFile(null);
    setUploadObject(null);
    setUploadProgress(0);
  };

  const getAccept = () => {
    if (isPromo) {
      return 'application/pdf';
    } else if (
      asset_type === CHALLENGE_ASSET_TYPES.EXPLAINER_VIDEO ||
      asset_type === CHALLENGE_ASSET_TYPES.PROMO_VIDEO ||
      asset_type === CHALLENGE_ASSET_TYPES.HYYPE_GROUP_VIDEO
    ) {
      return 'video/mp4,video/x-m4v,video/*';
    } else {
      return isLogo ? 'image/png' : 'image/jpeg,image/png,image/*';
    }
  };

  const getContentType = () => {
    if (isPromo) {
      return 'application/pdf';
    } else if (
      asset_type === CHALLENGE_ASSET_TYPES.EXPLAINER_VIDEO ||
      asset_type === CHALLENGE_ASSET_TYPES.HYYPE_GROUP_VIDEO
    ) {
      return 'video/mp4';
    } else {
      return 'image/jpeg';
    }
  };

  const s3UPloader = () => {
    if (!isUploading) {
      return (
        <ReactS3Uploader
          ref={uploader}
          onChange={handleSelect}
          getSignedUrl={getPresignedUrl}
          signingUrlMethod="PUT"
          accept={getAccept()}
          onProgress={(prog) => prog > 0 && setUploadProgress(prog)}
          onError={(error) => {
            console.log('error', error), setIsUploading(false);
          }}
          onFinish={() => {
            onSuccess({
              presignInfo: presignInfo.current,
              fileName: fileName.current,
            });
            if (isPromo) {
              setUploadFile(null);
              setIsUploading(false);
              setUploadProgress(0);
            }
          }}
          contentDisposition="auto"
          signingUrlHeaders={{
            'Content-Type': getContentType(),
            'Access-Control-Allow-Origin': '*',
          }}
          uploadRequestHeaders={{}}
          autoUpload={false}
        />
      );
    }
  };

  const content = () => {
    if (isUploading) {
      return (
        <div>
          Progress
          <Progress percent={uploadProgress} status="active" />
        </div>
      );
    } else {
      return s3UPloader();
    }
  };

  return <div>{content()}</div>;
};

export default UploadForm;
