import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form, Radio, Space, Switch } from 'antd';
import { withTheme } from 'styled-components';
import LoadingWrapper from '@components/LoadingWrapper';
import { PlayCircleOutlined, PlayCircleTwoTone } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, BasicButton, ShortInput, LongInput, ConfirmModal } from '@components';
import styles from './Settings.module.css';
import { showErrorMessage, showSuccessMessage } from '@global';
import { getPaymentSettings, updatePaymentSettings, toggleShouldCharge } from '@actions';

const WEEKLY_RATE = "WEEKLY_RATE";
const FLAT_RATE = "FLAT_RATE";

const PAYMENT_OPTIONS = [WEEKLY_RATE, FLAT_RATE];

const PaymentSettings = ({
  getPaymentSettings,
  updatePaymentSettings,
  toggleShouldCharge,
  theme
}) => {

  const [paymentSettings, setPaymentSettings] = useState(null);
  const [updatedSettings, setUpdatedSettings] = useState(null);
  const [enableSave, setEnableSave] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shouldCharge, setShouldCharge] = useState(null);

  useEffect(() => {
    getPaymentSettings(
      (settings) => {
        setShouldCharge(settings.charge_challenges);
        setUpdatedSettings(settings);
        setPaymentSettings(settings);
      },
      () => {}
    )
  }, []);

  useEffect(() => {
    if (updatedSettings && paymentSettings) {
      setEnableSave(paymentSettings.rate_formatted !== updatedSettings.rate_formatted || paymentSettings.type !== updatedSettings.type);
    }
  }, [updatedSettings, paymentSettings]);

  const isRateNumeric = () => {
    if (typeof updatedSettings.rate_formatted != "string") return false // we only process strings!
    return !isNaN(updatedSettings.rate_formatted) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(updatedSettings.rate_formatted)) // ...and ensure strings of whitespace fail
  }

  const onConfirmUpdate = () => {
    setLoading(true);
    updatePaymentSettings(
      updatedSettings.type,
      parseFloat(updatedSettings.rate_formatted) * 100,
      (settings) => {
        setShouldCharge(settings.charge_challenges);
        setUpdatedSettings(settings);
        setPaymentSettings(settings);
        setLoading(false);
        setShowConfirm(false);
        showSuccessMessage("Payment Settings Updated!");
      },
      () => {
        setLoading(false);
      }
    )
  }

  const onToggleShouldCharge = (should) => {
    setShouldCharge(should);
    toggleShouldCharge(
      should,
      (settings) => {
        setShouldCharge(settings.charge_challenges);
        setUpdatedSettings(settings);
        setPaymentSettings(settings);
        setLoading(false);
        setShowConfirm(false);
        showSuccessMessage(`Charging is now ${should ? "enabled" : "disabled"}`);
      },
      () => {
        setLoading(false);
      }
    )
  };

  return (
    <div style={{ height: "100vh" }}>
      <LoadingWrapper
        isLoading={paymentSettings == null}
        loadingText='Fetching Payment Data'
      >
        {paymentSettings && updatedSettings &&
          <div style={{ marginTop: "220px" }}>
            <div style={{ marginBottom: "40px" }}>
              <Space direction="vertical">
                <TitleText>Charge for Challenges</TitleText>

                <Switch
                  defaultChecked={shouldCharge}
                  onChange={(isToggled) => onToggleShouldCharge(isToggled)}
                  style={{
                    backgroundColor: shouldCharge
                      ? theme.colors.blurple
                      : theme.colors.lightShade,
                  }}
                />
              </Space>
            </div>

            <Space direction="vertical">
              <TitleText>Payment Type</TitleText>
              <Radio.Group
                onChange={(e) => setUpdatedSettings({
                  ...updatedSettings, type: e.target.value
                })}
                value={updatedSettings.type}
              >
                <Space direction="horizontal">
                  {PAYMENT_OPTIONS.map((option) =>
                    <Radio key={option} value={option} disabled={!shouldCharge}>{option === WEEKLY_RATE ? "WEEKLY RATE" : "FLAT RATE"}</Radio>
                  )}
                </Space>
              </Radio.Group>
            </Space>

            <div style={{ marginTop: "40px" }}>
              <Space direction="vertical">
                <TitleText>{updatedSettings.type === WEEKLY_RATE ? "Weekly Rate" : "Flat Rate"}</TitleText>

                <div style={{ width: "240px" }}>
                  <ShortInput
                    placeholder="$XXX.XX"
                    disabled={!shouldCharge}
                    onChange={(val) => setUpdatedSettings({
                      ...updatedSettings, rate_formatted: val.replace(/[^0-9\.]/g,'')
                    })}
                    value={updatedSettings.rate_formatted}
                  />
                </div>
              </Space>
            </div>

            <div style={{ marginTop: "40px" }}>
              <BasicButton
                type="primary"
                style={{ height: "46px" }}
                text="Update Payment Settings"
                disabled={!enableSave}
                onClick={() => {
                  if (isRateNumeric()) {
                    setShowConfirm(true);
                  } else {
                    showErrorMessage("Please enter a valid number for the rate.");
                  }
                }}
              />
            </div>
          </div>
        }
      </LoadingWrapper>

      {showConfirm &&
        <ConfirmModal
          title={"Confirm Update"}
          visible={showConfirm}
          loading={loading}
          onCancel={() => setShowConfirm(false)}
          onSubmit={() => onConfirmUpdate()}
        >
          <BasicText marginBottom="20px">
            Are you sure you want to update the Payment Settings to {updatedSettings.type === WEEKLY_RATE ? "weekly rate" : "flat rate"} at cost of ${updatedSettings.rate_formatted} {updatedSettings.type === WEEKLY_RATE ? "per week" : "total"}?
          </BasicText>
        </ConfirmModal>
      }
    </div>
  );
};


export default connect(null, {
  getPaymentSettings,
  updatePaymentSettings,
  toggleShouldCharge
})(withTheme(PaymentSettings));
