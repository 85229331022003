import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Form, Input, Modal } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, CustomButton, BasicButton, ShortInput, MuseoText } from '@components';
import '../App.css';
import './login.css'
import { validateEmail } from '../global_vars';
import logo from '../images/leftAlignSingleWordmark_light.png';
import { resetExistingPassword } from '@actions';

const ResetPasswordModal = ({
  visible,
  onCancel,
  resetExistingPassword
}) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const [successfullySent, setSuccessfullySent] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [error, setError] = useState(false);

  const validateForm = () => {
    return validateEmail(email);
  }

  useEffect(() => {
    setButtonDisabled(currentPassword.length < 6 ||
      newPassword.length < 6 ||
      newPassword != newPasswordConfirm
    );
  }, [currentPassword, newPassword, newPasswordConfirm]);

  const handleSubmit = () => {
    setError(false);
    setLoading(true);
    resetExistingPassword(
      currentPassword,
      newPassword,
      () => {
        setLoading(false);
        setSuccessfullySent(true);
      },
      (error) => {
        setLoading(false);
        setError(error);
      }
    );
  }

  const onFormSubmit = () => {
    handleSubmit();
  }


  return (
    <Modal
      title="Forgot Password"
      visible={visible}
      footer={null}
      onCancel={() => {
        setSuccessfullySent(false);
        onCancel();
      }}
    >

      {successfullySent ?
        <>
          <div style={{ marginBottom: "40px"}}>
            <BasicText>
              {"Your password has been successfully reset!"}
            </BasicText>
          </div>
          <Button
            type="primary"
            style={{ width: "100%", marginTop: "20px", height: "50px" }}
            onClick={() => {
              setSuccessfullySent(false);
              onCancel();
            }}
          >
              OK
          </Button>
        </>
      :
        <Form onFinish={onFormSubmit} initialValues={{ remember: true }}>
          <div style={{ marginBottom: "40px"}}>
            <BasicText>
              {"Enter the your current password and new password."}
            </BasicText>
          </div>
          <Form.Item>
            <div className="input_section">
              <ShortInput
                isPassword
                placeholder="current password"
                onChange={(val) => setCurrentPassword(val)}
                value={currentPassword}
              />
            </div>
          </Form.Item>

          <Form.Item>
            <div className="input_section">
              <ShortInput
                isPassword
                placeholder="new password"
                onChange={(val) => setNewPassword(val)}
                value={newPassword}
              />
            </div>
          </Form.Item>

          <Form.Item>
            <div className="input_section">
              <ShortInput
                isPassword
                placeholder="new password confirm"
                onChange={(val) => setNewPasswordConfirm(val)}
                value={newPasswordConfirm}
              />
            </div>
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%", marginTop: "20px", height: "50px" }}
              disabled={buttonDisabled}
              loading={loading}
            >
                Submit
            </Button>
          </Form.Item>
          {error && <BasicText color="red">{error}</BasicText>}
        </Form>
      }
    </Modal>
  );
};

// const mapStatesToProps = (state) => {
//   const { name, logged_in, login_error, force_email } = state.account;
//   const { invite_company_name } = state.company;
//   return { name, logged_in, login_error, force_email, invite_company_name };
// };

export default connect(null, {
  resetExistingPassword
})(ResetPasswordModal);
