import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Space, Switch } from 'antd';
import { withTheme } from 'styled-components';
import { CheckSquareOutlined } from '@ant-design/icons';
import PageWrapper from '@components/PageWrapper';
import { BasicButton, BasicText } from '@components';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import RejectedReasonsModal from '@review/RejectedReasonsModal';
import ChallengeInfoModal from '@review/ChallengeInfoModal';
import ReviewReportHistory from '@review/ReviewReportHistory';
import {
  getSubmissionToReview,
  getReportedSubmissionToReview,
  approveSubmission,
  rejectSubmission,
  handleSubmissionReport,
} from '@actions';
import styles from './ReviewEntry.module.css';
import { validateEmail, showSuccessMessage, showErrorMessage } from '@global';
import ReviewEntryVideo from '@review/entries/ReviewEntryVideo';

const ReviewEntry = ({
  getSubmissionToReview,
  getReportedSubmissionToReview,
  approveSubmission,
  rejectSubmission,
  theme,
  adminUser,
  overallReportPending,
  handleSubmissionReport,
  match,
}) => {
  const history = useHistory();
  const [showRejectedReasons, setShowRejectedReasons] = useState(false);
  const [showChallengeInfo, setShowChallengeInfo] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [submission, setSubmission] = useState(null);
  const [submissionId, setSubmissionId] = useState(match.params?.submission_id);
  const [submissionReviewStats, setSubmissionReviewStats] = useState(null);
  const [challenge, setChallenge] = useState(null);
  const [noneLeft, setNoneLeft] = useState(false);
  const challengeId = match.params?.challenge_id;
  const isReviewingChallenges = history.location.pathname.includes(
    'review_challenge_entry'
  );
  const isReporting = history.location.pathname.includes('reported_entry');
  const isViewing = history.location.pathname.includes('/view_entry');
  const isReview = !isReporting && !isViewing;

  useEffect(() => {
    getNextSubmission();
  }, []);

  const updatePathWithNewSubmissionId = (submissionId = null) => {
    if (isReview) {
      if (submissionId == null) {
        history.replace({
          pathname: isReviewingChallenges
            ? `/review_challenge_entry/${challengeId}`
            : `/review_entry`,
        });
      } else {
        history.replace({
          pathname: isReviewingChallenges
            ? `/review_challenge_entry/${challengeId}/${submissionId}`
            : `/review_entry/${submissionId}`,
        });
      }
    } else if (isReporting) {
      if (submissionId == null) {
        history.replace({ pathname: `/reported_entry` });
      } else {
        history.replace({ pathname: `/reported_entry/${submissionId}` });
      }
    }
  };

  const handleNextSubmission = (response) => {
    setApproveLoading(false);
    setRejectLoading(false);
    if (response.none_left) {
      setSubmissionId(null);
      updatePathWithNewSubmissionId(null);
      setSubmissionReviewStats(response.submission_review_stats);
      setNoneLeft(true);
    } else {
      console.log(response);
      setSubmissionId(response.submission.id);
      updatePathWithNewSubmissionId(response.submission.id);
      setSubmissionReviewStats(response.submission_review_stats);
      setSubmission(response.submission);
      if (challengeId) {
        setChallenge(response.submission.challenge);
      }
    }
  };

  const getNextSubmission = (clearCurrent = false) => {
    if (isReporting) {
      getReportedSubmissionToReview(
        submissionId,
        (response) => {
          console.log(response);
          handleNextSubmission(response);
        },
        () => {}
      );
    } else {
      getSubmissionToReview(
        challengeId,
        clearCurrent ? null : submissionId,
        (response) => {
          handleNextSubmission(response);
        },
        () => {}
      );
    }
  };

  const appendChallenge = () => {
    if (challenge) {
      return ` - ${challenge.title}`;
    } else {
      return '';
    }
  };

  const handleReportingResponse = (remove) => {
    handleSubmissionReport(submissionId, remove, (response) => {
      showSuccessMessage('Your action was processed!');
      if (isViewing) {
        window.location.reload();
      } else {
        handleNextSubmission(response);
        setShowRejectedReasons(false);
      }
    });
  };

  const approve = () => {
    if (isReporting || submission.has_been_reviewed) {
      setApproveLoading(true);
      handleReportingResponse(false);
    } else {
      setApproveLoading(true);
      approveSubmission(
        challengeId,
        submissionId,
        (response) => {
          showSuccessMessage('Entry approved!');
          if (isViewing) {
            window.location.reload();
          } else {
            handleNextSubmission(response);
          }
        },
        () => {
          setApproveLoading(false);
        }
      );
    }
  };

  const reject = (reason) => {
    if (isReporting || submission.has_been_reviewed) {
      setRejectLoading(true);
      handleReportingResponse(true);
    } else {
      setRejectLoading(true);
      rejectSubmission(
        challengeId,
        submissionId,
        reason,
        (response) => {
          showSuccessMessage('Entry not approved');
          if (isViewing) {
            window.location.reload();
          } else {
            handleNextSubmission(response);
            setShowRejectedReasons(false);
          }
        },
        () => {
          setRejectLoading(false);
        }
      );
    }
  };

  const additionalContent = () => {
    if (submission == null) return;

    if (isReview || isReporting) {
      return (
        <Space size={20}>
          <BasicButton
            text="Challenge Info"
            type="secondary"
            onClick={() => setShowChallengeInfo(true)}
          />

          <BasicButton
            text="Content Policy"
            type="secondary"
            onClick={() =>
              window.open('https://hyype.space/community', '_blank')
            }
          />
        </Space>
      );
    }
  };

  const getTitle = () => {
    if (noneLeft) {
      if (isReporting) {
        return `Return to Reported`;
      } else {
        return `Return to Review`;
      }
    }

    if (isReview) {
      return `Review Entries${appendChallenge()}`;
    } else if (isViewing) {
      if (submission) {
        return `@${submission.user.username} - ${submission.challenge.title}`;
      }
    } else if (isReporting) {
      return `Reported Entries`;
    }
  };

  const getButtonsText = () => {
    if (isReview || !submission.has_been_reviewed) {
      return {
        approve: 'Approve',
        reject: 'Reject',
      };
    } else if (isReporting) {
      return {
        approve: 'Keep Live',
        reject: 'Remove',
      };
    } else {
      return {
        approve: submission.is_approved ? null : 'Make Live',
        reject: submission.is_approved ? 'Remove' : null,
      };
    }
  };

  const content = () => {
    if (noneLeft) {
      return (
        <div className={styles.none_left_container}>
          <div style={{ marginRight: '40px' }}>
            <CheckSquareOutlined
              style={{ fontSize: '100px', color: theme.colors.blurple }}
            />
          </div>
          All entries have been reviewed or are in the process of being reviewed
          by another reviewer.
        </div>
      );
    } else if (submission) {
      return (
        <div>
          {submission.flag_entries && (
            <div
              style={{
                padding: '8px',
                backgroundColor: theme.colors.danger,
                color: theme.colors.snow,
                maxWidth: '400px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: '700',
              }}
            >
              THIS MEMBER HAS HAD THEIR ENTRIES FLAGGED
            </div>
          )}
          <div className={styles.video_and_history}>
            <ReviewEntryVideo
              submission={submission}
              onApprove={() => approve()}
              approveLoading={approveLoading}
              onReject={() => setShowRejectedReasons(true)}
              buttonsText={getButtonsText()}
              showButtons
              submissionReviewStats={submissionReviewStats}
              isReporting={isReporting}
              isViewing={isViewing}
              onEscalated={() => getNextSubmission(true)}
              onSkipEntry={() => getNextSubmission(true)}
            />

            <ReviewReportHistory entry={submission} />
          </div>
        </div>
      );
    }
  };

  return (
    <PageWrapper>
      <FixedHeaderContainer
        text={getTitle()}
        paddingBuffer="80px"
        paddingTop="56px"
        additionalContent={additionalContent()}
      >
        <div className={styles.review_entries_home_container}>{content()}</div>
      </FixedHeaderContainer>

      {showRejectedReasons && (
        <RejectedReasonsModal
          reasons={adminUser.submission_rejected_reasons}
          onSelectedReason={(reason) => reject(reason)}
          visible={showRejectedReasons}
          onCancel={() => setShowRejectedReasons(false)}
          rejectLoading={rejectLoading}
          isReport={isReporting}
        />
      )}

      {showChallengeInfo && (
        <ChallengeInfoModal
          visible={showChallengeInfo}
          onCancel={() => setShowChallengeInfo(false)}
          challenge={submission.challenge}
        />
      )}
    </PageWrapper>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  const { overallReportPending } = state.review;
  return { adminUser, overallReportPending };
};

export default connect(mapStatesToProps, {
  getSubmissionToReview,
  approveSubmission,
  getReportedSubmissionToReview,
  rejectSubmission,
  handleSubmissionReport,
})(withTheme(ReviewEntry));
