import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { WarningFilled } from '@ant-design/icons';
import { Table, Icon, Spin } from 'antd';
import { TitleText, BasicText, ShortInput, LongInput, CopyUrl, BasicButton } from '@components';
import styles from '@components/HeaderAndTable.module.css';

const ChallengeTable = ({
  challengeList,
  onChallengeSelected,
  marginTop,
  theme
}) => {

  const getChallengeTableData = () => {
    let dataArray = [];
    challengeList.forEach((challenge, i) => {
      dataArray.push({
        id: challenge.id,
        creator: challenge.creator,
        name: challenge.title,
        challenge_type: challenge.challenge_type,
        end_date: challenge.end_date,
        status: challenge.status,
        home_path: challenge.home_path,

      })
    });
    return dataArray;
  }

  const getLastColumn = () => {
    return {
      title: () => {
        return <div style={{ fontSize: "15px" }}>Status</div>;
      },
      dataIndex: 'status',
      key: 'status',
      width: 80,
      render(text, record) {
        return {
          children: (
            <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between"}}>
              <BasicText bold fontSize="15px">{text}</BasicText>
              {text === "Pending" &&
                <div style={{ marginLeft: "14px" }}>
                  <WarningFilled style={{ fontSize: "16px", color: theme.colors.yellow }} />
                </div>
              }
            </div>
          ),
        };
      }
    };
  }

  const getColumns = () => {
    let columns = [
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Name</div>;
        },
        dataIndex: 'name',
        key: 'name',
        width: 100,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px" bold>{text}</BasicText>
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Creator</div>;
        },
        dataIndex: 'creator',
        key: 'creator',
        width: 80,
        render(text, record) {
          return {
            children: <div style={{ display: "flex", alignItems: 'center', maxWidth: 150, overflow: 'hidden' }}>
                        <BasicText fontSize="15px">{text}</BasicText>
                      </div>
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Type</div>;
        },
        dataIndex: 'challenge_type',
        key: 'challenge_type',
        width: 80,
        render(text, record) {
          return {
            children: <BasicText fontSize="14px">{record.is_charity ? "Charity" : "Non-Charity"}</BasicText>,
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "14px" }}>End Date</div>;
        },
        dataIndex: 'end_date',
        key: 'end_date',
        width: 80,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px">{text}</BasicText>,
          };
        }
      },
      getLastColumn()
    ];

    return columns;
  }

  return (
    <div
      className={styles.table_container_style}
      style={{ marginTop: marginTop ? marginTop : "270px" }}
    >
      <Table
        pagination={false}
        columns={getColumns()}
        dataSource={getChallengeTableData()}
        rowKey="id"
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              onChallengeSelected(record)
            }
          };
        }}
      />
    </div>
  )
}

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {

})(withTheme(ChallengeTable));
