import {
  REFRESH_MEMBER_LIST,
  SET_VIEWING_MEMBER_LIST
} from '@actions/types';

// using snake case to be consistent with rails server
const getInitialState = () => {
  return {
    refreshMemberList: false,
    viewingMemberList: true
  };
};

const INITIAL_STATE = getInitialState();

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REFRESH_MEMBER_LIST: {
      return {...state, refreshMemberList: action.payload };
    }
    case SET_VIEWING_MEMBER_LIST: {
      return {...state, viewingMemberList: action.payload };
    }
    default:
      return state;
  }
};
