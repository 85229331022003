import { getAPIRequest, postAPIRequest } from '@actions';
import {
  SET_PLAYBACK_SPEED,
  UPDATE_REVIEW_STATS,
  SET_OVERALL_REVIEW_PENDING,
} from '@actions/types';

export const setPlaybackSpeed = (speed) => {
  return (dispatch) => {
    dispatch({ type: SET_PLAYBACK_SPEED, payload: speed });
  };
};

export const getPendingReviews = (onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_pending_reviews',
        {},
        (response) => {
          onSuccess(response);
        },
        (error) => {
          onFail(error);
        }
      )
    );
  };
};

export const getSubmissionToReview = (
  challenge_id,
  submission_id,
  onSuccess,
  onFail
) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_submission_to_review',
        { challenge_id, submission_id },
        (response) => {
          dispatch({
            type: SET_OVERALL_REVIEW_PENDING,
            payload:
              response.submission_review_stats.entry_and_challenge_pending,
          });
          onSuccess(response);
        },
        (error) => {
          onFail(error);
        }
      )
    );
  };
};

export const approveSubmission = (
  challenge_id,
  submission_id,
  onSuccess,
  onFail
) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/approve_submission',
        {
          challenge_id,
          submission_id,
        },
        (response) => {
          dispatch({
            type: SET_OVERALL_REVIEW_PENDING,
            payload:
              response.submission_review_stats.entry_and_challenge_pending,
          });
          onSuccess(response);
        },
        (error) => {
          onFail('Something went wrong.');
        }
      )
    );
  };
};

export const rejectSubmission = (
  challenge_id,
  submission_id,
  reason,
  onSuccess,
  onFail
) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/reject_submission',
        {
          challenge_id,
          submission_id,
          reason,
        },
        (response) => {
          dispatch({
            type: SET_OVERALL_REVIEW_PENDING,
            payload:
              response.submission_review_stats.entry_and_challenge_pending,
          });
          onSuccess(response);
        },
        (error) => {
          onFail('Something went wrong.');
        }
      )
    );
  };
};

export const getChallengeToReview = (
  challenge_id,
  onSuccess,
  onFail,
  isPortal = false
) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_challenge_to_review',
        { challenge_id, is_portal: isPortal },
        (response) => {
          dispatch({
            type: SET_OVERALL_REVIEW_PENDING,
            payload:
              response.challenge_review_stats.entry_and_challenge_pending,
          });
          onSuccess(response);
        },
        (error) => {
          onFail(error);
        }
      )
    );
  };
};

export const approveChallenge = (
  challenge_id,
  onSuccess,
  onFail,
  isPortalChallenge = false
) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/approve_challenge',
        {
          challenge_id,
          is_portal: isPortalChallenge,
        },
        (response) => {
          dispatch({
            type: SET_OVERALL_REVIEW_PENDING,
            payload:
              response.challenge_review_stats.entry_and_challenge_pending,
          });
          onSuccess(response);
        },
        (error) => {
          onFail('Something went wrong.');
        }
      )
    );
  };
};

export const rejectChallenge = (
  challenge_id,
  reason,
  onSuccess,
  onFail,
  isPortalChallenge = false
) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/reject_challenge',
        {
          challenge_id,
          reason,
          review_json: reason,
          is_portal: isPortalChallenge,
        },
        (response) => {
          dispatch({
            type: SET_OVERALL_REVIEW_PENDING,
            payload:
              response.challenge_review_stats.entry_and_challenge_pending,
          });
          onSuccess(response);
        },
        (error) => {
          onFail('Something went wrong.');
        }
      )
    );
  };
};

export const getChallegesByPendingReviews = (seen_ids, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_challenges_by_pending_reviews',
        { seen_ids },
        (response) => {
          onSuccess(response.challenges);
        },
        (error) => {
          onFail(error);
        }
      )
    );
  };
};

export const getChallegesByWaitingForReview = (
  seen_ids,
  onSuccess,
  onFail,
  isPortal = false
) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_challenges_by_waiting_for_review',
        { seen_ids, is_portal: isPortal },
        (response) => {
          onSuccess(response.challenges);
        },
        (error) => {
          onFail(error);
        }
      )
    );
  };
};

export const getReviewTimeStats = (onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_review_time_stats',
        {},
        (response) => {
          onSuccess(response);
        },
        (error) => {
          console.log(error);
          onFail(error);
        }
      )
    );
  };
};

export const escalateEntry = (admin_ids, submission_id, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/escalate_entry',
        {
          admin_ids,
          submission_id,
        },
        (response) => {
          onSuccess();
        },
        (error) => {
          onFail('Something went wrong.');
        }
      )
    );
  };
};

export const getBrandToReview = (brand_id, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_brand_to_review',
        { brand_id },
        (response) => {
          dispatch({
            type: SET_OVERALL_REVIEW_PENDING,
            payload: response.brand_review_stats.entry_and_challenge_pending,
          });
          onSuccess(response);
        },
        (error) => {
          onFail(error);
        }
      )
    );
  };
};

export const approveBrand = (brand_id, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/approve_brand',
        {
          brand_id,
        },
        (response) => {
          dispatch({
            type: SET_OVERALL_REVIEW_PENDING,
            payload: response.brand_review_stats.entry_and_challenge_pending,
          });
          onSuccess(response);
        },
        (error) => {
          onFail('Something went wrong.');
        }
      )
    );
  };
};

export const rejectBrand = (brand_id, review_json, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/reject_brand',
        {
          brand_id,
          review_json,
        },
        (response) => {
          dispatch({
            type: SET_OVERALL_REVIEW_PENDING,
            payload: response.brand_review_stats.entry_and_challenge_pending,
          });
          onSuccess(response);
        },
        (error) => {
          onFail('Something went wrong.');
        }
      )
    );
  };
};

export const getReactionToReview = (
  reaction_id,
  skip_ids,
  onSuccess,
  onFail
) => {
  console.log('sending with id from action', reaction_id);
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_reaction_to_review',
        { reaction_id, skip_ids },
        (response) => {
          dispatch({
            type: SET_OVERALL_REVIEW_PENDING,
            payload: response.reaction_review_stats.entry_and_challenge_pending,
          });
          onSuccess(response);
        },
        (error) => {
          onFail(error);
        }
      )
    );
  };
};

export const approveReaction = (reaction_id, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/approve_reaction',
        {
          reaction_id,
        },
        (response) => {
          dispatch({
            type: SET_OVERALL_REVIEW_PENDING,
            payload: response.reaction_review_stats.entry_and_challenge_pending,
          });
          onSuccess(response);
        },
        (error) => {
          onFail('Something went wrong.');
        }
      )
    );
  };
};

export const rejectReaction = (reaction_id, reason, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/reject_reaction',
        {
          reaction_id,
          reason,
        },
        (response) => {
          dispatch({
            type: SET_OVERALL_REVIEW_PENDING,
            payload: response.reaction_review_stats.entry_and_challenge_pending,
          });
          onSuccess(response);
        },
        (error) => {
          onFail('Something went wrong.');
        }
      )
    );
  };
};
