import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import { useHistory } from 'react-router-dom';
import { fetchTeamList } from '@actions';
import { withTheme } from 'styled-components';
import { NAV_SECTIONS } from '@global';
import TeamTable from '@team/TeamTable';
import styles from '@components/HeaderAndTable.module.css';
import LoadingWrapper from '@components/LoadingWrapper';
import { MuseoText, BasicButton, BottomTableLoader, SearchBar } from '@components';
import { Input, Space } from 'antd';
import { getAPIRequest, setRecentlyDeleted } from '@actions';
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor';
import FixedHeaderContainer from '@components/FixedHeaderContainer';

const { Search } = Input;

let _searchTimeout = null;

const TeamHome = ({
  adminUser,
  teamList,
  hasFetchedTeam,
  fetchTeamList,
  fetchingMore,
  getAPIRequest,
  isMore,
  theme
}) => {

  console.log("FETCHED TEAM", hasFetchedTeam);

  const listInnerRef = useRef();
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const history = useHistory();

  const performSearch = () => {
    const currentSearchTerm = searchTerm;
    if (currentSearchTerm.length > 0) {
      getAPIRequest(
        '/admin/search_team_member',
        { query: currentSearchTerm },
        response => {
          if (currentSearchTerm === searchTerm) {
            setSearchResults(response.members);
          }
        },
        error => {
          //onFail(error.error);
        },
      );
    }
  }

  useEffect(() => {
    clearTimeout(_searchTimeout);
    if (searchTerm.length > 0) {
      _searchTimeout = setTimeout(() => performSearch(), 200);
    }
  }, [searchTerm]);

  const onBottomLoadingVisible = (isVisible) => {
    if (isVisible) {
      if (!fetchingMore && isMore) fetchTeamList();
    }
  }

  useEffect(() => {
    if (!hasFetchedTeam) {
      fetchTeamList();
    }
  }, []);

  const header = () => {
    return (
      <div className={styles.fixed_header}>
        <div className={styles.header_container}>
          <div className={styles.header_text}>
            <MuseoText>Team Management</MuseoText>
          </div>

          <BasicButton
            text="Add new team member +"
            type="primary"
            onClick={() => {
              history.push({
                pathname: `/team/add_member`,
              })
            }}
            style={{ height: "46px", paddingLeft: "20px", paddingRight: "20px" }}
            fontSize="18px"
          />
        </div>

        <SearchBar
          searchTerm={searchTerm}
          onChange={(value) => setSearchTerm(value)}
        />
      </div>
    )
  };

  return (
    <PageWrapper>
      <LoadingWrapper isLoading={!hasFetchedTeam} loadingText="Fetching Team Data" size="large">
        <FixedHeaderContainer hideHeader additionalContent={header()}>
          <TeamTable
            teamList={searchTerm.length > 0 ? searchResults : teamList}
            onMemberSelected={(member) => {
              history.push({
                pathname: `/team_member/${member.id}`,
              })
            }}
          />
          {isMore && searchTerm.length === 0 &&
            <VisibilitySensor onChange={onBottomLoadingVisible}>
              <BottomTableLoader />
            </VisibilitySensor>
          }
        </FixedHeaderContainer>
      </LoadingWrapper>
    </PageWrapper>
  )
}

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  const { teamList, hasFetchedTeam, isMore, fetchingMore } = state.team;
  return { adminUser, teamList, hasFetchedTeam, isMore, fetchingMore };
};

export default connect(mapStatesToProps, {
  fetchTeamList,
  getAPIRequest
})(withTheme(TeamHome));
