import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { withTheme } from 'styled-components';
import { BasicButton, RejectButton, ApproveButton } from '@components';
import styles from './ReviewReactions.module.css';
import { validateEmail, showSuccessMessage, showErrorMessage } from '@global';
import { setPlaybackSpeed } from '@actions';
import ReviewStats from '@review/ReviewStats';
import EscalateEntry from '@review/entries/EscalateEntry';

const ReviewReactionVideo = ({
  reaction,
  setPlaybackSpeed,
  playBackSpeed,
  onApprove,
  approveLoading,
  onReject,
  rejectLoading,
  buttonsText,
  showButtons,
  reactionReviewStats,
  isReporting,
  isViewing,
  onSkipReaction,
  theme,
}) => {
  //console.log(buttonsText);

  const [progress, setProgress] = useState(0);
  const [enableApprove, setEnableApprove] = useState(false);
  const videoRef = useRef();
  const history = useHistory();

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = playBackSpeed;
    }
  }, [videoRef, playBackSpeed]);

  useEffect(() => {
    setEnableApprove(false);
  }, [reaction]);

  const handleProgress = (e) => {
    console.log(e.target.currentTime);
  };

  console.log(isReporting);

  const progressStats = () => {
    if (isReporting && reactionReviewStats) {
      return (
        <div className={styles.review_stats_container}>
          <ReviewStats
            stats={[
              {
                title: 'Pending',
                value: reactionReviewStats.pending,
                showDanger: true,
              },
              {
                title: 'Kept Live',
                value: reactionReviewStats.kept_live,
                showDanger: false,
              },
              {
                title: 'Removed',
                value: reactionReviewStats.removed,
                showDanger: false,
              },
            ]}
          />
        </div>
      );
    } else if (reactionReviewStats && !isViewing) {
      return (
        <div className={styles.review_stats_container}>
          <ReviewStats
            stats={[
              {
                title: 'Pending',
                value: reactionReviewStats.pending,
                showDanger: true,
              },
              {
                title: 'Approved',
                value: reactionReviewStats.approved,
                showDanger: false,
              },
              {
                title: 'Rejected',
                value: reactionReviewStats.rejected,
                showDanger: false,
              },
            ]}
          />
        </div>
      );
    }
  };

  //console.log(progress);

  return (
    <div>
      <div className={styles.video_and_controls}>
        <video
          key={reaction.id}
          className={styles.video_container}
          onEnded={() => setEnableApprove(true)}
          controls
          ref={videoRef}
          autoPlay
        >
          <source src={reaction.url.content} type="video/mp4" />
        </video>
      </div>

      {showButtons && (
        <div>
          <div className={styles.action_buttons_container}>
            {buttonsText.reject && (
              <div style={{ marginRight: '6px', display: 'flex', flex: 1 }}>
                <RejectButton
                  onClick={() => onReject()}
                  text={buttonsText.reject}
                />
              </div>
            )}

            {buttonsText.approve && (
              <div style={{ marginLeft: '6px', display: 'flex', flex: 1 }}>
                <ApproveButton
                  disabled={!enableApprove}
                  onClick={() => onApprove()}
                  text={buttonsText.approve}
                  loading={approveLoading}
                />
              </div>
            )}
          </div>
        </div>
      )}

      {reaction && (
        <div style={{ maxWidth: '300px' }}>
          <div style={{ fontSize: '15px', color: '#000', fontWeight: 700 }}>
            @{reaction.user.username} - Reacting to @
            {reaction.submitting_username} entry in {reaction.challenge.title}
          </div>
          <div style={{ fontSize: '13px', color: '#555', fontWeight: 700 }}>
            STATUS: {reaction.status}
          </div>
          <BasicButton
            text="Skip Reaction"
            type="secondary"
            style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
            onClick={() => onSkipReaction()}
          />
        </div>
      )}

      {progressStats()}
    </div>
  );
};

const mapStatesToProps = (state) => {
  const { playBackSpeed } = state.review;
  return { playBackSpeed };
};

export default connect(mapStatesToProps, {
  setPlaybackSpeed,
})(withTheme(ReviewReactionVideo));
