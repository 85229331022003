import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { TitleText, BasicText, BasicButton, ShortInput, LongInput } from '@components';
import styles from './ReviewChallenge.module.css';

const PrizeDisplay = ({
  prize,
  index
}) => {
  return (
    <div key={index} className={styles.custom_prize_container}>
      <div className={styles.image_and_content_container}>
        <div style={{ marginRight: "20px" }}>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => window.open(prize.image_url, "_blank")}
          >
            <img src={prize.image_url} width="80" height="80" style={{borderRadius: "12px"}}/>
          </div>
        </div>

        <div style={{ flex: 1 }}>
          <div className={styles.prize_top_container}>
            <div>
              <div>
                <BasicText useSpan fontSize="14px" bold>Rank: </BasicText>
                <BasicText useSpan fontSize="14px">{prize.final_rank}</BasicText>
              </div>
              <div style={{ marginTop: "3px"}}>
                <BasicText useSpan fontSize="14px" bold>Title: </BasicText>
                <BasicText useSpan fontSize="14px">{prize.title}</BasicText>
              </div>

              <div style={{ marginTop: "3px"}}>
                <BasicText useSpan fontSize="14px" bold>Est Face Value: </BasicText>
                <BasicText useSpan fontSize="14px">{prize.face_value_string ? prize.face_value_string : `$${prize.face_value}`}</BasicText>
              </div>
            </div>
          </div>

          <div style={{ marginTop: "3px"}}>
            <BasicText useSpan fontSize="14px" bold>Description: </BasicText>
            <BasicText useSpan fontSize="14px">{prize.description}</BasicText>
          </div>
        </div>
      </div>


    </div>
  );
};

export default PrizeDisplay;
