import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { BasicButton, BasicText } from '@components';
import MemberFormComponent from '@team/MemberFormComponent';
import  { } from '@actions';
import styles from './Team.module.css';

const MemberForm = ({
  memberInfo,
  onInfoUpdated,
  isEdit,
  canEditRole
}) => {

  return (
    <div className={styles.member_form_container} style={{ marginTop: isEdit ? "80px" : "150px" }}>
      <MemberFormComponent
        value={memberInfo.role}
        title="Role"
        onChange={(value) => onInfoUpdated({ ...memberInfo, role: value })}
        isRole
        isEdit={isEdit}
        canEdit={canEditRole}
      />

      <MemberFormComponent
        value={memberInfo.first_name}
        title="First Name"
        onChange={(value) => onInfoUpdated({ ...memberInfo, first_name: value })}
        isEdit={isEdit}
        canEdit={canEditRole}
      />

      <MemberFormComponent
        value={memberInfo.last_name}
        title="Last Name"
        onChange={(value) => onInfoUpdated({ ...memberInfo, last_name: value })}
        isEdit={isEdit}
        canEdit={canEditRole}

      />

      <MemberFormComponent
        value={memberInfo.company_name}
        title="Company Name"
        onChange={(value) => onInfoUpdated({ ...memberInfo, company_name: value })}
        isEdit={isEdit}
        canEdit={canEditRole}
      />

      <MemberFormComponent
        value={memberInfo.email}
        title="Email"
        onChange={(value) => onInfoUpdated({ ...memberInfo, email: value })}
        isEdit={isEdit}
        canEdit={false}
      />

      {isEdit ?
        <>
          <BasicText fontSize="13px">
            * Only super admins can create or change the role of an admin
          </BasicText>
          <BasicText fontSize="13px">
            * If you need to edit the email address please delete the account and invite a new member with the email address.
          </BasicText>
        </>
      :
        <BasicText>
          * Please ensure the email address is correct as members will be invited via email.
        </BasicText>
      }
    </div>
  )
};

// const mapStatesToProps = (state) => {
//   const { viewedChallengeInfo } = state.challenge;
//   return { viewedChallengeInfo };
// };

export default connect(null, {

})(MemberForm);
