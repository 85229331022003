import {
  UPDATE_SESSION,
  SET_ACCOUNT_MENU,
  SET_ADMIN_USER
} from '@actions/types';

const INITIAL_STATE = {
  logged_in: false,
  has_check_authorized: false,
  adminUser: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_SESSION:
      return { ...state, logged_in: action.payload, has_check_authorized: true };
    case SET_ACCOUNT_MENU:
      return { ...state, show_account_menu: action.payload };
    case SET_ADMIN_USER:
      return { ...state, adminUser: action.payload };
    default:
      return state;
  }
};
