import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import PageWrapper from '@components/PageWrapper';
import { BasicButton, ConfirmModal, BasicText } from '@components';
import MemberFormComponent from '@team/MemberFormComponent';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import  { inviteMember } from '@actions';
import styles from './Team.module.css';
import { validateEmail, showSuccessMessage, showErrorMessage  } from '@global';
import MemberForm from '@team/MemberForm';

const AddMember = ({
  inviteMember
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [showInviteConfirm, setShowInviteConfirm] = useState(false);
  const [memberInfo, setMemberInfo] = useState({
    role: "",
    first_name: "",
    last_name: "",
    email: "",
    company_name: ""
  });

  useEffect(() => {
    setSaveEnabled(
      memberInfo.role.length > 0 &&
      memberInfo.first_name.length > 0 &&
      memberInfo.last_name.length > 0 &&
      memberInfo.company_name.length > 0 &&
      validateEmail(memberInfo.email)
    );
  }, [memberInfo]);

  const onSubmit = () => {
    setLoading(true);
    inviteMember(
      memberInfo,
      (successMessage) => {
        setLoading(false);
        history.goBack();
        setTimeout(() => showSuccessMessage(successMessage, 10), 200);
      },
      (errorMessage) => {
        showErrorMessage(errorMessage, 6);
        setLoading(false);
      }
    );
  }

  const saveButton = () => {
    return (
      <BasicButton
        text="Invite"
        type="primary"
        disabled={!saveEnabled}
        onClick={() => {
          setShowInviteConfirm(true);
        }}
      />
    );
  }

  return (
    <PageWrapper>
      <FixedHeaderContainer
        text={"Add Member"}
        paddingBuffer="80px"
        additionalContent={saveButton()}
        paddingTop="56px"
      >
        <MemberForm
          memberInfo={memberInfo}
          onInfoUpdated={(updatedInfo) => setMemberInfo(updatedInfo)}
        />
      </FixedHeaderContainer>

      {showInviteConfirm &&
        <ConfirmModal
          title={"Confirm Invite"}
          visible={showInviteConfirm}
          loading={loading}
          onCancel={() => setShowInviteConfirm(false)}
          onSubmit={() => {
            onSubmit();
            setShowInviteConfirm(false);
          }}
        >
          <BasicText marginBottom="20px">
            Are you sure you want to invite this member with the following information:
          </BasicText>

          <MemberFormComponent
            value={memberInfo.role}
            title="Role"
            canEdit={false}
          />

          <MemberFormComponent
            value={memberInfo.first_name}
            title="First Name"
            canEdit={false}
          />

          <MemberFormComponent
            value={memberInfo.last_name}
            title="Last Name"
            canEdit={false}
          />

          <MemberFormComponent
            value={memberInfo.company_name}
            title="Company Name"
            canEdit={false}
          />

          <MemberFormComponent
            value={memberInfo.email}
            title="Email"
            canEdit={false}
          />
        </ConfirmModal>
      }
    </PageWrapper>
  )
};

export default connect(null, {
  inviteMember
})(AddMember);
