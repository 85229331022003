import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PageWrapper from "@components/PageWrapper";
import { useHistory } from "react-router-dom";
import { fetchTeamList } from "@actions";
import { withTheme } from "styled-components";
import { NAV_SECTIONS, CHALLENGE_TABLE_FILTERS } from "@global";
import MemberTable from "@members/MemberTable";
import styles from "@components/HeaderAndTable.module.css";
import SendNotificationModal from '@components/SendNotificationModal';
import LoadingWrapper from "@components/LoadingWrapper";
import CreateContainer from "@challenges/create/CreateContainer";
import FixedHeaderContainer from "@components/FixedHeaderContainer";
import {
  MuseoText,
  BasicButton,
  BottomTableLoader,
  SearchBar,
  FilterTabs,
} from "@components";
import { Input, Space } from "antd";
import { getAPIRequest, getMemberList, setRefreshMemberList } from "@actions";
import useOnScreen from "@components/useOnScreen";
import VisibilitySensor from "react-visibility-sensor/visibility-sensor";

const { Search } = Input;

let _searchTimeout = null;

const MemberHome = ({
  adminUser,
  getMemberList,
  getAPIRequest,
  refreshMemberList,
  setRefreshMemberList,
  theme,
}) => {
  const [members, setMembers] = useState(null);
  const [seenIds, setSeenIds] = useState([]);
  const [isMore, setIsMore] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [hasFetchedMembers, setHasFetchedMembers] = useState(false);
  const [isFetchingMembers, setIsFetchingMembers] = useState(true);
  const [totalMemberCount, setTotalMemberCount] = useState(null);
  const history = useHistory();
  const isFetchingMoreFromBottom = useRef(false);
  const [showSendNotification, setShowSendNotification] = useState(false);

  const performSearch = () => {
    const currentSearchTerm = searchTerm;
    if (currentSearchTerm.length > 0) {
      getAPIRequest(
        "/admin/search_members",
        { query: currentSearchTerm },
        (response) => {
          if (currentSearchTerm === searchTerm) {
            setSearchResults(response.members);
          }
        },
        (error) => {
          //onFail(error.error);
        }
      );
    }
  };

  useEffect(() => {
    fetchMembers(true);
  }, []);

  console.log("members", members);

  useEffect(() => {
    if (refreshMemberList) {
      fetchMembers(true);
      setRefreshMemberList(false);
    }
  }, [refreshMemberList]);

  useEffect(() => {
    clearTimeout(_searchTimeout);
    if (searchTerm.length > 0) {
      _searchTimeout = setTimeout(() => performSearch(), 200);
    }
  }, [searchTerm]);

  const onBottomLoadingVisible = (isVisible) => {
    if (isVisible) {
      if (!isFetchingMoreFromBottom.current && isMore) fetchMembers();
    }
  };

  console.log("total mem count", totalMemberCount);

  const fetchMembers = (isRefresh = false) => {
    if (!isRefresh && !isMore) return;
    if (!isRefresh && isFetchingMoreFromBottom.current) return;
    if (isRefresh) setHasFetchedMembers(false);

    isFetchingMoreFromBottom.current = true;
    getMemberList(
      isRefresh ? [] : seenIds,
      (response) => {
        if (response.total_members) setTotalMemberCount(response.total_members);
        const newMembers = response.members;
        if (isRefresh) {
          setSeenIds(newMembers.map((c) => c.id));
          setMembers(newMembers);
        } else {
          setSeenIds(seenIds.concat(newMembers.map((c) => c.id)));
          setMembers(members.concat(newMembers));
        }
        setIsMore(newMembers.length === 20);
        isFetchingMoreFromBottom.current = false;
        setHasFetchedMembers(true);
      },
      () => {}
    );
  };

  const header = () => {
    return (
      <div className={styles.fixed_header}>

        <div style={{ display: "flex", alignItems: 'center' }}>
          <SearchBar
            searchTerm={searchTerm}
            onChange={(value) => setSearchTerm(value)}
          />

          <BasicButton
            style={{ marginLeft: "44px", height: "50px", marginBottom: "16px", width: "180px", paddingLeft: "0px", paddingRight: "0px" }}
            type="secondary"
            onClick={() => {
              window.open("/download_contact", "_blank");
            }}
            text={"Download Contact Info"}
          />

          <BasicButton
            style={{ marginLeft: "20px", height: "50px", marginBottom: "16px", width: "150px", paddingLeft: "0px", paddingRight: "0px" }}
            type="secondary"
            onClick={() => {
              window.open("/download_referrals", "_blank");
            }}
            text={"Download Referrals"}
          />

          <BasicButton
            style={{ marginLeft: "20px", height: "50px", marginBottom: "16px", width: "150px", paddingLeft: "0px", paddingRight: "0px" }}
            type="secondary"
            onClick={() => {
              setShowSendNotification(true);
            }}
            text={"Send Notification"}
          />
        </div>
      </div>
    );
  };

  console.log("HELLO");

  return (
    <LoadingWrapper
      isLoading={!hasFetchedMembers}
      loadingText="Fetching Member Data"
      size="large"
    >
      <div style={{ marginTop: "220px" }}>
        {header()}

        <>
          <MemberTable
            memberList={searchTerm.length > 0 ? searchResults : members}
            totalMemberCount={totalMemberCount}
            onMemberSelected={(member) => {
              history.push({
                pathname: `/view_member/${member.id}`,
              });
            }}
          />
          {isMore && searchTerm.length === 0 && (
            <VisibilitySensor onChange={onBottomLoadingVisible}>
              <BottomTableLoader />
            </VisibilitySensor>
          )}
        </>
      </div>

      {showSendNotification &&
        <SendNotificationModal
          visible={showSendNotification}
          isChallenge={false}
          onCancel={() => setShowSendNotification(false)}
        />
      }
    </LoadingWrapper>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  const { refreshMemberList } = state.member;
  return { adminUser, refreshMemberList };
};

export default connect(mapStatesToProps, {
  getMemberList,
  getAPIRequest,
  setRefreshMemberList,
})(withTheme(MemberHome));
