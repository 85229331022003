import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, Form } from 'antd';
import { withTheme } from 'styled-components';
import PageWrapper from '@components/PageWrapper';
import LoadingWrapper from '@components/LoadingWrapper';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { EditFilled } from '@ant-design/icons';
import { Table, Icon, Spin } from 'antd';
import florrette from '@images/prizeFloretteSolid_shadow.png';
import SendGiftCardButton from './SendGiftCardButton';
import {
  TitleText,
  BasicText,
  ShortInput,
  LongInput,
  CopyUrl,
  BasicButton,
  DropDown,
  ConfirmModal,
  MuseoText,
} from '@components';
import styles from '@components/HeaderAndTable.module.css';
import { showSuccessMessage, showErrorMessage, CHALLENGE_TYPES } from '@global';
import { getMemberPrizes, fulfillIndividualPrize } from '@actions';

const FulfillChallengePrizes = ({
  member,
  getMemberPrizes,
  fulfillIndividualPrize,
  theme,
}) => {
  const history = useHistory();
  const [prizes, setPrizes] = useState([]);
  const [annualEarnings, setAnnualEarnings] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [retryLoadingId, setRetryLoadingId] = useState(null);
  const [prizeModal, setPrizeModal] = useState(null);

  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchDetails = () => {
    getMemberPrizes(
      member.id,
      (response) => {
        setPrizes(response.prizes);
        setAnnualEarnings(response.annual_earnings);
      },
      () => {}
    );
  };

  const getPrizeTableData = () => {
    if (prizes.length === 0) return [];
    let dataArray = [];
    prizes.forEach((prize, i) => {
      dataArray.push({
        id: prize.id,
        final_rank: prize.final_rank,
        title: prize.title,
        challenge_title: prize.challenge_title,
        winner: prize.user ? prize.user.winner : 'N/A',
        vote_count: prize.vote_count,
        status: prize.status,
        wegift_error: prize.wegift_error,
        is_fulfilled: prize.is_fulfilled,
        image_url: prize.image_url,
        place_string: prize.place_string,
        description: prize.description,
      });
    });
    console.log(dataArray);
    return dataArray;
  };

  const getLastColumn = () => {
    return {
      title: () => {
        return <div style={{ fontSize: '15px' }}>Status</div>;
      },
      dataIndex: 'status',
      key: 'status',
      width: 80,
      render(text, record) {
        return {
          children: (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              {text === 'Error' && !record.is_fulfilled ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    className={styles.error_text}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowErrorModal(record.wegift_error);
                    }}
                  >
                    Error
                  </div>
                  <BasicButton
                    style={{
                      width: '52px',
                      height: '30px',
                      borderRadius: '20px',
                      fontSize: '14px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    type="primary"
                    text="Retry"
                    loading={record.id === retryLoadingId}
                    stopPropagation
                    onClick={() => {
                      onRetry(record.id);
                    }}
                  />
                </div>
              ) : (
                <>
                  <BasicText bold fontSize="15px">
                    {text}
                  </BasicText>
                  {text === 'Pending' && (
                    <BasicButton
                      style={{
                        width: '52px',
                        height: '30px',
                        borderRadius: '20px',
                        fontSize: '14px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      type="primary"
                      text="Fulfill"
                      stopPropagation
                      loading={record.id === retryLoadingId}
                      onClick={() => {
                        onRetry(record.id);
                      }}
                    />
                  )}
                </>
              )}
            </div>
          ),
        };
      },
    };
  };

  const getColumns = () => {
    let columns = [
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Challenge</div>;
        },
        dataIndex: 'challenge_title',
        key: 'challenge_title',
        width: 100,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: 150,
                  overflow: 'hidden',
                }}
              >
                <BasicText fontSize="14px">{text}</BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Final Rank</div>;
        },
        dataIndex: 'final_rank',
        key: 'final_rank',
        width: 40,
        render(text, record) {
          return {
            children: (
              <BasicText fontSize="15px" bold>
                {text}
              </BasicText>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Prize</div>;
        },
        dataIndex: 'title',
        key: 'title',
        width: 100,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: 150,
                  overflow: 'hidden',
                }}
              >
                <BasicText fontSize="14px">{text}</BasicText>
              </div>
            ),
          };
        },
      },
      getLastColumn(),
    ];

    return columns;
  };

  const onRetry = (prizeId) => {
    setRetryLoadingId(prizeId);
    fulfillIndividualPrize(
      prizeId,
      (details) => {
        fetchDetails();
        setShowConfirm(false);
        setRetryLoadingId(null);
      },
      (error) => {
        setRetryLoadingId(null);
      }
    );
  };

  const annualBreakdowns = () => {
    if (annualEarnings) {
      return (
        <div>
          <div className={styles.stat_grids_container}>
            {annualEarnings.map((data) => (
              <div key={data.year} className={styles.stat_container}>
                <div className={styles.stat_title}>
                  {data.year} Earnings {data.is_current ? '(YTD)' : ''}
                </div>
                <div className={styles.stat_overall_container}>
                  <div className={styles.stat_overall}>{data.total}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <div>
        <div className={styles.table_container_style} style={{}}>
          <div className={styles.earnings_and_send_card}>
            <div>{annualBreakdowns()}</div>

            <div style={{ marginTop: `12px` }}>
              <SendGiftCardButton member={member} />
            </div>
          </div>

          <Table
            pagination={false}
            columns={getColumns()}
            dataSource={getPrizeTableData()}
            rowKey="id"
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  setPrizeModal(record);
                },
              };
            }}
          />
        </div>
      </div>

      <>
        {showErrorModal != null && (
          <Modal
            title={'Fulfill Prize Error'}
            visible={showErrorModal}
            footer={null}
            onCancel={() => setShowErrorModal(null)}
          >
            <div style={{ marginBottom: '20px' }}>
              <BasicText>{showErrorModal}</BasicText>
            </div>
          </Modal>
        )}

        {prizeModal && (
          <Modal
            title="Prize Details"
            visible={prizeModal}
            footer={null}
            onCancel={() => setPrizeModal(null)}
            className={styles.prize_modal_container}
          >
            <div className={styles.prize_modal_container}>
              <div className={styles.prize_modal_place}>
                {prizeModal.place_string}
              </div>
              <div className={styles.modal_florrette_container}>
                <img
                  src={florrette}
                  alt="logo"
                  className={styles.modal_florrette}
                />
                <div className={styles.modal_prize_image_container}>
                  <img
                    src={prizeModal.image_url}
                    alt="logo"
                    className={styles.modal_prize_image}
                  />
                </div>
              </div>
              <div className={styles.modal_prize_text}>{prizeModal.title}</div>

              <div className={styles.modal_prize_description}>
                {prizeModal.description}
              </div>
            </div>
          </Modal>
        )}
      </>
    </div>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getMemberPrizes,
  fulfillIndividualPrize,
})(withTheme(FulfillChallengePrizes));
