import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Icon, Spin } from 'antd';
import { TitleText, BasicText, ShortInput, LongInput, CopyUrl, BasicButton } from '@components';
import styles from '@components/HeaderAndTable.module.css';

const ReviewTimeTable = ({
  reviewData,
  title,
  marginTop
}) => {

  const getTimeTableData = () => {
    let dataArray = [];
    reviewData.forEach((data, i) => {
      dataArray.push({
        id: data[0],
        total: data[1].count,
        average: data[1].ave
      })
    });
    return dataArray;
  }

  const getColumns = () => {
    let columns = [
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>{title}</div>;
        },
        dataIndex: 'id',
        key: 'id',
        width: 120,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px" bold>{text}</BasicText>
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Total Reviews</div>;
        },
        dataIndex: 'total',
        key: 'total',
        width: 120,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px">{text}</BasicText>
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Average (sec)</div>;
        },
        dataIndex: 'average',
        key: 'average',
        width: 120,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px">{text}</BasicText>
          };
        }
      }
    ];

    return columns;
  }

  return (
    <div
      className={styles.table_container_style}
      style={{ marginTop: marginTop ? marginTop : "30px" }}
    >
      <Table
        pagination={false}
        columns={getColumns()}
        dataSource={getTimeTableData()}
        rowKey="id"
      />
    </div>
  )
}

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {

})(ReviewTimeTable);
