import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form, Space, Checkbox, Row } from 'antd';
import { withTheme } from 'styled-components';
import { PlayCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, BasicButton, ShortInput, LongInput } from '@components';
import { escalateEntry } from '@actions';
import { showSuccessMessage, showErrorMessage } from '@global';

const EscalateEntry = ({
  visible,
  submission,
  adminUser,
  onCancel,
  onEscalated,
  escalateEntry,
  theme
}) => {

  const { escalate_options } = adminUser;
  const [escalateIds, setEscalateIds] = useState([]);
  const [loading, setLoading] = useState(false);

  //console.log(escalate_options);

  const onEscalate = () => {
    setLoading(true);
    escalateEntry(
      escalateIds,
      submission.id,
      () => {
        setLoading(false);
        onEscalated();
        showSuccessMessage("Successfully alerted Admins");
      },
      () => {
        showErrorMessage("Something went wrong.");
        setLoading(false);
      }
    )
  }


  if (!visible) return null;

  return (
    <Modal
      title="Escalate Entry"
      visible={visible}
      footer={null}
      onCancel={() => {
        setEscalateIds([]);
        onCancel();
      }}
    >
      <BasicText>Please select the admin(s) you wish to escalate this entry to:</BasicText>

      <div style={{ marginTop: "20px" }}>
        <Checkbox.Group
          style={{ display: "block" }}
          onChange={(e) => setEscalateIds(e)}
        >
          {escalate_options.filter((a) => a.value !== adminUser.id).map((o) =>
            <Row key={o.value} style={{ marginBottom: "6px" }}>
              <Checkbox value={o.value}>{o.label}</Checkbox>
            </Row>
          )}
        </Checkbox.Group>
      </div>

      <div style={{ marginTop: "32px", width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <BasicButton
          text="Submit"
          type="primary"
          loading={loading}
          style={{ height: "42px" }}
          disabled={escalateIds.length === 0}
          onClick={() => {
            onEscalate();
          }}
        />
      </div>
    </Modal>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  escalateEntry
})(withTheme(EscalateEntry));
