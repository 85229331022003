import {
  UPDATE_NEW_CHALLENGE,
  RESET_NEW_CHALLENGE,
  SET_CHALLENGE_LIST,
  UPDATE_VIEWED_CHALLENGE,
  RESET_VIEWED_CHALLENGE,
  REFRESH_CHALLENGE_LIST,
} from '@actions/types';
import { getAPIRequest, postAPIRequest } from '@actions';

export const updateNewChallenge = (payload) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_NEW_CHALLENGE, payload });
  };
};

export const setRefreshChallengeList = (payload) => {
  return (dispatch) => {
    dispatch({ type: REFRESH_CHALLENGE_LIST, payload });
  };
};

export const resetNewChallenge = (payload) => {
  return (dispatch) => {
    dispatch({ type: RESET_NEW_CHALLENGE });
  };
};

export const updateViewedChallenge = (payload) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_VIEWED_CHALLENGE, payload });
  };
};

export const resetViewedChallenge = (payload) => {
  return (dispatch) => {
    dispatch({ type: RESET_VIEWED_CHALLENGE });
  };
};

export const updateChallengeOnServer = (
  data,
  onSuccess,
  onFail,
  isEdit = false
) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/create_or_update_challenge',
        { ...data, is_edit: isEdit },
        (response) => {
          if (!isEdit) {
            console.log('SEEEE MEEE');
            dispatch({
              type: UPDATE_NEW_CHALLENGE,
              payload: { challenge_id: response.challenge.challenge_id },
            });
          }
          onSuccess(response.challenge);
          dispatch(setRefreshChallengeList(true));
        },
        (error) => {
          onFail(error.error);
        }
      )
    );
  };
};

export const getPresignedUrl = (asset_type, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      getAPIRequest(
        '/admin/get_presigned_url',
        { asset_type },
        (response) => {
          onSuccess(response);
        },
        (error) => {
          onFail(error);
        }
      )
    );
  };
};

export const getUrlFromUploadId = (media_upload_id, onSuccess) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_url_from_upload_id',
        { media_upload_id },
        (response) => {
          onSuccess(response.url);
        }
      )
    );
  };
};

export const deleteChallenge = (challenge_id, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/delete_challenge',
        { challenge_id },
        (response) => {
          dispatch(setRefreshChallengeList(true));
          onSuccess && onSuccess();
        },
        (error) => {
          onFail && onFail();
        }
      )
    );
  };
};

export const getChallengesList = (type, seen_ids, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_challenge_list',
        {
          type,
          seen_ids,
        },
        (response) => {
          console.log(response);
          onSuccess(response.challenges);
        },
        (error) => {
          onFail(error);
        }
      )
    );
  };
};

export const getChallengeInfo = (challenge_id, onSuccess) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_challenge_info',
        { challenge_id },
        (response) => {
          onSuccess(response.challenge);
        }
      )
    );
  };
};

export const publishChallenge = (challenge_id, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/publish_challenge',
        { challenge_id },
        (response) => {
          if (response.success) {
            dispatch(setRefreshChallengeList(true));
            onSuccess && onSuccess();
          } else {
            onFail(response.message);
          }
        },
        (error) => {
          onFail('Something went wrong.');
        }
      )
    );
  };
};

export const removeMemberFromChallenge = (
  challenge_id,
  user_id,
  onSuccess,
  onFail
) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/remove_member_from_challenge',
        { challenge_id, user_id },
        (response) => {
          onSuccess && onSuccess();
        },
        (error) => {
          onFail && onFail();
        }
      )
    );
  };
};

export const toggleChallengePinned = (
  challenge_id,
  is_pinned,
  onSuccess,
  onFail
) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/toggle_challenge_pinned',
        { challenge_id, is_pinned },
        (response) => {
          onSuccess && onSuccess(response);
        },
        (error) => {
          onFail && onFail();
        }
      )
    );
  };
};

export const toggleEditorsChoice = (
  challenge_id,
  is_editors_choice,
  onSuccess,
  onFail
) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/toggle_editors_choice',
        { challenge_id, is_editors_choice },
        (response) => {
          onSuccess && onSuccess(response);
        },
        (error) => {
          onFail && onFail();
        }
      )
    );
  };
};
