import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Steps, Modal, Button } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, BasicButton, ShortInput, SaveOrDiscardModal } from '@components';
import BasicInfo from '@challenges/create/BasicInfo';
import BrandInfo from '@challenges/create/BrandInfo';
import MediaInfo from '@challenges/create/MediaInfo';
import PromoInfo from '@challenges/create/PromoInfo';
import PrizeInfo from '@challenges/create/PrizeInfo';
import RulesInfo from '@challenges/create/RulesInfo';
import {
  updateNewChallenge,
  updateChallengeOnServer,
  resetNewChallenge,
  deleteChallenge,
  getChallengesList
} from '@actions';
import {
  NEW_CHALLENGE_STATES,
  NEW_CHALLENGE_STEPS,
  showErrorMessage
} from '@global';
const { Step } = Steps;
import styles from './Create.module.css';

const CreateContainer = ({
  newChallengeInfo,
  updateNewChallenge,
  updateChallengeOnServer,
  resetNewChallenge,
  deleteChallenge,
  getChallengesList
}) => {
  const {
    showNewChallengeSteps,
    currentStepIndex,
    basic_info,
    brandInfo
  } = newChallengeInfo;

  const [loading, setLoading] = useState(false);
  const [showCancelConfirm, setShowCancelConfrim] = useState(false);
  const [hasSavedChallenge, setHasSavedChallenge] = useState(false);
  const history = useHistory();

  //console.log(newChallengeInfo);

  const customDot = (dot, { status, index }) => (
    <div style={{
      height: "8px",
      width: "8px",
      borderRadius: "4px",
      backgroundColor: status === "wait" ? "gray" : "#7176F9" }}
    />
  );

  const stepContent = () => {
    switch(currentStepIndex) {
    case 0:
      return  <BasicInfo
                updateChallenge={(data) => updateNewChallenge(data)}
                basic_info={newChallengeInfo.basic_info}
              />;
    case 1:
      return <MediaInfo
                updateChallenge={(data) => updateNewChallenge(data)}
                media_info={newChallengeInfo.media_info}
              />;
    case 2:
      return <PrizeInfo
                updateChallenge={(data) => updateNewChallenge(data)}
                prize_info={newChallengeInfo.prize_info}
              />;
    case 3:
      return <BrandInfo
                updateChallenge={(data) => updateNewChallenge(data)}
                brand_info={newChallengeInfo.brand_info}
                promo_info={newChallengeInfo.promo_info}
              />;
    default:
      return <div />;
    }
  }

  const nextEnabled = () => {
    switch(currentStepIndex) {
    case 0:
      return (
        (basic_info.title != null && basic_info.title.length > 0) &&
        (basic_info.description != null && basic_info.description.length > 0) &&
        (basic_info.start_time != null && basic_info.start_time.length > 0) &&
        (basic_info.end_time != null && basic_info.end_time.length > 0)
      );
    default:
      return true;
    }
  };

  const handleNextClicked = () => {
    setLoading(true);
    updateChallengeOnServer(
      newChallengeInfo,
      () => {
        setLoading(false);
        setHasSavedChallenge(true);
        if (currentStepIndex < NEW_CHALLENGE_STEPS.length - 1) {
          updateNewChallenge({ currentStepIndex: currentStepIndex + 1 });
        } else {
          // handle done case
          history.push({
            pathname: `/challenges/${newChallengeInfo.challenge_id}`,
          });
          setLoading(false);
          resetNewChallenge();
          getChallengesList();
          updateNewChallenge({ showNewChallengeSteps: false });
        }
      },
      (error) => {
        // handle error case
        showErrorMessage(error);
        setLoading(false);
      },
      currentStepIndex === 3
    );
  }

  const footerSection = () => {
    return (
      <div className={styles.footer_container}>
        {currentStepIndex === 0 ? <div /> :
          <BasicButton
            type="secondary"
            onClick={() => updateNewChallenge({ currentStepIndex: currentStepIndex - 1 })}
            text="Back"
          />
        }

        <BasicButton
          type="primary"
          loading={!showCancelConfirm && loading}
          disabled={!nextEnabled()}
          onClick={() => handleNextClicked()}
          text={currentStepIndex < 3 ? "Next" : "Done"}
        />
      </div>
    );
  };

  return (
    <Modal
      title="Create New Challenge"
      visible={showNewChallengeSteps}
      footer={null}
      onCancel={() => {
        if (hasSavedChallenge) {
          setShowCancelConfrim(true);
        } else {
          resetNewChallenge();
        }
      }}
      className={styles.modal_style}
    >
      <div style={{ minHeight: "620px" }}>
        <div className={styles.steps_container}>
          <Steps current={currentStepIndex} progressDot={customDot} style={{ color: "blueviolet" }}>
            <Step title="Basic" />
            <Step title="Media" />
            <Step title="Prizes" />
            <Step title="Brand" />
          </Steps>
        </div>

        <div className={styles.step_form_container}>
          {stepContent()}
          {footerSection()}
        </div>
      </div>

      {showCancelConfirm != null &&
        <SaveOrDiscardModal
          title={"Save Changes?"}
          visible={showCancelConfirm}
          loading={loading}
          onCancel={() => setShowCancelConfrim(false)}
          onSave={() => {
            setLoading(true);
            updateChallengeOnServer(
              newChallengeInfo,
              () => {
                setLoading(false);
                setShowCancelConfrim(false);
                resetNewChallenge();
              },
              () => {
                // handle error case
                setLoading(false);
              }
            );
          }}
          onDiscard={() => {
            deleteChallenge(newChallengeInfo.challenge_id);
            setShowCancelConfrim(false);
            resetNewChallenge();
          }}
        >
          <BasicText marginBottom="20px">
            Do you want to save this challenge and finish completing later? Or do you want to discard it?
          </BasicText>
        </SaveOrDiscardModal>
      }
    </Modal>
  );
};

const mapStatesToProps = (state) => {
  const { newChallengeInfo } = state.challenge;
  return { newChallengeInfo };
};

export default connect(mapStatesToProps, {
  updateNewChallenge,
  updateChallengeOnServer,
  resetNewChallenge,
  deleteChallenge,
  getChallengesList
})(CreateContainer);
