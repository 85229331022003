import React from 'react';

const BasicText = (props) => {
  if (props.useSpan) {
    return (
      <span
        style={{
          color: props.color ? props.color : "",
          fontWeight: props.bold ? "700" : "400",
          fontSize: props.fontSize ? props.fontSize : "15px",
          marginBottom: props.marginBottom ? props.marginBottom : "0px",
          maxWidth: props.maxWidth ? props.maxWidth : ""
        }}
      >
        {props.children}
      </span>
    )
  } else {
    return (
      <div
        style={{
          color: props.color ? props.color : "",
          fontWeight: props.bold ? "700" : "400",
          fontSize: props.fontSize ? props.fontSize : "15px",
          marginBottom: props.marginBottom ? props.marginBottom : "0px",
          maxWidth: props.maxWidth ? props.maxWidth : "",
          whiteSpace: "pre-wrap",
          textAlign: props.textAlign ? props.textAlign : 'left'
        }}
      >
        {props.children}
      </div>
    )
  }
}

export default BasicText;
