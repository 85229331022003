import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Space, Modal } from 'antd';
import { withTheme } from 'styled-components';
import { CheckSquareOutlined, InfoOutlined, CloseOutlined } from '@ant-design/icons';
import PageWrapper from '@components/PageWrapper';
import { BasicButton, RejectButton, ApproveButton, BasicText } from '@components';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import RejectedReasonsModal from '@review/RejectedReasonsModal';
import ReviewStats from '@review/ReviewStats';
import BasicSection from '@review/portal_challenges/BasicSection';
import MediaSection from '@review/portal_challenges/MediaSection';
import PrizesSection from '@review/portal_challenges/PrizesSection';
import PromoSection from '@review/portal_challenges/PromoSection';
import ReviewReportHistory from '@review/ReviewReportHistory';
import { validateEmail, showSuccessMessage, showErrorMessage  } from '@global';
import  {
  getChallengeToReview,
  approveChallenge,
  rejectChallenge
} from '@actions';
import styles from './ReviewChallenge.module.css';

const DEFAULT_JSON = {
  "Basic": { decided_on: false },
  "Media": { decided_on: false },
  "Prizes": { decided_on: false },
  "Promo": { decided_on: false }
};

const ReviewPortalChallenge = ({
  getChallengeToReview,
  approveChallenge,
  rejectChallenge,
  theme,
  adminUser,
  match
}) => {

  const history = useHistory();
  const [showRejectedReasons, setShowRejectedReasons] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [challengeId, setChallengeId] = useState(match.params?.challenge_id);
  const [challengeReviewStats, setChallengeReviewStats] = useState(null);
  const [challenge, setChallenge] = useState(null);
  const [noneLeft, setNoneLeft] = useState(false);
  const [enableApprove, setEnableApprove] = useState(false);

  const [approvedBasic, setApprovedBasic] = useState(false);
  const [approvedMedia, setApprovedMedia] = useState(false);

  const [reviewJson, setReviewJson] = useState(DEFAULT_JSON);

  const isFromReview = history.location.pathname.includes("/review_mgc_challenge");

  const [hasWatchedVideo, setHasWatchedVideo] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  const [rejectModal, setRejectModal] = useState(null);

  useEffect(() => {
    getNextChallenge();
  }, []);

  useEffect(() => {
    if (challenge && challenge.is_approved) {
      setApprovedBasic(true);
      setApprovedMedia(true);
    }
  }, [challenge]);


  const updatePathWithNewChallengeId = (challenge_id=null) => {
    let pathname = '/review_portal_challenge';
    if (challenge_id == null) {
      history.replace({ pathname: pathname })
    } else {
      history.replace({ pathname: `${pathname}/${challenge_id}` });
    }
  };

  const handleNextChallenge = (response) => {
    setApproveLoading(false);
    setRejectLoading(false);
    setApprovedBasic(false);
    setApprovedMedia(false);
    setHasWatchedVideo(false);
    if (response.none_left) {
      setChallengeId(null);
      updatePathWithNewChallengeId(null);
      setChallengeReviewStats(response.challenge_review_stats);
      setNoneLeft(true);
    } else {
      setChallengeId(response.challenge.challenge_id);
      updatePathWithNewChallengeId(response.challenge.challenge_id);
      setChallengeReviewStats(response.challenge_review_stats);
      setChallenge(response.challenge);
    }
  }

  const getNextChallenge = () => {
    getChallengeToReview(
      challengeId,
      (response) => {
        handleNextChallenge(response);
      },
      () => {},
      true
    )
  };

  const handleReportingResponse = (remove) => {
    handleChallengeReport(
      challengeId,
      remove,
      (response) => {
        showSuccessMessage("Your action was processed!");
        handleNextChallenge(response);
        setShowRejectedReasons(false);
      },
      () => {}
    )
  }


  const approve = () => {
    setApproveLoading(true);
    approveChallenge(
      challengeId,
      (response) => {
        showSuccessMessage("Challenge approved!");
        setReviewJson(DEFAULT_JSON);
        handleNextChallenge(response);
        window.scrollTo(0, 0);
      },
      () => { setApproveLoading(false) },
      true
    )
  }

  const checkCanReject = () => {
    let canReject = true;
    ["Basic", "Media", "Prizes", "Promo"].forEach((item, i) => {
      if (reviewJson[item].decided_on && !reviewJson[item].approved && !reviewJson[item].reason) {
        setRejectModal(`Please provide feedback for why the ${item} Info was rejected.`)
        canReject = false;
      }
    });

    return canReject;
  }

  const reject = () => {
    if (!checkCanReject()) return;

    setRejectLoading(true);
    rejectChallenge(
      challengeId,
      reviewJson,
      (response) => {
        showSuccessMessage("Challenge not approved");
        handleNextChallenge(response);
        setShowRejectedReasons(false);
        setReviewJson(DEFAULT_JSON);
        window.scrollTo(0, 0);
      },
      () => { setRejectLoading(false) },
      true
    )
  }

  const content = () => {
    if (noneLeft) {
      return (
        <div className={styles.none_left_container}>
          <div style={{ marginRight: "40px" }}>
            <CheckSquareOutlined style={{ fontSize: "100px", color: theme.colors.blurple }}/>
          </div>
          All challenges have been reviewed or are in the process of being reviewed by another reviewer.
        </div>
      )
    } else if (challenge) {
      return (
        <div className={styles.challenge_and_history}>
          <div className={styles.challenge_info}>
            <div className={styles.status_text} style={{ color: challenge.challenge_state === "REJECTED" ? theme.colors.danger : "#000" }}>
              Status: {challenge.challenge_state}
            </div>

            {challenge.submission_time &&
              <div className={styles.submission_time} style={{ marginBottom: challenge.challenge_state === "REJECTED" ? "0px" : "20px" }}>
                Submitted {challenge.submission_time}
              </div>
            }

            {challenge.challenge_state === "REJECTED" &&
              <div className={styles.submission_time}>
                Host will need to re-submit before it can be reviewed.
              </div>
            }

            <BasicSection
              challenge={challenge}
              reviewJson={reviewJson}
              updateReviewJson={(update) => setReviewJson({ ...reviewJson, ...update })}
              showApprove={challenge.challenge_state === "IN_REVIEW"}
            />

            <div className={styles.separator} />

            <MediaSection
              challenge={challenge}
              reviewJson={reviewJson}
              updateReviewJson={(update) => setReviewJson({ ...reviewJson, ...update })}
              setShowVideo={() => setShowVideo(true)}
              hasWatchedVideo={hasWatchedVideo}
            />

            <div className={styles.separator} />

            <PrizesSection
              challenge={challenge}
              reviewJson={reviewJson}
              updateReviewJson={(update) => setReviewJson({ ...reviewJson, ...update })}
              showApprove={challenge.challenge_state === "IN_REVIEW"}
            />

            <div className={styles.separator} />

            <PromoSection
              challenge={challenge}
              reviewJson={reviewJson}
              updateReviewJson={(update) => setReviewJson({ ...reviewJson, ...update })}
              showApprove={challenge.challenge_state === "IN_REVIEW"}
            />
          </div>

          <ReviewReportHistory entry={challenge} />
        </div>
      );
    }
  }

  const responseButton = () => {
    if  ((reviewJson["Basic"].decided_on && !reviewJson["Basic"].approved) ||
         (reviewJson["Media"].decided_on && !reviewJson["Media"].approved) ||
         (reviewJson["Prizes"].decided_on && !reviewJson["Prizes"].approved ||
          reviewJson["Promo"].decided_on && !reviewJson["Promo"].approved)
       ) {
         return (
           <RejectButton
             onClick={() => reject()}
             text="Reject"
             loading={rejectLoading}
           />
         )
       }

       if  (reviewJson["Basic"].approved && reviewJson["Media"].approved &&
            reviewJson["Prizes"].approved && reviewJson["Promo"].approved) {
            return (
              <ApproveButton
                onClick={() => approve()}
                text="Approve"
                loading={approveLoading}
              />
            )
          }

        return (
          <ApproveButton
            disabled
            onClick={() => {}}
            text="Approve"
            loading={approveLoading}
          />
        )
  };

  const additionalContent = () => {
    if (noneLeft) return null;

    if (challenge && challenge.challenge_state === "IN_REVIEW") {
      return (
        <Space size={20} className={styles.additional_header_content}>
          {responseButton()}

          <div
            className={styles.guidelines_button_container}
            onClick={() => window.open("https://hyype.space/community", "_blank")}
          >
            <div className={styles.guidelines_circle}>
              <InfoOutlined style={{ fontSize: "16px", color: theme.colors.snow, fontWeight: 700 }}/>
            </div>
          </div>
        </Space>
      )
    }
  };

  const progressStats = () => {
    if (challengeReviewStats) {
      return (
        <div className={styles.review_stats_container}>
          <ReviewStats
            stats={[
              { title: "Pending", value: challengeReviewStats.pending, showDanger: true },
              { title: "Approved", value: challengeReviewStats.approved, showDanger: false },
              { title: "Rejected", value: challengeReviewStats.rejected, showDanger: false },
            ]}
          />
        </div>
      );
    }
  }

  return (
    <PageWrapper>
      <FixedHeaderContainer
        text={challenge != null ? challenge.basic_info.title : noneLeft ? "Return to Review" : ""}
        paddingBuffer="80px"
        paddingTop="56px"
        additionalContent={additionalContent()}
        useMuseo
      >
        <div className={styles.review_challenges_container}>
          {progressStats()}
          {content()}
        </div>
      </FixedHeaderContainer>

      {challenge && showVideo &&
        <div className={styles.video_modal_conatiner}>
          <div className={styles.video_modal_background}>
            <div className={styles.modal_title_text}>
              {challenge.basic_info.title}
            </div>
            <div className={styles.video_container}>
              <div className={styles.video_and_controls}>
                <video
                  key={challenge.id}
                  className={styles.video_container}
                  onEnded={() => setHasWatchedVideo(true)}
                  controls
                  autoPlay
                >
                  <source src={challenge.media_info.explainer_video_url} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className={styles.video_modal_close_button} onClick={() => setShowVideo(false)}>
              <CloseOutlined style={{ fontSize: "20px", marginTop: "2px", marginLeft: "3px" }}/>
            </div>
          </div>
        </div>
      }

      <Modal
        title={"Complete Rejection Feedback"}
        visible={rejectModal}
        onCancel={() => setRejectModal(null)}
        footer={[
          <Button
            key="save"
            type="primary"
            onClick={() => setRejectModal(null)}
          >
            OK
          </Button>
        ]}
      >
        <BasicText>{rejectModal}</BasicText>
      </Modal>
    </PageWrapper>
  )
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getChallengeToReview,
  approveChallenge,
  rejectChallenge
})(withTheme(ReviewPortalChallenge));
