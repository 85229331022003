import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import { useHistory } from 'react-router-dom';
import { fetchTeamList } from '@actions';
import { withTheme } from 'styled-components';
import { NAV_SECTIONS, CHALLENGE_TABLE_FILTERS } from '@global';
import ReportedChallengeTable from '@reports/challenges/ReportedChallengeTable';
import styles from '@review/entries/ReviewEntry.module.css';
import LoadingWrapper from '@components/LoadingWrapper';
import CreateContainer from '@challenges/create/CreateContainer';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { MuseoText, BasicButton, BottomTableLoader, SearchBar, FilterTabs } from '@components';
import { Input, Space } from 'antd';
import useOnScreen from '@components/useOnScreen';
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor';
import { getChallengeReports } from '@actions';

const ReportedChallenges = ({
  adminUser,
  getChallengeReports,
  theme
}) => {

  const history = useHistory();
  const [challenges, setChallenges] = useState([]);
  const [seenIds, setSeenIds] = useState([]);
  const [isMore, setIsMore] = useState(true);
  const isFetchingMoreFromBottom = useRef(false);
  const [hasFetchedChallenges, setHasFetchedChallenges] = useState(false);


  useEffect(() => {
    fetchReports();
  }, []);

  const onBottomLoadingVisible = (isVisible) => {
    if (isVisible) {
      if (!isFetchingMoreFromBottom.current && isMore) fetchReports();
    }
  }

  const fetchReports = () => {
    if (!isMore && isFetchingMoreFromBottom.current) return;
    isFetchingMoreFromBottom.current = true;

    getChallengeReports(
      challenges ? challenges.map((s) => s.id) : [],
      (newChallenges) => {
        setChallenges(challenges.concat(newChallenges));
        setSeenIds(seenIds.concat(newChallenges.map((c) => c.id)));
        setIsMore(newChallenges.length === 20);
        isFetchingMoreFromBottom.current = false;
        setHasFetchedChallenges(true);
      },
      () => {}
    );
  };

  return (
    <PageWrapper>
      <FixedHeaderContainer
        text={'Reported Challenges'}
        paddingBuffer="80px"
        paddingTop="56px"
      >
        {hasFetchedChallenges &&
          <div className={styles.review_entries_home_container}>
            <BasicButton
              text="Review all"
              type="primary"
              style={{ width: "360px", height: "44px" }}
              onClick={() => {
                history.push({
                  pathname: `/reported_challenge`,
                });
              }}
            />

            <div className={styles.or_search_text}>
              -- or --
            </div>
            <>
              <ReportedChallengeTable
                challengeList={challenges}
                onChallengeSelected={(challenge) => {
                  history.push({
                    pathname: `/reported_challenge/${challenge.id}`,
                  });
                }}
              />
              {isMore &&
                <VisibilitySensor onChange={onBottomLoadingVisible}>
                  <BottomTableLoader />
                </VisibilitySensor>
              }
            </>
          </div>
        }
      </FixedHeaderContainer>
    </PageWrapper>
  )
}

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getChallengeReports
})(withTheme(ReportedChallenges));
