import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form, Space } from 'antd';
import { withTheme } from 'styled-components';
import {
  PlayCircleOutlined,
  CheckOutlined,
  PlayCircleTwoTone,
} from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import {
  TitleText,
  BasicText,
  BasicButton,
  ShortInput,
  LongInput,
} from '@components';
import styles from './ReviewChallenge.module.css';

const MediaSection = ({
  challenge,
  isApproved,
  onApprove,
  showApprove,
  hasWatchedVideo,
  setShowVideo,
  theme,
}) => {
  return (
    <div className={styles.approve_section}>
      <div className={styles.section_header_container}>
        <div className={styles.section_header}>Media Information</div>

        {showApprove && (
          <div className={styles.approve_check}>
            <div
              className={styles.approve_section_button}
              style={{
                backgroundColor: isApproved
                  ? theme.colors.blurple
                  : theme.colors.buttonDisabled,
              }}
            >
              <CheckOutlined
                style={{ color: theme.colors.snow, fontWeight: 700 }}
              />
            </div>
          </div>
        )}
      </div>

      {challenge.media_info.explainer_video_url ? (
        <Space size={40} className={styles.media_container}>
          <div>
            <div
              className={styles.attribute_title}
              style={{ marginBottom: '12px' }}
            >
              Explainer Video
            </div>

            <div className={styles.explainer_container}>
              <img
                className={styles.explainer_container}
                src={challenge.media_info.explainer_cover_url}
              />

              <div className={styles.play_explainer_cover} />

              <div
                className={styles.play_explainer}
                onClick={() => setShowVideo(true)}
              >
                <PlayCircleTwoTone
                  style={{ fontSize: '60px' }}
                  twoToneColor={challenge.brand_info.brand_color}
                />
              </div>
            </div>
          </div>

          <div>
            <div
              className={styles.attribute_title}
              style={{ marginBottom: '12px' }}
            >
              Explainer Image
            </div>
            <div
              className={styles.explainer_container}
              onClick={() =>
                window.open(challenge.media_info.explainer_cover_url, '_blank')
              }
            >
              <img
                className={styles.explainer_container}
                src={challenge.media_info.explainer_cover_url}
              />
            </div>
          </div>
        </Space>
      ) : (
        <div>Media Not Uploaded Yet</div>
      )}

      {challenge.media_info.categories &&
        challenge.media_info.categories.length > 0 && (
          <div style={{ marginTop: '18px' }}>
            <BasicText bold fontSize="16px">
              Selected Categories:
              <div style={{ marginTop: '8px' }}>
                {challenge.media_info.categories.map((category, index) => {
                  return (
                    <div key={index} style={{ marginBottom: '8px' }}>
                      <BasicText
                        fontSize="14px"
                        bold
                        color={theme.colors.blurple}
                      >
                        {category}
                      </BasicText>
                    </div>
                  );
                })}
              </div>
            </BasicText>
          </div>
        )}

      {challenge.media_info.explainer_video_url && showApprove && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            marginBottom: '80px',
          }}
        >
          <BasicButton
            text="Approve Media Info"
            type={isApproved ? 'primary' : 'secondary'}
            style={{
              marginTop: '40px',
              height: '44px',
              width: '100%',
              marginBottom: '12px',
            }}
            onClick={() => onApprove()}
            disabled={!hasWatchedVideo}
          />
          <BasicText fontSize="14px">
            To approve media you must watch the explainer video above.
          </BasicText>
        </div>
      )}
    </div>
  );
};

export default connect(null, {})(withTheme(MediaSection));
