import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form } from 'antd';
import BeatLoader from "react-spinners/BeatLoader";
import { withTheme } from 'styled-components';
import { PlayCircleOutlined, PlayCircleTwoTone } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, BasicButton, ShortInput, LongInput } from '@components';
import StatsLoader from '@challenges/stats/StatsLoader';
import styles from './ChallengeStats.module.css';
import florrette from '@images/prizeFloretteSolid_shadow.png';
import { downloadFiles, downlaodVideosIntoZip } from '@global';
import emptyPodium from '@images/blankPodium.svg';

const Podium = ({
  stats,
  loading,
  hasData,
  challenge,
  theme
}) => {

  const [prizeModal, setPrizeModal] = useState(null);
  const [downloading, setDownloading] = useState(false);

  const podiumThumb = (podiumPlace) => {
    console.log(podiumPlace);
    const { submission } = podiumPlace;
    return (
      <div
        style={{ overflow: "hidden", cursor: "pointer", backgroundColor: "#2c2c2c", minHeight: submission.is_first_place ? "177px" : "124px", display: "flex", alignItems: "center", borderRadius: "12px" }}
        onClick={() => window.open(submission.url.content, "_blank")}
      >
        <img resizeMode="cover" src={submission.url.thumb} width="100%" height="100%" style={{}}  />
      </div>
    )
  }

  const podiumSubtext = (podiumPlace) => {
    const { submission } = podiumPlace;
    return (
      <div className={styles.podium_text_container}>
        <div className={styles.podium_username}>
          {submission.user.username}
        </div>
        <div className={styles.podium_claps}>
          {submission.vote_count}
        </div>
      </div>
    )
  }

  const podiumPrize = (prize) => {
    return (
      <div
        className={styles.prize_container}
        onClick={() => setPrizeModal(prize)}
      >
        <div className={styles.florrette_container}>
          <img src={florrette} alt="logo" className={styles.florrette} />
          <div className={styles.prize_image_container}>
            <img src={prize.image_url} alt="logo" className={styles.prize_image} />
          </div>
        </div>
        <div className={styles.prize_text}>
          {prize.place_string} Prize
        </div>
      </div>
    )
  }

  const podiumContent = () => {
    if (loading) {
      return (
        <div className={styles.loading_container}>
          <StatsLoader />
        </div>
      )
    } else {
      return (
        <>
          {stats ?
            <>
              <div className={styles.podium_inner_container}>

                <div className={styles.podium_other_place}>
                  {stats.podium.second_place && stats.podium.second_place.submission &&
                    <div className={styles.podium_content}>
                      {podiumThumb(stats.podium.second_place)}
                      {podiumSubtext(stats.podium.second_place)}
                      {stats.podium.second_place.prize && podiumPrize(stats.podium.second_place.prize)}
                    </div>
                  }
                </div>


                <div className={styles.podium_first_place}>
                  {stats.podium.first_place && stats.podium.first_place.submission &&
                    <div className={styles.podium_content}>
                      {podiumThumb(stats.podium.first_place)}
                      {podiumSubtext(stats.podium.first_place)}
                      {stats.podium.first_place.prize && podiumPrize(stats.podium.first_place.prize)}
                    </div>
                  }
                </div>

                <div className={styles.podium_other_place}>
                  {stats.podium.third_place && stats.podium.third_place.submission &&
                    <div className={styles.podium_content}>
                      {podiumThumb(stats.podium.third_place)}
                      {podiumSubtext(stats.podium.third_place)}
                      {stats.podium.third_place.prize && podiumPrize(stats.podium.third_place.prize)}
                    </div>
                  }
                </div>
              </div>

              <div
                className={styles.download_button}
                onClick={() => {
                  setDownloading(true)
                  downlaodVideosIntoZip(
                    stats.top_three_download,
                    challenge.basic_info.title,
                    3,
                    () => setDownloading(false)
                  )
                }}
              >
                {downloading ?
                  <div style={{ marginTop: "2px" }}>
                    <BeatLoader
                      size={8}
                      color={theme.colors.blurple}
                    />
                  </div>
                :
                  <div className={styles.download_text}>
                    Download Top 3 Entry Videos
                  </div>
                }
              </div>
            </>
          :
            <div className={styles.empty_podium}>
              <img
                src={emptyPodium}
              />
              <div className={styles.empty_text}>
                See the top three entries here while the Challenge is running!
              </div>
            </div>
          }
        </>
      )
    }
  }


  return (
    <div className={styles.top_three_section}>
      <div className={styles.section_title}>
        Podium
      </div>
      {podiumContent()}

      {prizeModal &&
        <Modal
          title="Prize Details"
          visible={prizeModal}
          footer={null}
          onCancel={() => setPrizeModal(null)}
          className={styles.prize_modal_container}
        >
          <div className={styles.prize_modal_container}>
            <div className={styles.prize_modal_place}>
              {prizeModal.place_string}
            </div>
            <div className={styles.modal_florrette_container}>
              <img src={florrette} alt="logo" className={styles.modal_florrette} />
              <div className={styles.modal_prize_image_container}>
                <img src={prizeModal.image_url} alt="logo" className={styles.modal_prize_image} />
              </div>
            </div>
            <div className={styles.modal_prize_text}>
              {prizeModal.title}
            </div>

            <div className={styles.modal_prize_description}>
              {prizeModal.description}
            </div>
          </div>
        </Modal>
      }
    </div>
  );
};



export default connect(null, {

})(withTheme(Podium));
