import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import { useHistory } from 'react-router-dom';
import { fetchTeamList } from '@actions';
import { withTheme } from 'styled-components';
import { NAV_SECTIONS, CHALLENGE_TABLE_FILTERS } from '@global';
import ReportedEntryTable from '@reports/entries/ReportedEntryTable';
import styles from '@review/entries/ReviewEntry.module.css';
import LoadingWrapper from '@components/LoadingWrapper';
import CreateContainer from '@challenges/create/CreateContainer';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { MuseoText, BasicButton, BottomTableLoader, SearchBar, FilterTabs } from '@components';
import { Input, Space } from 'antd';
import useOnScreen from '@components/useOnScreen';
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor';
import { getEntryReports } from '@actions';

const ReportedEntries = ({
  adminUser,
  getEntryReports,
  theme
}) => {

  const history = useHistory();
  const [submissions, setSubmissions] = useState([]);
  const [seenIds, setSeenIds] = useState([]);
  const [isMore, setIsMore] = useState(true);
  const isFetchingMoreFromBottom = useRef(false);
  const [hasFetchedSubmissions, setHasFetchedSubmissions] = useState(false);

  console.log(submissions);

  useEffect(() => {
    fetchReports();
  }, []);

  const onBottomLoadingVisible = (isVisible) => {
    if (isVisible) {
      if (!isFetchingMoreFromBottom.current && isMore) fetchReports();
    }
  }

  const fetchReports = () => {
    if (!isMore && isFetchingMoreFromBottom.current) return;
    isFetchingMoreFromBottom.current = true;

    getEntryReports(
      submissions ? submissions.map((s) => s.id) : [],
      (newSubmissions) => {
        setSubmissions(submissions.concat(newSubmissions));
        setSeenIds(seenIds.concat(newSubmissions.map((c) => c.id)));
        setIsMore(newSubmissions.length === 20);
        isFetchingMoreFromBottom.current = false;
        setHasFetchedSubmissions(true);
      },
      () => {}
    );
  };

  return (
    <PageWrapper>
      <FixedHeaderContainer
        text={'Reported Entries'}
        paddingBuffer="80px"
        paddingTop="56px"
      >
        {hasFetchedSubmissions &&
          <div className={styles.review_entries_home_container}>
            <BasicButton
              text="Review all"
              type="primary"
              style={{ width: "360px", height: "44px" }}
              onClick={() => {
                history.push({
                  pathname: `/reported_entry`,
                });
              }}
            />

            <div className={styles.or_search_text}>
              -- or --
            </div>
            <>
              <ReportedEntryTable
                submissionList={submissions}
                onSubmissionSelected={(submission) => {
                  history.push({
                    pathname: `/reported_entry/${submission.id}`,
                  });
                }}
              />
              {isMore &&
                <VisibilitySensor onChange={onBottomLoadingVisible}>
                  <BottomTableLoader />
                </VisibilitySensor>
              }
            </>
          </div>
        }
      </FixedHeaderContainer>
    </PageWrapper>
  )
}

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getEntryReports
})(withTheme(ReportedEntries));
