import React from 'react';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { BasicText } from '@components';

const DeleteButton = (props) => {
  return (
    <Button
      {...props}
      onClick={() => props.onClick()}
      style={{ ...{
        height: "36px",
        width: "42px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#ff4b4b"
      }, ...props.style }}
    >
      <DeleteOutlined style={{ fontSize: "20px", color: "#fff"}} />
    </Button>
  )
}

export default DeleteButton;
