import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import LoadingWrapper from '@components/LoadingWrapper';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { Button, Modal, Form, Space, Checkbox, Row } from 'antd';
import { withTheme } from 'styled-components';
import { PlayCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import RevenueTableSection from '@payments/RevenueTableSection';
import { TitleText, BasicText, BasicButton, MuseoText, FilterTabs } from '@components';
import { getMemberPayoutData } from '@actions';
import { showSuccessMessage, showErrorMessage } from '@global';
import styles from './Payments.module.css';

const FILTER_OPTIONS = ["All time", "YTD", "Past 30d"];

const MemberHeader = ({
  adminUser,
  memberData,
  getMemberPayoutData,
  currentFilter,
  setCurrentFilter,
  currentTableFilter,
  setCurrentTableFilter,
  dataLoaded,
  theme
}) => {

  const TABLE_FILTER_OPTIONS = adminUser.member_payout_years;

  const [displayedStats, setDisplayedStats] = useState(null);
  const [downloadCSV, setDownloadCSV] = useState(false);

  useEffect(() => {
    if (memberData) return;

    getMemberPayoutData(
      () => {

      },
      () => {}
    )
  }, []);

  useEffect(() => {
    if (memberData) {
      setDisplayedStats(memberData.last_thirty);
    }
  }, [memberData]);

  const onDownloadCSV = () => {
    setDownloadCSV(true);
    setTimeout(() => setDownloadCSV(false), 1000);
  }

  const updateDisplayedStats = (selectedOption) => {
    setCurrentFilter(selectedOption);

    switch (selectedOption) {
      case FILTER_OPTIONS[0]:
        setDisplayedStats(memberData.overall);
        break;
      case FILTER_OPTIONS[1]:
        setDisplayedStats(memberData.year_to_date);
        break;
      case FILTER_OPTIONS[2]:
        setDisplayedStats(memberData.last_thirty);
        break;
      default:
        return null;
    }
  }

  const revenueGrids = () => {
    if (displayedStats) {
      return (
        <div className={styles.stat_grids_container}>
          <div className={styles.stat_container}>
            <div className={styles.stat_title}>
              Member Winners
            </div>
            <div className={styles.stat_overall_container}>
              <div className={styles.stat_overall}>
                {displayedStats.num_winners}
              </div>
            </div>
          </div>

          <div className={styles.stat_container}>
            <div className={styles.stat_title}>
              Total Earnings
            </div>
            <div className={styles.stat_overall_container}>
              <div className={styles.stat_overall}>
                {displayedStats.total_payouts}
              </div>
            </div>
          </div>

          <div className={styles.stat_container}>
            <div className={styles.stat_title}>
              Avg. Earnings
            </div>
            <div className={styles.stat_overall_container}>
              <div className={styles.stat_overall}>
                {displayedStats.average_payout}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <div>
      <FilterTabs
        options={FILTER_OPTIONS}
        selectedOption={currentFilter}
        onSelectOption={(selectedOption) => {
          updateDisplayedStats(selectedOption);
        }}
      />

      {revenueGrids()}

      <div className={styles.member_filter_and_export}>
        <FilterTabs
          options={TABLE_FILTER_OPTIONS}
          selectedOption={currentTableFilter}
          onSelectOption={(selectedOption) => {
            setCurrentTableFilter(selectedOption);
          }}
        />

        <div style={{ display: "flex", alignItems: "center", marginRight: "-300px" }}>
          <BasicButton
            text="EXPORT ALL"
            type="secondary"
            onClick={() => window.open("/download_member_payouts/all", "_blank")}
          />

          <BasicButton
            text="EXPORT > 600"
            type="primary"
            style={{ marginLeft: "12px" }}
            onClick={() => window.open("/download_member_payouts/over_600", "_blank")}
          />
        </div>
      </div>
    </div>
  )
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  const { memberData } = state.payment;
  return { adminUser, memberData };
};

export default connect(mapStatesToProps, {
  getMemberPayoutData
})(withTheme(MemberHeader));
