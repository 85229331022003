import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form } from 'antd';
import { withTheme } from 'styled-components';
import { PlayCircleOutlined, PlayCircleTwoTone } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, BasicButton, ShortInput, LongInput } from '@components';
import styles from './ReviewStats.module.css';

const ReviewStats = ({
  stats,
  title,
  theme
}) => {

  const gridStat = (number, stat, useRed=false) => {
    return (
      <div className={styles.grid_stat_container}>
        <div className={styles.grid_trio_number} style={{ color: useRed ? theme.colors.danger : "#000" }}>
          {number}
        </div>
        <div className={styles.grid_trio_text}>
          {stat}
        </div>
      </div>
    )
  }

  return (
    <div>
      {title && <BasicText bold fontSize="18px">{title}</BasicText>}
      <div className={styles.overall_stats_grid}>
        <div className={styles.grid_trio_item}>
          {gridStat(stats[0].value, stats[0].title, stats[0].showDanger)}
        </div>
        <div className={styles.grid_trio_item}>
          {gridStat(stats[1].value, stats[1].title, stats[1].showDanger)}
        </div>

        <div className={styles.grid_trio_item}>
          {gridStat(stats[2].value, stats[2].title, stats[2].showDanger)}
        </div>
      </div>
    </div>
  );
};

const mapStatesToProps = (state) => {
  const { reviewStats } = state.review;
  return { reviewStats };
};

export default connect(mapStatesToProps, {
})(withTheme(ReviewStats));
