import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form } from 'antd';
import { withTheme } from 'styled-components';
import LoadingWrapper from '@components/LoadingWrapper';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import LiveStats from '@challenges/stats/LiveStats';
import NavBar from '@navigation/navbar';
import { PlayCircleOutlined, PlayCircleTwoTone } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, BasicButton, ShortInput, LongInput, MuseoText } from '@components';
import styles from './BrandViewStats.module.css';
import BrandLogin from '@admin/BrandLogin';
import { getLiveStatsPage, submitBrandLogin, logoutUser } from '@actions';


const BrandViewStats = ({
  match,
  getLiveStatsPage,
  submitBrandLogin,
  logged_in,
  logoutUser,
  theme
}) => {

  const [fetchingData, setFetchingData] = useState(true);
  const [showInvalid, setShowInvalid] = useState(false);
  const [showInputPassword, setShowInputPassword] = useState(false);
  const [challenge, setChallenge] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [invalidError, setInvalidError] = useState(null);

  useEffect(() => {
    getLiveStatsPage(
      match?.params?.challenge_id,
      match?.params?.live_stats_hash,
      (response) => {
        console.log(response);
        if (response.success) {
          setChallenge(response.challenge);
        } else {
          if (response.show_password) {
            setShowInputPassword(true);
          } else {
            setInvalidError(response.message);
            setShowInvalid(true);
          }
        }
        setFetchingData(false);
      },
      () => {}
    )
  }, []);

  const additionalContent = () => {
    if (!logged_in) { // show this if brand is viewing it
      return (
        <div style={{ display: 'flex', marginLeft: "12px" }}>
          <BasicButton
            text="Go to Landing Page"
            type="primary"
            style={{ height: "42px" }}
            onClick={() => {
              window.open(challenge.share_url, "_blank");
            }}
          />
          <div
            className={styles.logout_button}
            onClick={() => {
              logoutUser(() => {
                window.location.reload();
              });
            }}
          >
            Logout
          </div>
        </div>
      );
    } else {
      return (
        <div style={{ display: 'flex', marginLeft: "12px" }}>
          <BasicButton
            text="Go to Landing Page"
            type="primary"
            style={{ height: "42px" }}
            onClick={() => {
              window.open(challenge.share_url, "_blank");
            }}
          />
        </div>
      );
    }
  }

  const content = () => {
    if (challenge) {
      return (
        <FixedHeaderContainer
          text={challenge.basic_info.title}
          onBackPressed={() => console.log("back pressed")}
          fixedContent={null}
          paddingBuffer="80px"
          paddingTop="50px"
          useMuseo
          hideMobileAdditional
          additionalContent={additionalContent()}
          hideBack
          titleSize="30px"
        >
          <div className={styles.stats_container}>
            <LiveStats
              challenge={challenge}
            />

            <div className={styles.bottom_logout}>
              {additionalContent()}
            </div>
          </div>
        </FixedHeaderContainer>
      )
    } else if (showInputPassword)  {
      return (
        <BrandLogin
          loading={loading}
          error={error}
          handleSubmit={(password) => {
            setLoading(true);
            submitBrandLogin(
              match?.params?.challenge_id,
              match?.params?.live_stats_hash,
              password,
              (response) => {
                if (response.success) {
                  setChallenge(response.challenge);
                  showInputPassword(false);
                } else {
                  setError(response.message);
                }
                setLoading(false);
              },
              () => { setLoading(false) }
            )
          }}
        />
      )
    } else {
      return (
        <div style={{ marginTop: "100px" }}>
          {invalidError}
        </div>
      )
    }
  }

  if (logged_in) {
    return (
      <LoadingWrapper isLoading={fetchingData}>
        <div className={styles.container}>
          {content()}
        </div>
      </LoadingWrapper>
    );
  } else {
    return (
      <LoadingWrapper isLoading={fetchingData}>
        <div className={styles.router_wrapper}>
          <div className={styles.router_desktop_padder}> </div>
          {challenge && <NavBar />}
          <div className={styles.router_main_area}>
            <div className={styles.container}>
              {content()}
            </div>
          </div>
          <div className={styles.router_desktop_padder} style={{ background: "#fff" }}> </div>
        </div>

      </LoadingWrapper>
    );
  }
};

const mapStatesToProps = (state) => {
  const { logged_in } = state.auth;
  return { logged_in };
};


export default connect(mapStatesToProps, {
  getLiveStatsPage,
  submitBrandLogin,
  logoutUser
})(withTheme(BrandViewStats));
