import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, Form } from 'antd';
import { withTheme } from 'styled-components';
import PageWrapper from '@components/PageWrapper';
import LoadingWrapper from '@components/LoadingWrapper';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { EditFilled } from '@ant-design/icons';
import { Table, Icon, Spin } from 'antd';
import florrette from '@images/prizeFloretteSolid_shadow.png';
import { TitleText, BasicText, ShortInput, LongInput, CopyUrl, BasicButton, DropDown, ConfirmModal } from '@components';
import styles from '@components/HeaderAndTable.module.css';
import { showSuccessMessage, showErrorMessage, CHALLENGE_TYPES } from '@global';
import { getPrizesData, fulfillPrizesForChallenge, fulfillIndividualPrize } from '@actions';

const FulfillChallengePrizes = ({
  prizeData,
  match,
  fulfillPrizesForChallenge,
  fulfillIndividualPrize,
  theme
}) => {

  const history = useHistory();


  const getPrizeTableData = () => {
    if (prizeData == null) return [];
    let dataArray = [];
    prizeData.forEach((prize, i) => {
      dataArray.push({
        id: prize.id,
        final_rank: prize.final_rank,
        title: prize.title,
        place_string: prize.place_string,
        date: prize.date_string,
        challenge: prize.challenge.title,
        brand: prize.challenge.brand_name,
        userName: `${prize.user.first_name} ${prize.user.last_name}`,
        email: prize.user.email,
        date_string: prize.date_string
      })
    });
    console.log(dataArray);
    return dataArray;
  }

  const getColumns = () => {
    let columns = [
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Date</div>;
        },
        dataIndex: 'date_string',
        key: 'date_string',
        width: 40,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px" bold>{text}</BasicText>
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>User</div>;
        },
        dataIndex: 'userName',
        key: 'userName',
        width: 100,
        render(text, record) {
          return {
            children: <div style={{ display: "flex", alignItems: 'center', maxWidth: 150, overflow: 'hidden' }}>
                        <BasicText fontSize="14px">{text}</BasicText>
                      </div>
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Rank</div>;
        },
        dataIndex: 'place_string',
        key: 'place_string',
        width: 40,
        render(text, record) {
          return {
            children: <BasicText fontSize="14px">{text}</BasicText>,
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Prize</div>;
        },
        dataIndex: 'title',
        key: 'title',
        width: 100,
        render(text, record) {
          return {
            children: <BasicText fontSize="14px">{text}</BasicText>,
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Challenge</div>;
        },
        dataIndex: 'challenge',
        key: 'challenge',
        width: 80,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px">{text}</BasicText>,
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Email</div>;
        },
        dataIndex: 'email',
        key: 'email',
        width: 80,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px">{text}</BasicText>,
          };
        }
      },
    ];

    return columns;
  }

  return (
    <div
      className={styles.table_container_style}
      style={{ marginTop: "40px" }}
    >
      <Table
        pagination={false}
        columns={getColumns()}
        dataSource={getPrizeTableData()}
        rowKey="id"
        rowClassName={styles.unclickable_table}
      />
    </div>
  )
}

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getPrizesData,
  fulfillPrizesForChallenge,
  fulfillIndividualPrize
})(withTheme(FulfillChallengePrizes));
