import React from 'react';
import { Input } from 'antd';
import { BasicText } from '@components';
import './inputs.css';

const StandardInput = (props) => {

  return (
    <input
      className="standard_input"
      placeholder={props.placeholder}
      type={props.type ? props.type : "text"}
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}
      style={{ fontSize: "16px", borderWidth: "0px" }}
      disabled={props.disabled}
    />
  )
}

export default StandardInput;
