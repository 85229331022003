import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Icon, Spin } from 'antd';
import { TitleText, BasicText, ShortInput, LongInput, CopyUrl, BasicButton } from '@components';
import styles from '@components/HeaderAndTable.module.css';
import { getHostChallenges } from '@actions';

const HostChallenges = ({
  brand,
  getHostChallenges
}) => {

  const history = useHistory();
  const [challenges, setChallenges] = useState([]);

  useEffect(() => {
    getHostChallenges(
      brand.id,
      (ch) => setChallenges(ch),
      () => {}
    )
  }, []);

  const getTeamTableData = () => {
    let dataArray = [];
    challenges.forEach((challenge, i) => {
      dataArray.push({
        id: challenge.id,
        title: challenge.title,
        visibility: challenge.is_private ? "Private" : "Public",
        status: challenge.challenge_state,
        was_published: challenge.was_published
      })
    });
    return dataArray;
  }

  const getColumns = () => {
    let columns = [
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Name</div>;
        },
        dataIndex: 'title',
        key: 'title',
        width: 120,
        render(text, record) {
          return {
            children: <div style={{ display: "flex", alignItems: 'center', maxWidth: 150, overflow: 'hidden' }}>
                        <BasicText fontSize="15px" bold>{text}</BasicText>
                      </div>
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Visibility</div>;
        },
        dataIndex: 'visibility',
        key: 'visibility',
        width: 120,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px">{text}</BasicText>
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Status</div>;
        },
        dataIndex: 'status',
        key: 'status',
        width: 120,
        render(text, record) {
          return {
            children: <BasicText fontSize="12px">{text}</BasicText>,
          };
        }
      }
    ];

    return columns;
  }

  return (
    <div
      className={styles.table_container_style}
      style={{ marginTop: "160px" }}
    >
      <Table
        pagination={false}
        columns={getColumns()}
        dataSource={getTeamTableData()}
        rowKey="id"
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              if (record.was_published) {
                history.push({
                  pathname: `/challenge_stats/${record.id}`,
                });
              } else {
                history.push({
                  pathname: `/challenges/${record.id}`,
                });
              }
            }
          };
        }}
      />
    </div>
  )
}

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getHostChallenges
})(HostChallenges);
