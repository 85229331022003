import React from "react"
import PropTypes from "prop-types"
import App from '../application/App.js'

class ApplicationContainer extends React.Component {
  render () {
    return (
      <React.Fragment>
        <App />
      </React.Fragment>
    );
  }
}

export default ApplicationContainer;
