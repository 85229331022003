import React from 'react';
import { Button } from 'antd';
import { BasicText } from '@components';
import { withTheme } from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';
import ClipLoader from 'react-spinners/ClipLoader';

const RejectButton = ({ onClick, text, theme, loading }) => {
  return (
    <div
      style={{
        cursor: 'pointer',
        width: '100%',
        height: '44px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: '16px',
        paddingRight: '16px',
        backgroundColor: theme.colors.danger,
        borderRadius: '8px',
        color: theme.colors.snow,
      }}
      onClick={() => onClick()}
    >
      {loading ? (
        <ClipLoader size={22} color={'#fff'} />
      ) : (
        <CloseOutlined style={{ color: theme.colors.snow, fontSize: '20px' }} />
      )}

      <div style={{ marginLeft: '12px', fontSize: '16px', fontWeight: 700 }}>
        {text}
      </div>
    </div>
  );
};

export default withTheme(RejectButton);
