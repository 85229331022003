import {
  UPDATE_TEAM_LIST,
  FETCHING_MORE_TEAM
} from '@actions/types';
const TEAM_TAKE_COUNT = 20;

// using snake case to be consistent with rails server
const getInitialState = () => {
  return {
    teamList: [],
    seenIds: [],
    isMore: true,
    hasFetchedTeam: false,
    fetchingMore: false
  };
};

const INITIAL_STATE = getInitialState();

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_TEAM_LIST: {
      const newMembers = action.payload.members;
      const isRefresh = action.payload.isRefresh;
      return {
        ...state,
        teamList: isRefresh ? newMembers : state.teamList.concat(newMembers),
        seenIds: isRefresh ? newMembers.map((m) => m.id) : state.seenIds.concat(newMembers.map((m) => m.id)),
        isMore: newMembers.length == TEAM_TAKE_COUNT,
        hasFetchedTeam: true,
        fetchingMore: false
      };
    }
    case FETCHING_MORE_TEAM:
      return { ...state, fetchingMore: true };
    default:
      return state;
  }
};
