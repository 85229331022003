import { getAPIRequest, postAPIRequest } from '@actions';
import { showSuccessMessage, showErrorMessage } from '@global';

export const getBundleOfferings = (onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      getAPIRequest(
        '/admin/get_bundle_offerings',
        {},
        (response) => {
          onSuccess(response.bundles);
        },
        (error) => {
          onFail();
        }
      )
    );
  };
};

export const createBundle = (bundle_info, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/create_bundle',
        {
          ...bundle_info,
        },
        (response) => {
          if (response.success) {
            showSuccessMessage('Successfully created bundle!');
            onSuccess(response.bundles);
          } else {
            showErrorMessage(response.reason);
            onFail();
          }
        },
        (error) => {
          showErrorMessage('Something went wrong.');
          onFail();
        }
      )
    );
  };
};

export const editBundleName = (
  bundled_offering_id,
  display_name,
  onSuccess,
  onFail
) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/edit_bundle_name',
        {
          bundled_offering_id,
          display_name,
        },
        (response) => {
          if (response.success) {
            showSuccessMessage('Successfully changed name!');
            onSuccess(response.bundles);
          } else {
            showErrorMessage(response.reason);
            onFail();
          }
        },
        (error) => {
          showErrorMessage('Something went wrong.');
          onFail();
        }
      )
    );
  };
};

export const showBundle = (bundled_offering_id, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/show_bundle',
        {
          bundled_offering_id,
        },
        (response) => {
          if (response.success) {
            showSuccessMessage('Successfully showed bundle!');
            onSuccess(response.bundles);
          } else {
            showErrorMessage(response.reason);
            onFail(response.bundles);
          }
        },
        (error) => {
          showErrorMessage('Something went wrong.');
          onFail();
        }
      )
    );
  };
};

export const hideBundle = (bundled_offering_id, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/hide_bundle',
        {
          bundled_offering_id,
        },
        (response) => {
          if (response.success) {
            showSuccessMessage('Successfully hid bundle!');
            onSuccess(response.bundles);
          } else {
            showErrorMessage(response.reason);
            onFail();
          }
        },
        (error) => {
          showErrorMessage('Something went wrong.');
          onFail();
        }
      )
    );
  };
};

export const getPaymentSettings = (onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      getAPIRequest(
        '/admin/get_payment_settings',
        {},
        (response) => {
          onSuccess(response.payment_settings);
        },
        (error) => {
          onFail();
        }
      )
    );
  };
};

export const updatePaymentSettings = (type, rate, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/update_payment_settings',
        {
          type,
          rate,
        },
        (response) => {
          onSuccess(response.payment_settings);
        },
        (error) => {
          showErrorMessage('Something went wrong.');
          onFail();
        }
      )
    );
  };
};

export const toggleShouldCharge = (should_charge, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/toggle_charging',
        {
          should_charge,
        },
        (response) => {
          onSuccess(response.payment_settings);
        },
        (error) => {
          showErrorMessage('Something went wrong.');
          onFail();
        }
      )
    );
  };
};

export const getTemplates = (onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      getAPIRequest(
        '/admin/hyype_groups/templates',
        {},
        (response) => {
          onSuccess(response.templates);
        },
        (error) => {
          onFail();
        }
      )
    );
  };
};

export const createTemplate = (templateInfo, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/hyype_groups/template/create',
        { ...templateInfo },
        (response) => {
          if (response.success) {
            showSuccessMessage('Successfully created template!');
            onSuccess(response.templates);
          } else {
            showErrorMessage(response.reason);
            onFail();
          }
        },
        (error) => {
          showErrorMessage('Something went wrong.');
          onFail();
        }
      )
    );
  };
};

export const editTemplate = (templateInfo, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/hyype_groups/template/edit',
        { ...templateInfo },
        (response) => {
          if (response.success) {
            showSuccessMessage('Successfully edited template!');
            onSuccess(response.templates);
          } else {
            showErrorMessage(response.reason);
            onFail();
          }
        },
        (error) => {
          showErrorMessage('Something went wrong.');
          onFail();
        }
      )
    );
  };
};

export const setTemplateOrder = (templateIds, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/hyype_groups/set_template_order',
        { template_ids: templateIds },
        (response) => {
          if (response.success) {
            showSuccessMessage('Successfully changed template order!');
            onSuccess(response.templates);
          } else {
            showErrorMessage(response.reason);
            onFail();
          }
        },
        (error) => {
          showErrorMessage('Something went wrong.');
          onFail();
        }
      )
    );
  };
};

export const toggleImmersiveFeed = (show_immersive, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/toggle_immersive_feed',
        {
          show_immersive,
        },
        (response) => {
          if (response.success) {
            showSuccessMessage('Successfully created bundle!');
            onSuccess(response.bundles);
          } else {
            showErrorMessage(response.reason);
            onFail();
          }
        },
        (error) => {
          showErrorMessage('Something went wrong.');
          onFail();
        }
      )
    );
  };
};

export const getPromos = (onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      getAPIRequest(
        '/admin/get_promotion_rules',
        {},
        (response) => {
          onSuccess(response.promotions);
        },
        (error) => {
          onFail();
        }
      )
    );
  };
};

export const createPromoRule = (data, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/create_promotion_rule',
        data,
        (response) => {
          if (response.success) {
            showSuccessMessage('Successfully created Promotion Rule!');
            onSuccess(response.promotions);
          } else {
            showErrorMessage(response.reason);
            onFail();
          }
        },
        (error) => {
          showErrorMessage('Something went wrong.');
          onFail();
        }
      )
    );
  };
};

export const editPromoRule = (data, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/update_promotion_rule',
        data,
        (response) => {
          if (response.success) {
            showSuccessMessage('Successfully updated Promotion Rule!');
            onSuccess(response.promotions);
          } else {
            showErrorMessage(response.reason);
            onFail();
          }
        },
        (error) => {
          showErrorMessage('Something went wrong.');
          onFail();
        }
      )
    );
  };
};

export const deletePromoRule = (data, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/delete_promotion_rule',
        data,
        (response) => {
          if (response.success) {
            showSuccessMessage('Successfully deleted Promotion Rule!');
            onSuccess(response.promotions);
          } else {
            showErrorMessage(response.reason);
            onFail();
          }
        },
        (error) => {
          showErrorMessage('Something went wrong.');
          onFail();
        }
      )
    );
  };
};
