import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Form } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, BasicButton, ShortInput, SaveOrDiscardModal, LongInput } from '@components';
import styles from './Create.module.css';
import { validURL } from '@global';

const PromoInfoModal = ({
  updateChallenge,
  visible,
  onCancel,
  onDone,
  promo_info
}) => {

  const [title, setTitle] = useState(promo_info.title);
  const [description, setDescription] = useState(promo_info.description);
  const [link, setLink] = useState(promo_info.promotion_link);
  const [code, setCode] = useState(promo_info.coupon_code);

  const shouldEnableDone = () => {
    return (
      title && title.length > 0 &&
      description && description.length > 0 &&
      link && validURL(link)
    )
  }

  return (
    <Modal
      title="Promotion Details"
      visible={visible}
      footer={null}
      onCancel={() => onCancel()}
      className={styles.promo_modal_style}
    >
      <Form.Item>
        <ShortInput
          inputHeader="Enter Promo Title"
          placeholder="20% off Dope Kicks"
          onChange={(val) => setTitle(val)}
          value={title}
        />
      </Form.Item>

      <Form.Item>
        <LongInput
          inputHeader="Promo description (Recommend < 100 words)"
          onChange={(val) => setDescription(val)}
          value={description}
          minRows={3}
          maxRows={3}
        />
      </Form.Item>

      <Form.Item>
        <ShortInput
          inputHeader="Promotion Link"
          placeholder="https://xyz.com/dope_kicks"
          onChange={(val) => setLink(val)}
          value={link}
        />
      </Form.Item>

      <Form.Item>
        <ShortInput
          inputHeader="Coupon Code (Optional)"
          placeholder="XYZ123"
          onChange={(val) => setCode(val)}
          value={code}
        />
        <div className={styles.coupon_footer}>
          {"* Leave blank if you wish not to show code and/or it's embedded in the link"}
        </div>
      </Form.Item>

      <div className={styles.promo_footer_container}>
        <BasicButton
          type="default"
          onClick={() => onCancel()}
          text="Cancel"
        />

        <BasicButton
          type="primary"
          disabled={!shouldEnableDone()}
          onClick={() => {
            updateChallenge({
              promo_info: {
                ...promo_info,
                title: title,
                description: description,
                promotion_link: link,
                coupon_code: code
            }});
            setTitle(null)
            setDescription(null);
            setLink(null);
            setCode(null);
            onDone();
          }}
          text={"Done"}
        />
      </div>

    </Modal>
  );
};

export default connect(null, {

})(PromoInfoModal);
