import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Form } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, BasicButton, ShortInput, SaveOrDiscardModal, LongInput } from '@components';
import styles from './Create.module.css';
import { validURL } from '@global';

const PromoDisplay = ({
  promo_info,
  onDelete,
  onEdit
}) => {

  return (
    <div className={styles.promo_display_container}>
      <div className={styles.promo_section_title}>
        TITLE
      </div>
      <div className={styles.promo_section_text}>
          {promo_info.title}
      </div>

      <div className={styles.promo_section_title}>
        DESCRIPTION
      </div>
      <div className={styles.promo_section_text}>
          {promo_info.description}
      </div>

      <div className={styles.promo_link_and_code}>
        <div style={{ maxWidth: "600px", marginRight: "40px" }}>
          <div className={styles.promo_section_title}>
            PROMOTION LINK
          </div>
          <div className={styles.promo_section_link}>
              {promo_info.promotion_link}
          </div>
        </div>

        <div style={{ width: "200px" }}>
          <div className={styles.promo_section_title}>
            PROMOTION CODE
          </div>
          <div className={styles.promo_section_link}>
              {promo_info.coupon_code != null ? promo_info.coupon_code : "N/A"}
          </div>
        </div>

      </div>

      <div className={styles.promo_edit_buttons_container}>
        <BasicButton
          text="Edit"
          type="primary"
          style={{ marginRight: "20px", width: "120px" }}
          onClick={() => onEdit()}
        />

        <BasicButton
          text="Remove"
          type="danger"
          style={{ width: "120px" }}
          onClick={() => onDelete()}
        />
      </div>
    </div>
  );
};

export default connect(null, {

})(PromoDisplay);
