import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Space, Modal } from 'antd';
import { withTheme } from 'styled-components';
import {
  CheckSquareOutlined,
  InfoOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import PageWrapper from '@components/PageWrapper';
import {
  BasicButton,
  RejectButton,
  ApproveButton,
  BasicText,
} from '@components';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import RejectedReasonsModal from '@review/RejectedReasonsModal';
import ReviewStats from '@review/ReviewStats';
import MemberSection from '@review/hosts/MemberSection';
import ContactSection from '@review/hosts/ContactSection';
import StripeSection from '@review/hosts/StripeSection';
import OrganizationSection from '@review/hosts/OrganizationSection';
import { validateEmail, showSuccessMessage, showErrorMessage } from '@global';
import { getBrandToReview, approveBrand, rejectBrand } from '@actions';
import styles from './ReviewHosts.module.css';

const DEFAULT_JSON = {
  Organization: { decided_on: false },
  Contact: { decided_on: false },
  Profile: { decided_on: false },
};

const INDIVIDUAL_DEFAULT_JSON = {
  Organization: { approved: true },
  Contact: { decided_on: false },
  Profile: { decided_on: false },
};

const ReviewHosts = ({
  getBrandToReview,
  approveBrand,
  rejectBrand,
  theme,
  adminUser,
  match,
}) => {
  const history = useHistory();
  const [showRejectedReasons, setShowRejectedReasons] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [brandId, setBrandId] = useState(match.params?.brand_id);
  const [host, setHost] = useState(null);
  const [noneLeft, setNoneLeft] = useState(false);
  const [enableApprove, setEnableApprove] = useState(false);
  const [brandReviewStats, setBrandReviewStats] = useState(null);
  const [rejectModal, setRejectModal] = useState(null);
  const scrollRef = useRef();

  const [reviewJson, setReviewJson] = useState(DEFAULT_JSON);

  useEffect(() => {
    getNextBrand();
  }, []);

  //if (host) console.log("is charity", host.brand.is_charity);

  const updatePathWithNewBrandId = (brand_id = null) => {
    let pathname = '/review_hosts';
    if (brand_id == null) {
      history.replace({ pathname: pathname });
    } else {
      history.replace({ pathname: `${pathname}/${brand_id}` });
    }
  };

  const handleNextBrand = (response) => {
    if (response.none_left) {
      setBrandReviewStats(response.brand_review_stats);
      setBrandId(null);
      setHost(null);
      updatePathWithNewBrandId(null);
      setNoneLeft(true);
    } else {
      setReviewJson(
        response.host.host_type === 'INDIVIDUAL'
          ? INDIVIDUAL_DEFAULT_JSON
          : DEFAULT_JSON
      );
      setBrandReviewStats(response.brand_review_stats);
      setBrandId(response.host.brand.id);
      updatePathWithNewBrandId(response.host.brand.id);
      setHost(response.host);
    }
  };

  const getNextBrand = () => {
    getBrandToReview(
      brandId,
      (response) => {
        handleNextBrand(response);
      },
      () => {}
    );
  };

  const approve = () => {
    //scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });

    setApproveLoading(true);
    approveBrand(
      brandId,
      (response) => {
        setApproveLoading(false);
        showSuccessMessage('Brand approved!');
        handleNextBrand(response);
        window.location.reload();
      },
      () => {
        setRejectLoading(false);
      }
    );
  };

  const reject = () => {
    // scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });

    if (
      reviewJson['Profile'].decided_on &&
      !reviewJson['Profile'].approved &&
      !reviewJson['Profile'].reason
    ) {
      setRejectModal('Please provide feedback for why the Brand was rejected.');
      return;
    }
    if (
      reviewJson['Contact'].decided_on &&
      !reviewJson['Contact'].approved &&
      !reviewJson['Contact'].reason
    ) {
      setRejectModal(
        'Please provide feedback for why the Contact was rejected.'
      );
      return;
    }
    if (
      reviewJson['Organization'].decided_on &&
      !reviewJson['Organization'].approved &&
      !reviewJson['Organization'].reason
    ) {
      setRejectModal(
        'Please provide feedback for why the Organization Info was rejected.'
      );
      return;
    }

    setRejectLoading(true);
    rejectBrand(
      brandId,
      reviewJson,
      (response) => {
        setRejectLoading(false);
        showSuccessMessage('Brand not approved');
        handleNextBrand(response);
        window.location.reload();
      },
      () => {
        setRejectLoading(false);
      }
    );
  };

  const content = () => {
    if (noneLeft) {
      return (
        <div className={styles.none_left_container}>
          <div style={{ marginRight: '40px' }}>
            <CheckSquareOutlined
              style={{ fontSize: '100px', color: theme.colors.blurple }}
            />
          </div>
          All hosts have been reviewed or are in the process of being reviewed
          by another reviewer.
        </div>
      );
    } else if (host) {
      return (
        <div className={styles.challenge_and_history}>
          <div className={styles.challenge_info}>
            <div style={{ marginTop: '20px' }}>
              <BasicText fontSize="13px">
                Submitted on: {host.submitted_on} ET
              </BasicText>
              <BasicText useSpan fontSize="13px">
                {'STATUS: '}
                <BasicText bold useSpan fontSize="13px">
                  {host.brand.review_state}
                </BasicText>
              </BasicText>
            </div>

            <ContactSection
              host={host}
              reviewJson={reviewJson}
              updateReviewJson={(update) =>
                setReviewJson({ ...reviewJson, ...update })
              }
            />

            <div className={styles.separator} />

            <MemberSection
              host={host}
              reviewJson={reviewJson}
              updateReviewJson={(update) =>
                setReviewJson({ ...reviewJson, ...update })
              }
            />

            <div className={styles.separator} />

            {host.host_type !== 'INDIVIDUAL' && (
              <OrganizationSection
                host={host}
                reviewJson={reviewJson}
                updateReviewJson={(update) =>
                  setReviewJson({ ...reviewJson, ...update })
                }
              />
            )}
          </div>
        </div>
      );
    }
  };

  const progressStats = () => {
    if (brandReviewStats) {
      return (
        <div className={styles.review_stats_container}>
          <ReviewStats
            stats={[
              {
                title: 'Pending',
                value: brandReviewStats.pending,
                showDanger: true,
              },
              {
                title: 'Approved',
                value: brandReviewStats.approved,
                showDanger: false,
              },
              {
                title: 'Rejected',
                value: brandReviewStats.rejected,
                showDanger: false,
              },
            ]}
          />
        </div>
      );
    }
  };

  const responseButton = () => {
    if (
      (reviewJson['Organization'].decided_on &&
        !reviewJson['Organization'].approved) ||
      (reviewJson['Contact'].decided_on && !reviewJson['Contact'].approved) ||
      (reviewJson['Profile'].decided_on && !reviewJson['Profile'].approved)
    ) {
      return (
        <RejectButton
          onClick={() => reject()}
          text="Reject"
          loading={rejectLoading}
        />
      );
    }

    if (
      reviewJson['Organization'].approved &&
      reviewJson['Contact'].approved &&
      reviewJson['Profile'].approved
    ) {
      return (
        <ApproveButton
          onClick={() => approve()}
          text="Approve"
          loading={approveLoading}
        />
      );
    }

    return (
      <ApproveButton
        disabled
        onClick={() => {}}
        text="Approve"
        loading={approveLoading}
      />
    );
  };

  const additionalContent = () => {
    if (noneLeft) return null;

    return (
      <Space size={20} className={styles.additional_header_content}>
        {responseButton()}

        <div
          className={styles.guidelines_button_container}
          onClick={() => window.open('https://hyype.space/community', '_blank')}
        >
          <div className={styles.guidelines_circle}>
            <InfoOutlined
              style={{
                fontSize: '16px',
                color: theme.colors.snow,
                fontWeight: 700,
              }}
            />
          </div>
        </div>
      </Space>
    );
  };

  return (
    <PageWrapper>
      <FixedHeaderContainer
        text={host?.brand?.name ? host.brand.name : 'Review Hosts'}
        paddingBuffer="80px"
        paddingTop="56px"
        useMuseo
        additionalContent={additionalContent()}
      >
        <div className={styles.review_challenges_container} ref={scrollRef}>
          {progressStats()}
          {content()}
        </div>
      </FixedHeaderContainer>

      <Modal
        title={'Complete Rejection Feedback'}
        visible={rejectModal}
        onCancel={() => setRejectModal(null)}
        footer={[
          <Button
            key="save"
            type="primary"
            onClick={() => setRejectModal(null)}
          >
            OK
          </Button>,
        ]}
      >
        <BasicText>{rejectModal}</BasicText>
      </Modal>
    </PageWrapper>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getBrandToReview,
  approveBrand,
  rejectBrand,
})(withTheme(ReviewHosts));
