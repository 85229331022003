import axios from 'axios';
import { setLoggedIn } from '@actions';

export const getAPIRequest = (path, params={}, onSuccess, onFail) => {
  return (dispatch) => {
    axios.get(path, { withCredentials: true, params: params })
    .then(response => response.data)
    .then(json => {
      if (onSuccess) {
        onSuccess(json);
      }
    })
    .catch(error => {
      const e = processError(error);
      // failed to authenticate
      if (e.status === 401) {
        dispatch(setLoggedIn(false));
      }
      if (onFail) {
        onFail(processError(error));
      }
    });
  }
};

export const postAPIRequest = (path, data, onSuccess, onFail) => {
  return (dispatch) => {
    axios.post(path, { withCredentials: true, ...data })
    .then(response => response.data)
    .then(json => {
      if (onSuccess) {
        onSuccess(json);
      }
    })
    .catch(error => {
      //console.log(error.response);
      const e = processError(error);
      // failed to authenticate
      if (e.status === 401) {
        dispatch(setLoggedIn(false));
      }
      if (onFail) {
        onFail(processError(error));
      }
    });
  }
};

const processError = error => {
  console.log(error);
  if (error.response) {
    if (error.response.data && error.response.data.reason) {
      return { status: error.response.status, error: error.response.data.reason };
    }
    if (error.response.status === 401) {
      return { status: error.response.status, error: 'Incorrect email or password' };
    }
    if (error.response.status === 404) {
      return { status: error.response.status, error: 'Something went wrong' };
    }
    if (error.response.status >= 500) {
      return { status: error.response.status, error: 'Something went wrong' };
    }
    return { status: error.response.status, error: error.response.data.error };
  } else {
    return { status: 400, error: 'Something went wrong' };
  }
};
