import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Space } from 'antd';
import { withTheme } from 'styled-components';
import { CheckSquareOutlined, InfoOutlined, CloseOutlined } from '@ant-design/icons';
import PageWrapper from '@components/PageWrapper';
import { BasicButton, RejectButton, ApproveButton, DeleteButton, ConfirmModal, BasicText } from '@components';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import RejectedReasonsModal from '@review/RejectedReasonsModal';
import ReviewStats from '@review/ReviewStats';
import BasicSection from '@review/challenges/BasicSection';
import MediaSection from '@review/challenges/MediaSection';
import ReviewReportHistory from '@review/ReviewReportHistory';
import { validateEmail, showSuccessMessage, showErrorMessage  } from '@global';
import  {
  getChallengeToReview,
  approveChallenge,
  rejectChallenge,
  handleChallengeReport,
  getReportedChallengeToReview,
  deleteChallenge
} from '@actions';
import styles from './ReviewChallenge.module.css';

const ReviewChallenge = ({
  getChallengeToReview,
  approveChallenge,
  rejectChallenge,
  theme,
  adminUser,
  handleChallengeReport,
  getReportedChallengeToReview,
  deleteChallenge,
  match
}) => {

  const history = useHistory();
  const [showRejectedReasons, setShowRejectedReasons] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [challengeId, setChallengeId] = useState(match.params?.challenge_id);
  const [challengeReviewStats, setChallengeReviewStats] = useState(null);
  const [challenge, setChallenge] = useState(null);
  const [noneLeft, setNoneLeft] = useState(false);
  const [enableApprove, setEnableApprove] = useState(false);

  const [approvedBasic, setApprovedBasic] = useState(false);
  const [approvedMedia, setApprovedMedia] = useState(false);

  const isFromReview = history.location.pathname.includes("/review_mgc_challenge");
  const isReporting = history.location.pathname.includes("/reported_challenge");

  const [hasWatchedVideo, setHasWatchedVideo] = useState(false);
  const [showVideo, setShowVideo] = useState(false);

  const [showDeleteConfrim, setShowDeleteConfirm] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    getNextChallenge();
  }, []);

  useEffect(() => {
    if (challenge && challenge.is_approved) {
      setApprovedBasic(true);
      setApprovedMedia(true);
    }
  }, [challenge]);

  if (challenge) console.log(challenge.media_info.explainer_video_url);

  const updatePathWithNewChallengeId = (challenge_id=null) => {
    let pathname = isFromReview ? '/review_mgc_challenge' : '/mgc_history';
    if (isReporting) pathname = '/reported_challenge';
    if (challenge_id == null) {
      history.replace({ pathname: pathname })
    } else {
      history.replace({ pathname: `${pathname}/${challenge_id}` });
    }
  };

  const handleNextChallenge = (response) => {
    console.log(response);
    setApproveLoading(false);
    setRejectLoading(false);
    setApprovedBasic(false);
    setApprovedMedia(false);
    setHasWatchedVideo(false);
    if (response.none_left) {
      setChallengeId(null);
      updatePathWithNewChallengeId(null);
      setChallengeReviewStats(response.challenge_review_stats);
      setNoneLeft(true);
    } else {
      setChallengeId(response.challenge.challenge_id);
      updatePathWithNewChallengeId(response.challenge.challenge_id);
      setChallengeReviewStats(response.challenge_review_stats);
      setChallenge(response.challenge);
    }
  }

  const getNextChallenge = () => {
    if (isReporting) {
      getReportedChallengeToReview(
        challengeId,
        (response) => {
          handleNextChallenge(response);
        },
        () => {}
      )
    } else {
      getChallengeToReview(
        challengeId,
        (response) => {
          handleNextChallenge(response);
        },
        () => {}
      )
    }
  };

  const handleReportingResponse = (remove) => {
    handleChallengeReport(
      challengeId,
      remove,
      (response) => {
        showSuccessMessage("Your action was processed!");
        handleNextChallenge(response);
        setShowRejectedReasons(false);
      },
      () => {}
    )
  }


  const approve = () => {
    if (isReporting) {
      setApproveLoading(true);
      handleReportingResponse(false);
    } else {
      setApproveLoading(true);
      approveChallenge(
        challengeId,
        (response) => {
          showSuccessMessage("Challenge approved!");
          if (isFromReview) {
            handleNextChallenge(response);
          } else {
            window.location.reload();
          }
        },
        () => { setApproveLoading(false) }
      )
    }
  }

  const reject = (reason) => {
    if (isReporting) {
      setRejectLoading(true);
      handleReportingResponse(true);
    } else {
      setRejectLoading(true);
      rejectChallenge(
        challengeId,
        reason,
        (response) => {
          showSuccessMessage("Challenge not approved");
          if (isFromReview) {
            handleNextChallenge(response);
            setShowRejectedReasons(false);
          } else {
            window.location.reload();
          }
        },
        () => { setRejectLoading(false) }
      )
    }
  }

  const onDeleteConfirm = () => {
    setDeleteLoading(true);
    deleteChallenge(
      challenge.challenge_id,
      (response) => {
        setDeleteLoading(false);
        history.goBack();
        setTimeout(
          () => showSuccessMessage("Challenge successfully deleted.", 5),
          200
        );
      },
      () => {
        showErrorMessage("Something wend wrong.", 6);
        setDeleteLoading(false);
      }
    );
  };

  const content = () => {
    if (noneLeft) {
      return (
        <div className={styles.none_left_container}>
          <div style={{ marginRight: "40px" }}>
            <CheckSquareOutlined style={{ fontSize: "100px", color: theme.colors.blurple }}/>
          </div>
          All challenges have been reviewed or are in the process of being reviewed by another reviewer.
        </div>
      )
    } else if (challenge) {
      return (
        <div className={styles.challenge_and_history}>
          <div className={styles.challenge_info}>
            <div className={styles.status_text} style={{ color: challenge.challenge_state === "REJECTED" ? theme.colors.danger : "#000" }}>
              Status: {challenge.challenge_state}
            </div>

            {challenge.submission_time &&
              <div className={styles.submission_time} style={{ marginBottom: challenge.challenge_state === "REJECTED" ? "0px" : "20px" }}>
                Submitted {challenge.submission_time}
              </div>
            }

            {challenge.challenge_state === "REJECTED" &&
              <div className={styles.submission_time}>
                Member will need to re-submit with a drop date and challenge length before it can be reviewed.
              </div>
            }

            <BasicSection
              challenge={challenge}
              isApproved={approvedBasic}
              onApprove={() => setApprovedBasic(!approvedBasic)}
              showApprove={challenge.challenge_state === "IN_REVIEW"}
            />

            <MediaSection
              challenge={challenge}
              isApproved={approvedMedia}
              onApprove={() => setApprovedMedia(!approvedMedia)}
              showApprove={challenge.challenge_state === "IN_REVIEW"}
              setShowVideo={() => setShowVideo(true)}
              hasWatchedVideo={hasWatchedVideo}
            />
          </div>

          <ReviewReportHistory entry={challenge} />
        </div>
      );
    }
  }

  const additionalContent = () => {
    if (noneLeft) return null;

    let addContent = null;

    if (challenge && challenge.challenge_state === "IN_REVIEW") {
      addContent = (
        <>
          <RejectButton
            onClick={() => setShowRejectedReasons(true)}
            text="Reject"
          />

          <ApproveButton
            disabled={!approvedBasic || !approvedMedia}
            onClick={() => approve()}
            text="Approve"
            loading={approveLoading}
          />

          <div
            className={styles.guidelines_button_container}
            onClick={() => window.open("https://hyype.space/community", "_blank")}
          >
            <div className={styles.guidelines_circle}>
              <InfoOutlined style={{ fontSize: "16px", color: theme.colors.snow, fontWeight: 700 }}/>
            </div>
          </div>
        </>
      );
    } else if (isReporting) {
      addContent= (
        <>
          <RejectButton
            onClick={() => setShowRejectedReasons(true)}
            text="Remove"
          />

          <ApproveButton
            onClick={() => approve()}
            text="Keep Live"
            loading={approveLoading}
          />

          <div
            className={styles.guidelines_button_container}
            onClick={() => window.open("https://hyype.space/community", "_blank")}
          >
            <div className={styles.guidelines_circle}>
              <InfoOutlined style={{ fontSize: "16px", color: theme.colors.snow, fontWeight: 700 }}/>
            </div>
          </div>
        </>
      )
    }

    return (
      <Space size={20} className={styles.additional_header_content}>
        {addContent}

        {challenge && challenge.challenge_state !== "IN_REVIEW" &&
          <DeleteButton
            onClick={() => setShowDeleteConfirm(true)}
            style={{ height: "48px" }}
          />
        }
      </Space>
    )
  };

  const progressStats = () => {
    if (isReporting && challengeReviewStats) {
      return (
        <div className={styles.review_stats_container}>
          <ReviewStats
            stats={[
              { title: "Pending", value: challengeReviewStats.pending, showDanger: true },
              { title: "Kept Live", value: challengeReviewStats.kept_live, showDanger: false },
              { title: "Removed", value: challengeReviewStats.removed, showDanger: false },
            ]}
          />
        </div>
      );
    } else if (challengeReviewStats) {
      return (
        <div className={styles.review_stats_container}>
          <ReviewStats
            stats={[
              { title: "Pending", value: challengeReviewStats.pending, showDanger: true },
              { title: "Approved", value: challengeReviewStats.approved, showDanger: false },
              { title: "Rejected", value: challengeReviewStats.rejected, showDanger: false },
            ]}
          />
        </div>
      );
    }
  }

  return (
    <PageWrapper>
      <FixedHeaderContainer
        text={challenge != null ? challenge.basic_info.title : noneLeft ? isFromReview ? "Return to Review" : "Return to Reported" : ""}
        paddingBuffer="80px"
        paddingTop="56px"
        additionalContent={additionalContent()}
        useMuseo
      >
        <div className={styles.review_challenges_container}>
          {progressStats()}
          {content()}
        </div>
      </FixedHeaderContainer>

      {showRejectedReasons &&
        <RejectedReasonsModal
          reasons={adminUser.challenge_rejected_reasons}
          onSelectedReason={(reason) => reject(reason)}
          visible={showRejectedReasons}
          onCancel={() => setShowRejectedReasons(false)}
          rejectLoading={rejectLoading}
          isReport={isReporting}
        />
      }

      {challenge && showVideo &&
        <div className={styles.video_modal_conatiner}>
          <div className={styles.video_modal_background}>
            <div className={styles.modal_title_text}>
              {challenge.basic_info.title}
            </div>
            <div className={styles.video_container}>
              <div className={styles.video_and_controls}>
                <video
                  key={challenge.id}
                  className={styles.video_container}
                  onEnded={() => setHasWatchedVideo(true)}
                  controls
                  autoPlay
                >
                  <source src={challenge.media_info.explainer_video_url} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className={styles.video_modal_close_button} onClick={() => setShowVideo(false)}>
              <CloseOutlined style={{ fontSize: "20px", marginTop: "2px", marginLeft: "3px" }}/>
            </div>
          </div>
        </div>
      }

      {showDeleteConfrim && (
        <ConfirmModal
          title={"Confirm Delete"}
          visible={showDeleteConfrim}
          loading={deleteLoading}
          onCancel={() => setShowDeleteConfirm(false)}
          onSubmit={() => onDeleteConfirm()}
        >
          <BasicText marginBottom="20px">
            Are you sure you want to delete this challenge? This action can not
            be undone.
          </BasicText>
        </ConfirmModal>
      )}
    </PageWrapper>
  )
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getChallengeToReview,
  approveChallenge,
  rejectChallenge,
  handleChallengeReport,
  getReportedChallengeToReview,
  deleteChallenge
})(withTheme(ReviewChallenge));
