import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import styles from './UserGraph.module.css';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Bar, LabelList, BarChart } from 'recharts';
import { CustomTab, FilterTabs, TitleText, MuseoText } from '@components';
import { BarChartOutlined, LineChartOutlined, LoadingOutlined } from '@ant-design/icons';
import { getUserGraphData } from '@actions';


const GraphContainer = ({
  challenge,
  getUserGraphData,
  theme
}) => {

  const timeSpans = [
    "DAILY",
    "WEEKLY",
    "MONTHLY"
  ];

  const [selectedSpan, setSelectedSpan] = useState(timeSpans[0]);
  const [loadingGraph, setLoadingGraph] = useState(true);
  const [data, setData] = useState([]);
  const [showLineGraph, setShowLineGraph] = useState(true);
  let isFetchingGraph = false;

  // console.log(challenge);
  // console.log(types[selectedTypeIndex], selectedSpan);

  useEffect(() => {
    fetchGraph();
  }, [selectedSpan]);

  const fetchGraph = () => {
    setLoadingGraph(true);
    getUserGraphData(
      selectedSpan,
      (data) => {
        setData(data);
        setLoadingGraph(false);
      },
      () => {
        setLoadingGraph(false);
      }
    )
  };

  const getYAxisOffset = (data) => {
    const maxNum = Math.max(...data.map((d) => d.value));
    if (maxNum >= 100) {
      return maxNum + 15;
    } else {
      return maxNum + 5;
    }
  }

  const content = () => {
    if (loadingGraph) {
      return (
        <div className={styles.loading_graph_container}>
          <LoadingOutlined />
        </div>
      )
    } if (!showLineGraph) {
      return (
        <BarChart width={740} height={400} data={data}>
          <Tooltip />
          <XAxis dataKey="name" style={{ fontSize: "11px" }} />
          <YAxis style={{ fontFamily: "MuseoModerno-Bold", color: theme.colors.blurple }} domain={[0, getYAxisOffset(data)]} />
          <Bar dataKey="value" fill={theme.colors.blurple}>

          </Bar>
        </BarChart>
      )
    } else {
      return (
        <LineChart width={740} height={400} data={data}>
          <Tooltip />
          <Line type="monotone" dataKey="value" stroke={theme.colors.blurple} />
          <CartesianGrid stroke="#ccc" />
          <XAxis dataKey="name" style={{ fontSize: "11px" }} />
          <YAxis style={{ fontFamily: "MuseoModerno-Bold", color: theme.colors.blurple }} />
        </LineChart>
      )
    }
  }

  return (
    <div style={{ marginTop: "80px", marginBottom: "120px" }}>
      <MuseoText>Member Signup Graph</MuseoText>
      <div style={{ marginTop: "8px", marginBottom: "40px", maxWidth: "700px", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <FilterTabs
          options={timeSpans}
          selectedOption={selectedSpan}
          onSelectOption={(selectedOption) => {
            if (selectedOption !== selectedSpan) {
              setSelectedSpan(selectedOption)
            }
          }}
        />

        <div style={{ display: "flex", alignItems: "center", marginTop: "12px" }}>
          <div style={{ padding: 4, cursor: "pointer" }} onClick={() => setShowLineGraph(true)}>
            <LineChartOutlined style={{ color: showLineGraph ? theme.colors.blurple : "#eee", fontSize: "32px" }} />
          </div>

          <div style={{ padding: 4, cursor: "pointer", marginLeft: "8px" }}  onClick={() => setShowLineGraph(false)}>
            <BarChartOutlined style={{ color: showLineGraph ? "#eee" : theme.colors.blurple, fontSize: "32px" }} />
          </div>
        </div>
      </div>

      <div className={styles.inner_graph_container}>
        {content()}
      </div>
    </div>
  );
};

export default connect(null, {
  getUserGraphData
})(withTheme(GraphContainer));
