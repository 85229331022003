import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import { useHistory } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Tabs } from 'antd';
const { TabPane } = Tabs;
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { NAV_SECTIONS, CHALLENGE_TABLE_FILTERS } from '@global';
import styles from '@entries/ExploreEntries.module.css';
import LoadingWrapper from '@components/LoadingWrapper';
import {
  MuseoText,
  BasicButton,
  BottomTableLoader,
  SearchBar,
  FilterTabs,
} from '@components';
import { Input, Space } from 'antd';
import { getRecentEntries } from '@actions';
import EntryGrid from '@entries/EntryGrid';
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor';

const { Search } = Input;

let _searchTimeout = null;

const ExploreEntries = ({
  adminUser,
  recentEntries,
  popularEntries,
  rejectedEntries,
  theme,
}) => {
  const history = useHistory();
  const isFetchingMoreFromBottom = useRef(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const tabSelectorOptions = () => {
    return [
      'RECENT ENTRIES',
      'POPULAR ENTRIES',
      'REJECTED ENTRIES',
    ].map((stat, index) => <TabPane key={index} value={index} tab={stat} />);
  };

  const onSelectedOption = (option) => {
    setSelectedTab(option);
  };

  //console.log("selcted tab", selectedTab);

  const header = () => {
    return (
      <div className={styles.fixed_header}>
        <div className={styles.header_container}>
          <div className={styles.header_text}>
            <MuseoText>Explore Entries</MuseoText>
          </div>
        </div>

        <div className={styles.radio_container}>
          <Tabs
            defaultActiveKey={
              selectedTab === 0 || selectedTab === '0' ? '0' : '1'
            }
            onChange={(e) => onSelectedOption(e)}
          >
            {tabSelectorOptions()}
          </Tabs>
        </div>
      </div>
    );
  };

  const entryGrid = () => {
    if (selectedTab === 0 || selectedTab === '0') {
      return <EntryGrid type={'RECENT'} />;
    } else if (selectedTab === 1 || selectedTab === '1') {
      return <EntryGrid type={'POPULAR'} />;
    } else {
      return <EntryGrid type={'REJECTED'} />;
    }
  };

  return (
    <PageWrapper>
      <FixedHeaderContainer hideHeader additionalContent={header()}>
        <div className={styles.header_text}>
          <MuseoText>Explore Entries</MuseoText>
        </div>
        <div className={styles.explorer_container}>{entryGrid()}</div>
      </FixedHeaderContainer>
    </PageWrapper>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  const { recentEntries, popularEntries, rejectedEntries } = state.entry;
  return { adminUser, recentEntries, popularEntries, rejectedEntries };
};

export default connect(mapStatesToProps, {})(withTheme(ExploreEntries));
