import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Icon, Spin } from 'antd';
import { TitleText, BasicText, ShortInput, LongInput, CopyUrl, BasicButton } from '@components';
import styles from '@components/HeaderAndTable.module.css';

const PurchaseHistoryTable = ({
  purchaseList,
  marginTop
}) => {

  const getPurchaseTableData = () => {
    let dataArray = [];
    purchaseList.forEach((purchase, i) => {
      dataArray.push({
        id: purchase.id,
        title: purchase.title,
        price: purchase.price,
        purchased_on: purchase.puchased_time,
        platform: purchase.platform,
        order_id: purchase.order_id
      })
    });
    return dataArray;
  }

  const getColumns = () => {
    let columns = [
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Bundle</div>;
        },
        dataIndex: 'title',
        key: 'title',
        width: 140,
        render(text, record) {
          return {
            children: <div style={{ display: "flex", alignItems: 'center', maxWidth: 180, overflow: 'hidden' }}>
                        <BasicText fontSize="14px" bold>{text}</BasicText>
                      </div>
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Price</div>;
        },
        dataIndex: 'price',
        key: 'price',
        width: 60,
        render(text, record) {
          return {
            children: <BasicText fontSize="14px">{text}</BasicText>
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Purchased On</div>;
        },
        dataIndex: 'purchased_on',
        key: 'purchased_on',
        width: 80,
        render(text, record) {
          return {
            children: <BasicText fontSize="12px">{text}</BasicText>,
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Platform</div>;
        },
        dataIndex: 'platform',
        key: 'platform',
        width: 60,
        render(text, record) {
          return {
            children: <BasicText fontSize="14px">{text}</BasicText>,
          };
        }
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Order Id</div>;
        },
        dataIndex: 'order_id',
        key: 'order_id',
        width: 120,
        render(text, record) {
          return {
            children: <BasicText fontSize="13px">{text}</BasicText>,
          };
        }
      }
    ];

    return columns;
  }

  return (
    <div
      className={styles.table_container_style}
      style={{ marginTop: "40px" }}
    >
      <Table
        pagination={false}
        columns={getColumns()}
        dataSource={getPurchaseTableData()}
        rowKey="id"
      />
    </div>
  )
}

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {

})(PurchaseHistoryTable);
