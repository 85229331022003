import {
  UPDATE_SESSION,
  SET_ADMIN_USER,
  SET_OVERALL_REVIEW_PENDING,
  SET_OVERALL_REPORT_PENDING,
  SET_OVERALL_FULFILL_PENDING
} from '@actions/types';
import {
  getAPIRequest,
  postAPIRequest
} from '@actions';

export const checkAuthorized = () => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/check_auth',
        {},
        response => {

          dispatch({ type: SET_ADMIN_USER, payload: response.user });
          dispatch(setLoggedIn(true));
          dispatch({ type: SET_OVERALL_REVIEW_PENDING, payload: response.user.pending_reviews });
          dispatch({ type: SET_OVERALL_REPORT_PENDING, payload: response.user.pending_reports });
          dispatch({ type: SET_OVERALL_FULFILL_PENDING, payload: response.user.pending_fulfills });
        }
      )
    );
  };
};

export const setLoggedIn = (isLoggedIn) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SESSION, payload: isLoggedIn });
  };
};

export const submitLogin = (data, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      postAPIRequest(
        '/admin/login',
        data,
        response => {
          // to do set props for home page
          dispatch({ type: SET_ADMIN_USER, payload: response.user });
          dispatch(setLoggedIn(true));
          dispatch({ type: SET_OVERALL_REVIEW_PENDING, payload: response.user.pending_reviews });
          dispatch({ type: SET_OVERALL_REPORT_PENDING, payload: response.user.pending_reports });
          dispatch({ type: SET_OVERALL_FULFILL_PENDING, payload: response.user.pending_fulfills });
          onSuccess();
        },
        error => {
          onFail(error.error);
        },
      ),
    );
  };
};

export const logoutUser = (onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      postAPIRequest(
        '/admin/logout',
        {},
        response => {
          dispatch({ type: UPDATE_SESSION, payload: false });
          onSuccess();
        },
        error => {
          onFail(error.error);
        },
      ),
    );
  };
};
