import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PageWrapper from "@components/PageWrapper";
import { useHistory } from "react-router-dom";
import { fetchTeamList } from "@actions";
import { withTheme } from "styled-components";
import { NAV_SECTIONS, CHALLENGE_TABLE_FILTERS } from "@global";
import HostTable from "@hosts/HostTable";
import styles from "@components/HeaderAndTable.module.css";
import LoadingWrapper from "@components/LoadingWrapper";
import CreateContainer from "@challenges/create/CreateContainer";
import FixedHeaderContainer from "@components/FixedHeaderContainer";
import {
  MuseoText,
  BasicButton,
  BottomTableLoader,
  SearchBar,
  FilterTabs,
} from "@components";
import { Input, Space } from "antd";
import { getAPIRequest, getHostList, setRefreshMemberList } from "@actions";
import useOnScreen from "@components/useOnScreen";
import VisibilitySensor from "react-visibility-sensor/visibility-sensor";

const { Search } = Input;

let _searchTimeout = null;

const HostHome = ({
  adminUser,
  getHostList,
  getAPIRequest,
  refreshMemberList,
  setRefreshMemberList,
  theme,
}) => {
  const [hosts, setHosts] = useState(null);
  const [seenIds, setSeenIds] = useState([]);
  const [isMore, setIsMore] = useState(true);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [hasFetchedHosts, setHasFetchedHosts] = useState(false);
  const [isFetchingHosts, setIsFetchingHosts] = useState(true);
  const history = useHistory();
  const isFetchingMoreFromBottom = useRef(false);

  const performSearch = () => {
    const currentSearchTerm = searchTerm;
    if (currentSearchTerm.length > 0) {
      getAPIRequest(
        "/admin/search_hosts",
        { query: currentSearchTerm },
        (response) => {
          if (currentSearchTerm === searchTerm) {
            setSearchResults(response.hosts);
          }
        },
        (error) => {
          //onFail(error.error);
        }
      );
    }
  };

  useEffect(() => {
    fetchHosts(true);
  }, []);

  // useEffect(() => {
  //   if (refreshMemberList) {
  //     fetchMembers(true);
  //     setRefreshMemberList(false);
  //   }
  // }, [refreshMemberList]);

  useEffect(() => {
    clearTimeout(_searchTimeout);
    if (searchTerm.length > 0) {
      _searchTimeout = setTimeout(() => performSearch(), 200);
    }
  }, [searchTerm]);

  const onBottomLoadingVisible = (isVisible) => {
    if (isVisible) {
      if (!isFetchingMoreFromBottom.current && isMore) fetchHosts();
    }
  };

  const fetchHosts = (isRefresh = false) => {
    if (!isRefresh && !isMore) return;
    if (!isRefresh && isFetchingMoreFromBottom.current) return;
    if (isRefresh) setHasFetchedHosts(false);

    isFetchingMoreFromBottom.current = true;
    getHostList(
      isRefresh ? [] : seenIds,
      (newHosts) => {
        if (isRefresh) {
          setSeenIds(newHosts.map((c) => c.id));
          setHosts(newHosts);
        } else {
          setSeenIds(seenIds.concat(newHosts.map((c) => c.id)));
          setHosts(hosts.concat(newHosts));
        }
        setIsMore(newHosts.length === 20);
        isFetchingMoreFromBottom.current = false;
        setHasFetchedHosts(true);
      },
      () => {}
    );
  };

  const header = () => {
    return (
      <div className={styles.fixed_header}>
        <div className={styles.header_container}>
          <div className={styles.header_text}>
            <MuseoText>Hosts</MuseoText>
          </div>
        </div>

        <SearchBar
          searchTerm={searchTerm}
          onChange={(value) => setSearchTerm(value)}
        />
      </div>
    );
  };


  return (
    <LoadingWrapper
      isLoading={!hasFetchedHosts}
      loadingText="Fetching Host Data"
      size="large"
    >
      <div style={{ marginTop: "220px" }}>
        <SearchBar
          searchTerm={searchTerm}
          onChange={(value) => setSearchTerm(value)}
        />

        <>
          <HostTable
            hostList={searchTerm.length > 0 ? searchResults : hosts}
            onHostSelcted={(host) => {
              if (host.is_approved) {
                history.push({
                  pathname: `/view_host/${host.id}`,
                });
              } else {
                history.push({
                  pathname: `/review_hosts/${host.id}`,
                });
              }

            }}
          />
          {isMore && searchTerm.length === 0 && (
            <VisibilitySensor onChange={onBottomLoadingVisible}>
              <BottomTableLoader />
            </VisibilitySensor>
          )}
        </>
      </div>
    </LoadingWrapper>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  const { refreshMemberList } = state.member;
  return { adminUser, refreshMemberList };
};

export default connect(mapStatesToProps, {
  getHostList,
  getAPIRequest,
  setRefreshMemberList,
})(withTheme(HostHome));
