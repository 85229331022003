import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Select, Radio } from 'antd';
import PageWrapper from '@components/PageWrapper';
import { Tabs } from 'antd';
const { TabPane } = Tabs;
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import LoadingWrapper from '@components/LoadingWrapper';
import { withTheme } from 'styled-components';
import { PlayCircleOutlined, PlayCircleTwoTone } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, MuseoText } from '@components';
import styles from './Fulfill.module.css';
import HostPayoutHome from '@fulfillment/hosts/HostPayoutHome';
import FulfillPrizeHome from '@fulfillment/prizes/FulfillPrizeHome';
import { updateFulfillSection } from '@actions';

const FulfillmentHome = ({
  adminUser,
  viewingFulfillHosts,
  updateFulfillSection,
  theme
}) => {
  const [timeStats, setTimeStats] = useState(null);
  const [selectedTab, setSelectedTab] = useState(viewingFulfillHosts ? 0 : 1);

  useEffect(() => {
    setSelectedTab(viewingFulfillHosts ? 0 : 1);
  }, [viewingFulfillHosts])

  const isAdmin = adminUser.role == "ADMIN";

  //console.log(selectedTab, viewingFulfillHosts);

  // useEffect(() => {
  //   getReviewTimeStats(
  //     (response) => setTimeStats(response),
  //     () => {}
  //   )
  // }, []);


  const tabSelectorOptions = () => {
    return ["Host Payouts", "Member Prizes"].map((stat, index) => (
      <TabPane
        key={index}
        value={index}
        tab={stat}
      />
    ));
  }

  const onSelectedOption = (option) => {
    updateFulfillSection(option === 0 || option === "0" ? true : false);
    //setSelectedTab(option);
  };

  const tableContent = () => {
    if (viewingFulfillHosts) {
      return <HostPayoutHome />;
    } else {
      return <FulfillPrizeHome />;
    }
  }

  const header = () => {
    return (
      <div className={styles.header}>
        <div className={styles.header_container}>
          <div className={styles.header_text}>
            <MuseoText>Fulfillment</MuseoText>
          </div>
        </div>
        <div className={styles.radio_container}>
          <Tabs defaultActiveKey={selectedTab === 0 || selectedTab === "0" ? "0" : "1"} onChange={(e) => onSelectedOption(e)}>
            {tabSelectorOptions()}
          </Tabs>
        </div>
      </div>
    )
  }

  return (
    <PageWrapper>
      <div className={styles.container}>

        {tableContent()}
        {header()}
      </div>
    </PageWrapper>
  );
};

const mapStatesToProps = (state) => {
  const { viewingFulfillHosts } = state.challenge;
  const { adminUser } = state.auth;
  return { adminUser, viewingFulfillHosts };
};

export default connect(mapStatesToProps, {
  updateFulfillSection
})(withTheme(FulfillmentHome));
