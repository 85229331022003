import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form } from 'antd';
import { withTheme } from 'styled-components';
import { PlayCircleOutlined, PlayCircleTwoTone } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, BasicButton, ShortInput, LongInput } from '@components';
import StatsLoader from '@challenges/stats/StatsLoader';
import LeaderboardItem from '@challenges/stats/LeaderboardItem';
import FullLeaderboardModal from '@challenges/stats/FullLeaderboardModal';
import Podium from '@challenges/stats/Podium';
import Leaderboard from '@challenges/stats/Leaderboard';
import styles from './ChallengeStats.module.css';
import { getLeaderBoardStats } from '@actions';
import { downloadFiles, downlaodVideosIntoZip } from '@global';

const PodiumAndLeaderboard = ({
  challenge,
  hasData,
  getLeaderBoardStats,
  theme
}) => {

  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showFullLeaderboard, setShowFullLeaderboard] = useState(false);

  useEffect(() => {
    if (hasData) {
      getLeaderBoardStats(
        challenge.challenge_id,
        (st) => {
          setStats(st);
          setLoading(false)
        },
        (error) => {
          setLoading(false);
        }
      );
    } else {
      setLoading(false);
    }
  }, []);




  return (
    <div className={styles.podium_leaderboard_container}>
      <Podium
        stats={stats}
        hasData={hasData}
        loading={loading}
        challenge={challenge}
      />

      <Leaderboard
        stats={stats}
        hasData={hasData}
        loading={loading}
        challenge={challenge}
      />
    </div>
  );
};



export default connect(null, {
  getLeaderBoardStats
})(withTheme(PodiumAndLeaderboard));
