import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Button, Space } from 'antd';
import { CheckSquareOutlined, InfoOutlined } from '@ant-design/icons';
import PageWrapper from '@components/PageWrapper';
import LoadingWrapper from '@components/LoadingWrapper';
import { BasicButton, BasicText, DeleteButton, ConfirmModal, RejectButton, ApproveButton  } from '@components';
import MemberStatsInfo from '@members/MemberStatsInfo';
import ThunderSection from '@members/ThunderSection';
import BasicInfo from '@members/BasicInfo';
import RejectedReasonsModal from '@review/RejectedReasonsModal';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import ReviewReportHistory from '@review/ReviewReportHistory';
import ReviewStats from '@review/ReviewStats';
import  {
  getUserInfo,
  suspendUser,
  unSuspendUser,
  getReportedMemberToReview,
  handleMemberReport
} from '@actions';
import styles from '@members/Members.module.css';
import { validateEmail, showSuccessMessage, showErrorMessage  } from '@global';
import MemberForm from '@team/MemberForm';

const ReportedMember = ({
  adminUser,
  match,
  getUserInfo,
  suspendUser,
  unSuspendUser,
  getReportedMemberToReview,
  handleMemberReport,
  theme,
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [blockLoading, setBlockLoading] = useState(false);
  const [member, setMember] = useState(null);
  const [showBlockConfirm, setShowBlockConfirm] = useState(false);
  const isReporting = history.location.pathname.includes("/reported_user");

  const [showRejectedReasons, setShowRejectedReasons] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [memberId, setMemberId] = useState(match.params?.user_id);
  const [noneLeft, setNoneLeft] = useState(false);
  const [memberReviewStats, setMemberReviewStats] = useState(null);
  const [fetchedMember, setFetchedMember] = useState(false);


  useEffect(() => {
    getNextMember();
  }, []);


  const getNextMember = () => {
    getReportedMemberToReview(
      memberId,
      (response) => {
        handleNextMember(response);
      },
      () => {}
    )
  }

  const updatePathWithNewMemberId = (user_id=null) => {
    if (user_id == null) {
      history.replace({ pathname: '/reported_member' })
    } else {
      history.replace({ pathname: `/reported_member/${user_id}` });
    }
  }

  const handleNextMember = (response) => {
    console.log(response);
    setApproveLoading(false);
    setRejectLoading(false);
    setFetchedMember(true);
    if (response.none_left) {
      setMemberId(null);
      updatePathWithNewMemberId(null);
      setMemberReviewStats(response.user_review_stats);
      setNoneLeft(true);
    } else {
      setMemberId(response.user.id);
      updatePathWithNewMemberId(response.user.id);
      setMemberReviewStats(response.user_review_stats);
      setMember(response.user);
    }
  }

  const handleReportingResponse = (remove) => {
    handleMemberReport(
      memberId,
      remove,
      (response) => {
        showSuccessMessage("Your action was processed!");
        handleNextMember(response);
        setShowRejectedReasons(false);
      },
      () => {}
    )
  }

  const approve = () => {
    setApproveLoading(true);
    handleReportingResponse(false);
  }

  const reject = (reason) => {
    setRejectLoading(true);
    handleReportingResponse(true);
  }

  const additionalContent = () => {
    return (
      <div style={{ marginLeft: "30px" }}>
        <Space>
          <RejectButton
            onClick={() => setShowRejectedReasons(true)}
            text="Suspend"
          />
          <ApproveButton
            onClick={() => approve()}
            text="Keep Live"
            loading={approveLoading}
          />
        </Space>
      </div>
    );
  }

  const content = () => {
    if (noneLeft) {
      return (
        <div className={styles.none_left_container}>
          <div style={{ marginRight: "40px" }}>
            <CheckSquareOutlined style={{ fontSize: "100px", color: theme.colors.blurple }}/>
          </div>
          All members have been reviewed or are in the process of being reviewed by another reviewer.
        </div>
      )
    } else {
      return (
        <>
          {member &&
            <div style={{ marginTop: "80px" }}>
              {member.is_blocked &&
                <div style={{ color: theme.colors.danger, fontWeight: 700, marginBottom: "20px", fontSize: "18px" }}>
                  CURRENTLY SUSPENDED
                </div>
              }

              <div className={styles.member_since}>
                {member.member_since}
              </div>
              <div className={styles.username_and_view_entries}>
                <div className={styles.username}>
                  @{member.username}
                </div>

                <BasicButton
                  type="secondary"
                  text="View Entries"
                  onClick={() => {
                    history.push({
                      pathname: `/member_entries/${member.id}`,
                    });
                  }}
                />
              </div>


              <MemberStatsInfo member={member} />

              <div>
                <div style={{ fontSize: "16px", fontWeight: 700, marginTop: "50px" }}>
                  Bio:
                </div>

                <div style={{ fontSize: "16px", marginTop: "12px", maxWidth: "400px" }}>
                  {member.bio}
                </div>
              </div>

              <div className={styles.basic_and_reports}>
                <BasicInfo member={member} />
                <ReviewReportHistory entry={member} />
              </div>

            </div>
          }
        </>
      );
    }
  }

  const progressStats = () => {
    if (memberReviewStats) {
      return (
        <div className={styles.review_stats_container}>
          <ReviewStats
            stats={[
              { title: "Pending", value: memberReviewStats.pending, showDanger: true },
              { title: "Kept Live", value: memberReviewStats.kept_live, showDanger: false },
              { title: "Removed", value: memberReviewStats.removed, showDanger: false },
            ]}
          />
        </div>
      );
    }
  }


  return (
    <PageWrapper>
      <FixedHeaderContainer
        text={noneLeft ? "No Pending Reports" : member == null ? "Loading member..." : `${member.first_name} ${member.last_name}`}
        paddingBuffer="80px"
        paddingTop="56px"
        additionalContent={member == null ? null : additionalContent()}
      >
        <LoadingWrapper isLoading={!fetchedMember} loadingText="" size="large">
          <div className={styles.member_content}>
            {progressStats()}
            {content()}
          </div>
        </LoadingWrapper>
      </FixedHeaderContainer>

      {showRejectedReasons &&
        <RejectedReasonsModal
          reasons={adminUser.challenge_rejected_reasons}
          onSelectedReason={(reason) => reject(reason)}
          visible={showRejectedReasons}
          onCancel={() => setShowRejectedReasons(false)}
          rejectLoading={rejectLoading}
          isReport
        />
      }
    </PageWrapper>
  )
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getUserInfo,
  suspendUser,
  unSuspendUser,
  getReportedMemberToReview,
  handleMemberReport
})(withTheme(ReportedMember));
