import React, { useState, useEffect } from 'react';
import styles from '@review/Review.module.css';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import LoadingWrapper from '@components/LoadingWrapper';
import { useHistory } from 'react-router-dom';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { withTheme } from 'styled-components';
import { BasicButton, MuseoText, BasicText } from '@components';
import ReviewStats from '@review/ReviewStats';
import { CSVDownload } from "react-csv";
import { getDownloadDataForMemberPayouts } from '@actions';

const DownloadPage = ({
  match,
  getDownloadDataForMemberPayouts,
  theme
}) => {

  const [downloadData, setDownloadData] = useState(null);

  useEffect(() => {
    getDownloadDataForMemberPayouts(
      match.params.downlowd_type,
      (data) => setDownloadData(data),
      () => {}
    )
  }, []);

  return (
    <PageWrapper>
      <div style={{ paddingTop: "44px" }}>
        <MuseoText>Downloading CSV for {match.params.downlowd_type}...</MuseoText>
      </div>

      {downloadData != null ?
        <CSVDownload data={downloadData} target="_blank" />
      :
        <LoadingWrapper isLoading loadingText="Downloading Data" />
      }

    </PageWrapper>
  );
};

export default connect(null, {
  getDownloadDataForMemberPayouts
})(withTheme(DownloadPage));
