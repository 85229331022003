import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Select, Form, Modal, Button } from 'antd';
import moment from 'moment-timezone';
import { useHistory, Link } from 'react-router-dom';
import AssetUploader from '@challenges/create/AssetUploader';
import AssetPreview from '@challenges/create/AssetPreview';
import {
  TitleText,
  BasicText,
  BasicButton,
  ShortInput,
  LongInput,
} from '@components';
import { getUrlFromUploadId } from '@actions';
import {
  NEW_CHALLENGE_STATES,
  NEW_CHALLENGE_STEPS,
  CHALLENGE_ASSET_TYPES,
  ordinalSuffixOf,
} from '@global';
import styles from './Create.module.css';

const { Option } = Select;

const IndividualPrize = ({
  updateChallenge,
  existingPrize,
  onSave,
  index,
  getUrlFromUploadId,
  prize_info,
}) => {
  const [showUploadImage, setShowUploadImage] = useState(false);
  const [prize, setPrize] = useState(
    existingPrize != null
      ? existingPrize
      : { final_rank: prize_info.length + 1 }
  );

  const updatePrize = (newData) => {
    const newPrizeData = { ...prize, ...newData };
    setPrize(newPrizeData);
  };

  const uploadImage = () => {
    return (
      <Modal
        title="Upload Prize Image"
        visible={showUploadImage}
        footer={null}
        onCancel={() => setShowUploadImage(false)}
      >
        <AssetUploader
          asset_type={CHALLENGE_ASSET_TYPES.PRIZE_IMAGE}
          onSuccess={(fileInfo) => {
            getUrlFromUploadId(fileInfo.presignInfo.upload_id, (url) => {
              updatePrize({
                upload_id: fileInfo.presignInfo.upload_id,
                file_name: fileInfo.fileName,
                image_url: url,
                index: index,
              });
              setShowUploadImage(false);
            });
          }}
        />
      </Modal>
    );
  };

  const rankSelector = () => {
    return (
      <Select
        style={{ width: 200 }}
        placeholder="Select Prize Rank"
        onChange={(val) => updatePrize({ final_rank: val })}
        defaultValue={prize.final_rank}
      >
        <Option value={1}>1st</Option>
        <Option value={2}>2nd</Option>
        <Option value={3}>3rd</Option>
      </Select>
    );
  };

  const shouldBeEnabled = () => {
    return (
      prize.image_url &&
      prize.final_rank &&
      prize.title &&
      prize.title.length > 0 &&
      prize.description &&
      prize.description.length > 0
    );
  };

  return (
    <div>
      <Form.Item>
        <div className={styles.input_label}>
          <BasicText bold useSpan>
            Prize Rank:{' '}
            <BasicText useSpan>
              {ordinalSuffixOf(prize.final_rank)} Place
            </BasicText>
          </BasicText>
        </div>
      </Form.Item>

      <Form.Item>
        <ShortInput
          inputHeader="Enter Prize Title"
          placeholder="Awesome Sneakers"
          onChange={(val) => updatePrize({ title: val })}
          value={prize.title}
        />
      </Form.Item>

      <Form.Item>
        <div className="input_section">
          <LongInput
            inputHeader="Enter Prize Description (< 100 words recommended)"
            onChange={(val) => updatePrize({ description: val })}
            value={prize.description}
            minRows={3}
          />
        </div>
      </Form.Item>

      <Form.Item>
        <div className={styles.input_label}>
          <BasicText bold>Prize Image</BasicText>
        </div>

        <AssetPreview
          url={prize.image_url}
          assetName="Image"
          fileName={prize.file_name}
          onClickEdit={() => setShowUploadImage(true)}
          instructions="Must have square dimensions. 1500 x 1500 px image recommended"
          showInstructionsOnSide
        />
      </Form.Item>

      <BasicButton
        type="primary"
        text="Save"
        disabled={!shouldBeEnabled()}
        onClick={() => onSave(prize)}
      />

      {showUploadImage && uploadImage()}
    </div>
  );
};

const mapStatesToProps = (state) => {
  const { newChallengeInfo } = state.challenge;
  return { newChallengeInfo };
};

export default connect(mapStatesToProps, {
  getUrlFromUploadId,
})(IndividualPrize);
