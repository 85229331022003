import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Icon, Spin } from 'antd';
import { TitleText, BasicText, StandardInput, LongInput, CopyUrl, BasicButton, DropDown } from '@components';
import styles from './Team.module.css';

const MemberFormComponent = ({
  adminUser,
  title,
  value,
  onChange,
  isRole,
  isEdit,
  canEdit
}) => {

  const valueField = () => {
    if (isRole) {
      return (
        <DropDown
          options={adminUser.privileged_roles}
          placeholder="Pick Role"
          onSelect={(value) => onChange(value)}
          defaultOption={isEdit ? value : null}
          disabled={isEdit && !canEdit}
        />
      );
    } else {
      return (
        <StandardInput
          value={value}
          onChange={(value) => onChange(value)}
          placeholder={`Enter ${title}`}
          style={{ borderWidth: "0px" }}
          disabled={isEdit && !canEdit}
        />
      );
    }
  }

  return (
    <div
      className={styles.form_component_container}
    >
      <div className={styles.form_label}>
        <BasicText bold fontSize="18px" color={!isEdit ? "#000" : canEdit ? "#000" : "#9e9e9e"}>
          {title}
        </BasicText>
      </div>

      <div className={styles.form_value_container}>
        {valueField()}
        {!isRole && <div className={styles.form_value_underline} />}
      </div>
    </div>
  )
}

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};


export default connect(mapStatesToProps, {

})(MemberFormComponent);
