import {
  getAPIRequest,
  postAPIRequest
} from '@actions';
import {
  SET_PLAYBACK_SPEED,
  UPDATE_REVIEW_STATS,
  SET_OVERALL_REPORT_PENDING
} from '@actions/types';

export const getPendingReports = (onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_pending_reports',
        {},
        response => {
          onSuccess(response);
        },
        error => {
          onFail(error);
        }
      )
    );
  };
};

export const getEntryReports = (seen_ids, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_submission_reports',
        { seen_ids },
        response => {
          console.log(response);
          onSuccess(response.submissions);
        },
        error => {
          onFail(error);
        }
      )
    );
  };
};

export const getReportedSubmissionToReview = (submission_id, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_reported_submission_to_review',
        { submission_id },
        response => {
          dispatch({ type: SET_OVERALL_REPORT_PENDING, payload: response.overall_pending });
          onSuccess(response);
        },
        error => {
          onFail(error);
        }
      )
    );
  };
};

export const handleSubmissionReport = (submission_id, remove, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/handle_submission_report',
        {
          remove, submission_id
        },
        response => {
          dispatch({ type: SET_OVERALL_REPORT_PENDING, payload: response.overall_pending });
          onSuccess(response);
        },
        error => {
          onFail("Something went wrong.");
        },
      ),
    );
  };
};


export const getChallengeReports = (seen_ids, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_challenge_reports',
        { seen_ids },
        response => {
          console.log(response);
          onSuccess(response.challenges);
        },
        error => {
          onFail(error);
        }
      )
    );
  };
};

export const getReportedChallengeToReview = (challenge_id, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_reported_challenge_to_review',
        { challenge_id },
        response => {
          dispatch({ type: SET_OVERALL_REPORT_PENDING, payload: response.overall_pending });
          onSuccess(response);
        },
        error => {
          onFail(error);
        }
      )
    );
  };
};

export const handleChallengeReport = (challenge_id, remove, onSuccess, onFail) => {
  console.log("id", challenge_id, remove);
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/handle_challenge_report',
        {
          challenge_id, remove
        },
        response => {
          dispatch({ type: SET_OVERALL_REPORT_PENDING, payload: response.overall_pending });
          onSuccess(response);
        },
        error => {
          onFail("Something went wrong.");
        },
      ),
    );
  };
};

export const getUserReports = (seen_ids, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_user_reports',
        { seen_ids },
        response => {
          console.log(response);
          onSuccess(response.users);
        },
        error => {
          onFail(error);
        }
      )
    );
  };
};

export const getReportedMemberToReview = (user_id, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_reported_user_to_review',
        { user_id },
        response => {
          dispatch({ type: SET_OVERALL_REPORT_PENDING, payload: response.overall_pending });
          onSuccess(response);
        },
        error => {
          onFail(error);
        }
      )
    );
  };
};

export const handleMemberReport = (user_id, remove, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/handle_user_report',
        {
          user_id, remove
        },
        response => {
          dispatch({ type: SET_OVERALL_REPORT_PENDING, payload: response.overall_pending });
          onSuccess(response);
        },
        error => {
          onFail("Something went wrong.");
        },
      ),
    );
  };
};


export const getReportedReactions = (onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_reported_reactions',
        {},
        response => {
          onSuccess(response.reported_reactions);
        },
        error => {
          onFail(error);
        }
      )
    );
  };
};

export const reviewReportedReaction = (reported_reaction_id, remove_reaction, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/review_reported_reaction',
        {
          reported_reaction_id, remove_reaction
        },
        response => {
          dispatch({ type: SET_OVERALL_REPORT_PENDING, payload: response.overall_pending });
          onSuccess(response.reported_reactions);
        },
        error => {
          onFail("Something went wrong.");
        },
      ),
    );
  };
};

export const toggleFreeform = (brand_id, is_allowed, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/toggle_freeform_privelege',
        {
          brand_id, is_allowed
        },
        response => {
          onSuccess(response.host);
        },
        error => {
          onFail("Something went wrong.");
        },
      ),
    );
  };
};

export const getReportedVideoReactionToReview = (reaction_id, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_reported_video_reaction_to_review',
        { reaction_id },
        response => {
          dispatch({ type: SET_OVERALL_REPORT_PENDING, payload: response.overall_pending });
          onSuccess(response);
        },
        error => {
          onFail(error);
        }
      )
    );
  };
};

export const handleVideoReactionReport = (reaction_id, remove, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/handle_video_reaction_report',
        {
          remove, reaction_id
        },
        response => {
          dispatch({ type: SET_OVERALL_REPORT_PENDING, payload: response.overall_pending });
          onSuccess(response);
        },
        error => {
          onFail("Something went wrong.");
        },
      ),
    );
  };
};



export const getReportedHyypeGroups = (onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_reported_hyype_groups',
        {},
        response => {
          onSuccess(response.reported_groups);
        },
        error => {
          onFail(error);
        }
      )
    );
  };
};

export const reviewReportedHyypeGroup = (reported_group_id, remove_group, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/review_reported_hyype_group',
        {
          reported_group_id, remove_group
        },
        response => {
          dispatch({ type: SET_OVERALL_REPORT_PENDING, payload: response.overall_pending });
          onSuccess(response.reported_groups);
        },
        error => {
          onFail("Something went wrong.");
        },
      ),
    );
  };
};

export const getReportedHyypeGroupEntries = (onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_reported_hyype_group_entries',
        {},
        response => {
          onSuccess(response.reported_entries);
        },
        error => {
          onFail(error);
        }
      )
    );
  };
};


export const reviewReportedHyypeGroupEntry = (reported_entry_id, remove_entry, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/review_reported_hyype_group_entry',
        {
          reported_entry_id, remove_entry
        },
        response => {
          dispatch({ type: SET_OVERALL_REPORT_PENDING, payload: response.overall_pending });
          onSuccess(response.reported_entries);
        },
        error => {
          onFail("Something went wrong.");
        },
      ),
    );
  };
};
