import React from 'react';
import styles from './ChallengeStats.module.css';
import { withTheme } from 'styled-components';

const GeographicItem = ({
  data,
  rank,
  theme
}) => {
  return (
    <div
      className={styles.leaderboard_item_container}
      style={{ cursor: "none", marginBottom: "16px" }}
    >
      <div className={styles.rank_username}>
        <div className={styles.leaderboard_rank}>
          {rank}
        </div>
        <div className={styles.leaderboard_username}>
          {data[0]}
        </div>
      </div>
      <div className={styles.leaderboard_claps}>
        {data[1]} members
      </div>
    </div>
  );
}

export default withTheme(GeographicItem);
