import {
  SET_OVERALL_FULFILL_PENDING,
  UPDATE_FULFILL_SECTION
} from "@actions/types";

import {
  getAPIRequest,
  postAPIRequest
} from '@actions';
import { showSuccessMessage, showErrorMessage } from '@global';

export const updateFulfillSection = (viewingHosts) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_FULFILL_SECTION, payload: viewingHosts });
  };
};


export const getHostPayoutChallengesList = (seen_ids, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        "/admin/get_fulfill_host_challenges",
        {
          seen_ids
        },
        (response) => {
          onSuccess(response.challenges);
        },
        (error) => {
          onFail(error);
        }
      )
    );
  };
};

export const getHostPayoutDetails = (challenge_id, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        "/admin/get_host_payout_details",
        {
          challenge_id
        },
        (response) => {
          onSuccess(response.payout_details);
        },
        (error) => {
          onFail(error);
        }
      )
    );
  };
};

export const editHostSplit = (challenge_id, new_split, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/edit_host_split',
        {
          new_split, challenge_id
        },
        response => {
          onSuccess(response.payout_details);
        },
        error => {
          onFail("Something went wrong.");
        },
      ),
    );
  };
};

export const sendHostPayout = (challenge_id, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/send_host_payout',
        {
          challenge_id
        },
        response => {
          if (response.success) {
            dispatch({ type: SET_OVERALL_FULFILL_PENDING, payload: response.pending_fulfills });
            onSuccess(response.payout_details);
          } else {
            onFail(response.reason);
          }
        },
        error => {
          onFail("Something went wrong.");
        },
      ),
    );
  };
};


export const getFulfillPrizeChallengesList = (seen_ids, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        "/admin/get_fulfill_prize_challenges",
        {
          seen_ids
        },
        (response) => {
          onSuccess(response.challenges);
        },
        (error) => {
          onFail(error);
        }
      )
    );
  };
};

export const getPrizesData = (challenge_id, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        "/admin/get_challenge_prizes",
        {
          challenge_id
        },
        (response) => {
          onSuccess(response);
        },
        (error) => {
          onFail(error);
        }
      )
    );
  };
};

export const fulfillPrizesForChallenge = (challenge_id, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/fulfill_challenge_prizes',
        {
          challenge_id
        },
        response => {
          if (response.success) {
            showSuccessMessage("Prizes fulfilled!")
            dispatch({ type: SET_OVERALL_FULFILL_PENDING, payload: response.pending_fulfills });
            onSuccess(response);
          } else {
            showErrorMessage(response.reason);
            onSuccess(response);
          }
        },
        error => {
          showErrorMessage("Something went wrong.");
          onFail("Something went wrong.");
        },
      ),
    );
  };
};

export const fulfillIndividualPrize = (prize_id, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/fulfill_individual_prize',
        {
          prize_id
        },
        response => {
          if (response.success) {
            showSuccessMessage("Prize fulfilled!")
            dispatch({ type: SET_OVERALL_FULFILL_PENDING, payload: response.pending_fulfills });
            onSuccess(response);
          } else {
            showErrorMessage(response.reason);
            onSuccess(response);
          }
        },
        error => {
          showErrorMessage("Something went wrong.");
          onFail("Something went wrong.");
        },
      ),
    );
  };
};

export const getAckPrizeInfo = (seen_ids, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/get_prize_ack_info',
        {
          seen_ids
        },
        response => {
          if (response.success) {
            onSuccess(response.prizes);
          } else {
            showErrorMessage(response.reason);
          }
        },
        error => {
          showErrorMessage("Something went wrong.");
          onFail("Something went wrong.");
        },
      ),
    );
  };
};
