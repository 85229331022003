import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor';
import { withTheme } from 'styled-components';
import { Table, Icon, Spin } from 'antd';
import { TitleText, BasicText, BottomTableLoader } from '@components';
import styles from '@components/HeaderAndTable.module.css';
import { getVideoReactions } from '@actions';

const VideoReactionsTable = ({ getVideoReactions, theme }) => {
  const [reactions, setReactions] = useState([]);
  const [seenIds, setSeenIds] = useState([]);
  const [isMore, setIsMore] = useState(true);
  const isFetching = useRef(false);

  console.log('reactions', reactions.length);

  useEffect(() => {
    fetchReactions();
  }, []);

  const fetchReactions = () => {
    if (!isMore || isFetching.current) return;
    // console.log('fetching');
    isFetching.current = true;
    getVideoReactions(seenIds, (newReactions) => {
      setReactions(reactions.concat(newReactions));
      setSeenIds(seenIds.concat(newReactions.map((r) => r.id)));
      setIsMore(newReactions.length > 0);
      isFetching.current = false;
    });
  };

  const onBottomLoadingVisible = (isVisible) => {
    if (isVisible) {
      if (!isFetching.current && isMore) fetchReactions();
    }
  };

  const getReactionTableData = () => {
    let dataArray = [];
    reactions.forEach((reaction, i) => {
      dataArray.push({
        id: reaction.id,
        created_at: reaction.created_at,
        username: reaction.user.username,
        url: reaction.url.content,
        creator: `@${reaction.reacting_to_user.username} in ${reaction.challenge.title}`,
      });
    });
    return dataArray;
  };

  const getColumns = () => {
    let columns = [
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Username</div>;
        },
        dataIndex: 'username',
        key: 'username',
        width: 80,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  overflow: 'hidden',
                }}
              >
                <BasicText fontSize="15px" bold>
                  @{text}
                </BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Created At</div>;
        },
        dataIndex: 'created_at',
        key: 'created_at',
        width: 80,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: 150,
                  overflow: 'hidden',
                }}
              >
                <BasicText fontSize="13px">{text}</BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Reacting To</div>;
        },
        dataIndex: 'creator',
        key: 'creator',
        width: 180,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px">{text}</BasicText>,
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Video Link</div>;
        },
        dataIndex: 'url',
        key: 'url',
        width: 80,
        render(text, record) {
          return {
            children: (
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  window.open(record.url, '_blank');
                }}
              >
                <BasicText color={theme.colors.blurple} fontSize="15px">
                  Watch Video
                </BasicText>
              </div>
            ),
          };
        },
      },
    ];

    return columns;
  };

  return (
    <div
      className={styles.member_container_style}
      style={{ marginLeft: '20px', marginTop: '65px' }}
    >
      <Table
        pagination={false}
        columns={getColumns()}
        dataSource={getReactionTableData()}
        rowKey="id"
        rowClassName={styles.unclickable_table}
      />

      {isMore && (
        <VisibilitySensor onChange={onBottomLoadingVisible}>
          <BottomTableLoader />
        </VisibilitySensor>
      )}
    </div>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getVideoReactions,
})(withTheme(VideoReactionsTable));
