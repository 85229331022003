import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form, Space } from 'antd';
import { withTheme } from 'styled-components';
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import {
  PlayCircleOutlined,
  CheckCircleFilled,
  WarningFilled,
  PlayCircleTwoTone,
} from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import {
  TitleText,
  BasicText,
  BasicButton,
  ShortInput,
  LongInput,
} from '@components';
import styles from './ReviewChallenge.module.css';
import ResponseButtons from '@review/hosts/ResponseButtons';
import { formatPhoneNumber } from '@global';

const MediaSection = ({
  challenge,
  reviewJson,
  updateReviewJson,
  hasWatchedVideo,
  setShowVideo,
  theme,
}) => {
  const { media_info } = challenge;
  const [showPreviewAudio, setShowPreviewAudio] = useState(false);
  const [hasPlayedAudio, setHasPlayedAudio] = useState(false);

  return (
    <div className={styles.approve_section} style={{ marginTop: '40px' }}>
      <div className={styles.section_header_container}>
        <div className={styles.section_header}>Media Information</div>

        {reviewJson['Media'].decided_on && (
          <>
            {!reviewJson['Media'].approved ? (
              <div className={styles.approve_check}>
                <WarningFilled
                  style={{
                    color: theme.colors.danger,
                    fontWeight: 700,
                    fontSize: '26px',
                  }}
                />
              </div>
            ) : (
              <div className={styles.approve_check}>
                <CheckCircleFilled
                  style={{
                    color: theme.colors.blurple,
                    fontWeight: 700,
                    fontSize: '26px',
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>

      {challenge.media_info.explainer_video_url ? (
        <Space size={40} className={styles.media_container}>
          <div>
            <div
              className={styles.attribute_title}
              style={{ marginBottom: '12px' }}
            >
              Explainer Video
            </div>

            <div className={styles.explainer_container}>
              <img
                className={styles.explainer_container}
                src={challenge.media_info.explainer_cover_url}
              />

              <div className={styles.play_explainer_cover} />

              <div
                className={styles.play_explainer}
                onClick={() => setShowVideo(true)}
              >
                <PlayCircleTwoTone
                  style={{ fontSize: '60px' }}
                  twoToneColor={challenge.brand_info.brand_color}
                />
              </div>
            </div>
          </div>

          <div>
            <div
              className={styles.attribute_title}
              style={{ marginBottom: '12px' }}
            >
              Explainer Image
            </div>
            <div
              className={styles.explainer_container}
              onClick={() =>
                window.open(challenge.media_info.explainer_cover_url, '_blank')
              }
            >
              <img
                className={styles.explainer_container}
                src={challenge.media_info.explainer_cover_url}
              />
            </div>
          </div>
        </Space>
      ) : (
        <div>Media Not Uploaded Yet</div>
      )}

      {challenge.media_info.audio_required && (
        <div style={{ marginTop: '18px' }}>
          <BasicText bold fontSize="16px">
            Audio Required Challenge
            <div
              className={styles.play_audio_button}
              style={{ fontSize: '16px' }}
              onClick={() => {
                setShowPreviewAudio(true);
                setHasPlayedAudio(true);
              }}
            >
              <div
                style={{
                  width: '40px',
                  height: '40px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CaretRightOutlined
                  style={{
                    fontSize: '30px',
                    color: theme.colors.snow,
                    marginRight: '10px',
                  }}
                />
              </div>
              Challenge Entry Audio
            </div>
          </BasicText>
        </div>
      )}

      {media_info.categories && media_info.categories.length > 0 && (
        <div style={{ marginTop: '18px' }}>
          <BasicText bold fontSize="16px">
            Selected Categories:
            <div style={{ marginTop: '8px' }}>
              {media_info.categories.map((category, index) => {
                return (
                  <div key={index} style={{ marginBottom: '8px' }}>
                    <BasicText
                      fontSize="14px"
                      bold
                      color={theme.colors.blurple}
                    >
                      {category}
                    </BasicText>
                  </div>
                );
              })}
            </div>
          </BasicText>
        </div>
      )}

      <div style={{ marginTop: '20px' }}>
        <ResponseButtons
          approveText="Approve Media"
          rejectText="Reject Media"
          showRejected={
            reviewJson['Media'].decided_on && !reviewJson['Media'].approved
          }
          rejectedReason={reviewJson['Media'].reason}
          disabled={
            !hasWatchedVideo || (media_info.audio_required && !hasPlayedAudio)
          }
          onApprove={() => {
            updateReviewJson({
              Media: { decided_on: true, approved: true },
            });
          }}
          onReject={() => {
            updateReviewJson({
              Media: { decided_on: true, approved: false },
            });
          }}
          updateRejectedReason={(val) => {
            updateReviewJson({
              Media: { decided_on: true, approved: false, reason: val },
            });
          }}
        />
        <div style={{ marginTop: '8px' }}>
          <BasicText fontSize="14px" bold>
            * To approve media you must watch the explainer video above and
            played the audio if the Challenge requires an audio clip. Please
            ensure video is not longer than 60 seconds.
          </BasicText>
        </div>
      </div>

      {showPreviewAudio && (
        <Modal
          title={'Preview Required Audio'}
          visible={showPreviewAudio}
          onCancel={() => setShowPreviewAudio(false)}
          footer={null}
        >
          <audio controls autoPlay>
            <source src={media_info.audio_required_url} />
          </audio>
        </Modal>
      )}
    </div>
  );
};

export default connect(null, {})(withTheme(MediaSection));
