import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Switch, Space } from 'antd';
import { withTheme } from 'styled-components';
import PageWrapper from '@components/PageWrapper';
import {
  BasicButton,
  DeleteButton,
  ConfirmModal,
  BasicText,
  TitleText,
} from '@components';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import LiveStats from '@challenges/stats/LiveStats';
import ShareLiveStatsModal from '@challenges/stats/ShareLiveStatsModal';
import { useHistory } from 'react-router-dom';
import SendNotificationModal from '@components/SendNotificationModal';
import {
  getChallengeStats,
  deleteChallenge,
  toggleVisibility,
  toggleStatsPageVisibility,
  toggleChallengePinned,
  toggleEditorsChoice,
} from '@actions';
import styles from './ChallengeStats.module.css';
import { validateEmail, showSuccessMessage, showErrorMessage } from '@global';

const ChallengeStatsHome = ({
  getChallengeStats,
  deleteChallenge,
  toggleVisibility,
  toggleStatsPageVisibility,
  match,
  toggleChallengePinned,
  toggleEditorsChoice,
  theme,
}) => {
  const history = useHistory();
  const [fetchedCallenge, setFetchedChallenge] = useState(false);
  const [challenge, setChallenge] = useState(null);

  const [showDeleteConfrim, setShowDeleteConfirm] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showShareStats, setShowShareStats] = useState(false);
  const [showSendNotification, setShowSendNotification] = useState(false);

  if (challenge) console.log(challenge);

  useEffect(() => {
    fetchStats();
  }, []);

  const fetchStats = () => {
    getChallengeStats(
      match.params.challenge_id,
      (c) => setChallenge(c),
      (message) => showErrorMessage(message)
    );
  };

  if (challenge == null) return <div />;

  const onDeleteConfirm = () => {
    setDeleteLoading(true);
    deleteChallenge(
      challenge.challenge_id,
      (response) => {
        setDeleteLoading(false);
        history.goBack();
        setTimeout(
          () => showSuccessMessage('Challenge successfully deleted.', 5),
          200
        );
      },
      () => {
        showErrorMessage('Something wend wrong.', 6);
        setDeleteLoading(false);
      }
    );
  };

  const additionalContent = () => {
    if (challenge === null) return null;

    return (
      <div className={styles.additional_content_container}>
        {fixedContent()}
        <div className={styles.edit_delete_container}>
          <div className={styles.additional_content_buffer}>
            <BasicButton
              type="primary"
              text="Edit"
              style={{ height: '48px' }}
              onClick={() => {
                history.push({
                  pathname: `/challenges/${challenge.challenge_id}`,
                });
              }}
            />
          </div>
          <div className={styles.delete_buffer}>
            <DeleteButton
              onClick={() => setShowDeleteConfirm(true)}
              style={{ height: '48px' }}
            />
          </div>
        </div>
      </div>
    );
  };

  const onToggleVisibility = (isToggled) => {
    toggleVisibility(
      challenge.challenge_id,
      isToggled,
      (response) => {
        showSuccessMessage(response.message);
        setChallenge(response.challenge);
      },
      (error) => showErrorMessage(error)
    );
  };

  const onTogglePinChallenge = (shouldPin) => {
    toggleChallengePinned(
      challenge.challenge_id,
      shouldPin,
      (response) => {
        showSuccessMessage(response.message);
        setChallenge(response.challenge);
      },
      (error) => showErrorMessage(error)
    );
  };

  const onToggleEditorsChoice = (shouldPin) => {
    toggleEditorsChoice(
      challenge.challenge_id,
      shouldPin,
      (response) => {
        showSuccessMessage(response.message);
        setChallenge(response.challenge);
      },
      (error) => showErrorMessage(error)
    );
  };

  const fixedContent = () => {
    return (
      <div
        style={{
          display: 'flex',
          padding: '12px',
          paddingLeft: '16px',
          paddingRight: '16px',
          backgroundColor: theme.colors.mint,
          borderRadius: '12px',
          maxWidth: '220px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <BasicText bold>Visible in App</BasicText>
        <div style={{ marginLeft: '24px' }}>
          <Switch
            defaultChecked={challenge.is_approved}
            onChange={(isToggled) => onToggleVisibility(isToggled)}
            style={{
              backgroundColor: challenge.is_approved
                ? theme.colors.blurple
                : theme.colors.lightShade,
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <PageWrapper>
      <FixedHeaderContainer
        text={challenge.basic_info.title}
        onBackPressed={() => console.log('back pressed')}
        fixedContent={null}
        paddingBuffer="80px"
        paddingTop="56px"
        useMuseo
        additionalContent={additionalContent()}
        hideMobileAdditional
      >
        <div className={styles.content_container}>
          <div className={styles.mobile_additional_display}>
            {additionalContent()}
          </div>

          <div style={{ marginBottom: '12px' }}>{challenge.approved_text}</div>

          <div className={styles.type_challenge_header}>
            TYPE:{' '}
            {challenge.is_ugc
              ? `${challenge.visibility} MGC`.toUpperCase()
              : challenge.type.toUpperCase()}
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            {!challenge.is_ugc && (
              <div
                className={styles.share_live_stats_button}
                style={{ marginRight: '40px' }}
                onClick={() => setShowShareStats(true)}
              >
                Share Live Stats
              </div>
            )}

            <div
              className={styles.share_live_stats_button}
              onClick={() => setShowSendNotification(true)}
            >
              Send Notification
            </div>
          </div>

          {challenge.is_ugc && challenge.is_private && (
            <div
              className={styles.share_live_stats_button}
              onClick={() => {
                history.push({
                  pathname: `/remove_member/${challenge.challenge_id}`,
                });
              }}
            >
              Remove a Member
            </div>
          )}

          <div style={{ marginBottom: '20px' }}>
            <Space>
              <span>
                <TitleText>Pin Challenge</TitleText>
              </span>

              <Switch
                defaultChecked={challenge.is_pinned}
                onChange={(isToggled) => onTogglePinChallenge(isToggled)}
                style={{
                  backgroundColor: challenge.is_pinned
                    ? theme.colors.blurple
                    : theme.colors.lightShade,
                }}
              />
            </Space>

            <Space style={{ marginLeft: '32px' }}>
              <span>
                <TitleText>Editors Choice</TitleText>
              </span>

              <Switch
                defaultChecked={challenge.editors_choice}
                onChange={(isToggled) => onToggleEditorsChoice(isToggled)}
                style={{
                  backgroundColor: challenge.editors_choice
                    ? theme.colors.blurple
                    : theme.colors.lightShade,
                }}
              />
            </Space>
          </div>

          <LiveStats challenge={challenge} />
        </div>
      </FixedHeaderContainer>

      {showDeleteConfrim && (
        <ConfirmModal
          title={'Confirm Delete'}
          visible={showDeleteConfrim}
          loading={deleteLoading}
          onCancel={() => setShowDeleteConfirm(false)}
          onSubmit={() => onDeleteConfirm()}
        >
          <BasicText marginBottom="20px">
            Are you sure you want to delete this challenge? This action can not
            be undone.
          </BasicText>
        </ConfirmModal>
      )}

      {showShareStats && (
        <ShareLiveStatsModal
          challenge={challenge}
          onCancel={() => setShowShareStats(false)}
          onToggleStatsVisibility={(is_visible) => {
            toggleStatsPageVisibility(
              challenge.challenge_id,
              is_visible,
              (response) => {
                showSuccessMessage(response.message);
                setChallenge(response.challenge);
              },
              () => {}
            );
          }}
        />
      )}

      {showSendNotification && (
        <SendNotificationModal
          visible={showSendNotification}
          isChallenge
          challengeId={challenge.challenge_id}
          onCancel={() => setShowSendNotification(false)}
        />
      )}
    </PageWrapper>
  );
};

// const mapStatesToProps = (state) => {
//   const { viewedChallengeInfo } = state.challenge;
//   return { viewedChallengeInfo };
// };

export default connect(null, {
  getChallengeStats,
  deleteChallenge,
  toggleVisibility,
  toggleStatsPageVisibility,
  toggleChallengePinned,
  toggleEditorsChoice,
})(withTheme(ChallengeStatsHome));
