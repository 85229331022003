import React, { useEffect } from 'react';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PrivateRoute from './PrivateRoute';
import Login from '@admin/login';
import AdminHome from '@admin/AdminHome';
import ChallengeHome from '@challenges/ChallengeHome';
import TeamHome from '@team/TeamHome';
import AddAdmin from '@team/AddAdmin';
import ViewAdmin from '@team/ViewAdmin';
import AcceptInvite from '@team/AcceptInvite';
import EditChallenge from '@challenges/edit/EditChallenge';
import ChallengeStatsHome from '@challenges/stats/ChallengeStatsHome';
import RemoveMember from '@challenges/edit/RemoveMember';
import BrandViewStats from '@challenges/stats/BrandViewStats';
import CommunityHome from '@members/CommunityHome';
import ViewMember from '@members/ViewMember';
import MemberEntries from '@members/MemberEntries';
import PurchaseHistory from '@members/PurchaseHistory';
import ReviewHome from '@review/ReviewHome';
import ReviewEntries from '@review/entries/ReviewEntries';
import ReviewEntry from '@review/entries/ReviewEntry';
import ReviewChallenges from '@review/challenges/ReviewChallenges';
import ReviewChallenge from '@review/challenges/ReviewChallenge';
import ReviewPortalChallenges from '@review/portal_challenges/ReviewPortalChallenges';
import ReviewPortalChallenge from '@review/portal_challenges/ReviewPortalChallenge';
import ReviewTime from '@review/ReviewTime';
import ReviewReactions from '@review/reactions/ReviewReactions';
import ReviewHosts from '@review/hosts/ReviewHosts';
import ReportHome from '@reports/ReportHome';
import ReportedEntries from '@reports/entries/ReportedEntries';
import ReportedChallenges from '@reports/challenges/ReportedChallenges';
import ReportedMembers from '@reports/members/ReportedMembers';
import ReportedMember from '@reports/members/ReportedMember';
import ReportedReactions from '@reports/ReportedReactions';
import ReportedHyypeGroups from '@reports/ReportedHyypeGroups';
import ReportedHyypeGroupEntries from '@reports/ReportedHyypeGroupEntries';
import ExploreEntries from '@entries/ExploreEntries';
import OverviewStats from '@admin/OverviewStats';
import DownloadReferrals from '@members/DownloadReferrals';
import DownloadContactInfo from '@members/DownloadContactInfo';
import FulfillmentHome from '@fulfillment/FulfillmentHome';
import PayoutDetails from '@fulfillment/hosts/PayoutDetails';
import FulfillChallengePrizes from '@fulfillment/prizes/FulfillChallengePrizes';
import PaymentsHome from '@payments/PaymentsHome';
import DownloadPage from '@payments/DownloadPage';
import SettingsHome from '@settings/SettingsHome';
import PrizeAcknowledgementList from '@fulfillment/prizes/PrizeAcknowledgementList';
import VideoReactionsTable from '../../video_reactions/VideoReactionsTable';
import NavBar from './navbar';
import AccountMenu from './AccountMenu';
import PageNotFound from '@admin/PageNotFound';
import ForgotPassword from '../ForgotPassword';
import ViewHost from '@hosts/ViewHost';
import './Router.css';
import HyypeGroupsHome from '../hyype_groups/HyypeGroupsHome';

const RouterComponent = ({ adminUser, logged_in, getAdminEvents }) => {
  const authed = logged_in;
  const history = useHistory();

  return (
    <BrowserRouter history={history} forceRefresh={false}>
      <div className={authed ? 'Router-wrapper' : ''}>
        <div className={authed ? 'Router-desktop-padder' : ''}> </div>
        {authed && <AccountMenu />}
        {authed && <NavBar />}
        <div className={authed ? 'Router-main-area' : ''}>
          <Switch>
            <Route path="/login" exact component={Login} />
            <Route path="/mem_inv/:inv_hs" component={AcceptInvite} />
            <Route path="/rst_pwd/:rst_hs" component={AcceptInvite} />
            <Route
              path="/lv_st/:challenge_id/:live_stats_hash"
              component={BrandViewStats}
            />

            <PrivateRoute
              authed={authed}
              exact
              path="/"
              component={AdminHome}
            />

            <PrivateRoute
              authed={authed}
              exact
              path="/overview"
              component={adminUser.show_overview ? OverviewStats : PageNotFound}
            />

            <PrivateRoute
              authed={authed}
              exact
              path="/challenges/:challenge_id"
              component={
                adminUser.show_challenges ? EditChallenge : PageNotFound
              }
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/challenges"
              component={
                adminUser.show_challenges ? ChallengeHome : PageNotFound
              }
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/challenge_stats/:challenge_id"
              component={
                adminUser.show_challenges ? ChallengeStatsHome : PageNotFound
              }
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/remove_member/:challenge_id"
              component={
                adminUser.show_challenges ? RemoveMember : PageNotFound
              }
            />

            <PrivateRoute
              authed={authed}
              exact
              path="/team"
              component={adminUser.show_team ? TeamHome : PageNotFound}
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/review_team"
              component={adminUser.show_team ? TeamHome : PageNotFound}
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/team/add_member"
              component={adminUser.show_team ? AddAdmin : PageNotFound}
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/team_member/:admin_id"
              component={adminUser.show_team ? ViewAdmin : PageNotFound}
            />

            <PrivateRoute
              authed={authed}
              exact
              path="/community"
              component={adminUser.show_members ? CommunityHome : PageNotFound}
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/view_member/:user_id"
              component={adminUser.show_members ? ViewMember : PageNotFound}
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/member_entries/:user_id"
              component={adminUser.show_members ? MemberEntries : PageNotFound}
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/purchase_history/:user_id"
              component={
                adminUser.show_members ? PurchaseHistory : PageNotFound
              }
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/download_referrals"
              component={
                adminUser.show_members ? DownloadReferrals : PageNotFound
              }
            />

            <PrivateRoute
              authed={authed}
              exact
              path="/download_contact"
              component={
                adminUser.show_members ? DownloadContactInfo : PageNotFound
              }
            />

            <PrivateRoute
              authed={authed}
              exact
              path="/review_content"
              component={adminUser.show_review ? ReviewHome : PageNotFound}
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/review_entries"
              component={adminUser.show_review ? ReviewEntries : PageNotFound}
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/review_entry"
              component={adminUser.show_review ? ReviewEntry : PageNotFound}
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/review_entry/:submission_id"
              component={adminUser.show_review ? ReviewEntry : PageNotFound}
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/review_challenge_entry/:challenge_id"
              component={adminUser.show_review ? ReviewEntry : PageNotFound}
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/review_challenge_entry/:challenge_id/:submission_id"
              component={adminUser.show_review ? ReviewEntry : PageNotFound}
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/view_entry/:submission_id"
              component={adminUser.show_review ? ReviewEntry : PageNotFound}
            />

            <PrivateRoute
              authed={authed}
              exact
              path="/review_mgc_challenges"
              component={
                adminUser.show_review ? ReviewChallenges : PageNotFound
              }
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/review_mgc_challenge"
              component={adminUser.show_review ? ReviewChallenge : PageNotFound}
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/review_mgc_challenge/:challenge_id"
              component={adminUser.show_review ? ReviewChallenge : PageNotFound}
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/mgc_history/:challenge_id"
              component={adminUser.show_review ? ReviewChallenge : PageNotFound}
            />

            <PrivateRoute
              authed={authed}
              exact
              path="/review_portal_challenges"
              component={
                adminUser.show_review ? ReviewPortalChallenges : PageNotFound
              }
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/review_portal_challenge/:challenge_id"
              component={
                adminUser.show_review ? ReviewPortalChallenge : PageNotFound
              }
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/review_portal_challenge"
              component={
                adminUser.show_review ? ReviewPortalChallenge : PageNotFound
              }
            />

            <PrivateRoute
              authed={authed}
              exact
              path="/review_hosts"
              component={adminUser.show_review ? ReviewHosts : PageNotFound}
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/review_hosts/:brand_id"
              component={adminUser.show_review ? ReviewHosts : PageNotFound}
            />

            <PrivateRoute
              authed={authed}
              exact
              path="/review_reactions"
              component={adminUser.show_review ? ReviewReactions : PageNotFound}
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/review_reactions/:reaction_id"
              component={adminUser.show_review ? ReviewReactions : PageNotFound}
            />

            <PrivateRoute
              authed={authed}
              exact
              path="/reported"
              component={adminUser.show_reported ? ReportHome : PageNotFound}
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/reported_entries"
              component={
                adminUser.show_reported ? ReportedEntries : PageNotFound
              }
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/reported_entry"
              component={adminUser.show_reported ? ReviewEntry : PageNotFound}
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/reported_entry/:submission_id"
              component={adminUser.show_reported ? ReviewEntry : PageNotFound}
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/reported_challenges"
              component={
                adminUser.show_reported ? ReportedChallenges : PageNotFound
              }
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/reported_challenge"
              component={
                adminUser.show_reported ? ReviewChallenge : PageNotFound
              }
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/reported_challenge/:challenge_id"
              component={
                adminUser.show_reported ? ReviewChallenge : PageNotFound
              }
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/review_time"
              component={adminUser.show_review_time ? ReviewTime : PageNotFound}
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/reported_reactions"
              component={
                adminUser.show_reported ? ReportedReactions : PageNotFound
              }
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/reported_hyype_groups"
              component={
                adminUser.show_reported ? ReportedHyypeGroups : PageNotFound
              }
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/reported_hyype_group_entries"
              component={
                adminUser.show_reported
                  ? ReportedHyypeGroupEntries
                  : PageNotFound
              }
            />

            <PrivateRoute
              authed={authed}
              exact
              path="/reported_video_reactions"
              component={adminUser.show_review ? ReviewReactions : PageNotFound}
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/reported_video_reactions/:reaction_id"
              component={adminUser.show_review ? ReviewReactions : PageNotFound}
            />

            <PrivateRoute
              authed={authed}
              exact
              path="/reported_members"
              component={
                adminUser.show_reported ? ReportedMembers : PageNotFound
              }
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/reported_member"
              component={
                adminUser.show_reported ? ReportedMember : PageNotFound
              }
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/reported_member/:user_id"
              component={
                adminUser.show_reported ? ReportedMember : PageNotFound
              }
            />

            <PrivateRoute
              authed={authed}
              exact
              path="/explore_entries"
              component={adminUser.show_entries ? ExploreEntries : PageNotFound}
            />

            <PrivateRoute
              authed={authed}
              exact
              path="/fulfill_home"
              component={
                adminUser.show_fulfill ? FulfillmentHome : PageNotFound
              }
            />

            <PrivateRoute
              authed={authed}
              exact
              path="/prize_ack"
              component={
                adminUser.show_fulfill ? PrizeAcknowledgementList : PageNotFound
              }
            />

            <PrivateRoute
              authed={authed}
              exact
              path="/host_payout/:challenge_id"
              component={adminUser.show_fulfill ? PayoutDetails : PageNotFound}
            />
            <PrivateRoute
              authed={authed}
              exact
              path="/challenge_prizes/:challenge_id"
              component={
                adminUser.show_fulfill ? FulfillChallengePrizes : PageNotFound
              }
            />

            <Route
              path="/forgot_password/:reset_token"
              component={ForgotPassword}
            />

            <PrivateRoute
              authed={authed}
              exact
              path="/view_host/:host_id"
              component={adminUser.show_members ? ViewHost : PageNotFound}
            />

            <PrivateRoute
              authed={authed}
              exact
              path="/payments"
              component={adminUser.show_payments ? PaymentsHome : PageNotFound}
            />

            <PrivateRoute
              authed={authed}
              exact
              path="/download_member_payouts/:downlowd_type"
              component={adminUser.show_payments ? DownloadPage : PageNotFound}
            />

            <PrivateRoute
              authed={authed}
              exact
              path="/settings"
              component={adminUser.show_settings ? SettingsHome : PageNotFound}
            />

            <PrivateRoute
              authed={authed}
              exact
              path="/hyype_groups"
              component={
                adminUser.show_hyype_groups ? HyypeGroupsHome : PageNotFound
              }
            />

            <PrivateRoute
              authed={authed}
              exact
              path="/video_reactions"
              component={
                adminUser.show_reported ? VideoReactionsTable : PageNotFound
              }
            />

            <Route component={PageNotFound} />
          </Switch>
        </div>
        <div
          className={authed ? 'Router-desktop-padder' : ''}
          style={{ background: '#fff' }}
        >
          {' '}
        </div>
      </div>
    </BrowserRouter>
  );
};

const mapStatesToProps = (state) => {
  const { logged_in, adminUser } = state.auth;
  return { logged_in, adminUser };
};

export default connect(mapStatesToProps, {})(RouterComponent);
