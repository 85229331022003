import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import { useHistory } from 'react-router-dom';
import {
  CloseCircleOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
} from '@ant-design/icons';
import { withTheme } from 'styled-components';
import { NAV_SECTIONS, CHALLENGE_TABLE_FILTERS } from '@global';
import Modal from 'react-modal';
import styles from '@entries/ExploreEntries.module.css';
import LoadingWrapper from '@components/LoadingWrapper';
import {
  MuseoText,
  BasicButton,
  BottomTableLoader,
  SearchBar,
  FilterTabs,
} from '@components';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0,0,0,0)',
    border: '0px',
  },
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.9)',
  },
};

const PlayEntryModal = ({
  submission,
  closeModal,
  showBack,
  onBack,
  showForward,
  onForward,
  index,
  theme,
}) => {
  if (!submission) return null;

  useEffect(() => {
    videoRef.current?.load();
  }, [submission]);

  const videoRef = useRef();

  return (
    <Modal
      isOpen={submission != null}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className={styles.top_cancel}>
        <div
          style={{ cursor: 'pointer', marginRight: '80px' }}
          onClick={() => closeModal()}
        >
          <CloseCircleOutlined style={{ color: '#fff', fontSize: '40px' }} />
        </div>
      </div>
      <div className={styles.video_and_buttons}>
        <div className={styles.browse_button}>
          {showBack && (
            <div
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation();
                onBack();
              }}
            >
              <LeftCircleOutlined style={{ color: '#fff', fontSize: '42px' }} />
            </div>
          )}
        </div>

        <video
          className={styles.video_container}
          controls
          autoPlay
          ref={videoRef}
        >
          <source src={submission.url.content} type="video/mp4" />
        </video>

        <div className={styles.browse_button}>
          {showForward && (
            <div
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation();
                onForward();
              }}
            >
              <RightCircleOutlined
                style={{ color: '#fff', fontSize: '42px' }}
              />
            </div>
          )}
        </div>
      </div>

      <div className={styles.video_info_container}>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            @{submission.user.username} ({submission.user.first_name}{' '}
            {submission.user.last_name})
          </div>

          <div>{submission.string_vote_count}</div>
        </div>
        <div style={{ marginTop: '4px', fontSize: '14px', color: '#999' }}>
          {submission.challenge_title} on {submission.created_at}
        </div>

        {submission.is_rejected && (
          <>
            <div style={{ marginTop: '4px', fontSize: '14px', color: '#999' }}>
              Rejected by {submission.admin_name} on {submission.reviewed_at}
            </div>

            <div style={{ marginTop: '4px', fontSize: '14px', color: '#999' }}>
              Reason: {submission.rejected_reason}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  const { recentEntries, popularEntries } = state.entry;
  return { adminUser, recentEntries, popularEntries };
};

export default connect(mapStatesToProps, {})(withTheme(PlayEntryModal));
