import React from 'react';
import { Modal, Button } from 'antd';
import { BasicButton } from '@components';
import { withTheme } from 'styled-components';
import { WarningOutlined } from '@ant-design/icons';

const ConfirmModal = ({
  title,
  visible,
  disabled,
  loading,
  onSubmit,
  onCancel,
  children,
  theme
}) => {
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={() => onCancel()}
      footer={[
        <BasicButton
          key="cancel"
          text="Cancel"
          type="default"
          onClick={() => onCancel()}
          style={{ fontSize: "14px" }}
        />,
        <BasicButton
          key="submit"
          type="primary"
          text="Confirm"
          loading={loading}
          onClick={() => onSubmit()}
          style={{ fontSize: "14px" }}
        />
      ]}
    >
      <div style={{ width: "100%", display: "flex", justifyContent: "center"}}>
        <WarningOutlined  style={{ fontSize: "64px", color: theme.colors.blurple }} />
      </div>

      <div style={{ marginTop: "24px" }}>
        {children}
      </div>
    </Modal>
  )
}

export default withTheme(ConfirmModal);
