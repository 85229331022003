import React from 'react';
import { useHistory } from 'react-router-dom';
import { BasicText, MuseoText } from '@components';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styles from './FixedHeader.module.css';

const FixedHeaderContainer = ({
  text,
  marginTop,
  onBackPressed,
  fixedContent,
  paddingBuffer,
  additionalContent,
  preventHistoryBack,
  hideHeader,
  useMuseo,
  paddingTop,
  hideMobileAdditional,
  hideBack,
  titleSize,
  children
}) => {
  const history = useHistory();

  return (
    <div className={styles.overall_conatiner}>

      <div className={styles.children_container}>
        {children}
      </div>

      <div
        className={styles.header_container}
        style={{ marginTop: marginTop ? marginTop : "", paddingTop: paddingTop ? paddingTop : "8px" }}
      >
        <div className={styles.title_container}>
          {!hideHeader &&
            <>
              {!hideBack &&
                <div
                  className={styles.back_conatiner}
                  onClick={() => {
                    onBackPressed && onBackPressed();
                    history.goBack();
                  }}
                >
                  <ArrowLeftOutlined style={{fontSize: "24px", color: "#222", marginRight: "22px", marginTop: "5px" }} />
                </div>
              }
              <div style={{ marginRight: "40px"}}>
                {useMuseo ?
                  <MuseoText bold fontSize={titleSize ? titleSize : "24px"}>{text}</MuseoText>
                :
                  <BasicText bold fontSize="20px">{text}</BasicText>
                }
              </div>
            </>
          }

          {additionalContent &&
            <div className={styles.desktop_additional}>
              {additionalContent}
            </div>
          }


        </div>

        {additionalContent && !hideMobileAdditional &&
          <div className={styles.mobile_additional}>
            {additionalContent}
          </div>
        }

        {fixedContent != null && fixedContent}
      </div>
    </div>
  )
}

export default FixedHeaderContainer;
