import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Table, Icon, Spin } from "antd";
import {
  TitleText,
  BasicText,
  ShortInput,
  LongInput,
  CopyUrl,
  BasicButton,
} from "@components";
import styles from "@components/HeaderAndTable.module.css";

const MemberTable = ({ memberList, onMemberSelected, totalMemberCount }) => {
  const getMemberTableData = () => {
    let dataArray = [];
    memberList.forEach((member, i) => {
      dataArray.push({
        id: member.id,
        username: member.username,
        status: member.status,
        first_name: member.first_name,
        last_name: member.last_name,
        submissions: member.submissions,
        challenges: member.challenges,
        joined: member.joined,
        number: totalMemberCount - i,
        referred_by: member.referred_by
      });
    });
    return dataArray;
  };

  const getColumns = () => {
    let columns = [
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Username</div>;
        },
        dataIndex: "username",
        key: "username",
        width: 100,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: 150,
                  overflow: "hidden",
                }}
              >
                <BasicText fontSize="15px" bold>
                  {text}
                </BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Joined</div>;
        },
        dataIndex: "joined",
        key: "joined",
        width: 80,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: 150,
                  overflow: "hidden",
                }}
              >
                <BasicText fontSize="15px">{text}</BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Joined #</div>;
        },
        dataIndex: "number",
        key: "number",
        width: 80,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  maxWidth: 150,
                  overflow: "hidden",
                }}
              >
                <BasicText fontSize="15px">{text}</BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>First Name</div>;
        },
        dataIndex: "first_name",
        key: "first_name",
        width: 80,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px">{text}</BasicText>,
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Last Name</div>;
        },
        dataIndex: "last_name",
        key: "last_name",
        width: 80,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px">{text}</BasicText>,
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Entries</div>;
        },
        dataIndex: "submissions",
        key: "submissions",
        width: 40,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px">{text}</BasicText>,
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Challenges</div>;
        },
        dataIndex: "challenges",
        key: "challenges",
        width: 40,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px">{text}</BasicText>,
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: "15px" }}>Referred</div>;
        },
        dataIndex: "referred_by",
        key: "referred_by",
        width: 80,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px">{text}</BasicText>,
          };
        },
      },
    ];

    return columns;
  };

  return (
    <div className={styles.member_container_style} style={{ marginTop: "40px" }}>
      <Table
        pagination={false}
        columns={getColumns()}
        dataSource={getMemberTableData()}
        rowKey="id"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              onMemberSelected(record);
            },
          };
        }}
      />
    </div>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {})(MemberTable);
