import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form, Space } from 'antd';
import { withTheme } from 'styled-components';
import { PlayCircleOutlined, CheckCircleFilled, WarningFilled, CloseSquareOutlined, CheckSquareOutlined } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, BasicButton, ShortInput, LongInput } from '@components';
import styles from './ReviewHosts.module.css';
import ResponseButtons from '@review/hosts/ResponseButtons';

const AdditionalSection = ({
  host,
  reviewJson,
  updateReviewJson,
  isView,
  theme
}) => {

  const { brand } = host;
  const company_rep = brand.company_rep;


  const charityChecks = () => {
    return brand.charity_status.checks.map((check) =>
      <div key={check.type}>
        <Space>
          <div>{check.type}: {check.status ? "Verified" : "Check Failed"}</div>
          {check.status ? <CheckCircleFilled style={{ color: theme.colors.approveGreen }} /> : <WarningFilled style={{ color: theme.colors.danger }} />}
        </Space>
      </div>
    );
  }

  return (
    <div className={styles.approve_section} style={{ marginTop: "40px" }}>
      <div className={styles.section_header_container} style={{ height: "50px" }}>
        <div className={styles.section_header}>
          Organization Info
        </div>

        {!isView && reviewJson["Organization"].decided_on &&
          <>
            {!reviewJson["Organization"].approved ?
              <div className={styles.approve_check}>
                <WarningFilled style={{ color: theme.colors.danger, fontWeight: 700, fontSize: "26px"  }} />
              </div>
            :
              <div className={styles.approve_check}>
                <CheckCircleFilled style={{ color: theme.colors.blurple, fontWeight: 700, fontSize: "26px"  }} />
              </div>
            }
          </>
        }

      </div>

      <div className={styles.attribute_title}>
        Legal Business Name
      </div>
      <div className={styles.attribute_text}>
        {brand.name}
      </div>

      <div className={styles.attribute_title}>
        Organization Website
      </div>
      <div className={styles.attribute_text}>
        {brand.organization_url}
      </div>

      <div className={styles.attribute_title}>
        Is the Host a current employee at the brand?
      </div>
      <div className={styles.attribute_text}>
        {host.is_agency ? "NO" : "YES"}
      </div>

      {host.is_agency && company_rep &&
        <div style={{ marginLeft: "40px"}}>
          <div className={styles.attribute_title}>
            Company Contact First Name
          </div>
          <div className={styles.attribute_text}>
            {company_rep.first_name}
          </div>

          <div className={styles.attribute_title}>
            Company Contact Last Name
          </div>
          <div className={styles.attribute_text}>
            {company_rep.last_name}
          </div>

          <div className={styles.attribute_title}>
            Company Contact Position
          </div>
          <div className={styles.attribute_text}>
            {company_rep.position}
          </div>

          <div className={styles.attribute_title}>
            Company Contact Email
          </div>
          <div className={styles.attribute_text}>
            {company_rep.email}
          </div>

          <div className={styles.attribute_title}>
            Company Contact Phone Number
          </div>
          <div className={styles.attribute_text}>
            {company_rep.phone_number}
          </div>
        </div>
      }

      <div className={styles.attribute_title} style={{ marginTop: "40px" }}>
        Is the company a charity organization?
      </div>
      <div className={styles.attribute_text}>
        {brand.is_charity ? "YES" : "NO"}
      </div>

      {brand.is_charity &&
        <>
          {brand.charity_status.success ?
            <div style={{ marginLeft: "40px"}}>
              <div className={styles.attribute_title}>
                EIN
              </div>
              <div className={styles.attribute_text}>
                {brand.ein}
              </div>

              <div className={styles.attribute_title}>
                <Space>
                  Charity Check Results

                  {brand.charity_status.success && brand.charity_status.checks[0].status && brand.charity_status.checks[0].status ?
                    <CheckSquareOutlined style={{ fontSize: "24px", color: theme.colors.snow, backgroundColor: theme.colors.approveGreen }} />
                  :
                    <CloseSquareOutlined style={{ fontSize: "24px", color: theme.colors.snow, backgroundColor: theme.colors.danger }} />
                  }
                </Space>
              </div>
              <div className={styles.attribute_text}>
                <div>Company Name: {brand.charity_status.company_name} (stated Company Name: {brand.name})</div>
                {charityChecks()}
              </div>
            </div>
          :
            <div style={{ marginLeft: "40px"}}>
              <div className={styles.attribute_title}>
                EIN
              </div>
              <div className={styles.attribute_text}>
                {brand.ein}
              </div>

              <div className={styles.attribute_title}>
                <Space>
                  Charity Check Results

                  {brand.charity_status.success && brand.charity_status.checks[0].status && brand.charity_status.checks[0].status ?
                    <CheckSquareOutlined style={{ fontSize: "24px", color: theme.colors.snow, backgroundColor: theme.colors.approveGreen }} />
                  :
                    <CloseSquareOutlined style={{ fontSize: "24px", color: theme.colors.snow, backgroundColor: theme.colors.danger }} />
                  }
                </Space>
              </div>
              <div className={styles.attribute_text}>
                <div>The following error occurred with the Charity Check API:</div>
                <div>{brand.charity_status.reason}</div>
              </div>
            </div>
          }
        </>
      }




      {!isView &&
        <div style={{ marginTop: "20px" }}>
          <ResponseButtons
            approveText="Approve Organization"
            rejectText="Reject Organization"
            showRejected={reviewJson["Organization"].decided_on && !reviewJson["Organization"].approved}
            rejectedReason={reviewJson["Organization"].reason}
            onApprove={() => {
              updateReviewJson({
                "Organization": { decided_on: true, approved: true }
              })
            }}
            onReject={() => {
              updateReviewJson({
                "Organization": { decided_on: true, approved: false }
              })
            }}
            updateRejectedReason={(val) => {
              updateReviewJson({
                "Organization": { decided_on: true, approved: false, reason: val }
              })
            }}
          />
        </div>
      }
    </div>
  );
};

export default connect(null, {

})(withTheme(AdditionalSection));
