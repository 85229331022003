import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import LoadingWrapper from '@components/LoadingWrapper';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { Button, Modal, Form, Space, Checkbox, Row } from 'antd';
import { withTheme } from 'styled-components';
import { PlayCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import RevenueTableSection from '@payments/RevenueTableSection';
import { TitleText, BasicText, BasicButton, MuseoText, FilterTabs } from '@components';
import { } from '@actions';
import { showSuccessMessage, showErrorMessage } from '@global';
import styles from './Payments.module.css';

const FILTER_OPTIONS = ["All time", "YTD", "Past 30d"];
const TABLE_FILTER_OPTIONS = ["Challenges", "Prize Bundles", "Tokens", "Claps"];

const RevenueHeader = ({
  revenueData,
  currentFilter,
  setCurrentFilter,
  currentTableFilter,
  setCurrentTableFilter,
  dataLoaded,
  theme
}) => {

  const [displayedStats, setDisplayedStats] = useState(null);

  useEffect(() => {
    if (revenueData) {
      setDisplayedStats(revenueData.last_thirty);
    }
  }, [revenueData]);

  const updateDisplayedStats = (selectedOption) => {
    setCurrentFilter(selectedOption);

    switch (selectedOption) {
      case FILTER_OPTIONS[0]:
        setDisplayedStats(revenueData.overall);
        break;
      case FILTER_OPTIONS[1]:
        setDisplayedStats(revenueData.year_to_date);
        break;
      case FILTER_OPTIONS[2]:
        setDisplayedStats(revenueData.last_thirty);
        break;
      default:
        return null;
    }
  }

  const revenueGrids = () => {
    if (displayedStats) {
      return (
        <div className={styles.stat_grids_container}>
          <div className={styles.stat_container}>
            <div className={styles.stat_title}>
              Total Revenue
            </div>
            <div className={styles.stat_overall_container}>
              <div className={styles.stat_overall}>
                {displayedStats.all}
              </div>
            </div>
          </div>

          <div className={styles.stat_container}>
            <div className={styles.stat_title}>
              Challenge Revenue
            </div>
            <div className={styles.stat_overall_container}>
              <div className={styles.stat_overall}>
                {displayedStats.challenges}
              </div>
            </div>
          </div>

          <div className={styles.stat_container}>
            <div className={styles.stat_title}>
              Token Revenue
            </div>
            <div className={styles.stat_overall_container}>
              <div className={styles.stat_overall}>
                {displayedStats.tokens}
              </div>
            </div>
          </div>

          <div className={styles.stat_container}>
            <div className={styles.stat_title}>
              Clap Revenue
            </div>
            <div className={styles.stat_overall_container}>
              <div className={styles.stat_overall}>
                {displayedStats.claps}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <div>
      <FilterTabs
        options={FILTER_OPTIONS}
        selectedOption={currentFilter}
        onSelectOption={(selectedOption) => {
          updateDisplayedStats(selectedOption);
        }}
      />

      {revenueGrids()}

      <FilterTabs
        options={TABLE_FILTER_OPTIONS}
        selectedOption={currentTableFilter}
        onSelectOption={(selectedOption) => {
          setCurrentTableFilter(selectedOption);
        }}
      />
    </div>
  )
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {

})(withTheme(RevenueHeader));
