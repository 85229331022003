import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form, Space } from 'antd';
import { withTheme } from 'styled-components';
import { PlayCircleOutlined, CheckCircleFilled, WarningFilled } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, BasicButton, ShortInput, LongInput } from '@components';
import styles from './ReviewHosts.module.css';
import ResponseButtons from '@review/hosts/ResponseButtons';

const StripeSection = ({
  host,
  reviewJson,
  updateReviewJson,
  theme
}) => {

  const { brand } = host;

  return (
    <div className={styles.approve_section}>
      <div className={styles.section_header_container} style={{ height: "50px" }}>
        <div className={styles.section_header}>
          Payouts
        </div>

        {reviewJson["Payouts"].decided_on &&
          <>
            {!reviewJson["Payouts"].approved ?
              <div className={styles.approve_check}>
                <WarningFilled style={{ color: theme.colors.danger, fontWeight: 700, fontSize: "26px"  }} />
              </div>
            :
              <div className={styles.approve_check}>
                <CheckCircleFilled style={{ color: theme.colors.blurple, fontWeight: 700, fontSize: "26px"  }} />
              </div>
            }
          </>
        }

      </div>

      <div className={styles.attribute_title}>
        Stripe Account Connected
      </div>
      <div className={styles.attribute_text}>
        {brand.stripe_account_id ? "Connected" : "Not Connected"}
      </div>

      {brand.stripe_account_id &&
        <>
          <div className={styles.attribute_title}>
            Account Number
          </div>
          <div className={styles.attribute_text}>
            {brand.stripe_account_id}
          </div>
        </>
      }

      <div style={{ marginTop: "20px" }}>
        <ResponseButtons
          approveText="Approve Payouts"
          rejectText="Reject Payouts"
          showRejected={reviewJson["Payouts"].decided_on && !reviewJson["Payouts"].approved}
          rejectedReason={reviewJson["Payouts"].reason}
          onApprove={() => {
            updateReviewJson({
              "Payouts": { decided_on: true, approved: true }
            })
          }}
          onReject={() => {
            updateReviewJson({
              "Payouts": { decided_on: true, approved: false }
            })
          }}
          updateRejectedReason={(val) => {
            updateReviewJson({
              "Payouts": { decided_on: true, approved: false, reason: val }
            })
          }}
        />
      </div>
    </div>
  );
};

export default connect(null, {

})(withTheme(StripeSection));
