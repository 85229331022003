import React from 'react';
import { Modal, Button } from 'antd';

const SaveOrDiscardModal = ({
  title,
  visible,
  disabled,
  loading,
  onDiscard,
  onSave,
  onCancel,
  children
}) => {
  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={() => onCancel()}
      footer={[
        <Button
          key="save"
          loading={loading}
          type="primary"
          onClick={() => onSave()}
        >
          Save
        </Button>,
        <Button
          key="discard"
          type="danger"
          onClick={() => onDiscard()}
        >
          Discard
        </Button>
      ]}
    >
      {children}
    </Modal>
  )
}

export default SaveOrDiscardModal;
