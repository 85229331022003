import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Button, Space } from 'antd';
import { MuseoText, BasicText, BasicButton } from '@components';
import { withTheme } from 'styled-components';
import '../App.css';
import { setAccountMenu, logoutUser } from '../actions';
import logo from '../images/lightning_brandmark_dark_solid.png';
import './Navbar.css';
import NavCounter from '@navigation/NavCounter';
import { getHomePathForRole, hasAccessToSection } from '@global';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';

const NavBar = ({
  adminUser,
  overallReviewPending,
  overallReportPending,
  overallFulfillPending,
  logoutUser,
  theme,
}) => {
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState(history.location.pathname);

  const isViewingPath = (path) => {
    //console.log("called with", path, currentPath);
    if (currentPath === '/' || currentPath === '/login') {
      return path === getHomePathForRole(adminUser.role);
    }
    return currentPath.includes(path);
  };

  const renderBurgerMenu = () => {
    return (
      <div className="Navbar-mobile-top-menu">
        <Button
          icon={<MenuOutlined style={{ color: '#000' }} />}
          type="link"
          onClick={() => setIsOpen(true)}
        />
        <img
          src={logo}
          alt="logo"
          style={{ width: '30px', marginLeft: '10px', cursor: 'pointer' }}
          onClick={() => setIsOpen(true)}
        />
      </div>
    );
  };

  const renderCloseButton = () => {
    return (
      <div className="Navbar-close">
        <Button
          icon={<CloseOutlined />}
          type="link"
          onClick={() => setIsOpen(false)}
          size={30}
        />
      </div>
    );
  };

  const renderLink = (path, title) => {
    return (
      <div
        className={`Router-nav-link ${
          isViewingPath(path) ? 'Router-active-link' : ''
        }`}
      >
        <Link
          to={path}
          className="Navbar-nav-link"
          onClick={() => setCurrentPath(path)}
          style={{ color: isViewingPath(path) ? theme.colors.blurple : '#222' }}
        >
          {title}
        </Link>
      </div>
    );
  };

  const renderCounterLink = (path, title, number, showDanger = false) => {
    return (
      <div
        className={`Router-nav-link ${
          isViewingPath(path) ? 'Router-active-link' : ''
        }`}
      >
        <Link
          to={path}
          className="Navbar-nav-link"
          onClick={() => setCurrentPath(path)}
          style={{ color: isViewingPath(path) ? theme.colors.blurple : '#222' }}
        >
          <div>{title}</div>
          <NavCounter showDanger={showDanger} number={number} />
        </Link>
      </div>
    );
  };

  const renderLinks = () => {
    return (
      <div>
        {hasAccessToSection(adminUser, '/overview') &&
          renderLink('/overview', 'Overview')}

        {hasAccessToSection(adminUser, '/team') && renderLink('/team', 'Team')}

        {hasAccessToSection(adminUser, '/review_team') &&
          renderLink('/review_team', 'Review Team')}

        {hasAccessToSection(adminUser, '/community') &&
          renderLink('/community', 'Community')}

        {hasAccessToSection(adminUser, '/challenges') &&
          renderLink('/challenges', 'Challenges')}

        {hasAccessToSection(adminUser, '/explore_entries') &&
          renderLink('/explore_entries', 'Entries')}

        {hasAccessToSection(adminUser, '/review_content') &&
          renderCounterLink('/review_content', 'Review', overallReviewPending)}

        {hasAccessToSection(adminUser, '/fulfill_home') &&
          renderCounterLink(
            '/fulfill_home',
            'Fulfillment',
            overallFulfillPending
          )}

        {hasAccessToSection(adminUser, '/reported') &&
          renderCounterLink(
            '/reported',
            'Reported',
            overallReportPending,
            true
          )}

        {hasAccessToSection(adminUser, '/payments') &&
          renderLink('/payments', 'Payments')}

        {hasAccessToSection(adminUser, '/settings') &&
          renderLink('/settings', 'Settings')}

        {hasAccessToSection(adminUser, '/hyype_groups') &&
          renderLink('/hyype_groups', 'Hyype Groups')}

        {hasAccessToSection(adminUser, '/video_reactions') &&
          renderLink('/video_reactions', 'Video Reactions')}

        {renderCloseButton()}
      </div>
    );
  };

  const navContent = () => {
    if (history.location.pathname.includes('/lv_st/')) {
      return (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginTop: '40px',
              marginBottom: '40px',
              marginRight: '20px',
            }}
          >
            <img
              src={logo}
              alt="logo"
              onClick={() => history.push({ pathname: '/' })}
              className="logo_nav_container"
            />

            <MuseoText marginLeft="12px" color="#222" fontSize="28px">
              hs
            </MuseoText>

            <div
              style={{
                marginLeft: '12px',
                height: '26px',
                width: '3px',
                backgroundColor: '#9e9e9e',
                borderRadius: '2px',
              }}
            />

            <div style={{ marginLeft: '12px', marginTop: '4px' }}>
              <BasicText bold fontSize="20px">
                Live Stats
              </BasicText>
            </div>
          </div>

          <div
            style={{ cursor: 'pointer', marginLeft: '8px' }}
            onClick={() => window.open('mailto:hello@hyype.space', '_blank')}
          >
            <BasicText fontSize="16px">Contact Hyype Space</BasicText>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginTop: '40px',
              marginBottom: adminUser.is_staging ? '12px' : '40px',
              marginRight: '20px',
            }}
          >
            <img
              src={logo}
              alt="logo"
              onClick={() => history.push({ pathname: '/' })}
              className="logo_nav_container"
            />

            <MuseoText marginLeft="12px" color="#222" fontSize="28px">
              hs
            </MuseoText>

            <div
              style={{
                marginLeft: '12px',
                height: '26px',
                width: '3px',
                backgroundColor: '#9e9e9e',
                borderRadius: '2px',
              }}
            />

            <div style={{ marginLeft: '12px', marginTop: '4px' }}>
              <BasicText bold fontSize="20px">
                {adminUser.display_role}
              </BasicText>
            </div>
          </div>

          {adminUser.is_staging && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100px',
                marginBottom: '12px',
                borderRadius: '8px',
                paddingTop: '2px 2px',
                backgroundColor: theme.colors.danger,
              }}
            >
              <BasicText bold color={theme.colors.snow}>
                STAGING
              </BasicText>
            </div>
          )}

          {renderLinks()}

          <div className={'mobile_logoout'}>
            <div className={`Router-nav-link`}>
              <div className="Navbar-nav-link" onClick={() => logoutUser()}>
                <div style={{ color: 'red' }}>Logout</div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div style={{ backgroundColor: '#E5E5E5' }}>
      {renderBurgerMenu()}
      <div
        style={{ paddingTop: '8px' }}
        className={`Navbar-container ${isOpen ? 'Navbar-container-open' : ''}`}
      >
        <nav
          onClick={() => {
            setIsOpen(false);
          }}
        >
          {navContent()}
        </nav>
      </div>
    </div>
  );
};

const mapStatesToProps = (state) => {
  const {
    overallReviewPending,
    overallReportPending,
    overallFulfillPending,
  } = state.review;
  const { show_account_menu, adminUser } = state.auth;
  return {
    show_account_menu,
    adminUser,
    overallReviewPending,
    overallReportPending,
    overallFulfillPending,
  };
};

export default connect(mapStatesToProps, {
  setAccountMenu,
  logoutUser,
})(withTheme(NavBar));
