import React, { useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import { EditFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Table, Icon, Spin, Switch, Modal, Form } from 'antd';
import { NAV_SECTIONS, CHALLENGE_ASSET_TYPES, hyypeGroupsDomain } from '@global';
import styles from '@components/HeaderAndTable.module.css';
import LoadingWrapper from '@components/LoadingWrapper';
import FixedHeaderContainer from '@components/FixedHeaderContainer';

import {
  MuseoText,
  BasicButton,
  ShortInput,
  SearchBar,
  BasicText,
} from '@components';
import {
  getHyypeGroups,
} from '@actions';

const HyypeGroupsHome = ({
  adminUser,
  getHyypeGroups,
  theme,
}) => {
  const [hyypeGroups, setHyypeGroups] = useState(null);
  const [searchTerm, setSearchTerm] = useState('')
  useEffect(() => {
    fetchGroups();
  }, []);

  const fetchGroups = () => {
    getHyypeGroups(
      (b) => setHyypeGroups(b),
      () => { }
    );
  };

  const domain = hyypeGroupsDomain(adminUser)

  const header = () => {
    return (
      <div className={styles.fixed_header}>
        <div className={styles.header_container}>
          <div className={styles.header_text}>
            <MuseoText>Hyype Groups</MuseoText>
          </div>
        </div>
        <SearchBar
          searchTerm={searchTerm}
          onChange={(value) => setSearchTerm(value)}
        />
      </div>
    )
  };

  const matchingGroups = useMemo(() => {
    if (!hyypeGroups) return []
    if (searchTerm.length === 0) return hyypeGroups
    const loweredSearchTerm = searchTerm.toLowerCase()
    return hyypeGroups.filter((hyypeGroup) => ['title', 'description'].some((key) => (hyypeGroup[key] || "").toLowerCase().includes(loweredSearchTerm)))
  })

  const getTableData = () => {
    if (hyypeGroups == null) return [];
    let dataArray = [];
    matchingGroups.forEach((hyypeGroup, i) => {
      dataArray.push({ ...hyypeGroup });
    });
    return dataArray;
  };

  const getColumns = () => {
    let columns = [
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Title</div>;
        },
        dataIndex: 'title',
        key: 'title',
        width: 100,
        sorter: (a, b) => (a.title || "").localeCompare(b.title || ""),
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100px',
                  justifyContent: 'space-between',
                }}
              >
                <BasicText fontSize='15px'><a href={`${domain}/groups/${record.external_id}`} target="_blank" rel="noreferrer">{text}</a></BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Description</div>;
        },
        dataIndex: 'description',
        key: 'description',
        sorter: (a, b) => (a.description || "").localeCompare(b.description || ""),
        width: 100,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100px',
                  overflow: 'hidden',
                }}
              >
                <BasicText fontSize='15px'>{text}</BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Password</div>;
        },
        dataIndex: 'share_password',
        sorter: (a, b) => (a.share_password || "").localeCompare(b.share_password || ""),
        key: 'share_password',
        width: 100,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100px',
                  overflow: 'hidden',
                }}
              >
                <BasicText fontSize='15px'>{record.share_password}</BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Entry Count</div>;
        },
        dataIndex: 'entry_count',
        key: 'entry_count',
        width: 50,
        sorter: (a, b) => a.entry_count - b.entry_count,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '50px',
                  overflow: 'hidden',
                }}
              >
                <BasicText fontSize='15px'>{record.entry_count}</BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Group View Count</div>;
        },
        dataIndex: 'view_count',
        key: 'view_count',
        width: 50,
        sorter: (a, b) => a.view_count - b.view_count,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '50px',
                  overflow: 'hidden',
                }}
              >
                <BasicText fontSize='15px'>{record.view_count || 0}</BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Created</div>;
        },
        dataIndex: 'published_at',
        key: 'published_at',
        width: 100,
        sorter: (a, b) => !a.published_at ? -1 : !b.published_at ? 1 : new Date(a.published_at).getTime() - new Date(b.published_at).getTime(),
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100px',
                  overflow: 'hidden',
                }}
              >
                <BasicText fontSize='15px'>{record.published_at ? new Date(record.published_at).toLocaleDateString('en-US') : 'Not Published'}</BasicText>
              </div>
            ),
          };
        },
      }
    ];

    return columns;
  };

  return (
    <PageWrapper>
      <FixedHeaderContainer hideHeader additionalContent={header()}>
        <LoadingWrapper
          isLoading={hyypeGroups == null}
          loadingText='Fetching Hyype Group Data'
        >
          <div style={{ marginTop: '220px' }}>
            <Table
              pagination={false}
              columns={getColumns()}
              dataSource={getTableData()}
              rowKey='id'
              rowClassName={styles.unclickable_table}
              sortDirections={['ascend', 'descend']}
            />
          </div>
        </LoadingWrapper>
      </FixedHeaderContainer>
    </PageWrapper>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getHyypeGroups,
})(withTheme(HyypeGroupsHome));