import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Card, Button, Form, Input } from 'antd';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, CustomButton, BasicButton, ShortInput, MuseoText } from '@components';
import '../App.css';
import './login.css'
import logo from '../images/leftAlignSingleWordmark_light.png';


const PageNotFound = () => {

  return (
    <div style={{
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: 'column',
      flex: 1,
      paddingTop: "80px" 
    }}>
      Page Not Found
    </div>
  );
};

// const mapStatesToProps = (state) => {
//   const { name, logged_in, login_error, force_email } = state.account;
//   const { invite_company_name } = state.company;
//   return { name, logged_in, login_error, force_email, invite_company_name };
// };

export default connect(null, {

})(PageNotFound);
