import React, { useState, useEffect } from 'react';
import PageWrapper from '@components/PageWrapper';
import { CheckOutlined, ExclamationOutlined } from '@ant-design/icons';
import { BasicButton, BasicText } from '@components';
import styles from './EditChallenge.module.css';

const CompletionStatusBar = ({
  complete_status,
  onBasicClicked,
  onBrandClicked,
  onMediaClicked,
  onPromoClicked,
  onPrizeClicked,
  onRulesClicked
}) => {

  return (
    <div className={styles.status_container}>
      <div className={styles.through_line} />

      <div className={styles.status_section_container} onClick={() => onBasicClicked()}>
        <div className={complete_status.basic_info ? styles.completed_circle : styles.incomplete_circle}>
          {complete_status.basic_info ?
            <CheckOutlined style={{fontSize: "16px", color: "#fff" }} />
          :
            <ExclamationOutlined style={{fontSize: "16px", color: "#fff" }} />
          }
        </div>
        <BasicText bold fontSize="12px">Basic</BasicText>
      </div>

      <div className={styles.status_section_container} onClick={() => onMediaClicked()}>
        <div className={complete_status.media_info ? styles.completed_circle : styles.incomplete_circle}>
          {complete_status.media_info ?
            <CheckOutlined style={{fontSize: "16px", color: "#fff" }} />
          :
            <ExclamationOutlined style={{fontSize: "16px", color: "#808080" }} />
          }
        </div>
        <BasicText bold fontSize="12px">Media</BasicText>
      </div>

      <div className={styles.status_section_container} onClick={() => onPrizeClicked()}>
        <div className={complete_status.prize_info ? styles.completed_circle : styles.incomplete_circle}>
          {complete_status.prize_info ?
            <CheckOutlined style={{fontSize: "16px", color: "#fff" }} />
          :
            <ExclamationOutlined style={{fontSize: "16px", color: "#808080" }} />
          }
        </div>
        <BasicText bold fontSize="12px">Prizes</BasicText>
      </div>

      <div className={styles.status_section_container} onClick={() => onBrandClicked()}>
        <div className={complete_status.brand_info ? styles.completed_circle : styles.incomplete_circle}>
          {complete_status.brand_info ?
            <CheckOutlined style={{fontSize: "16px", color: "#fff" }} />
          :
            <ExclamationOutlined style={{fontSize: "16px", color: "#808080" }} />
          }
        </div>
        <BasicText bold fontSize="12px">Brand</BasicText>
      </div>
    </div>
  )
};

const mapStatesToProps = (state) => {
  const { newChallengeInfo } = state.challenge;
  return { newChallengeInfo };
};

export default CompletionStatusBar;
