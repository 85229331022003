import React from 'react';
import styles from '@components/buttons/CustomButton.module.css';
import { BasicText } from '@components';
import { withTheme } from 'styled-components';
import ClipLoader from "react-spinners/ClipLoader";

const CustomButton = ({
  type,
  disabled,
  bold,
  fontSize,
  loading,
  text,
  onClick,
  theme
}) => {

  return (
    <button
      onClick={() => onClick()}
      className={styles.button_container}
      style={{ backgroundColor: disabled ? theme.colors.buttonDisabled : theme.colors.blurple }}
      type='submit'
    >
      {loading &&
        <ClipLoader
          size={22}
          color={"#fff"}
        />
      }

      {loading &&
        <div style={{ width: "20px" }} />
      }

      <BasicText textAlign="center" bold={bold} color="#ffffff" fontSize={fontSize ? fontSize : "16px"}>
        {text}
      </BasicText>
    </button>
  )
}

export default withTheme(CustomButton);
