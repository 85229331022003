import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PageWrapper from "@components/PageWrapper";
import { useHistory } from "react-router-dom";
import { fetchTeamList } from "@actions";
import { withTheme } from "styled-components";
import { NAV_SECTIONS, CHALLENGE_TABLE_FILTERS } from "@global";
import MemberTable from "@members/MemberTable";
import styles from "@components/HeaderAndTable.module.css";
import LoadingWrapper from "@components/LoadingWrapper";
import FixedHeaderContainer from "@components/FixedHeaderContainer";
import {
  MuseoText,
  BasicButton,
  BottomTableLoader,
  SearchBar,
  FilterTabs,
  ConfirmModal,
  BasicText,
} from "@components";
import { Input, Space } from "antd";
import {
  getAPIRequest,
  getMemberList,
  setRefreshMemberList,
  removeMemberFromChallenge,
} from "@actions";
import useOnScreen from "@components/useOnScreen";

const { Search } = Input;

let _searchTimeout = null;

const RemoveMember = ({
  adminUser,
  getAPIRequest,
  removeMemberFromChallenge,
  match,
  theme,
}) => {
  const [members, setMembers] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const history = useHistory();
  const challengeId = match.params?.challenge_id;
  const [loading, setLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const performSearch = () => {
    const currentSearchTerm = searchTerm;
    if (currentSearchTerm.length > 0) {
      getAPIRequest(
        "/admin/search_members_for_removal",
        { query: currentSearchTerm, challenge_id: challengeId },
        (response) => {
          if (currentSearchTerm === searchTerm) {
            setSearchResults(response.users);
          }
        },
        (error) => {
          //onFail(error.error);
        }
      );
    }
  };

  useEffect(() => {
    clearTimeout(_searchTimeout);
    if (searchTerm.length > 0) {
      _searchTimeout = setTimeout(() => performSearch(), 200);
    }
  }, [searchTerm]);

  const header = () => {
    return (
      <div style={{ marginTop: "20px" }}>
        <SearchBar
          searchTerm={searchTerm}
          onChange={(value) => setSearchTerm(value)}
        />
      </div>
    );
  };

  const removeMember = () => {
    setLoading(true);
    removeMemberFromChallenge(
      challengeId,
      selectedUser.id,
      () => {
        setSearchResults([]);
        setSearchTerm("");
        setSelectedUser(null);
        setLoading(false);
      },
      () => {}
    );
  };

  return (
    <PageWrapper>
      <LoadingWrapper loadingText="Fetching Member Data" size="large">
        <FixedHeaderContainer
          text={"Remove Member"}
          paddingBuffer="80px"
          paddingTop="56px"
          fixedContent={header()}
        >
          <>
            <MemberTable
              memberList={searchResults}
              onMemberSelected={(member) => {
                setSelectedUser(member);
              }}
            />
          </>
        </FixedHeaderContainer>
      </LoadingWrapper>

      {selectedUser != null && (
        <ConfirmModal
          title={"Confirm Remove"}
          visible={selectedUser != null}
          onCancel={() => setSelectedUser(null)}
          loading={loading}
          onSubmit={() => {
            removeMember();
          }}
        >
          <BasicText marginBottom="20px">
            {`Are you sure you want to remove ${selectedUser.username} from this Challenge?`}
          </BasicText>
        </ConfirmModal>
      )}
    </PageWrapper>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getAPIRequest,
  removeMemberFromChallenge,
})(withTheme(RemoveMember));
