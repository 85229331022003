import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import { useHistory } from 'react-router-dom';
import { Button, Modal, Form, Space, Checkbox, Row, Tabs } from 'antd';
const { TabPane } = Tabs;
import { withTheme } from 'styled-components';
import { NAV_SECTIONS } from '@global';
import styles from '@components/HeaderAndTable.module.css';
import BundleHome from '@settings/BundleHome';
import HyypeGroupTemplates from '@settings/HyypeGroupTemplates';
import PaymentSettings from '@settings/PaymentSettings';
import FeedSettings from '@settings/FeedSettings';
import LoadingWrapper from '@components/LoadingWrapper';
import {
  MuseoText,
  BasicButton,
  BottomTableLoader,
  SearchBar,
} from '@components';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import PromoSettings from './PromoSettings';

const SettingsHome = ({ adminUser, theme }) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const onSelectedOption = (option) => {
    setSelectedTab(option);
  };

  const tabSelectorOptions = () => {
    return [
      'Payment Settings',
      'Prize Bundles',
      'Feed Settings',
      'Hyype Group Templates',
      'Promos',
    ].map((stat, index) => <TabPane key={index} value={index} tab={stat} />);
  };

  const header = () => {
    return (
      <div className={styles.fixed_header}>
        <div className={styles.header_container}>
          <div className={styles.header_text}>
            <MuseoText>Settings</MuseoText>
          </div>
        </div>

        <div className={styles.radio_container} style={{ marginTop: '0px' }}>
          <Tabs
            defaultActiveKey={
              selectedTab === 0 || selectedTab === '0' ? '0' : '4'
            }
            onChange={(e) => onSelectedOption(e)}
          >
            {tabSelectorOptions()}
          </Tabs>
        </div>
      </div>
    );
  };

  const content = () => {
    if (selectedTab === 0 || selectedTab === '0') {
      return <PaymentSettings />;
    } else if (selectedTab === 1 || selectedTab === '1') {
      return <BundleHome />;
    } else if (selectedTab === 2 || selectedTab === '2') {
      return <FeedSettings />;
    } else if (selectedTab === 3 || selectedTab === '3') {
      return <HyypeGroupTemplates />;
    } else {
      return <PromoSettings />;
    }
  };

  return (
    <PageWrapper>
      <FixedHeaderContainer hideHeader additionalContent={header()}>
        {content()}
      </FixedHeaderContainer>
    </PageWrapper>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {})(withTheme(SettingsHome));
