import React, { useState, useEffect } from 'react';
import styles from './Review.module.css';
import { connect } from 'react-redux';
import { Button, Modal, Radio, Space } from 'antd';
import PageWrapper from '@components/PageWrapper';
import LoadingWrapper from '@components/LoadingWrapper';
import { useHistory } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { BasicButton, MuseoText, BasicText } from '@components';

const RejectReasonsModal = ({
  visible,
  onCancel,
  onSelectedReason,
  rejectLoading,
  reasons,
  isReport,
  theme
}) => {

  const [reason, setReason] = useState(null);

  return (
    <Modal
      title={isReport ? "Removal Reasons" : "Rejected Reasons"}
      visible
      onCancel={() => onCancel()}
      footer={[
        <BasicButton
          key="cancel"
          text="Cancel"
          type="default"
          onClick={() => onCancel()}
          style={{ fontSize: "14px" }}
        />,
        <BasicButton
          key="submit"
          type="primary"
          text={isReport ? "Submit Removal" : "Submit Rejection"}
          loading={rejectLoading}
          onClick={() => onSelectedReason(reason)}
          style={{ fontSize: "14px" }}
          disabled={reason == null}
        />
      ]}
    >
      <Radio.Group onChange={(e) => setReason(e.target.value)} value={reason}>
        <Space direction="vertical">
          {reasons.map((reason) =>
            <Radio key={reason} value={reason}>{reason}</Radio>
          )}
        </Space>
      </Radio.Group>
    </Modal>
  );
};

export default connect(null, {

})(withTheme(RejectReasonsModal));
