import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Form, Space } from 'antd';
import { withTheme } from 'styled-components';
import { PlayCircleOutlined, CheckCircleFilled, WarningFilled } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import { TitleText, BasicText, BasicButton, ShortInput, LongInput } from '@components';
import styles from './ReviewChallenge.module.css';
import ResponseButtons from '@review/hosts/ResponseButtons';
import { formatPhoneNumber } from '@global';

const BasicSection = ({
  challenge,
  reviewJson,
  updateReviewJson,
  theme
}) => {

  return (
    <div className={styles.approve_section}>
      <div className={styles.section_header_container}>
        <div className={styles.section_header}>
          Basic Information
        </div>

        {reviewJson["Basic"].decided_on &&
          <>
            {!reviewJson["Basic"].approved ?
              <div className={styles.approve_check}>
                <WarningFilled style={{ color: theme.colors.danger, fontWeight: 700, fontSize: "26px"  }} />
              </div>
            :
              <div className={styles.approve_check}>
                <CheckCircleFilled style={{ color: theme.colors.blurple, fontWeight: 700, fontSize: "26px"  }} />
              </div>
            }
          </>
        }
      </div>

      <div className={styles.attribute_title}>
        Creator
      </div>

      {challenge.is_ugc ?
        <div className={styles.attribute_text}>
          {challenge.user.first_name} {challenge.user.last_name} (@{challenge.user.username})
        </div>
      :
      <div className={styles.attribute_text}>
        {challenge.brand_info.display_name ? challenge.brand_info.display_name : challenge.brand_info.name}
      </div>
      }


      <div className={styles.attribute_title}>
        Challenge Title
      </div>
      <div className={styles.attribute_text}>
        {challenge.basic_info.title}
      </div>

      <div className={styles.attribute_title}>
        Challenge Description
      </div>
      <div className={styles.attribute_text}>
        {challenge.basic_info.description}
      </div>

      <Space size={160}>
        <div>
          <div className={styles.attribute_title}>
            Drop Date
          </div>
          <div className={styles.attribute_text}>
            {challenge.basic_info.drop_date}
          </div>
        </div>

        <div>
          <div className={styles.attribute_title}>
            End Date
          </div>
          <div className={styles.attribute_text}>
            {challenge.basic_info.end_date}
          </div>
        </div>
      </Space>

      <div className={styles.attribute_title}>
        Visibility
      </div>
      <div className={styles.attribute_text}>
        {challenge.visibility}
      </div>


      <div style={{ marginTop: "20px" }}>
        <ResponseButtons
          approveText="Approve Basic"
          rejectText="Reject Basic"
          showRejected={reviewJson["Basic"].decided_on && !reviewJson["Basic"].approved}
          rejectedReason={reviewJson["Basic"].reason}
          onApprove={() => {
            updateReviewJson({
              "Basic": { decided_on: true, approved: true }
            })
          }}
          onReject={() => {
            updateReviewJson({
              "Basic": { decided_on: true, approved: false }
            })
          }}
          updateRejectedReason={(val) => {
            updateReviewJson({
              "Basic": { decided_on: true, approved: false, reason: val }
            })
          }}
        />
      </div>
    </div>
  );
};

export default connect(null, {

})(withTheme(BasicSection));
