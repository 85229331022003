import {
  REFRESH_MEMBER_LIST
} from '@actions/types';
import {
  getAPIRequest,
  postAPIRequest
} from '@actions';
import { showSuccessMessage, showErrorMessage } from '@global';

export const getHostList = (seen_ids, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_host_list',
        {
          seen_ids
        },
        response => {
          onSuccess(response.hosts);
        },
        error => {
          onFail(error);
        }
      )
    );
  };
};

export const getHostInfo = (brand_id, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_host_info',
        {
          brand_id
        },
        response => {
          onSuccess(response);
        },
        error => {
          onFail(error);
        }
      )
    );
  };
};

export const getHostChallenges = (brand_id, onSuccess, onFail) => {
  return (dispatch) => {
    dispatch(
      getAPIRequest(
        '/admin/get_host_challenges',
        {
          brand_id
        },
        response => {
          onSuccess(response.challenges);
        },
        error => {
          onFail(error);
        }
      )
    );
  };
};

export const refundChallengePayment = (challenge_payment_id, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/refund_challenge_payment',
        {
          challenge_payment_id
        },
        response => {
          if (response.success) {
            showSuccessMessage("Refund success!")
            onSuccess(response.purchases);
          } else {
            showErrorMessage(response.reason);
            onFail();
          }
        },
        error => {
          showErrorMessage("Something went wrong.");
          onFail("Something went wrong.");
        },
      ),
    );
  };
};

export const refundBundlePayment = (prize_bundle_id, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/refund_prize_bundle',
        {
          prize_bundle_id
        },
        response => {
          if (response.success) {
            showSuccessMessage("Refund success!")
            onSuccess(response.purchases);
          } else {
            showErrorMessage(response.reason);
            onFail();
          }
        },
        error => {
          showErrorMessage("Something went wrong.");
          onFail("Something went wrong.");
        },
      ),
    );
  };
};

export const suspendHost = (host_id, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/suspend_host',
        {
          host_id
        },
        response => {
          if (response.success) {
            showSuccessMessage("Host Suspended.")
            onSuccess(response.host);
          } else {
            showErrorMessage(response.reason);
            onFail();
          }
        },
        error => {
          showErrorMessage("Something went wrong.");
          onFail("Something went wrong.");
        },
      ),
    );
  };
};

export const unSuspendHost = (host_id, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/unsuspend_host',
        {
          host_id
        },
        response => {
          if (response.success) {
            showSuccessMessage("Host Re-instated.")
            onSuccess(response.host);
          } else {
            showErrorMessage(response.reason);
            onFail();
          }
        },
        error => {
          showErrorMessage("Something went wrong.");
          onFail("Something went wrong.");
        },
      ),
    );
  };
};

export const toggleChargingForBrand = (brand_id, should_charge, onSuccess, onFail) => {
  return (dispatch, getState) => {
    dispatch(
      postAPIRequest(
        '/admin/toggle_charging_for_brand',
        {
          brand_id, should_charge
        },
        response => {
          onSuccess(response.host);
        },
        error => {
          showErrorMessage("Something went wrong.");
          onFail("Something went wrong.");
        },
      ),
    );
  };
};
