import React from 'react';
import styles from './Members.module.css';

const MemberStatsInfo = ({ member }) => {
  const { stats } = member;

  console.log(member);

  const gridStat = (number, stat) => {
    return (
      <div className={styles.grid_stat_container}>
        <div className={styles.grid_trio_number}>{number}</div>
        <div className={styles.grid_trio_text}>{stat}</div>
      </div>
    );
  };

  return (
    <div className={styles.image_and_stats}>
      <img src={member.image_url} className={styles.profile_image} />

      <div className={styles.overall_stats_grid}>
        <div className={styles.grid_trio_item}>
          {gridStat(stats.following, 'Following')}
        </div>
        <div className={styles.grid_trio_item}>
          {gridStat(stats.followers, 'Followers')}
        </div>

        <div className={styles.grid_trio_item}>
          {gridStat(stats.claps_received, 'Claps Received')}
        </div>

        <div className={styles.grid_trio_item}>
          {gridStat(stats.submissions, 'Entries')}
        </div>
        <div className={styles.grid_trio_item}>
          {gridStat(stats.challenges, 'Challenges')}
        </div>

        <div className={styles.grid_trio_item}>
          {gridStat(stats.prizes, 'Prizes won')}
        </div>
      </div>
    </div>
  );
};

export default MemberStatsInfo;
