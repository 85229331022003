import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, Form } from 'antd';
import { withTheme } from 'styled-components';
import PageWrapper from '@components/PageWrapper';
import LoadingWrapper from '@components/LoadingWrapper';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { EditFilled } from '@ant-design/icons';
import { Table, Icon, Spin } from 'antd';
import florrette from '@images/prizeFloretteSolid_shadow.png';
import {
  TitleText,
  BasicText,
  ShortInput,
  LongInput,
  CopyUrl,
  BasicButton,
  DropDown,
  ConfirmModal,
} from '@components';
import styles from '@components/HeaderAndTable.module.css';
import { showSuccessMessage, showErrorMessage, CHALLENGE_TYPES } from '@global';
import {
  getPrizesData,
  fulfillPrizesForChallenge,
  fulfillIndividualPrize,
} from '@actions';

const FulfillChallengePrizes = ({
  getPrizesData,
  match,
  fulfillPrizesForChallenge,
  fulfillIndividualPrize,
  theme,
}) => {
  const history = useHistory();
  const [prizeData, setPrizeData] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [retryLoadingId, setRetryLoadingId] = useState(null);
  const [prizeModal, setPrizeModal] = useState(null);

  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchDetails = () => {
    getPrizesData(
      match.params.challenge_id,
      (details) => {
        setPrizeData(details);
      },
      () => {}
    );
  };

  const getPrizeTableData = () => {
    if (prizeData == null) return [];
    let dataArray = [];
    prizeData.prizes.forEach((prize, i) => {
      dataArray.push({
        id: prize.id,
        final_rank: prize.final_rank,
        title: prize.title,
        winner: prize.user ? prize.user.winner : 'N/A',
        vote_count: prize.vote_count,
        status: prize.status,
        wegift_error: prize.wegift_error,
        is_fulfilled: prize.is_fulfilled,
        image_url: prize.image_url,
        place_string: prize.place_string,
        description: prize.description,
      });
    });
    console.log(dataArray);
    return dataArray;
  };

  const getLastColumn = () => {
    return {
      title: () => {
        return <div style={{ fontSize: '15px' }}>Status</div>;
      },
      dataIndex: 'status',
      key: 'status',
      width: 80,
      render(text, record) {
        return {
          children: (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              {text === 'Error' && !record.is_fulfilled ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    className={styles.error_text}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowErrorModal(record.wegift_error);
                    }}
                  >
                    Error
                  </div>
                  <BasicButton
                    style={{
                      width: '52px',
                      height: '30px',
                      borderRadius: '20px',
                      fontSize: '14px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    type="primary"
                    text="Retry"
                    loading={record.id === retryLoadingId}
                    stopPropagation
                    onClick={() => {
                      onRetry(record.id);
                    }}
                  />
                </div>
              ) : (
                <BasicText bold fontSize="15px">
                  {text}
                </BasicText>
              )}
            </div>
          ),
        };
      },
    };
  };

  const getColumns = () => {
    let columns = [
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Final Rank</div>;
        },
        dataIndex: 'final_rank',
        key: 'final_rank',
        width: 40,
        render(text, record) {
          return {
            children: (
              <BasicText fontSize="15px" bold>
                {text}
              </BasicText>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Prize</div>;
        },
        dataIndex: 'title',
        key: 'title',
        width: 100,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: 150,
                  overflow: 'hidden',
                }}
              >
                <BasicText fontSize="14px">{text}</BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Winner</div>;
        },
        dataIndex: 'winner',
        key: 'winner',
        width: 100,
        render(text, record) {
          return {
            children: <BasicText fontSize="14px">{text}</BasicText>,
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Claps</div>;
        },
        dataIndex: 'vote_count',
        key: 'vote_count',
        width: 80,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px">{text}</BasicText>,
          };
        },
      },
      getLastColumn(),
    ];

    return columns;
  };

  const actionContent = () => {
    if (prizeData.challenge.challenge_type === CHALLENGE_TYPES.CUSTOM_PRIZE) {
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <BasicButton
            text="EMAIL HOST"
            type="primary"
            onClick={() => {
              window.open(`mailto:${prizeData.host_email}`, '_blank');
            }}
            style={{ width: '200px', height: '46px', fontSize: '18px' }}
          />

          <BasicButton
            text="FULFILL ALL"
            type="primary"
            onClick={() => setShowConfirm(true)}
            disabled={prizeData.attempted_fulfill}
            style={{
              width: '200px',
              height: '46px',
              fontSize: '18px',
              marginLeft: '20px',
            }}
          />
        </div>
      );
    } else {
      return (
        <BasicButton
          text="FULFILL ALL"
          type="primary"
          onClick={() => setShowConfirm(true)}
          disabled={prizeData.attempted_fulfill}
          style={{ width: '200px', height: '46px', fontSize: '18px' }}
        />
      );
    }
  };

  const onConfirmFulfill = () => {
    setLoading(true);
    fulfillPrizesForChallenge(
      prizeData.challenge.id,
      (details) => {
        setPrizeData(details);
        setShowConfirm(false);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
      }
    );
  };

  const onRetry = (prizeId) => {
    setRetryLoadingId(prizeId);
    fulfillIndividualPrize(
      prizeId,
      (details) => {
        setPrizeData(details);
        setShowConfirm(false);
        setRetryLoadingId(null);
      },
      (error) => {
        setRetryLoadingId(null);
      }
    );
  };

  return (
    <PageWrapper>
      <LoadingWrapper
        isLoading={prizeData == null}
        loadingText="Fetching Payout Data"
        size="large"
      >
        <FixedHeaderContainer
          text={
            prizeData != null
              ? `${prizeData.challenge.title} - Prize Fulfillment`
              : ''
          }
          fixedContent={null}
          paddingBuffer="80px"
          paddingTop="54px"
          useMuseo
        >
          {prizeData && (
            <div style={{ marginTop: '120px' }}>
              <div
                className={styles.clickable_link}
                onClick={() => {
                  history.push({
                    pathname: `/challenge_stats/${prizeData.challenge.id}`,
                  });
                }}
              >
                View Challenge
              </div>

              <div className={styles.prize_header}>{actionContent()}</div>

              <div
                className={styles.table_container_style}
                style={{ marginTop: '40px' }}
              >
                <Table
                  pagination={false}
                  columns={getColumns()}
                  dataSource={getPrizeTableData()}
                  rowKey="id"
                  onRow={(record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        setPrizeModal(record);
                      },
                    };
                  }}
                />
              </div>
            </div>
          )}
        </FixedHeaderContainer>
      </LoadingWrapper>

      {showConfirm && (
        <ConfirmModal
          title={'Confirm Fulfillment?'}
          visible={showConfirm}
          loading={loading}
          onCancel={() => setShowConfirm(false)}
          onSubmit={() => onConfirmFulfill()}
        >
          <div style={{ marginBottom: '20px' }}>
            <BasicText>
              An email will be sent to the prize recipients that their prize has
              been fulfilled. This action cannot be undone.
            </BasicText>
          </div>
        </ConfirmModal>
      )}

      {showErrorModal != null && (
        <Modal
          title={'Fulfill Prize Error'}
          visible={showErrorModal}
          footer={null}
          onCancel={() => setShowErrorModal(null)}
        >
          <div style={{ marginBottom: '20px' }}>
            <BasicText>{showErrorModal}</BasicText>
          </div>
        </Modal>
      )}

      {prizeModal && (
        <Modal
          title="Prize Details"
          visible={prizeModal}
          footer={null}
          onCancel={() => setPrizeModal(null)}
          className={styles.prize_modal_container}
        >
          <div className={styles.prize_modal_container}>
            <div className={styles.prize_modal_place}>
              {prizeModal.place_string}
            </div>
            <div className={styles.modal_florrette_container}>
              <img
                src={florrette}
                alt="logo"
                className={styles.modal_florrette}
              />
              <div className={styles.modal_prize_image_container}>
                <img
                  src={prizeModal.image_url}
                  alt="logo"
                  className={styles.modal_prize_image}
                />
              </div>
            </div>
            <div className={styles.modal_prize_text}>{prizeModal.title}</div>

            <div className={styles.modal_prize_description}>
              {prizeModal.description}
            </div>
          </div>
        </Modal>
      )}
    </PageWrapper>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getPrizesData,
  fulfillPrizesForChallenge,
  fulfillIndividualPrize,
})(withTheme(FulfillChallengePrizes));
