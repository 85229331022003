import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, Form } from 'antd';
import { withTheme } from 'styled-components';
import PageWrapper from '@components/PageWrapper';
import LoadingWrapper from '@components/LoadingWrapper';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { EditFilled } from '@ant-design/icons';
import { Table, Icon, Spin } from 'antd';
import {
  TitleText,
  BasicText,
  ShortInput,
  LongInput,
  CopyUrl,
  BasicButton,
  DropDown,
  ConfirmModal,
} from '@components';
import styles from '@components/HeaderAndTable.module.css';
import { showSuccessMessage, showErrorMessage } from '@global';
import { getHostPayoutDetails, editHostSplit, sendHostPayout } from '@actions';

const PayoutDetails = ({
  getHostPayoutDetails,
  editHostSplit,
  sendHostPayout,
  match,
  theme,
}) => {
  const history = useHistory();
  const [payoutDetails, setPayoutDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hostSplit, setHostSplit] = useState(null);
  const [showEditSplit, setShowEditSplit] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  console.log(hostSplit);

  useEffect(() => {
    fetchDetails();
  }, []);

  const fetchDetails = () => {
    getHostPayoutDetails(
      match.params.challenge_id,
      (details) => {
        setHostSplit(details.host_percentage);
        setPayoutDetails(details);
      },
      () => {}
    );
  };

  const getChallengeTableData = () => {
    if (payoutDetails == null) return [];
    let dataArray = [];
    [payoutDetails].forEach((challenge, i) => {
      dataArray.push({
        id: challenge.id,
        entry_fee: challenge.entry_fee,
        num_entrants: challenge.num_entrants,
        total_raised: challenge.total_raised_string,
        host_percentage: challenge.host_percentage,
        host_payout: challenge.host_payout_string,
        status: challenge.status,
        change_split_text: challenge.change_split_text,
      });
    });
    return dataArray;
  };

  const getColumns = () => {
    let columns = [
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Entry Fee</div>;
        },
        dataIndex: 'entry_fee',
        key: 'entry_fee',
        width: 100,
        render(text, record) {
          return {
            children: (
              <BasicText fontSize="15px" bold>
                {text}
              </BasicText>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}># Entrants</div>;
        },
        dataIndex: 'num_entrants',
        key: 'num_entrants',
        width: 80,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: 150,
                  overflow: 'hidden',
                }}
              >
                <BasicText fontSize="15px">{text}</BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Total Raised</div>;
        },
        dataIndex: 'total_raised',
        key: 'total_raised',
        width: 80,
        render(text, record) {
          return {
            children: <BasicText fontSize="14px">{text}</BasicText>,
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Host Split</div>;
        },
        dataIndex: 'host_percentage',
        key: 'host_percentage',
        width: 80,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <BasicText fontSize="15px">{text}</BasicText>
                {!payoutDetails.brand_paid && (
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowEditSplit(true)}
                  >
                    <EditFilled
                      style={{
                        color: theme.colors.blurple,
                        fontSize: '20px',
                        marginTop: '4px',
                      }}
                    />
                  </div>
                )}
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Host Split</div>;
        },
        dataIndex: 'host_payout',
        key: 'host_payout',
        width: 80,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px">{text}</BasicText>,
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Status</div>;
        },
        dataIndex: 'status',
        key: 'status',
        width: 80,
        render(text, record) {
          return {
            children: <BasicText fontSize="15px">{text}</BasicText>,
          };
        },
      },
    ];

    return columns;
  };

  const onSubmitChangeSplit = () => {
    setLoading(true);
    editHostSplit(
      payoutDetails.id,
      hostSplit,
      (details) => {
        setPayoutDetails(details);
        setShowEditSplit(false);
        setLoading(false);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const onConfirmSendPayout = () => {
    setLoading(true);
    sendHostPayout(
      payoutDetails.id,
      (details) => {
        setPayoutDetails(details);
        setShowConfirm(false);
        setLoading(false);
        showSuccessMessage('Transfer to Host initiated on Stripe!');
      },
      (error) => {
        showErrorMessage(error);
        setLoading(false);
      }
    );
  };

  const actionContent = () => {
    if (payoutDetails.brand_paid) {
      return (
        <>
          {payoutDetails.host_payout > 0 ? (
            <div>
              <BasicText>
                The host was paid on {payoutDetails.paid_date}.
              </BasicText>
              <div style={{ marginTop: '12px' }}>
                <div
                  className={styles.clickable_link}
                  onClick={() => {
                    window.open(
                      `https://dashboard.stripe.com/connect/accounts/${payoutDetails.stripe_account_id}`,
                      '_blank'
                    );
                  }}
                >
                  View Host Account on Stripe
                </div>
              </div>
            </div>
          ) : (
            <div>
              <BasicText>
                Marked complete on {payoutDetails.paid_date}.
              </BasicText>
            </div>
          )}
        </>
      );
    } else {
      if (payoutDetails.stripe_account_id == null) {
        return (
          <div style={{ maxWidth: '400px' }}>
            <BasicText>
              The host has removed their Stripe Connect account. Pleaes reach
              out to the host and inform them to add a Stripe Connect account in
              their Host portal.
            </BasicText>
            <div style={{ marginTop: '12px' }}>
              <BasicButton
                text={'Email Host'}
                type="primary"
                onClick={() => {
                  window.open(`mailto:${payoutDetails.host_email}`, '_blank');
                }}
              />
            </div>
          </div>
        );
      } else {
        return (
          <div style={{ marginTop: '12px' }}>
            <BasicButton
              text={
                payoutDetails.host_payout > 0 ? 'Send Payout' : 'Mark Complete'
              }
              type="primary"
              onClick={() => {
                setShowConfirm(true);
              }}
            />
          </div>
        );
      }
    }
  };

  return (
    <PageWrapper>
      <LoadingWrapper
        isLoading={payoutDetails == null}
        loadingText="Fetching Payout Data"
        size="large"
      >
        <FixedHeaderContainer
          text={
            payoutDetails != null ? `${payoutDetails.title} - Host payout` : ''
          }
          fixedContent={null}
          paddingBuffer="80px"
          paddingTop="54px"
          useMuseo
        >
          <div style={{ marginTop: '120px' }}>
            <div
              className={styles.clickable_link}
              onClick={() => {
                history.push({
                  pathname: `/challenge_stats/${payoutDetails.id}`,
                });
              }}
            >
              View Challenge
            </div>
            <div
              className={styles.table_container_style}
              style={{ marginTop: '40px' }}
            >
              <Table
                pagination={false}
                columns={getColumns()}
                dataSource={getChallengeTableData()}
                rowKey="id"
              />
            </div>

            <div style={{ marginTop: '40px' }}>
              {payoutDetails && actionContent()}
            </div>
          </div>
        </FixedHeaderContainer>
      </LoadingWrapper>

      {payoutDetails != null && (
        <Modal
          title="Edit Host Split"
          visible={showEditSplit}
          onCancel={() => setShowEditSplit(false)}
          footer={null}
        >
          <BasicText>{payoutDetails.change_split_text}</BasicText>
          <div style={{ marginTop: '24px' }}>
            <BasicText bold>
              Edit Split (max {payoutDetails.is_charity ? '60%' : '50%'})
            </BasicText>
            <div style={{ marginTop: '12px' }}>
              <DropDown
                options={payoutDetails.percentage_options}
                placeholder="Host Split"
                onSelect={(value) => {
                  setHostSplit(value);
                }}
                defaultOption={hostSplit}
                minWidth="100%"
              />
            </div>
          </div>

          <div
            style={{
              marginTop: '40px',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <BasicButton
              type="default"
              text="Cancel"
              onClick={() => setShowEditSplit(false)}
              style={{ width: '48%' }}
            />

            <BasicButton
              type="primary"
              text="Submit"
              disabled={hostSplit === payoutDetails.host_percentage}
              onClick={() => onSubmitChangeSplit()}
              style={{ width: '48%' }}
              loading={loading}
            />
          </div>
        </Modal>
      )}

      {showConfirm && (
        <ConfirmModal
          title={
            payoutDetails.host_payout > 0 ? 'Confirm Payout?' : 'Mark Complete'
          }
          visible={showConfirm}
          loading={loading}
          onCancel={() => setShowConfirm(false)}
          onSubmit={() => onConfirmSendPayout()}
        >
          {payoutDetails.host_payout > 0 ? (
            <>
              <BasicText useSpan>
                You have selected to send a payout via Stripe Vendor payments to
                <BasicText useSpan bold>
                  {` ${payoutDetails.brand_name} `}
                </BasicText>
                in the amount of
                <BasicText useSpan bold>
                  {` ${payoutDetails.host_payout_string}.`}
                </BasicText>
              </BasicText>

              <div style={{ marginTop: '16px' }}>
                <BasicText>
                  An email will be sent to the host receiving the payout that
                  the payout has been processed. This action cannot be undone.
                </BasicText>
              </div>
            </>
          ) : (
            <BasicText>
              Confirm you want to mark this payout complete. No transfers will
              be sent to the Host because the current payout value is $0.
            </BasicText>
          )}
        </ConfirmModal>
      )}
    </PageWrapper>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getHostPayoutDetails,
  editHostSplit,
  sendHostPayout,
})(withTheme(PayoutDetails));
