import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import { EditFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { withTheme } from 'styled-components';
import { Table, Icon, Spin, Switch, Modal, Form } from 'antd';
import { NAV_SECTIONS } from '@global';
import styles from '@components/HeaderAndTable.module.css';
import LoadingWrapper from '@components/LoadingWrapper';
import {
  MuseoText,
  BasicButton,
  ShortInput,
  SearchBar,
  BasicText,
} from '@components';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import {
  getBundleOfferings,
  editBundleName,
  showBundle,
  hideBundle,
  createBundle,
} from '@actions';

const DEFAULT_PRIZES = [
  {
    name: 'First Place Prize',
    face_value: null,
    final_rank: 1,
    image_url: 'https://dfpmi9nhks3a5.cloudfront.net/prizeBundleImage_1st.png',
  },
  {
    name: 'Second Place Prize',
    face_value: null,
    final_rank: 2,
    image_url: 'https://dfpmi9nhks3a5.cloudfront.net/prizeBundleImage_2nd.png',
  },
  {
    name: 'Third Place Prize',
    face_value: null,
    final_rank: 3,
    image_url: 'https://dfpmi9nhks3a5.cloudfront.net/prizeBundleImage_3rd.png',
  },
];

const BundleHome = ({
  adminUser,
  getBundleOfferings,
  editBundleName,
  showBundle,
  hideBundle,
  createBundle,
  theme,
}) => {
  const [bundles, setBundles] = useState(null);
  const [showEditName, setShowEditName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [newBundle, setNewBundle] = useState(null);

  console.log(newBundle);

  useEffect(() => {
    fetchBundles();
  }, []);

  const fetchBundles = () => {
    getBundleOfferings(
      (b) => setBundles(b),
      () => {}
    );
  };

  const getBundleTableData = () => {
    if (bundles == null) return [];
    let dataArray = [];
    bundles.forEach((bundle, i) => {
      dataArray.push({
        id: bundle.id,
        name: bundle.display_name,
        first_place: bundle.prizes[0].face_value_string,
        second_place: bundle.prizes[1].face_value_string,
        third_place: bundle.prizes[2].face_value_string,
        total_value: bundle.total_value_string,
        is_visible: bundle.is_visible,
        host_price: bundle.host_price,
      });
    });
    return dataArray;
  };

  const getLastColumn = () => {
    return {
      title: () => {
        return <div style={{ fontSize: '15px' }}></div>;
      },
      dataIndex: 'visible',
      key: 'visible',
      width: 160,
      render(text, record) {
        return {
          children: (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Switch
                defaultChecked={record.is_visible}
                onChange={(checked) => {
                  checked ? onShowBundle(record) : onHideBundle(record);
                }}
              />
            </div>
          ),
        };
      },
    };
  };

  const getColumns = () => {
    let columns = [
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Name</div>;
        },
        dataIndex: 'name',
        key: 'name',
        width: 180,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  minWidth: '180px',
                  display: 'flex',
                  alignItems: 'center',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <BasicText fontSize='15px'>{text}</BasicText>
                <div
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowEditName(record)}
                >
                  <EditFilled
                    style={{
                      color: theme.colors.blurple,
                      fontSize: '20px',
                      marginTop: '4px',
                    }}
                  />
                </div>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>1st Place</div>;
        },
        dataIndex: 'first_place',
        key: 'first_place',
        width: 60,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: 60,
                  overflow: 'hidden',
                }}
              >
                <BasicText fontSize='15px'>{text}</BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>2nd Place</div>;
        },
        dataIndex: 'second_place',
        key: 'second_place',
        width: 60,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: 60,
                  overflow: 'hidden',
                }}
              >
                <BasicText fontSize='15px'>{text}</BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>3rd Place</div>;
        },
        dataIndex: 'third_place',
        key: 'third_place',
        width: 60,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: 60,
                  overflow: 'hidden',
                }}
              >
                <BasicText fontSize='15px'>{text}</BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Price</div>;
        },
        dataIndex: 'host_price',
        key: 'host_price',
        width: 80,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: 150,
                  overflow: 'hidden',
                }}
              >
                <BasicText fontSize='15px' bold>
                  {text}
                </BasicText>
              </div>
            ),
          };
        },
      },
      {
        title: () => {
          return <div style={{ fontSize: '15px' }}>Status</div>;
        },
        dataIndex: 'is_visible',
        key: 'is_visible',
        width: 80,
        render(text, record) {
          return {
            children: (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  maxWidth: 150,
                  overflow: 'hidden',
                }}
              >
                <BasicText fontSize='15px'>
                  {record.is_visible ? 'Live' : 'Inactive'}
                </BasicText>
              </div>
            ),
          };
        },
      },
      getLastColumn(),
    ];

    return columns;
  };

  const onConfirmEditName = () => {
    setLoading(true);
    editBundleName(
      showEditName.id,
      showEditName.new_name,
      (b) => {
        setLoading(false);
        setBundles(b);
        setShowEditName(null);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const onShowBundle = (bundle) => {
    setLoading(true);
    showBundle(
      bundle.id,
      (b) => {
        setLoading(false);
        setBundles(b);
      },
      (b) => {
        setTimeout(() => window.location.reload(), 2000);
        setLoading(false);
      }
    );
  };

  const onHideBundle = (bundle) => {
    setLoading(true);
    hideBundle(
      bundle.id,
      (b) => {
        setLoading(false);
        setBundles(b);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const onCreateConfirm = () => {
    setLoading(true);
    createBundle(
      newBundle,
      (b) => {
        setLoading(false);
        setBundles(b);
        setNewBundle(null);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const shouldEnableCreateBundle = () => {
    return (
      newBundle &&
      newBundle.display_name?.length > 0 &&
      newBundle.prizes[0].face_value?.length > 0 &&
      newBundle.prizes[1].face_value?.length > 0 &&
      newBundle.prizes[2].face_value?.length > 0 &&
      newBundle.host_price?.length > 0
    );
  };

  return (
    <LoadingWrapper
      isLoading={bundles == null}
      loadingText='Fetching Bundle Data'
    >
      <div style={{ marginTop: '220px' }}>
        <div style={{ marginBottom: '24px' }}>
          <BasicButton
            type='secondary'
            text='+ NEW BUNDLE'
            onClick={() => setNewBundle({ prizes: DEFAULT_PRIZES })}
          />
        </div>
        <Table
          pagination={false}
          columns={getColumns()}
          dataSource={getBundleTableData()}
          rowKey='id'
          rowClassName={styles.unclickable_table}
        />
      </div>

      {showEditName != null && (
        <Modal
          title='Edit Bundle Name'
          visible={setShowEditName != null}
          onCancel={() => setShowEditName(null)}
          footer={null}
        >
          <Form.Item>
            <ShortInput
              inputHeader='Current Name'
              onChange={(val) => {}}
              value={showEditName.name}
              disabled
            />
          </Form.Item>

          <Form.Item>
            <ShortInput
              inputHeader='New Name'
              onChange={(val) =>
                setShowEditName({ ...showEditName, new_name: val })
              }
              value={showEditName.new_name}
            />
          </Form.Item>

          <div
            style={{
              marginTop: '40px',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <BasicButton
              type='default'
              text='Cancel'
              onClick={() => setShowEditName(null)}
              style={{ width: '48%' }}
            />

            <BasicButton
              type='primary'
              text='Submit'
              disabled={
                !(
                  showEditName &&
                  showEditName.new_name &&
                  showEditName.new_name.length > 0
                )
              }
              onClick={() => onConfirmEditName()}
              style={{ width: '48%' }}
              loading={loading}
            />
          </div>
        </Modal>
      )}

      {newBundle != null && (
        <Modal
          title='New Prize Bundle'
          visible={newBundle != null}
          onCancel={() => setNewBundle(null)}
          footer={null}
        >
          <Form.Item>
            <ShortInput
              inputHeader='Bundle Name'
              onChange={(val) =>
                setNewBundle({ ...newBundle, display_name: val })
              }
              value={newBundle.display_name}
            />
          </Form.Item>

          <Form.Item>
            <ShortInput
              inputHeader='1st Place Value'
              onChange={(val) =>
                setNewBundle({
                  ...newBundle,
                  prizes: [
                    {
                      ...newBundle.prizes[0],
                      face_value: `$${val.replace(/[^0-9]+/g, '')}`,
                    },
                    newBundle.prizes[1],
                    newBundle.prizes[2],
                  ],
                })
              }
              value={newBundle.prizes[0].face_value}
            />
          </Form.Item>

          <Form.Item>
            <ShortInput
              inputHeader='2nd Place Value'
              onChange={(val) =>
                setNewBundle({
                  ...newBundle,
                  prizes: [
                    newBundle.prizes[0],
                    {
                      ...newBundle.prizes[1],
                      face_value: `$${val.replace(/[^0-9]+/g, '')}`,
                    },
                    newBundle.prizes[2],
                  ],
                })
              }
              value={newBundle.prizes[1].face_value}
            />
          </Form.Item>

          <Form.Item>
            <ShortInput
              inputHeader='3rd Place Value'
              onChange={(val) =>
                setNewBundle({
                  ...newBundle,
                  prizes: [
                    newBundle.prizes[0],
                    newBundle.prizes[1],
                    {
                      ...newBundle.prizes[2],
                      face_value: `$${val.replace(/[^0-9]+/g, '')}`,
                    },
                  ],
                })
              }
              value={newBundle.prizes[2].face_value}
            />
          </Form.Item>

          <Form.Item>
            <ShortInput
              inputHeader='Host Price'
              onChange={(val) =>
                setNewBundle({
                  ...newBundle,
                  host_price: `$${val.replace(/[^0-9]+/g, '')}`,
                })
              }
              value={newBundle.host_price}
            />
          </Form.Item>

          <div
            style={{
              marginTop: '40px',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <BasicButton
              type='default'
              text='Cancel'
              onClick={() => setNewBundle(null)}
              style={{ width: '48%' }}
            />

            <BasicButton
              type='primary'
              text='Submit'
              disabled={!shouldEnableCreateBundle()}
              onClick={() => onCreateConfirm()}
              style={{ width: '48%' }}
              loading={loading}
            />
          </div>
        </Modal>
      )}
    </LoadingWrapper>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getBundleOfferings,
  editBundleName,
  showBundle,
  createBundle,
  hideBundle,
})(withTheme(BundleHome));
