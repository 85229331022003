import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Space, Modal } from 'antd';
import { withTheme } from 'styled-components';
import {
  CheckSquareOutlined,
  InfoOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import PageWrapper from '@components/PageWrapper';
import ReviewReportHistory from '@review/ReviewReportHistory';
import ReviewReactionVideo from '@review/reactions/ReviewReactionVideo';
import {
  BasicButton,
  RejectButton,
  ApproveButton,
  BasicText,
} from '@components';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import RejectedReasonsModal from '@review/RejectedReasonsModal';
import ReviewStats from '@review/ReviewStats';
import { validateEmail, showSuccessMessage, showErrorMessage } from '@global';
import {
  getReactionToReview,
  getReportedVideoReactionToReview,
  approveReaction,
  rejectReaction,
  handleVideoReactionReport,
} from '@actions';
import styles from './ReviewReactions.module.css';

const ReviewReactions = ({
  getReactionToReview,
  getReportedVideoReactionToReview,
  approveReaction,
  rejectReaction,
  handleVideoReactionReport,
  theme,
  adminUser,
  match,
}) => {
  const history = useHistory();
  const [showRejectedReasons, setShowRejectedReasons] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [rejectLoading, setRejectLoading] = useState(false);
  const [reactionId, setReactionId] = useState(match.params?.reaction_id);
  const [reaction, setReaction] = useState(null);
  const [noneLeft, setNoneLeft] = useState(false);
  const [enableApprove, setEnableApprove] = useState(false);
  const [reactionReviewStats, setReactionReviewStats] = useState(null);
  const [rejectModal, setRejectModal] = useState(null);
  const [skipReactionIds, setSkipReactionIds] = useState([]);
  const scrollRef = useRef();
  const isReporting = history.location.pathname.includes(
    'reported_video_reaction'
  );
  const isViewing = history.location.pathname.includes('/view_reaction');
  const isReview = !isReporting && !isViewing;

  useEffect(() => {
    getNextReaction();
  }, []);

  //if (host) console.log("is charity", host.brand.is_charity);

  console.log(reactionReviewStats);

  const updatePathWithNewReactionId = (reaction_id = null) => {
    let pathname = isReporting
      ? '/reported_video_reactions'
      : '/review_reactions';
    if (reaction_id == null) {
      history.replace({ pathname: pathname });
    } else {
      history.replace({ pathname: `${pathname}/${reaction_id}` });
    }
  };

  const handleNextReaction = (response) => {
    if (response.none_left) {
      setReactionReviewStats(response.reaction_review_stats);
      setReactionId(null);
      setReaction(null);
      updatePathWithNewReactionId(null);
      setNoneLeft(true);
    } else {
      setReactionReviewStats(response.reaction_review_stats);
      setReactionId(response.reaction.id);
      updatePathWithNewReactionId(response.reaction.id);
      setReaction(response.reaction);
    }
  };

  const getNextReaction = (isSkip) => {
    let skips = [];
    if (isSkip) {
      skips = skipReactionIds.concat(reactionId);
      setSkipReactionIds(skips);
    }

    if (isReporting) {
      getReportedVideoReactionToReview(
        reactionId,
        (response) => {
          handleNextReaction(response);
        },
        () => {}
      );
    } else {
      console.log('skipping with id', reactionId, skipReactionIds);
      getReactionToReview(
        isSkip ? null : reactionId,
        skips,
        (response) => {
          handleNextReaction(response);
        },
        () => {}
      );
    }
  };

  const handleReportingResponse = (remove) => {
    handleVideoReactionReport(reactionId, remove, (response) => {
      setApproveLoading(false);
      setRejectLoading(false);
      showSuccessMessage('Your action was processed!');
      if (isViewing) {
        window.location.reload();
      } else {
        handleNextReaction(response);
        setShowRejectedReasons(false);
      }
    });
  };

  const approve = () => {
    if (isReporting || reaction.has_been_reviewed) {
      setApproveLoading(true);
      handleReportingResponse(false);
    } else {
      setApproveLoading(true);
      approveReaction(
        reactionId,
        (response) => {
          setApproveLoading(false);
          showSuccessMessage('Reaction approved!');
          handleNextReaction(response);
          window.location.reload();
        },
        () => {
          setRejectLoading(false);
        }
      );
    }
  };

  const reject = (reason) => {
    if (isReporting || reaction.has_been_reviewed) {
      setRejectLoading(true);
      handleReportingResponse(true);
    } else {
      setRejectLoading(true);
      rejectReaction(
        reactionId,
        reason,
        (response) => {
          setShowRejectedReasons(false);
          showSuccessMessage('Reaction not approved');
          handleNextReaction(response);
          window.location.reload();
        },
        () => {
          setRejectLoading(false);
        }
      );
    }
  };

  const content = () => {
    if (noneLeft) {
      return (
        <div className={styles.none_left_container}>
          <div style={{ marginRight: '40px' }}>
            <CheckSquareOutlined
              style={{ fontSize: '100px', color: theme.colors.blurple }}
            />
          </div>
          All reactions have been reviewed or are in the process of being
          reviewed by another reviewer.
        </div>
      );
    } else if (reaction) {
      return (
        <div>
          <div style={{ marginTop: '20px' }}>
            <BasicText fontSize="13px">
              Submitted on: {reaction.submitted_on} ET
            </BasicText>
            <BasicText useSpan fontSize="13px">
              {'STATUS: '}
              <BasicText bold useSpan fontSize="13px">
                {reaction.status}
              </BasicText>
            </BasicText>
          </div>

          <div className={styles.video_and_history}>
            <ReviewReactionVideo
              reaction={reaction}
              onApprove={() => approve()}
              approveLoading={approveLoading}
              onReject={() => setShowRejectedReasons(true)}
              buttonsText={getButtonsText()}
              showButtons
              reactionReviewStats={reactionReviewStats}
              isReporting={isReporting}
              isViewing={isViewing}
              onSkipReaction={() => {
                getNextReaction(true);
              }}
            />

            <ReviewReportHistory entry={reaction} />
          </div>
        </div>
      );
    }
  };

  const progressStats = () => {
    if (reactionReviewStats) {
      return (
        <div className={styles.review_stats_container}>
          <ReviewStats
            stats={[
              {
                title: 'Pending',
                value: reactionReviewStats.pending,
                showDanger: true,
              },
              {
                title: 'Approved',
                value: reactionReviewStats.approved,
                showDanger: false,
              },
              {
                title: 'Rejected',
                value: reactionReviewStats.rejected,
                showDanger: false,
              },
            ]}
          />
        </div>
      );
    }
  };

  const additionalContent = () => {
    if (noneLeft) return null;

    return (
      <Space size={20}>
        <BasicButton
          text="Content Policy"
          type="secondary"
          onClick={() => window.open('https://hyype.space/community', '_blank')}
        />
      </Space>
    );
  };

  const getButtonsText = () => {
    if (isReview || !reaction.has_been_reviewed) {
      return {
        approve: 'Approve',
        reject: 'Reject',
      };
    } else if (isReporting) {
      return {
        approve: 'Keep Live',
        reject: 'Remove',
      };
    } else {
      return {
        approve: reaction.is_approved ? null : 'Make Live',
        reject: reaction.is_approved ? 'Remove' : null,
      };
    }
  };

  return (
    <PageWrapper>
      <FixedHeaderContainer
        text={
          isViewing
            ? 'Video Reaction'
            : isReporting
            ? 'Reported Reactions'
            : 'Review Reactions'
        }
        paddingBuffer="80px"
        paddingTop="56px"
        useMuseo
        additionalContent={additionalContent()}
      >
        <div className={styles.review_reactions_container}>{content()}</div>
      </FixedHeaderContainer>

      {showRejectedReasons && (
        <RejectedReasonsModal
          reasons={adminUser.submission_rejected_reasons}
          onSelectedReason={(reason) => reject(reason)}
          visible={showRejectedReasons}
          onCancel={() => setShowRejectedReasons(false)}
          rejectLoading={rejectLoading}
          isReport={isReporting}
        />
      )}
    </PageWrapper>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  return { adminUser };
};

export default connect(mapStatesToProps, {
  getReactionToReview,
  getReportedVideoReactionToReview,
  approveReaction,
  rejectReaction,
  handleVideoReactionReport,
})(withTheme(ReviewReactions));
