import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PageWrapper from '@components/PageWrapper';
import LoadingWrapper from '@components/LoadingWrapper';
import FixedHeaderContainer from '@components/FixedHeaderContainer';
import { Button, Modal, Form, Space, Checkbox, Row, Tabs } from 'antd';
const { TabPane } = Tabs;
import { withTheme } from 'styled-components';
import { PlayCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { useHistory, Link } from 'react-router-dom';
import RevenueTableSection from '@payments/RevenueTableSection';
import PayoutsTableSection from '@payments/PayoutsTableSection';
import MemberTableSection from '@payments/MemberTableSection';
import { TitleText, BasicText, BasicButton, MuseoText, FilterTabs } from '@components';
import RevenueHeader from '@payments/RevenueHeader';
import PayoutsHeader from '@payments/PayoutsHeader';
import MemberHeader from '@payments/MemberHeader';
import { getRevenueData } from '@actions';
import { showSuccessMessage, showErrorMessage } from '@global';
import styles from './Payments.module.css';

const FILTER_OPTIONS = ["All time", "YTD", "Past 30d"];
const TABLE_FILTER_OPTIONS = ["Challenges", "Prize Bundles", "Tokens", "Claps"];

const PaymentsHome = ({
  adminUser,
  revenueData,
  getRevenueData,
  theme
}) => {

  const [selectedTab, setSelectedTab] = useState(0);
  const [currentFilter, setCurrentFilter] = useState(FILTER_OPTIONS[2]);
  const [currentTableFilter, setCurrentTableFilter] = useState(TABLE_FILTER_OPTIONS[0]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getRevenueData(
      (data) => {},
      () => {}
    )
  }, []);


  const contentSpecificHeader = () => {
    if (selectedTab === 0 || selectedTab === "0") {
      return (
        <RevenueHeader
          currentFilter={currentFilter}
          setCurrentFilter={(filter) => setCurrentFilter(filter)}
          currentTableFilter={currentTableFilter}
          setCurrentTableFilter={(filter) => setCurrentTableFilter(filter)}
          revenueData={revenueData}
        />
      )
    } else if (selectedTab === 1 || selectedTab === "1") {
      return (
        <PayoutsHeader
          currentFilter={currentFilter}
          setCurrentFilter={(filter) => setCurrentFilter(filter)}
          currentTableFilter={currentTableFilter}
          setCurrentTableFilter={(filter) => setCurrentTableFilter(filter)}
        />
      );
    } else if (selectedTab === 2 || selectedTab === "2") {
      return (
        <MemberHeader
          currentFilter={currentFilter}
          setCurrentFilter={(filter) => setCurrentFilter(filter)}
          currentTableFilter={currentTableFilter}
          setCurrentTableFilter={(filter) => setCurrentTableFilter(filter)}
        />
      );
    }
  }


  const header = () => {
    return (
      <div className={styles.fixed_header}>
        <div className={styles.header_container} style={{ flexDirection: "column", alignItems: "flex-start" }}>
          <div className={styles.header_text}>
            <MuseoText>Payments</MuseoText>
          </div>

          <div className={styles.radio_container}>
            <Tabs defaultActiveKey={selectedTab === 0 || selectedTab === "0" ? "0" : "1"} onChange={(e) => onSelectedOption(e)}>
              {tabSelectorOptions()}
            </Tabs>
          </div>

          {contentSpecificHeader()}
        </div>
      </div>
    )
  }

  const table = () => {
    if (selectedTab === 0 || selectedTab === "0") {
      return (
        <div style={{ marginTop: "480px" }}>
          <RevenueTableSection
            currentFilter={currentTableFilter}
          />
        </div>
      );
    } else if (selectedTab === 1 || selectedTab === "1") {
      return (
        <div style={{ marginTop: "480px" }}>
          <PayoutsTableSection
            currentFilter={currentTableFilter}
          />
        </div>
      );
    } else if (selectedTab === 2 || selectedTab === "2") {
      return (
        <div style={{ marginTop: "480px" }}>
          <MemberTableSection
            currentFilter={currentTableFilter}
          />
        </div>
      );
    }
  }

  const onSelectedOption = (option) => {
    if (option.toString() != selectedTab.toString()) setCurrentFilter(FILTER_OPTIONS[2]);

    switch (option) {
      case "0":
        setCurrentTableFilter("Challenges");
        break;
      case "1":
        setCurrentTableFilter("Host");
        break;
      case "2":
        setCurrentTableFilter(adminUser.payout_current_year);
        break;
      default:
        return null;
    }

    setSelectedTab(option);
  };

  const tabSelectorOptions = () => {
    return ["Purchases", "Payouts", "Member Earnings"].map((stat, index) => (
      <TabPane
        key={index}
        value={index}
        tab={stat}
      />
    ));
  }

  return (
    <PageWrapper>
      <LoadingWrapper isLoading={false} loadingText="Fetching Payment Data" size="large">
        <FixedHeaderContainer hideHeader additionalContent={header()}>
          <div className={styles.home_container}>
            {table()}
          </div>
        </FixedHeaderContainer>
      </LoadingWrapper>
    </PageWrapper>
  );
};

const mapStatesToProps = (state) => {
  const { adminUser } = state.auth;
  const { revenueData } = state.payment;
  return { adminUser, revenueData };
};

export default connect(mapStatesToProps, {
  getRevenueData
})(withTheme(PaymentsHome));
