import React from 'react';
import ScaleLoader from "react-spinners/ScaleLoader";
import { withTheme } from 'styled-components';

const StatsLoader = ({ theme }) => {
  return (
    <ScaleLoader
      size={60}
      width={4}
      color={theme.colors.blurple}
    />
  );
}

export default withTheme(StatsLoader);
